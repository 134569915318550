import { useEffect } from 'react'

const useExpandSelectedRows = ({ tableRef, selectedRowIds, mergedTableData }) => {
  useEffect(() => {
    const clusterIdsWithSelectedOptions = []

    for (const [id, isSelected] of Object.entries(selectedRowIds)) {
      const splittedId = id.split('.')
      const [clusterId] = splittedId
      const isOption = splittedId.length === 2

      if (isOption && isSelected) {
        clusterIdsWithSelectedOptions.push(clusterId)
        tableRef.current.toggleRowExpanded(id, isSelected)
      }
    }
    clusterIdsWithSelectedOptions.forEach((clusterId) =>
      tableRef.current.toggleRowExpanded(clusterId, true),
    )
  }, [mergedTableData, selectedRowIds, tableRef])
}

export default useExpandSelectedRows

// TODO: CWP-2981: Write tests
