import { Text } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import styles from 'routes/deals/financing/shared-components/useStandardMoneyField.module.css'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'

const useStandardMoneyField = ({ name, value, valueHeadquarter, label, validationFunction }) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  return [
    {
      name,
      label,
      value: formatMoney(value?.amount, value?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name,
      label,
      value: value?.amount,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        icon: <Text className={styles.inputPaddingRight}>{value?.currency}</Text>,
        'data-testid': `standardMoneyField-${name}`,
        valueStateMessage: (
          <span>
            {t(
              'pages.deals.tranches.details.interest-rate-hedging-requirement-card.error.non-negative-number',
            )}
          </span>
        ),
      },
      validationFunction: validationFunction ?? isAmountValid,

      isShownInDisplay: false,
      isShownInEdit: true,
    },
    {
      name,
      label,
      customInfoComponent: ConvertedAmount(valueHeadquarter?.amount, valueHeadquarter?.currency),

      isShownInDisplay:
        !!valueHeadquarter && !!value.amount && value.currency !== valueHeadquarter?.currency,
      isShownInEdit: false,
    },
  ]
}

export default useStandardMoneyField
