import { ValueState, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GroupedMultiComboBox from 'components/ui/multi-combo-box/GroupedMultiComboBox'
import useRiskConformityDeviations from 'hooks/services/deals/configurations/useRiskConformityDeviations'

const dealRiskConformityDeviationMultiComboBoxProps = {
  deviationCodes: PropTypes.arrayOf(PropTypes.string),
  businessSegmentCode: PropTypes.string,
  onDeviationsUpdate: PropTypes.func.isRequired,
  onDeprecatedDeviationsUpdate: PropTypes.func.isRequired,
}

const DealRiskConformityDeviationMultiComboBox = ({
  deviationCodes = [],
  businessSegmentCode,
  onDeprecatedDeviationsUpdate = () => {},
  onDeviationsUpdate = (_) => {},
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.risk-conformity.deviations',
  })
  const {
    data: { deviations = [] } = {},
    isLoading,
    isError,
  } = useRiskConformityDeviations(businessSegmentCode)

  const { validDeviations, deprecatedDeviations } = useMemo(
    () =>
      deviations.reduce(
        (result, deviation) => {
          if (deviation.deprecated && deviationCodes.includes(deviation.code)) {
            result.deprecatedDeviations.push(deviation)
          } else if (!deviation.deprecated) {
            result.validDeviations.push(deviation)
          }
          return result
        },
        { validDeviations: [], deprecatedDeviations: [] },
      ),
    [deviations, deviationCodes],
  )
  const groupedOptions = [
    { options: validDeviations },
    { title: t('deprecated-group-title'), options: deprecatedDeviations },
  ]

  useEffect(() => {
    onDeprecatedDeviationsUpdate(deprecatedDeviations)
  }, [deprecatedDeviations, onDeprecatedDeviationsUpdate])

  const updateDeviations = ({ detail: { items = [] } = {} }) => {
    const newDeviations = items.map((item) => item.dataset.value)
    onDeviationsUpdate(newDeviations)
  }

  const { valueState, valueStateMessage } = useMemo(() => {
    if (!deviationCodes.length) {
      return { valueState: ValueState.Error, valueStateMessage: t('deviations-error') }
    }
    return deprecatedDeviations.length
      ? { valueState: ValueState.Warning, valueStateMessage: t('deprecated-warning') }
      : { valueState: ValueState.None }
  }, [deprecatedDeviations.length, deviationCodes.length, t])

  return (
    <GroupedMultiComboBox
      {...props}
      selected={deviationCodes}
      groupedOptions={groupedOptions}
      isLoading={isLoading}
      isError={isError}
      onSelectionChange={updateDeviations}
      valueState={valueState}
      valueStateMessage={valueStateMessage && <Text wrapping>{valueStateMessage}</Text>}
    />
  )
}

DealRiskConformityDeviationMultiComboBox.propTypes = dealRiskConformityDeviationMultiComboBoxProps

export default DealRiskConformityDeviationMultiComboBox
