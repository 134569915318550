import {
  DatePicker,
  Text,
  FlexBoxDirection,
  FlexBox,
  ValueState,
  FlexBoxAlignItems,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import {
  useShortDateFormatter,
  useNumberFormatter,
  useCustomizableCurrencyFormatter,
} from 'hooks/i18n/useI18n'
import { useAmortizationFrequencyTypeCodes } from 'hooks/services/deals/financing/configGetters/useAmortizationFrequencyTypeCodes'
import { useAmortizationTypeCodes } from 'hooks/services/deals/financing/configGetters/useAmortizationTypeCodes'
import styles from 'routes/deals/financing/trancheDetails/cards/amortization-card/useAmortizationColumnDefinitions.module.css'
import { ColumnHideOptions } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/MultiSelectCardTable'
import { SelectAndUnselectAllTableCellEdit } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/SelectAndUnselectAllTableCellEdit'
import { SelectAndUnselectTableCellEdit } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/SelectAndUnselectTableCellEdit'
import {
  StandardCellView,
  StandardCellViewPosition,
} from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/StandardCellView'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'
import isShareValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isShareValid'

const useAmortizationColumnDefinitions = ({
  isRowSelected,
  areAllRowsSelected,
  onSelectChange,
  onSelectAllChange,
  onValueChange,
  trancheCurrency,
}) => {
  const { format: formatDate, parse: parseDate, localePattern } = useShortDateFormatter()
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation()

  const amountPerIntervalInputRef = useRef()
  const ratePerAnnumInputRef = useRef()

  const isAmortizationTypeValid = (value) => !!value
  const isValidFromValid = (value) => !!value

  return [
    {
      columnKey: 'checkboxSelector',
      minWidthDisplay: 0,
      minWidthEdit: 25,
      title: () => (
        <SelectAndUnselectAllTableCellEdit
          isSelected={areAllRowsSelected}
          onSelectChangeAll={onSelectAllChange}
        />
      ),
      cellValueEdit: (dataRow, options) => (
        <SelectAndUnselectTableCellEdit
          isSelectable={options.isSelectable}
          isSelected={isRowSelected(dataRow)}
          onSelectChange={(e) => onSelectChange(dataRow, e)}
        />
      ),
      hideOption: ColumnHideOptions.hideInView,
    },
    {
      columnKey: 'amortizationType',
      key: 'amortizationConditionItemTypeCode',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      needsValidation: true,
      title: () => (
        <StandardCellView
          content={t('pages.deals.tranches.details.amortization-card.amortization-type')}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.amortizationConditionItemTypeShortText}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <FlexBox direction={FlexBoxDirection.Row}>
          <LoadingSelect
            loadingHook={useAmortizationTypeCodes}
            selectionName={'amortizationTypeCodes'}
            selectedKey={item.amortizationConditionItemTypeCode}
            onChange={(event) => {
              const selectedOptionId = event.detail.selectedOption.dataset.id
              onValueChange(
                item.id,
                'amortizationConditionItemTypeCode',
                selectedOptionId,
                isAmortizationTypeValid,
              )
            }}
            isErrorState={!isAmortizationTypeValid(item.amortizationConditionItemTypeCode)}
            valueStateMessage={
              <span>
                {t('pages.deals.tranches.details.amortization-card.amortization-type.hint')}
              </span>
            }
          />
        </FlexBox>
      ),
    },
    {
      columnKey: 'validFrom',
      key: 'validFrom',
      minWidthDisplay: 80,
      minWidthEdit: 190,
      popinText: t('pages.deals.tranches.details.amortization-card.valid-from'),
      needsValidation: true,
      title: () => (
        <StandardCellView
          content={t('pages.deals.tranches.details.amortization-card.valid-from')}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={formatDate(item.validFrom)}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <FlexBox direction={FlexBoxDirection.Row}>
          <DatePicker
            className={styles.validFromDatePicker}
            value={item.validFrom ? formatDate(item.validFrom) : ''}
            formatPattern={localePattern}
            onChange={(event) => {
              const selectedOptionId = parseDate(event.detail.value, localePattern)
              onValueChange(item.id, 'validFrom', selectedOptionId, isValidFromValid)
            }}
            valueState={isValidFromValid(item.validFrom) ? ValueState.None : ValueState.Error}
            valueStateMessage={
              <span>{t('pages.deals.tranches.details.amortization-card.valid-from.hint')}</span>
            }
          />
        </FlexBox>
      ),
    },
    {
      columnKey: 'amortizationFrequency',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.amortization-card.amortization-frequency'),
      title: () => (
        <StandardCellView
          content={t('pages.deals.tranches.details.amortization-card.amortization-frequency')}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.frequencyShortText}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <LoadingSelect
          loadingHook={useAmortizationFrequencyTypeCodes}
          selectionName={'amortizationFrequencyTypeCodes'}
          selectedKey={item.frequency}
          onChange={(event) => {
            const selectedOptionId = event.detail.selectedOption.dataset.id
            onValueChange(item.id, 'frequency', selectedOptionId)
          }}
        />
      ),
    },
    {
      columnKey: 'amortizationAmount',
      minWidthDisplay: 140,
      minWidthEdit: 200,
      popinText: t('pages.deals.tranches.details.amortization-card.amortization-amount'),
      title: () => (
        <StandardCellView
          content={t('pages.deals.tranches.details.amortization-card.amortization-amount')}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValue: (item) => (
        <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.End}>
          <Text className={styles.paddingTop}>
            {formatMoney(
              item.amountPerInterval,
              item.amountPerIntervalCurrency ?? trancheCurrency,
              {
                currencyDisplay: 'code',
              },
            )}
          </Text>
          {item.amountPerIntervalHeadquarterCurrency !== item.amountPerIntervalCurrency && (
            <div className={styles.subInfo} data-testid="amortization-amountPerIntervalHeadquarter">
              {formatMoney(
                item.amountPerIntervalHeadquarter,
                item.amountPerIntervalHeadquarterCurrency,
                {
                  currencyDisplay: 'code',
                },
              )}
            </div>
          )}
        </FlexBox>
      ),
      cellValueEdit: (item) => (
        <FormattedNumberInput
          value={convertToNumberIfValid(item.amountPerInterval)}
          valueState={isAmountValid(item.amountPerInterval) ? ValueState.None : ValueState.Error}
          valueStateMessage={
            <span>{t('pages.deals.tranches.details.fees-card.hint.amount-total')}</span>
          }
          icon={
            <Text className={styles.inputPaddingRight}>
              {item.amountPerIntervalCurrency ?? trancheCurrency}
            </Text>
          }
          iconEnd="true"
          onInput={(number) => {
            const isInternallyValid = amountPerIntervalInputRef?.current?.isValid
            const validationFunction = (value) => isInternallyValid && isAmountValid(value)
            onValueChange(
              item.id,
              'amountPerInterval',
              number ? Number(number) : number,
              validationFunction,
            )
          }}
          ref={amountPerIntervalInputRef}
        />
      ),
    },
    {
      columnKey: 'amortizationRate',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.amortization-card.amortization-rate'),
      title: () => (
        <StandardCellView
          content={t('pages.deals.tranches.details.amortization-card.amortization-rate')}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={!isNil(item.ratePerAnnum) ? formatShare(item.ratePerAnnum / 100) : ''}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <FormattedNumberInput
          value={convertToNumberIfValid(item.ratePerAnnum)}
          valueState={isShareValid(item.ratePerAnnum) ? ValueState.None : ValueState.Error}
          valueStateMessage={
            <span>{t('pages.deals.tranches.details.fees-card.hint.amount-share')}</span>
          }
          icon={<Text>{'%'}</Text>}
          iconEnd="true"
          onInput={(number) => {
            const isInternallyValid = ratePerAnnumInputRef?.current?.isValid
            const validationFunction = (value) => isInternallyValid && isShareValid(value)
            onValueChange(
              item.id,
              'ratePerAnnum',
              number ? Number(number) : number,
              validationFunction,
            )
          }}
          ref={ratePerAnnumInputRef}
        />
      ),
    },
  ]
}

export default useAmortizationColumnDefinitions
