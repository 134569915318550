import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useBusinessPartnerRoleAssign = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ businessPartnerId, roleId }) => {
      if (!businessPartnerId || !roleId) return
      const queryParams = new URLSearchParams({ role_id: roleId })
      await post({
        path: `/businesspartners/${businessPartnerId}/roles?${queryParams}`,
      })
    },
    { onSuccess, onError },
  )
}

export default useBusinessPartnerRoleAssign
