import isEmpty from 'lodash.isempty'
import omit from 'lodash.omit'
import mapDerivativeTradesData from 'components/domains/business-partners/tile/financial-product/derivatives/mapDerivativeTradesData'

const mapDerivativesData = (trades = []) => {
  if (!isEmpty(trades)) {
    const mappedRowData = mapDerivativeTradesData(trades)

    return mappedRowData.map((row) => ({
      ...omit(
        {
          ...row,
          subRows: row?.subRows?.map((subRow) => ({
            ...omit(subRow, ['dealTradeCondition']),
            tradeCondition: subRow?.dealTradeCondition,
          })),
        },
        ['dealTradeCondition'],
      ),
      tradeCondition: row?.dealTradeCondition,
    }))
  }
}

export default mapDerivativesData
