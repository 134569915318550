import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateCustomerOptions = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    // isLastDTO is needed to now if calls will follow and no toast is necessary
    // optionTypeCodeShortText is needed for a useful error message
    // eslint-disable-next-line no-unused-vars
    async ({ dealId, trancheId, optionId, optionTypeCodeShortText, isLastDTO, ...body }) => {
      await put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/options/${optionId}`,
        body: snakecaseKeys(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateCustomerOptions
