import snakecaseKeys from 'snakecase-keys'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { CUSTOM_FIELD_TYPES } from 'routes/deals/financing/financingConstants'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

const useMapCustomerSpecificAttributeChanges = () => {
  const { parse: parseDate, localePattern } = useShortDateFormatter()

  const result = {}

  return (newValues, oldValues) => {
    for (const [key, value] of Object.entries(newValues)) {
      const fieldType = oldValues[key].type

      switch (fieldType) {
        case CUSTOM_FIELD_TYPES.DATE: {
          result[key] = {
            type: fieldType,
            value: parseDate(value, localePattern),
          }
          break
        }
        case CUSTOM_FIELD_TYPES.BOOLEAN:
          {
            result[key] = {
              type: fieldType,
              value: Boolean(value),
            }
          }
          break
        case CUSTOM_FIELD_TYPES.PERCENTAGE: {
          result[key] = {
            type: fieldType,
            value: value ? convertPercentageToDecimal(value) : null,
          }
          break
        }
        case CUSTOM_FIELD_TYPES.STRING: {
          result[key] = {
            type: fieldType,
            value,
          }
          break
        }
        case CUSTOM_FIELD_TYPES.NUMBER: {
          result[key] = {
            type: fieldType,
            value,
          }
          break
        }
        case CUSTOM_FIELD_TYPES.CURRENCY: {
          result[key] = {
            type: fieldType,
            value: value?.amount,
            currencyCode: value.amount ? value.currency || 'EUR' : null,
          }
          break
        }
        case CUSTOM_FIELD_TYPES.ENUM: {
          result[key] = {
            type: fieldType,
            selectedTypeCode: value,
          }
          break
        }
      }
    }

    for (const [key, value] of Object.entries(result)) {
      result[key] = snakecaseKeys(value)
    }

    return result
  }
}

export default useMapCustomerSpecificAttributeChanges
