import { Button, ButtonDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const propTypes = {
  onClick: PropTypes.func.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const TrancheCreateButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Button id="createTrancheButton" design={ButtonDesign.Transparent} onClick={() => onClick()}>
      {t('pages.deals.role.tranches-table.create-button')}
    </Button>
  )
}
TrancheCreateButton.propTypes = propTypes

export default TrancheCreateButton
