import {
  Button,
  ButtonDesign,
  FlexBox,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes, { string } from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import styles from 'components/domains/deals/pricing/DealPricingHeaderActions.module.css'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { ConfigContext } from 'hooks/config/useConfig'
import { useCalculatePricing } from 'hooks/services/deals/pricing/useCalculatePricing'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'
import { DealContext } from 'routes/deals/DealContext'
import { stringTemplate } from 'utils/stringTemplate'

export const DealPricingHeaderActions = ({
  id,
  className,
  slot,
  isAllowedUpdatingPricing = true,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.pricing' })
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()

  const { deal } = useContext(DealContext)
  const { data: { meta } = {} } = useGetPricing({ dealUuid: deal.dealUuid })
  const config = useContext(ConfigContext)

  const canOpenedInPricingTool = !!meta?.calculation_id
  const pricingToolLink = useMemo(
    () =>
      stringTemplate(
        config?.deals ? config.deals.linktopricingtool : '',
        {
          pricingToolReferenceId: meta?.calculation_id ?? '',
        },
        [meta?.calculation_id],
      ),
    [config.deals, meta?.calculation_id],
  )

  const onCalculatePricingSuccess = () => {
    showToast({ children: t('calculate.success') })
  }
  const onCalculatePricingError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('calculate.error'), error: errorResponse })
  }

  const calculatePricing = useCalculatePricing({
    onSuccess: onCalculatePricingSuccess,
    onError: onCalculatePricingError,
  })

  const triggerPricingCalculation = () => {
    calculatePricing.mutate(deal.dealUuid)
  }

  const onCloseOpenInPricingToolMessage = ({ detail: { action } = {} } = {}) => {
    if (action === MessageBoxActions.OK) {
      window.open(pricingToolLink, '_blank')
    }
  }

  const openInPricingTool = () => {
    showMessageBox({
      children: t('pricing-tool.message'),
      type: MessageBoxTypes.Information,
      actions: [MessageBoxActions.OK, MessageBoxActions.Cancel],
      onClose: onCloseOpenInPricingToolMessage,
    })
  }

  return (
    <FlexBox id={id} className={`${styles.container} ${className}`} fitContainer slot={slot}>
      {isAllowedUpdatingPricing && (
        <Button
          key={'calculate'}
          design={ButtonDesign.Emphasized}
          onClick={triggerPricingCalculation}
        >
          {t('calculate')}
        </Button>
      )}
      {canOpenedInPricingTool && (
        <Button key={'pricing-tool'} onClick={openInPricingTool}>
          {t('pricing-tool')}
        </Button>
      )}
    </FlexBox>
  )
}

DealPricingHeaderActions.propTypes = {
  id: string,
  className: string,
  slot: PropTypes.any,
  isAllowedUpdatingPricing: PropTypes.bool,
}
