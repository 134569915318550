const mapAllOptionsForUpdate = ({ mergedTableData, dealId, trancheId }) => {
  const allOptions = []

  mergedTableData.forEach((cluster) => {
    allOptions.push(...cluster.subRows)
  })

  return allOptions.map((option, index) => ({
    trancheId,
    dealId,
    shouldApplyToAllTranches: true,
    isLastDTO: index === allOptions.length - 1,

    optionId: option.id,
    isActive: option.isActive,
    optionTypeCodeShortText: option.clusterOptionAttribute,
    attributes: option.subRows.map((attribute) => ({
      optionAttributeCode: attribute.optionAttributeCode,
      value: attribute.value,
    })),
  }))
}

const applyChangesToAllTranches = ({ mergedTableData, saveCustomerOption, dealId, trancheId }) => {
  const optionsForUpdate = mapAllOptionsForUpdate({ mergedTableData, dealId, trancheId })

  optionsForUpdate.forEach((mappedOption) => {
    saveCustomerOption(mappedOption)
  })
}

export default applyChangesToAllTranches
