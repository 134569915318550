import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCalculatePricing = (options) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async (dealUuid) =>
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/pricing`,
      }),
    options,
  )
}
