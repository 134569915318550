import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateBasicAgreement = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ deal, trancheIds }) => {
      const { data } = await post({
        path: `/financing-structures/deals/${deal?.dealUuid}/tranches/basic-agreements`,
        body: trancheIds,
      })
      return data
    },
    { onSuccess, onError },
  )
}
