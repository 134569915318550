import { FlexBox, FlexBoxAlignItems, Text } from '@fioneer/ui5-webcomponents-react'
import styles from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/renderDisplayModeCell.module.css'
import {
  CUSTOMER_OPTION_ROW_TYPES,
  OPTION_ATTRIBUTE_DATA_TYPES,
} from 'routes/deals/financing/trancheDetails/cards/customer-options-card/customerOptionsConstants'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const renderDisplayModeCell = (props, formatDate, formatNumber) => {
  const { cell, row } = props

  const columnKey = cell.column.id
  const renderClusterOptionAttributeCell = () => {
    switch (row.original.rowType) {
      case CUSTOMER_OPTION_ROW_TYPES.CLUSTER: {
        return <Text className={styles.textBold}>{cell.value}</Text>
      }
      case CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE: {
        if (row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.TEXT) {
          return (
            <FlexBox alignItems={FlexBoxAlignItems.Start} className={styles.textCell}>
              <Text>{cell.value}</Text>
            </FlexBox>
          )
        }
        return <Text>{cell.value}</Text>
      }
      default:
        return <Text>{cell.value}</Text>
    }
  }

  const renderValueCell = () => {
    if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.DATE
    ) {
      return <Text>{formatDate(cell.value)}</Text>
    } else if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.ENUM
    ) {
      return <Text>{cell.row.original.range[cell.value]}</Text>
    } else if (
      row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE &&
      row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.FLOAT
    ) {
      const value = cell.value ? formatNumber(convertToNumberIfValid(cell.value)) : cell.value
      return <Text>{value}</Text>
    }

    return <Text>{cell.value}</Text>
  }

  switch (columnKey) {
    case 'clusterOptionAttribute': {
      return renderClusterOptionAttributeCell()
    }
    case 'value': {
      return renderValueCell()
    }
  }
}

export default renderDisplayModeCell
