import { Link, MessageStrip, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import paths from 'routes/paths'

const DealSyndicationInfoMessageStrip = ({ pageData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { deal } = pageData

  const pathToOverview = `/${paths.deals}/${deal.displayId}/syndication/`
  const navigateToOverview = useCallback(
    (linkEvent) => {
      linkEvent.preventDefault()
      navigate(pathToOverview)
    },
    [navigate, pathToOverview],
  )

  return (
    <MessageStrip design={MessageStripDesign.Information} hideCloseButton>
      {`${t('pages.deals.financing.message-strip.syndication-information.description')} `}
      <Link onClick={navigateToOverview} href={pathToOverview}>
        {t('pages.deals.financing.message-strip.syndication-information.link')}
      </Link>
    </MessageStrip>
  )
}

DealSyndicationInfoMessageStrip.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      displayId: PropTypes.string,
    }),
  }),
}

export default DealSyndicationInfoMessageStrip
