import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import { DEAL_STATUS_RANKS, DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import NewAndExistingBusinessSwitch from 'routes/deals/financing/trancheDetails/financing-header/NewAndExistingBusinessSwitch'

const DealFinancingOverview = () => {
  const { t } = useTranslation()

  const dealContext = useContext(DealContext)
  const { deal: { dealUuid, workingVersion } = {} } = dealContext

  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()
  const { data: { rank } = {} } = useDealMini(dealUuid)

  const [searchParams] = useSearchParams()

  const breadcrumb = { text: t('pages.deals.breadcrumbs.financing') }

  const shouldShowNewAndExistingBusinessSwitch =
    rank >= DEAL_STATUS_RANKS.SIGNED && rank < DEAL_STATUS_RANKS.DEAD

  const newAndExistingBusinessSwitch = useMemo(
    () =>
      shouldShowNewAndExistingBusinessSwitch && (
        <NewAndExistingBusinessSwitch key="new-and-existing-business-switch" />
      ),
    [shouldShowNewAndExistingBusinessSwitch],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [newAndExistingBusinessSwitch],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      manageWorkingVersionButton,
      markFavoriteAction,
      newAndExistingBusinessSwitch,
      toggleWorkingVersionButton,
    ],
  )

  const isNewBusinessDeal = searchParams.get('dataSource') === DATA_SOURCES.NEW_BUSINESS

  const isSimplifiedView = useMemo(
    () => isNewBusinessDeal && workingVersion === WorkingVersionType.LIVE,
    [isNewBusinessDeal, workingVersion],
  )

  const isComplexView = useMemo(() => !isSimplifiedView, [isSimplifiedView])

  return (
    <ConfigurableComponentsPage
      pageData={{
        ...dealContext,
        isSimplifiedView,
        isComplexView,
      }}
      pageCode={availableDynamicPagesEnum.DealsFinancing}
      renderContent={({ children, pageConfig }) => (
        <DealDetailsWrapper
          isTabPage={isComplexView}
          actions={headerActions}
          additionalProperties={{
            ...(isComplexView && {
              includeUrlQuery: true,
              defaultSectionId: pageConfig.defaultTab,
            }),
          }}
          childBreadcrumbs={breadcrumb}
        >
          {children}
        </DealDetailsWrapper>
      )}
    />
  )
}

export default DealFinancingOverview
