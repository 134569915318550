import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import BusinessPartnerLink from 'components/domains/business-events-and-tasks/BusinessPartnerLink'
import BpsCell from 'components/ui/tables/cells/BpsCell'
import DateCell from 'components/ui/tables/cells/DateCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import PercentageCell from 'components/ui/tables/cells/PercentageCell'
import TextCell from 'components/ui/tables/cells/TextCell'
import {
  ROW_TYPE_TRADE,
  ROW_TYPE_CONDITION,
} from 'routes/deals/financing/non-loan-products/other-lending/constants'

const renderCell = (cell, row) => {
  const columnKey = cell.column.id

  const renderEntityCell = () => {
    const { value: { id } = {} } = cell ?? {}

    if (isNil(cell.value || isNil(id))) {
      // case necessary, because Entity cells return "-" for empty/undefined values
      return <></>
    }
    switch (row.original?.rowType) {
      case ROW_TYPE_TRADE:
      case ROW_TYPE_CONDITION:
        return (
          <EntityCell
            id={id}
            options={{
              // eslint-disable-next-line no-magic-numbers
              overflowMarginTreeLevel: row.original?.rowType === ROW_TYPE_CONDITION ? 3 : 2,
            }}
          />
        )
    }
  }

  const renderRateCell = () => {
    const currentRate = cell.value
    if (!isNil(currentRate)) {
      return (
        <PercentageCell
          value={[currentRate]}
          options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }}
        />
      )
    }
  }

  const renderMoneyCell = () => {
    const { original, headquarter } = cell.value ?? {}
    return <MoneyCell primary={original} secondary={headquarter} />
  }

  switch (columnKey) {
    case 'tradeCondition':
      return renderEntityCell()
    case 'counterparty':
      return !isNil(cell.value) ? (
        <BusinessPartnerLink
          businessPartnerId={cell.value}
          CustomComponent={EntityCell}
          customComponentProperties={{
            options: {
              openInNewTab: false,
            },
          }}
        />
      ) : (
        <></>
      )
    case 'mtm':
    case 'initialNotional':
    case 'currentNotional':
      return renderMoneyCell()
    case 'startDate':
    case 'maturityDate':
    case 'endOfFixedInterestPeriod':
      return <DateCell date={cell.value} />
    case 'spread':
      return (
        <BpsCell
          value={cell.value}
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      )
    case 'currentRate':
      return renderRateCell()
    default:
      return <TextCell text={cell.value} />
  }
}

const OtherLendingTableCell = ({ cell, row }) => renderCell(cell, row)

OtherLendingTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

export default OtherLendingTableCell
