import { ValueState as UI5ValueState } from '@fioneer/ui5-webcomponents-react'

const ValueState = Object.freeze({
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
  INFORMATION: 'INFORMATION',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
})

export default ValueState

/** @param {string?} [valueState] */
export const toUI5ValueState = (valueState) => {
  switch (valueState) {
    case undefined:
    case null:
      return valueState
    case ValueState.SUCCESS:
      return UI5ValueState.Success
    case ValueState.INFORMATION:
      return UI5ValueState.Information
    case ValueState.WARNING:
      return UI5ValueState.Warning
    case ValueState.ERROR:
      return UI5ValueState.Error
    case ValueState.NONE:
    default:
      return UI5ValueState.None
  }
}
