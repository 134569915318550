import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FINANCING_TRANCHE_PRICING_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DealPricingCustomModeStripes from 'components/domains/deals/pricing/DealPricingCustomModeStripes'
import { DealPricingHeaderActions } from 'components/domains/deals/pricing/DealPricingHeaderActions'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import useTranchePricing from 'hooks/services/deals/financing/useTranchePricing'
import { DealContext } from 'routes/deals/DealContext'
import TranchePricingAdditionalInformationPanel from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingAdditionalInformationPanel'
import TranchePricingGeneralInformationPanel from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingGeneralInformationPanel'
import TranchePricingStatusInformationPanel from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingStatusInformationPanel'

const TranchePricingCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.pricing-card',
  })
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const { trancheDisplayId } = useParams()
  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const {
    data: pricingData,
    isLoading,
    isError,
  } = useTranchePricing(deal.dealUuid, resolvedTrancheData.trancheId)

  const { lastEditedText } = useLastEditedTextByEmail({
    email: pricingData?.lastUpdated?.name,
    timestamp: pricingData?.lastUpdated?.lastUpdatedOn,
  })

  const {
    customFees = {},
    customRepayment = {},
    customSyndication = {},
  } = pricingData?.customFields || {}

  return (
    <Card
      header={
        <CardHeaderWithButtons title={t('title')} subTitle={lastEditedText}>
          <DealPricingHeaderActions
            isAllowedUpdatingPricing={allowedOperations.includes(FINANCING_TRANCHE_PRICING_UPDATE)}
          />
        </CardHeaderWithButtons>
      }
    >
      <LoadingStateWrapper
        isError={isError}
        isLoading={isLoading}
        renderContent={() => (
          <>
            <DealPricingCustomModeStripes
              customSyndication={{
                text: customSyndication.categoryShortText,
                isVisible: customSyndication.value,
              }}
              customRepayment={{
                text: customRepayment.categoryShortText,
                isVisible: customRepayment.value,
              }}
              customFees={{ text: customFees.categoryShortText, isVisible: customFees.value }}
            />
            <TranchePricingGeneralInformationPanel pricingData={pricingData} />
            <TranchePricingAdditionalInformationPanel pricingData={pricingData} />
            <TranchePricingStatusInformationPanel customFields={pricingData?.customFields} />
          </>
        )}
      />
    </Card>
  )
}

export default TranchePricingCard
