import {
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/limit-check/consolidation-points/DealConsolidationPointsTable.module.css'
import EmptyCardContent from 'components/ui/card/EmptyCardContent'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

const PLACE_HOLDER = '-'

const getMoneyPrefix = (value) => {
  switch (Math.sign(value.number)) {
    case 1:
      return { symbol: '+', design: styles.greenText }
    case -1:
      return { symbol: '-', design: styles.redText }
    case 0:
      return { symbol: '+/-', design: styles.neutralText }
  }
}

const Item = ({ text }) => (text ? <Text wrapping={true}>{text}</Text> : PLACE_HOLDER)
Item.propTypes = {
  text: PropTypes.string,
}

const LabelItem = ({ label, value, moneyFormatter, textRight = false, isPrefix = false }) => (
  <FlexBox
    justifyContent={textRight ? FlexBoxJustifyContent.End : FlexBoxJustifyContent.Start}
    className={styles.labelItem}
  >
    <Label showColon className={styles.label}>
      {label}
    </Label>
    {!!value && typeof value === 'object' ? (
      <span data-label={label} className={isPrefix ? getMoneyPrefix(value).design : ''}>
        {isPrefix
          ? `${getMoneyPrefix(value).symbol} ${moneyFormatter(value)}`
          : moneyFormatter(value)}
      </span>
    ) : (
      <Item text={value} />
    )}
  </FlexBox>
)
LabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ number: PropTypes.number, currencyCode: PropTypes.string }),
  ]),
  moneyFormatter: PropTypes.func,
  textRight: PropTypes.bool,
  isPrefix: PropTypes.bool,
}

const DealConsolidationPointsTable = ({ isLimitCheckSupported, consolidationPoints }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.consolidation-points-card.table',
  })

  const columnDefinitions = [
    {
      columnKey: 'businessSegment',
      title: t('column.business-segment'),
      demandPopin: false,
    },
    {
      columnKey: 'mainPropertyType',
      title: t('column.main-property-type'),
      demandPopin: true,
      popinText: t('column.main-property-type'),
      minWidth: 500,
    },
    {
      columnKey: 'creditLimitType',
      title: t('column.credit-limit-type'),
      demandPopin: true,
      popinText: t('column.credit-limit-type'),
      minWidth: 500,
    },
    {
      columnKey: 'exposureAmount',
      title: t('column.exposure-amount'),
      className: styles.textRightColumn,
      demandPopin: true,
      popinText: t('column.exposure-amount'),
      minWidth: 500,
    },
    {
      columnKey: 'comment',
      title: t('column.comment'),
      demandPopin: true,
      popinText: t('column.comment'),
      minWidth: 900,
    },
  ]

  const columns = columnDefinitions.map((columnDef) => (
    <TableColumn
      key={columnDef.columnKey}
      className={columnDef?.className}
      demandPopin={columnDef.demandPopin}
      popinText={columnDef.popinText}
      minWidth={columnDef.minWidth}
    >
      {columnDef.title}
    </TableColumn>
  ))

  const formatMoney = useCustomizableCurrencyFormatter()
  const moneyFormatter = (amount) =>
    !!amount && typeof amount?.number === 'number'
      ? formatMoney(Math.abs(amount.number), amount.currencyCode)
      : PLACE_HOLDER

  const isDataAvailable = !!consolidationPoints.length

  return isLimitCheckSupported && isDataAvailable ? (
    <Table columns={columns} className={`${styles.tableColumn} ${styles.table}`}>
      {consolidationPoints.map(
        ({
          consolidationPointUuid,
          businessSegmentCode,
          externalProductTypeSet,
          externalMainPropertyTypeSet,
          countryCode,
          creditLimitType,
          consolidationPointAmount,
          exposureAmount,
          reservedAmount,
          remainingAmount,
          limitComment,
        }) => (
          <TableRow key={consolidationPointUuid}>
            <TableCell data-column="business-segment">
              <div className={styles.multilineCell}>
                <Item text={businessSegmentCode} />
                <LabelItem label={t('label.product-set')} value={externalProductTypeSet} />
              </div>
            </TableCell>
            <TableCell data-column="main-property-type">
              <div className={styles.multilineCell}>
                <Item text={externalMainPropertyTypeSet} />
                <LabelItem label={t('label.country')} value={countryCode} />
              </div>
            </TableCell>
            <TableCell data-column="credit-limit-type">
              <div className={styles.multilineCell}>
                <Item text={creditLimitType} />
                <LabelItem
                  label={t('label.credit-limit-amount')}
                  value={consolidationPointAmount}
                  moneyFormatter={moneyFormatter}
                />
              </div>
            </TableCell>
            <TableCell data-column="exposure-amount">
              <div className={`${styles.textRight} ${styles.multilineCell}`}>
                <Item text={moneyFormatter(exposureAmount)} />
                <LabelItem
                  label={t('label.reserved-amount')}
                  value={reservedAmount}
                  moneyFormatter={moneyFormatter}
                  textRight={true}
                />
                <LabelItem
                  label={t('label.remaining-amount')}
                  value={remainingAmount}
                  moneyFormatter={moneyFormatter}
                  textRight={true}
                  isPrefix={true}
                />
              </div>
            </TableCell>
            <TableCell data-column="comment">
              <Item text={limitComment} />
            </TableCell>
          </TableRow>
        ),
      )}
    </Table>
  ) : (
    <EmptyCardContent
      title={!isLimitCheckSupported ? t('not-supported.title') : t('no-data.title')}
      subtitle={!isLimitCheckSupported ? t('not-supported.subtitle') : t('no-data.subtitle')}
    />
  )
}

DealConsolidationPointsTable.propTypes = {
  isLimitCheckSupported: PropTypes.bool.isRequired,
  consolidationPoints: PropTypes.arrayOf(
    PropTypes.shape({
      consolidationPointUuid: PropTypes.string.isRequired,
      businessSegmentCode: PropTypes.string,
      externalProductTypeSet: PropTypes.string,
      externalMainPropertyTypeSet: PropTypes.string,
      countryCode: PropTypes.string,
      creditLimitType: PropTypes.string,
      consolidationPointAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      exposureAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      reservedAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      remainingAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      limitComment: PropTypes.string,
      externalId: PropTypes.string,
      legalEntity: PropTypes.string,
      previousExposureAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      previousRemainingAmount: PropTypes.shape({
        number: PropTypes.number,
        currencyCode: PropTypes.string,
      }),
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
    }),
  ).isRequired,
}

export default DealConsolidationPointsTable
