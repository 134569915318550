import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateCustomerSpecificAttributes = (mutationOptions) => {
  const { patch } = useAccessTokenRequest()

  return useMutation(async ({ dealId, trancheId, body }) => {
    await patch({
      path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/custom-fields`,
      body: {
        custom_field_values: body,
      },
    })
  }, mutationOptions)
}

export default useUpdateCustomerSpecificAttributes
