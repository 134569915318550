import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import useCustomerSpecificAttributes from 'hooks/services/deals/financing/customer-specific-attributes/useCustomerSpecificAttributes'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import { DealContext } from 'routes/deals/DealContext'
import CustomerSpecificAttributesCard from 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/CustomerSpecificAttributesCard'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const CustomerSpecificAttributesCardLoader = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.customer-specific-attributes-card',
  })

  const { deal } = useContext(DealContext)

  const { isExistingBusinessViewSet } = useDataSourceQueryParams()

  const { trancheDisplayId } = useParams()
  const { data: resolvedTrancheData, isLoading: isLoadingTrancheData } = useTrancheByDisplayId(
    deal.dealUuid,
    trancheDisplayId,
  )
  const {
    data: customerSpecificAttributesData,
    isLoading: isLoadingCustomerAttributesData,
    isError,
    isFetching,
  } = useCustomerSpecificAttributes(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const { lastEditedText } = useLastEditedTextByEmail({
    email: customerSpecificAttributesData?.lastUpdated?.name,
    timestamp: customerSpecificAttributesData?.lastUpdated?.lastUpdatedOn,
  })

  const title = t(`title`)

  const isLoading = isLoadingCustomerAttributesData || isLoadingTrancheData

  if (isLoading || isError) {
    return (
      <DisplayAndEditCard
        cardHeaderTitle={title}
        cardHeaderSubtitle={lastEditedText}
        fieldDefinitions={[
          {
            name: 'loading',
            isShownInDisplay: true,
            isShownInEdit: false,
          },
        ]}
        isEmpty={false}
        isEditable={false}
        isLoading={isLoading}
        isError={isError}
        saveChanges={() => {}}
      />
    )
  }

  return (
    <CustomerSpecificAttributesCard
      title={title}
      customerSpecificAttributesData={customerSpecificAttributesData}
      lastEditedText={lastEditedText}
      dealId={deal.dealUuid}
      trancheId={resolvedTrancheData.trancheId}
      isFetching={isFetching}
    />
  )
}

export default CustomerSpecificAttributesCardLoader
