import {
  MultiComboBoxGroupItem,
  MultiComboBoxItem,
  MultiComboBox,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const groupedMultiComboBoxProps = {
  id: PropTypes.string,
  groupedOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
      }),
      PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.object),
      }),
    ]),
  ).isRequired,
  optionKeyName: PropTypes.string,
  optionDisplayName: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  initialValue: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}

const GroupedMultiComboBox = ({
  id,
  groupedOptions = [],
  optionKeyName = 'code',
  optionDisplayName = 'name',
  isLoading = false,
  isError = false,
  initialValue,
  selected = [],
  onSelectionChange = () => {},
  ...props
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.loading-multi-combo-box' })

  const renderOptions = useCallback(
    (groupOptions) =>
      groupOptions.map((option) => (
        <MultiComboBoxItem
          key={option[optionKeyName]}
          data-value={option[optionKeyName]}
          selected={selected.includes(option[optionKeyName])}
          text={option[optionDisplayName]}
        >
          {option[optionDisplayName]}
        </MultiComboBoxItem>
      )),
    [optionDisplayName, optionKeyName, selected],
  )

  const options = useMemo(() => {
    if (isLoading) {
      return [<MultiComboBoxItem key="loading" text={t('loading')} />]
    }
    if (isError) {
      return [<MultiComboBoxItem key="error" text={t('error')} />]
    }
    return groupedOptions.reduce((result, { title, options: groupOptions }) => {
      if (title && groupOptions.length) {
        result.push(<MultiComboBoxGroupItem key={title} text={title} />)
      }
      result.push(renderOptions(groupOptions))
      return result
    }, [])
  }, [isLoading, isError, groupedOptions, t, renderOptions])

  if (isLoading || isError || initialValue !== undefined) {
    options.unshift(
      <MultiComboBoxItem key="initialSelect" data-value={initialValue}>
        {initialValue}
      </MultiComboBoxItem>,
    )
  }

  return (
    <MultiComboBox id={id} onSelectionChange={onSelectionChange} {...props}>
      {options}
    </MultiComboBox>
  )
}

GroupedMultiComboBox.propTypes = groupedMultiComboBoxProps

export default GroupedMultiComboBox
