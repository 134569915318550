import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_ACCOUNTING_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import useAccounting from 'hooks/services/deals/financing/useAccounting'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import useUpdateAccounting from 'hooks/services/deals/financing/useUpdateAccounting'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import useStandardShareField from 'routes/deals/financing/shared-components/useStandardShareField'
import useBenchmarkTestResultFields from 'routes/deals/financing/trancheDetails/cards/accounting-card/fields/useBenchmarkTestResultFields'
import useBusinessModelCriterionFields from 'routes/deals/financing/trancheDetails/cards/accounting-card/fields/useBusinessModelCriterionFields'
import useContractualCashFlowCriterionFields from 'routes/deals/financing/trancheDetails/cards/accounting-card/fields/useContractualCashFlowCriterionFields'
import useValuationClassFields from 'routes/deals/financing/trancheDetails/cards/accounting-card/fields/useValuationClassFields'

const AccountingCard = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.accounting-card',
  })

  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const { trancheDisplayId } = useParams()
  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)
  const {
    data: accountingData,
    isLoading,
    isError,
    isFetching,
  } = useAccounting(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const {
    mutate: updateAccounting,
    isSuccess: isSuccessUpdateAccounting,
    isError: isErrorUpdateAccounting,
  } = useUpdateAccounting({
    onSuccess: () => {
      setIsWritingToBackend(false)
      queryClient.invalidateQueries([
        'deals',
        deal.dealUuid,
        'tranches',
        resolvedTrancheData.trancheId,
        'accounting',
      ])
      showToast({ children: tNoPrefix('toast.changes-saved') })
    },
    onError: () => {
      setIsWritingToBackend(false)
      showToast({ children: t('toast-message.error') })
    },
  })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: accountingData?.lastUpdated?.name,
    timestamp: accountingData?.lastUpdated?.lastUpdatedOn,
  })

  const businessModelCriterionFields = useBusinessModelCriterionFields({
    value: accountingData?.businessModelCriterionShortText,
    code: accountingData?.businessModelCriterionTypeCode,
  })

  const benchmarkTestResultFields = useBenchmarkTestResultFields({
    value: accountingData?.benchmarkTestResultShortText,
    code: accountingData?.benchmarkTestResultTypeCode,
  })

  const contractualCashFlowCriterionFields = useContractualCashFlowCriterionFields({
    value: accountingData?.contractualCashFlowCriterionShortText,
    code: accountingData?.contractualCashFlowCriterionTypeCode,
  })

  const valuationClassFields = useValuationClassFields({
    value: accountingData?.valuationClassShortText,
    code: accountingData?.valuationClassTypeCode,
  })

  const partSyndicatedShare = useStandardShareField({
    name: 'partToBeSyndicated',
    label: t('part-to-be-syndicated'),
    share: accountingData?.partToBeSyndicated,
  })

  const fieldDefinitions = [
    ...businessModelCriterionFields,
    ...benchmarkTestResultFields,
    ...contractualCashFlowCriterionFields,
    ...valuationClassFields,
    ...partSyndicatedShare,
  ]

  const onSaveChanges = (newValues) => {
    setIsWritingToBackend(true)
    updateAccounting({
      dealId: deal?.dealUuid,
      trancheId: resolvedTrancheData.trancheId,
      ...newValues,
    })
  }

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t(`title`)}
      cardHeaderSubtitle={lastEditedText}
      fieldDefinitions={fieldDefinitions}
      additionalEditHeaderProps={{ isSaveLoading: isWritingToBackend }}
      // Overwrites the loading state used for the LoadingStateWrapper after save has been clicked.
      // The above `isSaveLoading` is used instead to set an inert card state
      isHandlingSaveOverwrite={false}
      // we only pass the fetching state as the data for this card is delivered by a prop
      // and the card itself is already wrapped in a LoadingStateWrapper for the initial loading
      isLoading={isFetching || isLoading}
      isEmpty={false}
      isEditable={
        !isExistingBusinessViewSet &&
        allowedOperations.includes(FINANCING_TRANCHE_ACCOUNTING_UPDATE)
      }
      isError={isError}
      fillEmptyValuesWithPlaceholder={true}
      saveChanges={onSaveChanges}
      saveHookIsError={isErrorUpdateAccounting}
      saveHookIsSuccess={isSuccessUpdateAccounting}
    />
  )
}

export default AccountingCard
