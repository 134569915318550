import { useTranslation } from 'react-i18next'
import useStandardMoneyField from 'routes/deals/financing/shared-components/useStandardMoneyField'
import useStandardShareField from 'routes/deals/financing/shared-components/useStandardShareField'
import useDerivativeLineMaturityDateFields from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/specificFields/useDerivativeLineMaturityDateFields'
import useRequiredHedgeAmountShareFields from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/specificFields/useRequiredHedgeAmountShareFields'
import useRequiredHedgeAmountTotalFields from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/specificFields/useRequiredHedgeAmountTotalFields'
import { InterestRateHedgingRequirementsRiskTypeCodes } from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/interestRateHedgingRequirementsConstants'
import isNumberNonNegative from 'routes/deals/financing/utils/isNumberNonNegative'

const headquarterCurrency = 'EUR'

const useInterestHedgingCollapsibleFields = (
  requirementsData = {},
  {
    trancheCurrency,
    trancheMaturity,
    trancheOwnShareCommitment,
    requiredHedgeAmountTotal,
    setRequiredHedgeAmountTotal,
    requiredHedgeAmountShare,
    setRequiredHedgeAmountShare,
    setRequiredHedgeAmountTotalForRisksCalculation,
    setRequiredHedgeAmountShareForRisksCalculation,
    setDerivativeLineMaturityForRisksCalculation,
  },
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.interest-rate-hedging-requirement-card',
  })

  const requiredHedgeAmountTotalFields = useRequiredHedgeAmountTotalFields({
    name: 'requiredHedgeAmountTotal',
    label: t('required-hedge-amount-total'),
    value: {
      amount: requiredHedgeAmountTotal,
      currency: trancheCurrency,
    },
    valueHeadquarter: requirementsData.requiredHedgeAmountTotalHeadquarter,
    trancheOwnShareCommitment,
    setRequiredHedgeAmountTotalForRisksCalculation,
    setRequiredHedgeAmountShare,
    validationFunction: isNumberNonNegative,
  })

  const requiredHedgeAmountShareFields = useRequiredHedgeAmountShareFields({
    name: 'requiredHedgeAmountShare',
    label: t('required-hedge-amount-share'),
    share: requiredHedgeAmountShare,
    trancheOwnShareCommitment,
    setRequiredHedgeAmountShareForRisksCalculation,
    setRequiredHedgeAmountTotal,
    validationFunction: isNumberNonNegative,
  })

  const derivativeLineMaturityDateFields = useDerivativeLineMaturityDateFields({
    derivativeLineMaturity: requirementsData.derivativeLineMaturity,
    prefilledDate: trancheMaturity,
    setDerivativeLineMaturityForRisksCalculation,
  })

  const settlementRisk = requirementsData.risks?.find(
    (risk) => risk.riskTypeCode === InterestRateHedgingRequirementsRiskTypeCodes.SettlementRisk,
  )

  const settlementRiskFields = useStandardMoneyField({
    name: 'settlementRisk',
    label: t('settlement-risk'),
    value: {
      ...settlementRisk?.value,
      currency: headquarterCurrency,
    },
    valueHeadquarter: settlementRisk?.valueHeadquarter,
    validationFunction: isNumberNonNegative,
  })

  const replacementRisk = requirementsData.risks?.find(
    (risk) => risk.riskTypeCode === InterestRateHedgingRequirementsRiskTypeCodes.ReplacementRisk,
  )

  const replacementRiskFields = useStandardMoneyField({
    name: 'replacementRisk',
    label: t('replacement-risk'),
    value: {
      ...replacementRisk?.value,
      currency: headquarterCurrency,
    },
    valueHeadquarter: replacementRisk?.valueHeadquarter,
    validationFunction: isNumberNonNegative,
  })

  const derivativePremiumFields = useStandardMoneyField({
    name: 'derivativePremium',
    label: t('derivative-premium'),
    value: {
      ...requirementsData.derivativePremium,
      currency: trancheCurrency,
    },
    valueHeadquarter: requirementsData.derivativePremiumHeadquarter,
    validationFunction: () => true, // derivative premium is excluded from any form of validation
  })

  const derivativeMarginFields = useStandardShareField({
    name: 'derivativeMargin',
    label: t('derivative-margin'),
    share: requirementsData.derivativeMargin,
  })

  const strikeFixedRateFields = useStandardShareField({
    name: 'strikeFixedRate',
    label: t('strike-fixed-rate'),
    share: requirementsData.strikeFixedRate,
  })

  return [
    ...requiredHedgeAmountTotalFields,
    ...requiredHedgeAmountShareFields,
    ...derivativeLineMaturityDateFields,
    ...settlementRiskFields,
    ...replacementRiskFields,
    ...derivativePremiumFields,
    ...derivativeMarginFields,
    ...strikeFixedRateFields,
  ]
}

export default useInterestHedgingCollapsibleFields
