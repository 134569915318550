import { useTranslation } from 'react-i18next'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useFirstDrawdownDateFields = ({ firstDrawdownDate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })
  const { format: formatDate } = useShortDateFormatter()

  return [
    {
      name: 'firstDrawdownDate',
      label: t('first-drawdown-date'),
      value: formatDate(firstDrawdownDate),
      isShownInDisplay: true,
      isShownInEdit: false,
    },

    {
      name: 'firstDrawdownDate',
      label: t('first-drawdown-date'),
      value: formatDate(firstDrawdownDate),
      editComponentType: 'DatePicker',
      editComponentProps: {},
      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useFirstDrawdownDateFields
