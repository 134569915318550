import {
  BusyIndicatorSize,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCard.module.css'
import MultiSelectCardHeaderButtons from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCardHeaderButtons'
import MultiSelectCardHeaderTableButtons from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCardHeaderTableButtons'
import { ADDED } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/constants'
import MultiSelectCardCommentSection from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-comment-section/MultiSelectCardCommentSection'
import MultiSelectCardTable from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/MultiSelectCardTable'
import { useRowsTableResetEditState } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/useRowsTableResetEditState' // TODO: CWP-4777: move to general folder
import useCommentSaveHandler from 'routes/deals/financing/trancheDetails/cards/multi-select-card/useCommentSaveHandler'
import useMultiSelectCardSaveHandler from 'routes/deals/financing/trancheDetails/cards/multi-select-card/useMultiSelectCardSaveHandler'
import { useRowsTableSaveHandler } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/useRowsTableSaveHandler'

const MultiSelectCard = ({
  mainTitle,
  mainSubtitle,
  tableTitle,
  noDataText,
  columnDefinitions,
  dataRows,
  comment,
  updateComment,
  className,
  userIsAllowedToEdit = true,
  isLoading,
  isFetching,
  isError,
  reduxTableSliceActions,
  reduxStatePath,
  createDataEntry,
  updateDataEntry,
  deleteDataEntries,
  refreshData,
}) => {
  const [addedCounter, setAddedCounter] = useState(0)
  const [inEditMode, setInEditMode] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [rowsInEdit, setRowsInEdit] = useState([])
  const [waitingForRequests, setWaitingForRequests] = useState(false)
  const [editComment, setEditComment] = useState(comment)

  useEffect(() => {
    setEditComment(comment)
  }, [comment])

  const {
    addInvalidEditState,
    removeInvalidEditState,
    removeIdsFromInvalidEditStates,
    resetInvalidEditState,
  } = reduxTableSliceActions

  const resetEditState = useRowsTableResetEditState({
    rows: dataRows,
    setRowsInEdit,
    resetInvalidEditState,
  })

  const memoizedDataRows = useMemo(
    () => (!isLoading && !isError ? dataRows : []),
    [isError, isLoading, dataRows],
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!waitingForRequests) {
      resetEditState()
    }
  }, [resetEditState, memoizedDataRows, waitingForRequests])

  const { invalidEditStates } = useSelector((state) => get(state, reduxStatePath))
  const [isInvalidEdit, setIsInvalidEdit] = useState(invalidEditStates.length > 0)

  useEffect(() => {
    setIsInvalidEdit(invalidEditStates.length > 0)
  }, [invalidEditStates, rowsInEdit])

  let columnDefinitionsInitialized

  const cancelEditMode = () => {
    setInEditMode(false)
    resetEditState()
  }

  const handleAdd = () => {
    const newRowId = ADDED + addedCounter
    setRowsInEdit((prev) => [...prev, { id: newRowId }])
    setAddedCounter((prev) => prev + 1)
    columnDefinitionsInitialized.forEach((item) => {
      if (item.needsValidation) {
        dispatch(addInvalidEditState({ id: newRowId, attribute: item.key }))
      }
    })
  }
  const handleDelete = () => {
    if (selectedRows.length > 0) {
      setRowsInEdit((prev) => [
        ...prev.filter((segment) => !selectedRows.find((selected) => selected === segment.id)),
      ])
      dispatch(removeIdsFromInvalidEditStates(selectedRows))
      setSelectedRows((_) => [])
    }
  }

  const handleSelectRow = (rowId) => {
    setSelectedRows((prev) => [...prev, rowId])
  }

  const handleUnselectRow = (rowId) => {
    setSelectedRows((prev) => [...prev.filter((id) => id !== rowId)])
  }

  const handleSelectAllRows = () => {
    setSelectedRows((_) => [...rowsInEdit.map((row) => row.id)])
  }

  const handleUnselectAllRows = () => {
    setSelectedRows((_) => [])
  }

  const handleRowInEditChange = (rowInEdit) => {
    setRowsInEdit((prev) => [
      ...prev.map((row) => {
        if (row.id === rowInEdit.id) {
          return { ...rowInEdit }
        } else {
          return row
        }
      }),
    ])
  }

  const findRowInEdit = (id) => rowsInEdit?.find((rowInEdit) => rowInEdit.id === id)

  const updateRowInEdit = (rowInEdit, updatedFields) => {
    Object.assign(rowInEdit, updatedFields)
    handleRowInEditChange(rowInEdit)
  }

  const handleValueChange = (id, key, value, validationFunc) => {
    const rowInEdit = findRowInEdit(id)
    updateRowInEdit(rowInEdit, { [key]: value })
    if (validationFunc) {
      if (validationFunc(value)) {
        dispatch(removeInvalidEditState({ id, attribute: key }))
      } else {
        dispatch(addInvalidEditState({ id, attribute: key }))
      }
    }
  }

  const isRowSelected = (row) => !!selectedRows.find((selected) => selected === row.id)
  const areAllRowsSelected = selectedRows.length > 0 && selectedRows.length === rowsInEdit.length

  const handleAllSelectChange = (e) => {
    if (e.target.checked) {
      handleSelectAllRows()
    } else {
      handleUnselectAllRows()
    }
  }

  const handleSelectChange = (row, e) => {
    if (e.target.checked) {
      handleSelectRow(row.id)
    } else {
      handleUnselectRow(row.id)
    }
  }

  columnDefinitionsInitialized = columnDefinitions({
    isRowSelected,
    areAllRowsSelected,
    onSelectChange: handleSelectChange,
    onSelectAllChange: handleAllSelectChange,
    onValueChange: handleValueChange,
  })

  const saveRows = useRowsTableSaveHandler({
    rows: memoizedDataRows,
    rowsInEdit,
    createDataEntry,
    updateDataEntry,
    deleteDataEntries,
  })

  const saveComment = useCommentSaveHandler({
    originalComment: comment,
    newComment: editComment,
    updateComment,
  })

  const handleSave = useMultiSelectCardSaveHandler({
    saveRows,
    saveComment,
    setInEditMode,
    setWaitingForRequests,
    refreshData,
    resetEditState,
  })

  return (
    <Card
      className={className}
      header={
        <CardHeaderWithButtons title={mainTitle} subTitle={mainSubtitle}>
          <MultiSelectCardHeaderButtons
            idPrefix={mainTitle}
            isUpdateAllowed={userIsAllowedToEdit}
            inEditMode={inEditMode}
            isSaveDisabled={isInvalidEdit}
            isWaitingForRequests={waitingForRequests}
            onEdit={() => setInEditMode(true)}
            onCancel={cancelEditMode}
            onSave={handleSave}
          />
        </CardHeaderWithButtons>
      }
    >
      <LoadingStateWrapper
        isError={isError}
        isLoading={isLoading || !!isFetching}
        loadingSize={BusyIndicatorSize.Medium}
        loadingClassName={styles.loadingStateMargin}
        renderContent={() => (
          <FlexBox direction="Column">
            <FlexBox direction="Column" className={styles.contentContainer}>
              <FlexBox
                direction={FlexBoxDirection.Row}
                alignItems={FlexBoxAlignItems.Center}
                justifyContent={FlexBoxJustifyContent.SpaceBetween}
              >
                <Title className={styles.title} level="H5">
                  {`${tableTitle} (${rowsInEdit.length})`}
                </Title>
                <MultiSelectCardHeaderTableButtons
                  inEditMode={inEditMode}
                  onAdd={handleAdd}
                  onDelete={handleDelete}
                  selectedRows={selectedRows}
                />
              </FlexBox>
              <FlexBox>
                <MultiSelectCardTable
                  columnDefinitions={columnDefinitionsInitialized}
                  dataRows={memoizedDataRows}
                  rowsInEdit={rowsInEdit}
                  inEditMode={inEditMode}
                  noDataText={noDataText}
                  onRowInEditChange={handleRowInEditChange}
                  selectedRows={selectedRows}
                  onSelectRow={handleSelectRow}
                  onUnselectRow={handleUnselectRow}
                  onSelectAllRows={handleSelectAllRows}
                  onUnselectAllRows={handleUnselectAllRows}
                />
              </FlexBox>
            </FlexBox>
            <MultiSelectCardCommentSection
              comment={comment}
              inEditMode={inEditMode}
              editComment={editComment}
              setEditComment={setEditComment}
            />
          </FlexBox>
        )}
      />
    </Card>
  )
}

MultiSelectCard.propTypes = {
  mainTitle: PropTypes.string,
  mainSubtitle: PropTypes.string,
  tableTitle: PropTypes.string,
  noDataText: PropTypes.string,
  columnDefinitions: PropTypes.func.isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.object),
  comment: PropTypes.string,
  updateComment: PropTypes.func,
  className: PropTypes.string,
  userIsAllowedToEdit: PropTypes.bool,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  reduxTableSliceActions: PropTypes.shape({
    addInvalidEditState: PropTypes.func.isRequired,
    removeInvalidEditState: PropTypes.func.isRequired,
    removeIdsFromInvalidEditStates: PropTypes.func.isRequired,
    resetInvalidEditState: PropTypes.func.isRequired,
  }),
  reduxStatePath: PropTypes.string.isRequired,
  createDataEntry: PropTypes.func.isRequired,
  updateDataEntry: PropTypes.func.isRequired,
  deleteDataEntries: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
}

export default MultiSelectCard
