import get from 'lodash.get'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import {
  customFieldConfigProps,
  CustomFieldTypes,
} from 'components/domains/deals/card/customFieldConfigsProps'
import styles from 'components/domains/deals/custom-list-card/DealCustomListCardDisplayItem.module.css'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import {
  useAreaMeasurementUnitFormatter,
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { UserProfileContext } from 'routes/UserProfileContext'

const dealCustomListCardItemProps = {
  fieldConfig: customFieldConfigProps,
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

const DealCustomListCardDisplayItem = ({
  fieldConfig: { key, type, displayName, selectOptions, visibility } = {},
  data,
  ...props
}) => {
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}
  const { format: formatDate } = useShortDateFormatter({ timeZone })
  const { format: formatDateTime } = useShortDateFormatter({
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
  })
  const formatNumber = useNumberFormatter()
  const formatBoolean = useBooleanToTextFormatter()
  const formatCurrency = useCustomizableCurrencyFormatter()
  const formatMeasurement = useAreaMeasurementUnitFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const value = useMemo(() => get(data, key), [key, data])

  const formattedValue = useMemo(() => {
    switch (type) {
      case CustomFieldTypes.String:
        return <TextEllipsis className={styles.textEllipsis} content={value} />
      case CustomFieldTypes.Number:
        return formatNumber(value)
      case CustomFieldTypes.Bool:
        return formatBoolean(value)
      case CustomFieldTypes.Date:
        return formatDate(value)
      case CustomFieldTypes.Datetime:
        return formatDateTime(value)
      case CustomFieldTypes.Percentage:
        return formatPercentage(value)
      case CustomFieldTypes.Currency:
        return formatCurrency(value?.value, value?.currency_code)
      case CustomFieldTypes.Measurement:
        return `${formatNumber(value?.value)} ${formatMeasurement(value?.unit)}`
      case CustomFieldTypes.SingleSelect:
        return selectOptions.find(({ configSelectOptionUuid }) => configSelectOptionUuid === value)
          ?.value
      case CustomFieldTypes.MultiSelect:
        return (
          <div className={styles.deviationsList}>
            {value?.map((option, index) => (
              <span key={index} className={styles.deviation}>
                {
                  selectOptions.find(
                    ({ configSelectOptionUuid }) => configSelectOptionUuid === option,
                  )?.value
                }
              </span>
            ))}
          </div>
        )
      default:
        return value
    }
  }, [
    type,
    value,
    formatNumber,
    formatBoolean,
    formatDate,
    formatDateTime,
    formatPercentage,
    formatCurrency,
    formatMeasurement,
    selectOptions,
  ])

  if (!visibility.displayMode) return null

  return <CardShowItem {...props} label={displayName} value={formattedValue} />
}

DealCustomListCardDisplayItem.propTypes = dealCustomListCardItemProps

export default DealCustomListCardDisplayItem
