import { TextAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BcaDateCell,
  BcaMoneyCell,
  BcaNavigationCell,
  BcaPercentCell,
  BcaTextCell,
} from 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountTableCells'
import { renderAnalyticalTableCell } from 'components/ui/tables/analytical/AnalyticalTableCell'
import { useCustomizableCurrencyFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const useBankCustomerAccountsColumns = ({ isFetchingAdditionalData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.accounts-table.column',
  })
  const formatCurrency = useCustomizableCurrencyFormatter()
  const { format: formatDate } = useShortDateFormatter()

  const defaultDisables = useMemo(
    () => ({
      disableDragAndDrop: true,
      disableGlobalFilter: true,
      disableGroupBy: true,
    }),
    [],
  )

  const tableHelpers = useMemo(
    () => ({
      formatCurrency,
      formatDate,
    }),
    [formatCurrency, formatDate],
  )

  return useMemo(
    () => [
      {
        Header: t('account-number'),
        id: 'account-number',
        accessor: 'accountNumber',
        Cell: renderAnalyticalTableCell(BcaTextCell, { ...tableHelpers, column: 'accountNumber' }),
        minWidth: 100,
        width: 150,
        ...defaultDisables,
      },
      {
        Header: t('product-type'),
        id: 'product-type',
        accessor: 'productType',
        Cell: renderAnalyticalTableCell(BcaTextCell, { ...tableHelpers, column: 'productType' }),
        minWidth: 150,
        ...defaultDisables,
      },
      {
        Header: t('description'),
        id: 'description',
        accessor: 'description',
        Cell: renderAnalyticalTableCell(BcaTextCell, { ...tableHelpers, column: 'description' }),
        minWidth: 200,
        ...defaultDisables,
      },
      {
        Header: t('book-balance'),
        id: 'book-balance',
        accessor: 'bookBalance',
        Cell: renderAnalyticalTableCell(BcaMoneyCell, {
          ...tableHelpers,
          isLoadingContent: isFetchingAdditionalData,
          column: 'bookBalance',
        }),
        hAlign: TextAlign.Right,
        minWidth: 175,
        ...defaultDisables,
      },
      {
        Header: t('external-limit'),
        id: 'external-limit',
        accessor: 'externalLimit',
        Cell: renderAnalyticalTableCell(BcaMoneyCell, { ...tableHelpers, column: 'externalLimit' }),
        hAlign: TextAlign.Right,
        minWidth: 175,
        ...defaultDisables,
      },
      {
        Header: t('remaining'),
        id: 'remaining-balance',
        accessor: 'remainingBalance',
        Cell: renderAnalyticalTableCell(BcaMoneyCell, {
          ...tableHelpers,
          isLoadingContent: isFetchingAdditionalData,
          column: 'remaining',
        }),
        hAlign: TextAlign.Right,
        minWidth: 175,
        ...defaultDisables,
      },
      {
        Header: t('utilization'),
        id: 'utilization',
        accessor: 'utilization',
        Cell: renderAnalyticalTableCell(BcaPercentCell, {
          ...tableHelpers,
          isLoadingContent: isFetchingAdditionalData,
          column: 'utilization',
        }),
        hAlign: TextAlign.Right,
        minWidth: 100,
        width: 110,
        ...defaultDisables,
      },
      {
        Header: t('maturity'),
        id: 'maturity',
        accessor: 'maturityDate',
        Cell: renderAnalyticalTableCell(BcaDateCell, { ...tableHelpers, column: 'maturityDate' }),
        hAlign: TextAlign.Right,
        minWidth: 100,
        width: 110,
        disableResizing: true,
        ...defaultDisables,
      },
      {
        Header: '',
        accessor: 'navigation',
        Cell: renderAnalyticalTableCell(BcaNavigationCell),
        hAlign: TextAlign.Right,
        maxWidth: 30,
        disableSortBy: true,
        disableResizing: true,
        ...defaultDisables,
      },
    ],
    [defaultDisables, isFetchingAdditionalData, t, tableHelpers],
  )
}

export default useBankCustomerAccountsColumns
