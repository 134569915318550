import {
  ROW_TYPE_CONDITION,
  TABLE_ROW_HEIGHT,
} from 'routes/deals/financing/non-loan-products/repos/constants'

const mapReposConditionData = (conditions = []) =>
  conditions.map((condition) => {
    const {
      isin,
      conditionId,
      conditionType,
      issuerId,
      counterpartyId,
      startDate,
      maturityDate,
      currentNotional,
      currentNotionalHeadquarter,
      buyOrSell,
      payOrReceive,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
    } = condition ?? {}
    return {
      repoCondition: isin ?? conditionId,
      productSubType: conditionType,

      startDate,
      maturityDate,
      buyOrSell,
      payOrReceive,
      counterpartyIssuer: issuerId ?? counterpartyId,

      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      rowHeight: TABLE_ROW_HEIGHT,
      rowType: ROW_TYPE_CONDITION,
    }
  })

export default mapReposConditionData
