import isNil from 'lodash.isnil'
import merge from 'lodash.merge'

const createUpdateCustomerOptionDTOs = ({
  backendData,
  editOptions,
  shouldApplyToAllTranches = false,
}) => {
  const result = []

  const editOptionKeys = Object.keys(editOptions)
  const clusterKeys = Object.keys(backendData.clustered_options)

  editOptionKeys.forEach((optionKey) => {
    let completeOption
    for (const clusterKey of clusterKeys) {
      const cluster = backendData.clustered_options[clusterKey]
      const options = cluster.options
      const foundOption = options.find((option) => option.id === optionKey)
      if (foundOption) {
        completeOption = foundOption
        break
      }
    }
    let backendAttributes = {}
    for (const attribute of completeOption.attributes) {
      backendAttributes = {
        ...backendAttributes,
        [attribute.id]: {
          value: attribute.value,
          optionAttributeCode: attribute.option_attribute_code,
        },
      }
    }
    const allAttributes = merge(backendAttributes, editOptions[optionKey])

    const isActive = isNil(editOptions[optionKey].isActive)
      ? completeOption.is_active
      : editOptions[optionKey].isActive

    const convertedAttributes = Object.entries(allAttributes)
      .filter(([entryKey]) => entryKey !== 'isActive')
      .map(([, attributeBody]) => ({
        optionAttributeCode: attributeBody.optionAttributeCode,
        value: isActive ? attributeBody.value : null,
      }))

    result.push({
      optionId: completeOption.id,
      optionTypeCodeShortText: completeOption.option_type_code_short_text,
      shouldApplyToAllTranches: shouldApplyToAllTranches,
      isActive: isActive,
      attributes: convertedAttributes,
    })
  })

  return result
}

export default createUpdateCustomerOptionDTOs
