import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing paymentFrequency type ecode keys and their localized display name
 * The paymentFrequencyTypeCodes structure is as follows:
 * {
 *   "paymentFrequencyTypeCodes": [
 *       {
 *           "key": "1",
 *           "display_name": "Gewerbebau"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { paymentFrequencyTypeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const usePaymentFrequencyTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/PAYMENT_FREQUENCY_TYPE`,
    translated: true,
  })

  const transformedData = {
    paymentFrequencyTypeCodes: result?.data?.configuration?.PAYMENT_FREQUENCY_TYPE.map(
      (element) => ({
        key: element.code,
        display_name: element.short_text,
      }),
    ),
  }

  return {
    ...result,
    data: transformedData,
  }
}
