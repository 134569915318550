import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { InterestRateHedgingRequirementsRiskTypeCodes } from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/interestRateHedgingRequirementsConstants'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

export const useEditTrancheInterestRateHedgingRequirements = (options, parameters) => {
  const { put } = useAccessTokenRequest()
  const { parse: parseDate, localePattern } = useShortDateFormatter()
  const { dealId, trancheId } = parameters

  return useMutation(async ({ data }) => {
    const optionalData = data.required
      ? {
          interest_rate_hedge_type_code: data.interestRateHedgeTypeCode,
          derivative_line_maturity: parseDate(data.derivativeLineMaturity, localePattern),
          required_hedge_amount_total: data.requiredHedgeAmountTotal,
          risks: [
            {
              risk_type_code: InterestRateHedgingRequirementsRiskTypeCodes.SettlementRisk,
              value: data.settlementRisk,
            },
            {
              risk_type_code: InterestRateHedgingRequirementsRiskTypeCodes.ReplacementRisk,
              value: data.replacementRisk,
            },
          ],
          derivative_premium: data.derivativePremium,
          derivative_margin: convertPercentageToDecimal(data.derivativeMargin),
          strike_fixed_rate: convertPercentageToDecimal(data.strikeFixedRate),
        }
      : {}

    return await put({
      path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/interest-rate-hedging-requirements`,
      body: {
        comment: data.comment,
        required: data.required,
        ...optionalData,
      },
    })
  }, options)
}
