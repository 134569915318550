import { BusyIndicatorSize } from '@fioneer/ui5-webcomponents-react'
import styles from 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountTableCells.module.css'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import AnalyticalTableCell from 'components/ui/tables/analytical/AnalyticalTableCell'
import DateCell from 'components/ui/tables/cells/DateCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import NavigationCell from 'components/ui/tables/cells/NavigationCell'
import PercentageCell from 'components/ui/tables/cells/PercentageCell'
import TextCell from 'components/ui/tables/cells/TextCell'

export const BcaMoneyCell = (
  <AnalyticalTableCell alignRight className={styles.alignCell}>
    {(tableData, { formatCurrency, column, isLoadingContent, isErrorContent }) => (
      <RequestStateResolver
        isError={isErrorContent}
        errorToDisplay={<ErrorDataUnavailableInCell />}
        isLoading={isLoadingContent}
        busyIndicatorSize={BusyIndicatorSize.Small}
        renderContent={() =>
          tableData[column]?.isDataUnavailable ? (
            <ErrorDataUnavailableInCell />
          ) : (
            <MoneyCell
              isSectionHeader={false}
              primary={{
                amount: tableData[column]?.original?.value,
                currency: tableData[column]?.original?.currency,
              }}
              secondary={{
                amount: tableData[column]?.headquarterCurrency?.value,
                currency: tableData[column]?.headquarterCurrency?.currency,
              }}
              formatCurrency={formatCurrency}
              options={{ forceAlignRight: true }}
            />
          )
        }
      />
    )}
  </AnalyticalTableCell>
)

export const BcaTextCell = (
  <AnalyticalTableCell className={styles.alignCell}>
    {(tableData, { column }) => <TextCell text={tableData[column]} />}
  </AnalyticalTableCell>
)

export const BcaPercentCell = (
  <AnalyticalTableCell alignRight className={styles.alignCell}>
    {(tableData, { column, isLoadingContent, isErrorContent }) => (
      <RequestStateResolver
        isError={isErrorContent}
        errorToDisplay={<ErrorDataUnavailableInCell />}
        isLoading={isLoadingContent}
        busyIndicatorSize={BusyIndicatorSize.Small}
        renderContent={() => <PercentageCell value={tableData[column]} />}
      />
    )}
  </AnalyticalTableCell>
)

export const BcaDateCell = (
  <AnalyticalTableCell alignRight className={styles.alignCell}>
    {(tableData, { column }) => <DateCell date={tableData[column]} />}
  </AnalyticalTableCell>
)

export const BcaNavigationCell = (
  <AnalyticalTableCell className={styles.alignCell}>
    {(tableData) => !!tableData?.displayNavigation && <NavigationCell />}
  </AnalyticalTableCell>
)
