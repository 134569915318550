import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useState } from 'react'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import styles from 'routes/deals/financing/otherFinancingSources/fields/amount/AmountInput.module.css'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'

const AmountInput = ({ rowKey, value, handleOnChange, setInteractedWithRowState, isValid }) => {
  const [valueStateAmount, setValueStateAmount] = useState(
    isValid ? ValueState.None : ValueState.Error,
  )

  const validateAmountField = (newValue) => {
    !newValue || !isAmountValid(newValue)
      ? setValueStateAmount(ValueState.Error)
      : setValueStateAmount(ValueState.None)
    handleOnChange({ rowKey, newValue })
  }

  return (
    <FlexBox className={styles.amountWrapper}>
      <FormattedNumberInput
        required
        id="otherFinancingSourceAmountInput"
        className={styles.amountInput}
        type="Number"
        value={value}
        valueState={valueStateAmount}
        valueStateMessage={
          <span>
            {t('pages.deals.financing.other-financing-sources.validation.amount-required')}
          </span>
        }
        onInput={(newValue) => {
          validateAmountField(newValue)
          setInteractedWithRowState(rowKey, 'amount')
        }}
      />
    </FlexBox>
  )
}

AmountInput.propTypes = {
  rowKey: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
  setInteractedWithRowState: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
}

export default AmountInput
