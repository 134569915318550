import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const useCustomerSpecificAttributes = (dealId, trancheId) => {
  const { dataSourceQueryKeys, dataSourceQueryParam } = useDataSourceQueryParams()

  const result = useRequest({
    path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/custom-fields?${dataSourceQueryParam}`,
    translated: true,
    keys: ['deals', dealId, 'tranches', trancheId, 'custom-fields', ...dataSourceQueryKeys],

    options: {
      enabled: !!trancheId,
    },
  })

  // Ideally, all custom field keys would be in pascal case (upper camel case), which camelizes preserving the identity
  // and therefore, allowing for a correct PATCH payload.
  // However, keys in snake case, e.g., green_loan_documentation, would've been camelized as well,
  // and are therefore not correctly refrenced in the payload (e.g., greenLoanDocumentation)
  // making the PATCH incomplete (or even resetting said attribute, since a virtuall NULL was received).
  // To solve this, the first level of keys in the custom fields object is left unformatted, and only the rest of the data object is camelized.
  const customFieldsWithOriginalKeys = useMemo(() => {
    const customFieldsData = result.data?.custom_fields ?? {}
    const customFieldsKeys = Object.keys(customFieldsData)

    return Object.fromEntries(
      customFieldsKeys?.map((customFieldKey) => [
        customFieldKey,
        camelize(customFieldsData[customFieldKey]),
      ]),
    )
  }, [result.data?.custom_fields])

  return useMemo(
    () => ({
      ...result,
      data: { ...camelize(result.data), customFields: customFieldsWithOriginalKeys },
    }),
    [customFieldsWithOriginalKeys, result],
  )
}

export default useCustomerSpecificAttributes
