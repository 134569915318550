import isEmpty from 'lodash.isempty'
import { useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  FINANCING_TRANCHE_READ,
  FINANCING_OTHER_FINANCING_SOURCES_READ,
  SYNDICATION_STRUCTURE_READ,
  FINANCING_NON_LOAN_PRODUCTS_READ,
  FINANCING_TRANCHE_PRICING_READ,
  FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
  FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
  FINANCING_TRANCHE_FEE_READ,
  FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
  FINANCING_TRANCHE_ACCOUNTING_READ,
  FINANCING_CUSTOM_FIELDS_READ,
  FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
  FINANCING_TRANCHE_OPTIONS_READ,
} from 'api/deals/financing/allowedOperationsConstants'
import { businessEventsAllowedOperations } from 'api/events/eventAllowedOperations'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import useEventsAllowedOperations from 'hooks/services/business-events-and-tasks/events/useEventsAllowedOperations'
import useSyndication from 'hooks/services/deals/syndication/useSyndication'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'

export const useOverviewBusinessEventsDisplayHook = () => {
  const {
    data: allowedEventsOperationsData,
    isLoading: allowedEventsOperationsIsLoading,
    isError: allowedEventsOperationsIsError,
  } = useEventsAllowedOperations()

  const eventReadAllowed = !!allowedEventsOperationsData?.allowed_operations?.includes(
    businessEventsAllowedOperations.readEvent,
  )

  return {
    isLoading: false,
    renderComponent:
      !allowedEventsOperationsIsLoading && !allowedEventsOperationsIsError && eventReadAllowed,
  }
}

export const useFinancingSyndicationInfoMessageStripDisplayHook = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const hasSyndicationStructureReadPermission = allowedOperations.includes(
    SYNDICATION_STRUCTURE_READ,
  )

  const { data: syndicationData, isFetching } = useSyndication(deal.dealUuid)
  const areSyndicationCounterpartiesEmpty = useMemo(
    () => isEmpty(syndicationData?.counterparties),
    [syndicationData?.counterparties],
  )

  return {
    isLoading: isFetching,
    renderComponent: hasSyndicationStructureReadPermission && !areSyndicationCounterpartiesEmpty,
  }
}

export const useFinancingTranchesOverviewDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const hasTrancheReadPermission = allowedOperations.includes(FINANCING_TRANCHE_READ)

  return {
    renderComponent: hasTrancheReadPermission,
  }
}

export const useFinancingOtherFinancingSourcesDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const hasOtherFinancingSourcesReadPermission = allowedOperations.includes(
    FINANCING_OTHER_FINANCING_SOURCES_READ,
  )
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: hasOtherFinancingSourcesReadPermission && !isExistingBusinessViewSet,
  }
}

export const useFinancingBankCustomerAccountsDisplayHook = () => {
  const [searchParams] = useSearchParams()

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: isExistingBusinessViewSet,
  }
}

export const useFinancingDealSignedCheckDisplayHook = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const hasNonLoanProductsReadPermission = allowedOperations.includes(
    FINANCING_NON_LOAN_PRODUCTS_READ,
  )

  const { data: { rank } = {} } = useDealMini(deal.dealUuid)
  const isDealSigned = rank >= DEAL_STATUS_RANKS.SIGNED

  return {
    renderComponent: isDealSigned && hasNonLoanProductsReadPermission,
  }
}

export const useFinancingDealConsolidationPointsDisplayHook = () => {
  const { deal: { dealUuid, workingVersion } = {} } = useContext(DealContext)
  const { data: { rank } = {} } = useDealMini(dealUuid)

  return {
    renderComponent: rank < DEAL_STATUS_RANKS.SIGNED && workingVersion === WorkingVersionType.LIVE,
  }
}

export const useDealTranchePricingDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const hasPricingReadPermission = allowedOperations.includes(FINANCING_TRANCHE_PRICING_READ)

  return {
    renderComponent: !isExistingBusinessViewSet && hasPricingReadPermission,
  }
}

export const useDealTrancheInterestConditionsDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasInterestConditionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_INTEREST_CONDITIONS_READ,
  )

  return {
    renderComponent: hasInterestConditionsReadPermission,
  }
}

export const useDealTrancheAdditionalInterestInformationDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasAdditionalInterestInformationReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_READ,
  )

  return {
    renderComponent: hasAdditionalInterestInformationReadPermission,
  }
}

export const useDealTrancheFeesDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasFeeReadPermission = allowedOperations.includes(FINANCING_TRANCHE_FEE_READ)

  return {
    renderComponent: hasFeeReadPermission,
  }
}

export const useDealTrancheAmortizationDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasAmortizationConditionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_READ,
  )

  return {
    renderComponent: hasAmortizationConditionsReadPermission,
  }
}

export const useDealTrancheAccountingDisplayHook = () => {
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasAccountingReadPermission = allowedOperations.includes(FINANCING_TRANCHE_ACCOUNTING_READ)

  return {
    renderComponent: hasAccountingReadPermission,
  }
}

export const useDealTrancheCustomerSpecificAttributesDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasCustomerSpecificAttributesReadPermission = allowedOperations.includes(
    FINANCING_CUSTOM_FIELDS_READ,
  )

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: hasCustomerSpecificAttributesReadPermission && !isExistingBusinessViewSet,
  }
}

export const useDealTrancheBasicAgreementsCustomFieldsDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasCustomerSpecificAttributesReadPermission = allowedOperations.includes(
    FINANCING_CUSTOM_FIELDS_READ,
  )

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: hasCustomerSpecificAttributesReadPermission && isExistingBusinessViewSet,
  }
}

export const useDealTrancheInterestRateHedgingRequirementsDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasInterestRateHedgingRequirementReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_READ,
  )

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: !isExistingBusinessViewSet && hasInterestRateHedgingRequirementReadPermission,
  }
}

export const useDealTrancheCustomerOptionsDisplayHook = () => {
  const [searchParams] = useSearchParams()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const hasFinancingTrancheOptionsReadPermission = allowedOperations.includes(
    FINANCING_TRANCHE_OPTIONS_READ,
  )

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return {
    renderComponent: !isExistingBusinessViewSet && hasFinancingTrancheOptionsReadPermission,
  }
}
