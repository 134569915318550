import {
  Popover,
  PopoverPlacementType,
  PopoverHorizontalAlign,
  List,
  StandardListItem,
} from '@fioneer/ui5-webcomponents-react/dist'
import PropTypes from 'prop-types'
import { useState, useContext, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useDecisionPaperAttachments from 'hooks/services/business-events-and-tasks/decision-papers/useDecisionPaperAttachments'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'
import DecisionPaperContext from 'routes/business-events-and-tasks/decision-paper/DecisionPaperContext'
import DecisionPaperAttachmentsDialog from 'routes/business-events-and-tasks/decision-paper/attachments/DecisionPaperAttachmentsDialog'
import styles from 'routes/business-events-and-tasks/decision-paper/versions/DecisionPaperPopovers.module.css'

const DecisionPaperAttachmentsPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  openerId,
  handleExportClick,
  isEditView,
  decisionPaperVersion,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.business-events-and-tasks.decision-paper.attachments-popover',
  })
  const [isAttachmentsDialogOpen, setIsAttachmentsDialogOpen] = useState(false)
  const { event } = useContext(BusinessEventsAndTasksContext)
  const { decisionPaper: { archivedDocumentId } = {} } = useContext(DecisionPaperContext) ?? {}
  const hasArchivedDocument = !!archivedDocumentId
  const {
    isLoading,
    isError,
    data: attachmentsData,
  } = useDecisionPaperAttachments({ eventId: event.id, versionNumber: decisionPaperVersion })

  const renderNumberOfAttachments = useCallback(
    () => <>{t('number-of-attachments', { count: attachmentsData.attachments.length })}</>,
    [attachmentsData?.attachments.length, t],
  )

  const handleOpenArchiveDocumentClick = useCallback(() => {
    window.open(`/documents/${archivedDocumentId}`, '_blank')
  }, [archivedDocumentId])

  return createPortal(
    <>
      <Popover
        opener={openerId}
        open={isPopoverOpen}
        placementType={PopoverPlacementType.Bottom}
        horizontalAlign={PopoverHorizontalAlign.Left}
        onAfterClose={() => setIsPopoverOpen(false)}
        className={styles.popover}
      >
        <List>
          <StandardListItem onClick={() => setIsAttachmentsDialogOpen(true)} icon={'attachment'}>
            {t('attachment')}
            <SmallLoadingWrapper
              isLoading={isLoading}
              isError={isError}
              renderContent={renderNumberOfAttachments}
              error={''}
            />
          </StandardListItem>
          <StandardListItem onClick={handleExportClick} icon={'print'}>
            {t('print')}
          </StandardListItem>
          {hasArchivedDocument && (
            <StandardListItem onClick={handleOpenArchiveDocumentClick} icon={'pdf-attachment'}>
              {t('audit-document')}
            </StandardListItem>
          )}
        </List>
      </Popover>
      <DecisionPaperAttachmentsDialog
        decisionPaperVersion={decisionPaperVersion}
        isOpen={isAttachmentsDialogOpen}
        setIsOpen={setIsAttachmentsDialogOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        isEditView={isEditView}
      />
    </>,
    document.body,
  )
}

DecisionPaperAttachmentsPopover.propTypes = {
  isPopoverOpen: PropTypes.bool.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  openerId: PropTypes.string.isRequired,
  isEditView: PropTypes.bool,
  decisionPaperVersion: PropTypes.string,
}

export default DecisionPaperAttachmentsPopover
