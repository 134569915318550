import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'

const useStandardInputField = ({ name, label, value, validationFunction }) => [
  {
    label,
    name,
    value,

    isShownInDisplay: true,
    isShownInEdit: false,
  },
  {
    label,
    name,
    value,

    isShownInDisplay: false,
    isShownInEdit: true,

    editComponentType: editComponentTypes.Input,
    editComponentProps: {
      maxLength: 255,
    },

    validationFunction,
  },
]

useStandardInputField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
}

export default useStandardInputField
