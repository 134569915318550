import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'

const badGatewayStatusCode = 502
const internalServerErrorCode = 500
const pdfGenerationFailedCode = 'PDF_GENERATION_FAILED_ERROR'
const notAllNamesCouldBeResolvedCode = 'NOT_ALL_VOTER_NAMES_COULD_BE_RESOLVED'

const httpStatusCodesWithKnownErrorCodes = [internalServerErrorCode, badGatewayStatusCode]
const errorCodesToMessageCodeMapping = {
  [pdfGenerationFailedCode]: 'pdf-generation-failed',
  [notAllNamesCouldBeResolvedCode]: 'not-all-names-could-be-resolved',
}
const knownErrorCodes = Object.keys(errorCodesToMessageCodeMapping)
const useOverarchingVoteError = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'decision-stage.vote-options.overarching.error',
  })
  const showMessageBox = useShowMessageBox()

  const onError = useCallback(
    async ({ response }) => {
      if (httpStatusCodesWithKnownErrorCodes.includes(response.status)) {
        const responseBody = await response.json()
        if (knownErrorCodes.includes(responseBody.code)) {
          showMessageBox({
            type: MessageBoxTypes.Error,
            children: t(errorCodesToMessageCodeMapping[responseBody.code]),
          })
          return
        }
      }
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: t('generic'),
      })
    },
    [showMessageBox, t],
  )

  return useMemo(
    () => ({
      onError,
    }),
    [onError],
  )
}

export default useOverarchingVoteError
