import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useTypeCodes } from 'hooks/services/deals/financing/configGetters/useTypeCodes'

const useStandardDropdownField = ({
  name,
  label,
  value,
  code,
  selectionName,
  selectedKey,
  configurationTypeCode,
  loadingHook,
  validationFunction,
}) => {
  const useTypeCodesForStandardDropdown = () => useTypeCodes(configurationTypeCode)

  return [
    {
      label,
      name,
      value: value,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label,
      name,
      value: code,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: editComponentTypes.LoadingSelect,
      editComponentProps: {
        loadingHook: loadingHook ?? useTypeCodesForStandardDropdown,
        selectionName,
        selectedKey,
      },

      validationFunction,
    },
  ]
}

useStandardDropdownField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  selectionName: PropTypes.string.isRequired,
  selectedKey: PropTypes.string.isRequired,
  configurationTypeCode: PropTypes.string,
  loadingHook: PropTypes.string,
  validationFunction: PropTypes.func,
}

export default useStandardDropdownField
