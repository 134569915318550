import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_REMOVE } from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import TrancheDetailsOpenInCmlButton from 'components/domains/deals/financing/cml/TrancheDetailsOpenInCmlButton'
import TrancheDelete from 'components/domains/deals/financing/tranche-delete/TrancheDelete'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useTranche from 'hooks/services/deals/financing/useTranche'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import useSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useSyndicationsExistingBusiness'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'
import NewAndExistingBusinessSwitch from 'routes/deals/financing/trancheDetails/financing-header/NewAndExistingBusinessSwitch'
import paths from 'routes/paths'

const DealFinancingTrancheDetails = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const { manageWorkingVersionButton } = useDealWorkingVersionToolbarButtons()
  const { t } = useTranslation()
  const { trancheDisplayId } = useParams()

  // NOTE: This page is used for both drawdowns and tranches, therefore we need to check the pathname
  const { pathname } = useLocation()
  const isDrawdown = pathname.includes('drawdowns')

  // TODO: Tiles should be revealed again one by one, after they are adjusted
  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const {
    data: resolvedTrancheData,
    isLoading: isLoadingDisplayNameResolution,
    isError: isErrorDisplayNameResolution,
  } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const {
    data: baseTrancheData = {},
    isLoading: isLoadingTranche,
    isError: isErrorTranche,
    isFetching: isFetchingTranche,
  } = useTranche(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const {
    data: existingBusinessSyndicationData,
    isFetching: isFetchingSyndicationExistingBusiness,
  } = useSyndicationsExistingBusiness(deal.dealUuid, { enabled: isExistingBusinessViewSet })

  const ownShareExistingBusiness = useMemo(() => {
    if (!isExistingBusinessViewSet) return {}
    let ownShare

    if (isDrawdown) {
      const currentTrancheInSyndicationData =
        existingBusinessSyndicationData?.tranches?.find(({ drawDowns = [] } = {}) =>
          drawDowns.find(({ id } = {}) => id === trancheDisplayId),
        ) ?? {}
      ;({ ownShare } =
        currentTrancheInSyndicationData?.drawDowns?.find(
          ({ id } = {}) => id === trancheDisplayId,
        ) ?? {})
    } else {
      ;({ ownShare } =
        existingBusinessSyndicationData?.tranches?.find(({ id } = {}) => id === trancheDisplayId) ??
        {})
    }

    return {
      ownShare: {
        commitment: ownShare?.amount?.amountOriginalCurrency?.amount,
        available: ownShare?.availableAmount?.amountOriginalCurrency?.amount,
        outstanding: ownShare?.outstandingAmount?.amountOriginalCurrency?.amount,
        currency: ownShare?.amount?.amountOriginalCurrency?.currency,
      },
      ownShareHeadquarter: {
        commitment: ownShare?.amount?.amountHeadQuarterCurrency?.amount,
        available: ownShare?.availableAmount?.amountHeadQuarterCurrency?.amount,
        outstanding: ownShare?.outstandingAmount?.amountHeadQuarterCurrency?.amount,
        currency: ownShare?.amount?.amountHeadQuarterCurrency?.currency,
      },
      ownCommitmentShare: ownShare?.amountShare,
    }
  }, [existingBusinessSyndicationData, isDrawdown, isExistingBusinessViewSet, trancheDisplayId])

  const trancheData = useMemo(
    () => ({ ...baseTrancheData, ownShareExistingBusiness }),
    [baseTrancheData, ownShareExistingBusiness],
  )

  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === deal.workingVersion,
    [deal],
  )

  const workingVersionSuffix = useMemo(
    () => (isWorkingVersion ? '?working-version=true' : ''),
    [isWorkingVersion],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('pages.deals.breadcrumbs.financing'),
        href: `/${paths.deals}/${deal.displayId}/financing${workingVersionSuffix}`,
      },
      {
        text: `${
          !isDrawdown
            ? t('pages.deals.breadcrumbs.tranche')
            : t('pages.deals.tranches.details.drawdown')
        }: ${trancheData?.trancheName || trancheData?.externalContractId?.[0] || '...'}`,
      },
    ],
    [
      deal.displayId,
      t,
      trancheData?.trancheName,
      trancheData?.externalContractId,
      isDrawdown,
      workingVersionSuffix,
    ],
  )
  const { data: dealData = {} } = useDealMini(deal.dealUuid)

  const shouldShowNewAndExistingBusinessSwitch =
    dealData?.rank >= DEAL_STATUS_RANKS.SIGNED && dealData?.rank < DEAL_STATUS_RANKS.DEAD

  const trancheDetailsOpenInCmlAction = useMemo(() => {
    const externalContractId = trancheData?.externalContractId?.[0] ?? ''
    return (
      isExistingBusinessViewSet && (
        <TrancheDetailsOpenInCmlButton
          key={'open-in-cml-button'}
          externalContractId={externalContractId}
        />
      )
    )
  }, [isExistingBusinessViewSet, trancheData?.externalContractId])

  const newAndExistingBusinessAction = useMemo(
    () =>
      shouldShowNewAndExistingBusinessSwitch && (
        <NewAndExistingBusinessSwitch
          key={'new-and-existing-business-switch'}
          isNewBusinessEnabled={!!trancheData?.displayId}
          isExistingBusinessEnabled={trancheData?.externalContractId?.length === 1}
          trancheData={trancheData}
          dealDisplayId={deal.displayId}
        />
      ),
    [deal.displayId, shouldShowNewAndExistingBusinessSwitch, trancheData],
  )

  const deleteTrancheAction = useMemo(
    () =>
      !shouldShowNewAndExistingBusinessSwitch &&
      allowedOperations.includes(FINANCING_TRANCHE_REMOVE) && (
        <TrancheDelete key={'delete-tranche-button'} />
      ),
    [allowedOperations, shouldShowNewAndExistingBusinessSwitch],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={deal.dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [deal.dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [manageWorkingVersionButton],
        [trancheDetailsOpenInCmlAction, newAndExistingBusinessAction, deleteTrancheAction],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [
      deleteTrancheAction,
      manageWorkingVersionButton,
      markFavoriteAction,
      newAndExistingBusinessAction,
      trancheDetailsOpenInCmlAction,
    ],
  )

  return (
    <ConfigurableComponentsPage
      pageData={{
        tranche: trancheData,
        isFetching: isFetchingTranche || isFetchingSyndicationExistingBusiness,
        isExistingBusinessView: isExistingBusinessViewSet,
      }}
      pageCode={availableDynamicPagesEnum.DealsTranches}
      renderContent={({ children }) => (
        <DealDetailsWrapper actions={headerActions} childBreadcrumbs={breadcrumbs}>
          <RequestStateResolver
            isLoading={isLoadingTranche || isLoadingDisplayNameResolution}
            isError={isErrorTranche || isErrorDisplayNameResolution}
            errorToDisplay={<></>}
            renderContent={() => children}
          />
        </DealDetailsWrapper>
      )}
    />
  )
}

export default DealFinancingTrancheDetails
