import { Label, MessageBoxActions, MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import { HTTP_STATUS_CODES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/tranchesOverview/errorHandling/showWriteBasicAgreementError.module.css'

const showWriteBasicAgreementError = async (error) => {
  const errorResponseJson = await error.response.json()

  if (
    errorResponseJson.status === HTTP_STATUS_CODES.BAD_REQUEST ||
    errorResponseJson.status === HTTP_STATUS_CODES.BAD_GATEWAY
  ) {
    Modals.showMessageBox({
      type: MessageBoxTypes.Error,
      children: (
        <div className={styles.errorMessageBoxChildren}>
          <div>{t('pages.deals.financing.error.general')}</div>
          <div />
          <Label showColon>{t('pages.deals.financing.error.error-message-label')}</Label>
          {t(`pages.deals.financing.error.${errorResponseJson.code}`)}
        </div>
      ),
      actions: [MessageBoxActions.Close],
    })
  } else {
    throw error
  }
}

export default showWriteBasicAgreementError
