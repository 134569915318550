import { Modals, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'

/**
 * @param {import('react-hook-form').UseFormStateReturn<any>} formState
 * @param {boolean} isEditMode
 */
const isSubmitEnabled = (formState, isEditMode) => {
  if (isEditMode) {
    return formState.isDirty
  }
  return true
}

const useShowInvalidMessageBox = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.input.validation.error-message-box',
  })
  return () => {
    Modals.showMessageBox({
      titleText: t('title'),
      type: MessageBoxTypes.Error,
      children: t('message'),
    })
  }
}

/**
 * @param {(...params: any[]) => any} handleSubmit
 * @param {import('react-hook-form').UseFormReturn<any>} form
 * @param {object} [options]
 * @param {boolean} [options.isEditMode]
 */
const useValidatedHandleSubmit = (handleSubmit, form, { isEditMode = false } = {}) => {
  const { formState, trigger } = form
  const submitEnabled = isSubmitEnabled(formState, isEditMode)
  const showMessageBox = useShowInvalidMessageBox()

  const handleError = () => {
    showMessageBox()
    trigger()
  }

  return {
    handleSubmit: formState.isValid ? handleSubmit : handleError,
    isSubmitDisabled: !submitEnabled,
  }
}

export default useValidatedHandleSubmit
