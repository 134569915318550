import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useOtherFinancingSourceTypeCodes } from 'hooks/services/deals/financing/configGetters/useOtherFinancingSourceTypeCodes'
import styles from 'routes/deals/financing/otherFinancingSources/fields/type/TypeInputSelect.module.css'

const TypeInputSelect = ({
  rowKey,
  selectedKey,
  handleOnChange,
  setInteractedWithRowState,
  isValid,
}) => (
  <FlexBox className={styles.typeWrapper}>
    <LoadingSelect
      id="otherFinancingSourceTypeCodesLoadingSelect"
      className={styles.typeInputSelect}
      loadingHook={useOtherFinancingSourceTypeCodes}
      selectionName="typeCodes"
      optionKeyName="key"
      optionDisplayName="display_name"
      selectedKey={selectedKey}
      valueState={isValid ? ValueState.None : ValueState.Error}
      valueStateMessage={
        <span>{t('pages.deals.financing.other-financing-sources.validation.type-required')}</span>
      }
      onChange={(event) => {
        const newValue = event.detail.selectedOption.dataset.id
        handleOnChange({ rowKey, newValue })
        setInteractedWithRowState(rowKey, 'financingSourcesItemTypeCode')
      }}
      onBlur={() => {
        setInteractedWithRowState(rowKey, 'financingSourcesItemTypeCode')
      }}
    />
  </FlexBox>
)

TypeInputSelect.propTypes = {
  rowKey: PropTypes.string.isRequired,
  selectedKey: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  setInteractedWithRowState: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
}

export default TypeInputSelect
