import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useDeleteInterestConditions = (options, parameters) => {
  const { post } = useAccessTokenRequest()
  const { dealId, trancheId } = parameters

  return useMutation(
    async (interestConditionIds) =>
      await post({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/interest-conditions/items/delete`,
        body: interestConditionIds,
      }),
    options,
  )
}
