import {
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/limit-check/LimitCheckRequestHelperDialog.module.css'
import { useExternalTypeCodes } from 'components/domains/deals/limit-check/reservations/external-type/useExternalTypeCodes'
import { useDealReservationTableConfig } from 'components/domains/deals/limit-check/reservations/useDealReservationTableConfig'
import { useLimitCheckRequestHelperDialogData } from 'components/domains/deals/limit-check/useLimitCheckRequestHelperDialogData'
import Dialog, { DialogPrimaryButton } from 'components/ui/dialog/Dialog'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

export const LimitCheckRequestHelperDialog = ({
  dealUuid,
  isLoading,
  primaryButtonLabel,
  isDialogOpen,
  title,
  onSubmit,
  closeDialog,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.dialog',
  })
  const {
    data: { businessSegment, country, externalMainPropertyType },
    isError: isDataError,
    isLoading: isDataLoading,
  } = useLimitCheckRequestHelperDialogData({ dealUuid })
  const { getCountryName, InputWithLabel, SelectInputWithLabel } = useDealReservationTableConfig()
  const [externalProductType, setExternalProductType] = useState()

  const handleOnChange = (event) => setExternalProductType(event.detail.selectedOption.value)
  const handleOnSubmit = () => {
    onSubmit({
      dealUuid,
      businessSegment,
      country,
      externalMainPropertyType,
      externalProductType,
    })
  }

  const handleOnClose = () => {
    closeDialog()
    setExternalProductType()
  }

  const renderValueStateMessage = () => <span>{t('product-type.required')}</span>

  const renderContent = () => (
    <FlexBox direction={FlexBoxDirection.Column} className={styles.contentGap}>
      <InputWithLabel
        value={businessSegment}
        label={t('label.business-segment')}
        direction={FlexBoxDirection.Column}
        alignment={FlexBoxAlignItems.Start}
        disabled
      />
      <SelectInputWithLabel
        value={externalProductType}
        label={t('label.product-type')}
        hook={useExternalTypeCodes}
        selectionName={'productTypes'}
        optionDisplayName={'key'}
        isErrorState={externalProductType === ''}
        valueStateMessage={renderValueStateMessage()}
        direction={FlexBoxDirection.Column}
        alignment={FlexBoxAlignItems.Start}
        onChange={handleOnChange}
        required
      />
      <InputWithLabel
        value={externalMainPropertyType}
        label={t('label.main-property-type')}
        direction={FlexBoxDirection.Column}
        alignment={FlexBoxAlignItems.Start}
        disabled
      />
      <InputWithLabel
        value={getCountryName(country)}
        label={t('label.country')}
        direction={FlexBoxDirection.Column}
        alignment={FlexBoxAlignItems.Start}
        disabled
      />
    </FlexBox>
  )

  return (
    <Dialog
      open={isDialogOpen}
      className={styles.dialogWidth}
      headerText={title}
      onAfterClose={handleOnClose}
      primaryButton={
        <DialogPrimaryButton
          design={ButtonDesign.Emphasized}
          isLoading={isLoading}
          disabled={!externalProductType}
          onClick={handleOnSubmit}
        >
          {primaryButtonLabel}
        </DialogPrimaryButton>
      }
    >
      <RequestStateResolver
        isLoading={isDataLoading}
        isError={isDataError}
        renderContent={renderContent}
        errorToDisplay={<ErrorDataUnavailableInContent />}
        center
      />
    </Dialog>
  )
}

LimitCheckRequestHelperDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  primaryButtonLabel: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
}
