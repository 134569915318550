import camelize from 'camelize'
import { customEntityResponseSchema } from 'components/ui/customfield/customFieldSchema'
import { useRequest } from 'hooks/services/baseService'

/**
 * @param {string?} [externalContractId]
 */
const useBasicAgreementsCustomFields = ({ externalContractId }) => {
  const response = useRequest({
    path: `/financing-structures/basic-agreements/${externalContractId}/custom-fields`,
    keys: ['financing-structures', 'basic-agreements', externalContractId, 'custom-fields'],
    translated: true,
    options: { enabled: !!externalContractId },
  })

  const parsed = customEntityResponseSchema.optional().safeParse(camelize(response.data))

  if (parsed.success) {
    return {
      ...response,
      data: customEntityResponseSchema.optional().parse(camelize(response.data)),
    }
  }

  // eslint-disable-next-line no-console
  console.error(parsed.error)
  return {
    ...response,
    data: undefined,
    isError: true,
    error: parsed.error,
  }
}

export default useBasicAgreementsCustomFields
