import { CardHeader } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Card from 'components/ui/card/Card'
import CustomFieldsTile from 'components/ui/customfield/CustomFieldsTile'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useBasicAgreementsCustomFieldConfig from 'hooks/services/deals/financing/basic-agreements/useBasicAgreementsCustomFieldConfig'
import useBasicAgreementsCustomFields from 'hooks/services/deals/financing/basic-agreements/useBasicAgreementsCustomFields'

const BasicAgreementsCustomFieldsCard = () => {
  const { t } = useTranslation('translation')
  const { trancheDisplayId: externalContractId } = useParams()

  const {
    data: { customFieldMetadata } = {},
    isFetching: isMetadataLoading,
    isError: isMetadataError,
  } = useBasicAgreementsCustomFieldConfig()

  const {
    data: { customFields } = {},
    isFetching: isCustomFieldsLoading,
    isError: isCustomFieldsError,
  } = useBasicAgreementsCustomFields({ externalContractId })

  return (
    <RequestStateResolver
      isLoading={isCustomFieldsLoading || isMetadataLoading}
      isError={isCustomFieldsError || isMetadataError}
      errorToDisplay={
        <Card header={<CardHeader titleText={t('title')} />}>
          <ErrorDataUnavailableInContent />
        </Card>
      }
      center
      renderContent={() => (
        <CustomFieldsTile
          customFields={customFields ?? []}
          config={customFieldMetadata ?? []}
          isEditable={false}
          cardHeaderTitle={t(
            'pages.deals.tranches.details.customer-specific-attributes-card.title',
          )}
          saveChanges={() => {}}
        />
      )}
    />
  )
}

export default BasicAgreementsCustomFieldsCard
