import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useCustomAssessmentsHelper from 'hooks/services/central-data/custom-assessments/useCustomAssessmentsHelper'
import useDeal from 'hooks/services/deals/useDeal'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const assessmentPage = 'GENERAL_INFORMATION'

const DealGeneralInformationDynamic = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })
  const {
    deal: { dealUuid },
    allowedOperations,
  } = useContext(DealContext)

  const { data: allowedOperationsPbb } = usePbbCustomServiceAllowedOperations()

  const isAllowedDealUsesUpdate = allowedOperations.includes(dealsPermissions.updateDealUses)
  const isAllowedDealRiskStrategyUpdate = allowedOperations.includes(
    dealsPermissions.updateDealRiskStrategy,
  )
  const isAllowedPricingRead = allowedOperations.includes(dealsPermissions.readPricing)
  const isAllowedPricingUpdate =
    allowedOperations.includes(dealsPermissions.updatePricing) &&
    allowedOperationsPbb?.allowedOperations.includes(dealsPermissions.updatePricingPBB)
  const allowedOperationsForReporting = dealsPermissions.updateDealReporting

  const { data: deal, isLoading: isLoadingDeal, isError: isErrorDeal } = useDeal(dealUuid)

  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const {
    isFetching: isCustomAssessmentsFetching,
    isError: isCustomAssessmentsError,
    showCustomAssessments,
  } = useCustomAssessmentsHelper({
    entityType: cwpEntityTypes.DEAL,
    page: assessmentPage,
  })

  const breadcrumb = useMemo(
    () => ({
      text: t('general-information'),
    }),
    [t],
  )

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [dealUuid],
  )

  const headerActions = createSegmentedHeaderActions(
    [toggleWorkingVersionButton, manageWorkingVersionButton],
    [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
  )

  const showCustomAssessmentsSubsection = useMemo(
    () => showCustomAssessments || isCustomAssessmentsFetching || isCustomAssessmentsError,
    [showCustomAssessments, isCustomAssessmentsFetching, isCustomAssessmentsError],
  )

  const pageData = useMemo(
    () => ({
      deal: isLoadingDeal || isErrorDeal ? {} : deal,
      allowedOperations,
      isAllowedDealUsesUpdate,
      isAllowedDealRiskStrategyUpdate,
      isAllowedPricingRead,
      isAllowedPricingUpdate,
      allowedOperationsForReporting,
      entityType: cwpEntityTypes.DEAL,
      assessmentPage,
      showCustomAssessmentsSubsection,
      isLoadingDeal,
      isErrorDeal,
    }),
    [
      deal,
      isLoadingDeal,
      isErrorDeal,
      allowedOperations,
      isAllowedDealUsesUpdate,
      isAllowedDealRiskStrategyUpdate,
      isAllowedPricingRead,
      isAllowedPricingUpdate,
      allowedOperationsForReporting,
      showCustomAssessmentsSubsection,
    ],
  )

  return (
    <ConfigurableComponentsPage
      pageData={pageData}
      pageCode={availableDynamicPagesEnum.DealsGeneralInformation}
      renderContent={({ children, pageConfig }) => (
        <DealDetailsWrapper
          isTabPage={showCustomAssessmentsSubsection}
          actions={headerActions}
          additionalProperties={{
            ...(showCustomAssessmentsSubsection && {
              includeUrlQuery: true,
              defaultSectionId: pageConfig.defaultTab,
            }),
          }}
          childBreadcrumbs={breadcrumb}
        >
          {children}
        </DealDetailsWrapper>
      )}
    />
  )
}

export default DealGeneralInformationDynamic
