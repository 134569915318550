import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Switch,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useInterestHedgingCollapsibleFields from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/useInterestHedgingCollapsibleFields'
import styles from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/useInterestRateHedgeFieldDefinitions.module.css'
import useInterestRateHedgeTypeField from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/useInterestRateHedgeTypeField'

const useInterestRateHedgeFieldDefinitions = ({
  data = {},
  calculatedRisksData = {},
  areCollapsibleFieldsOpen,
  setAreCollapsibleFieldsOpen,
  trancheCurrency,
  trancheMaturity,
  trancheOwnShareCommitment,
  setRequiredHedgeAmountTotalForRisksCalculation,
  setRequiredHedgeAmountShareForRisksCalculation,
  setDerivativeLineMaturityForRisksCalculation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.interest-rate-hedging-requirement-card',
  })
  const { format: formatDate } = useShortDateFormatter()

  let handleBulkOnChangeFunction = undefined

  const [requiredHedgeAmountTotal, setRequiredHedgeAmountTotal] = useState()
  const [requiredHedgeAmountShare, setRequiredHedgeAmountShare] = useState()

  useEffect(() => {
    setRequiredHedgeAmountTotal(data.requiredHedgeAmountTotal?.amount)
    setRequiredHedgeAmountShare(data.requiredHedgeAmountShare)
    setRequiredHedgeAmountTotalForRisksCalculation(data.requiredHedgeAmountTotal?.amount)
    setRequiredHedgeAmountShareForRisksCalculation(data.requiredHedgeAmountShare)
    setDerivativeLineMaturityForRisksCalculation(data.derivativeLineMaturity)
  }, [
    data,
    setRequiredHedgeAmountTotal,
    setRequiredHedgeAmountShare,
    setDerivativeLineMaturityForRisksCalculation,
    setRequiredHedgeAmountShareForRisksCalculation,
    setRequiredHedgeAmountTotalForRisksCalculation,
  ])

  useEffect(() => {
    if (!isEmpty(calculatedRisksData)) {
      const settlementRiskValue = calculatedRisksData.settlementRisk.valueHeadquarter
        ? calculatedRisksData.settlementRisk.valueHeadquarter
        : calculatedRisksData.settlementRisk.value
      const replacementRiskValue = calculatedRisksData.replacementRisk.valueHeadquarter
        ? calculatedRisksData.replacementRisk.valueHeadquarter
        : calculatedRisksData.replacementRisk.value
      handleBulkOnChangeFunction({
        settlementRisk: settlementRiskValue,
        replacementRisk: replacementRiskValue,
      })
    }
  }, [calculatedRisksData, handleBulkOnChangeFunction])

  const collapsibleFields = useInterestHedgingCollapsibleFields(data, {
    trancheCurrency,
    trancheMaturity,
    trancheOwnShareCommitment,
    requiredHedgeAmountTotal,
    setRequiredHedgeAmountTotal,
    requiredHedgeAmountShare,
    setRequiredHedgeAmountShare,
    setRequiredHedgeAmountTotalForRisksCalculation,
    setRequiredHedgeAmountShareForRisksCalculation,
    setDerivativeLineMaturityForRisksCalculation,
  })

  const resetAndPrefillFields = (handleBulkOnChange) => {
    handleBulkOnChange({
      interestRateHedgeTypeCode: '',
      derivativeLineMaturity: formatDate(trancheMaturity),
      requiredHedgeAmountTotal: trancheOwnShareCommitment,
      requiredHedgeAmountShare: trancheOwnShareCommitment ? '100.00' : '',
      settlementRisk: '',
      replacementRisk: '',
      derivativePremium: '',
      derivativeMargin: '',
      strikeFixedRate: '',
    })
    setRequiredHedgeAmountTotal(trancheOwnShareCommitment)
    setRequiredHedgeAmountShare(trancheOwnShareCommitment ? 1 : undefined)
    setRequiredHedgeAmountTotalForRisksCalculation(trancheOwnShareCommitment)
    setRequiredHedgeAmountShareForRisksCalculation('100.00')
    setDerivativeLineMaturityForRisksCalculation(trancheMaturity)
  }

  const interestRateHedgeTypeFields = useInterestRateHedgeTypeField({
    interestRateHedgeTypeCode: data.interestRateHedgeTypeCode,
    interestRateHedgeTypeShortText: data.interestRateHedgeTypeShortText,
  })

  return [
    {
      name: 'required',
      label: t('required'),
      value: data.required,
      customInfoComponent: (
        <FlexBox
          fitContainer
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          className={styles.requiredDisplayFieldWrapper}
        >
          <Label>{t('required')}</Label>
          <Text>{data.required ? t('yes') : t('no')}</Text>
        </FlexBox>
      ),
      isShownInDisplay: true,
      isShownInEdit: true,
      renderCustomEditComponent: ({ handleOnChange, handleBulkOnChange, fieldValues }) => {
        // as the switch is always rendered, this is a simple "hack" to get the `handleBulkOnChange` function
        handleBulkOnChangeFunction = handleBulkOnChange
        return (
          <EditCardItem
            label={t('required')}
            editComponent={
              <FlexBox justifyContent={FlexBoxJustifyContent.End}>
                <Switch
                  checked={fieldValues.required}
                  data-testid="interest-rate-hedge-required-switch"
                  onChange={(event) => {
                    setAreCollapsibleFieldsOpen(event.target.checked)
                    handleOnChange('required', event.target.checked)
                    if (event.target.checked) {
                      resetAndPrefillFields(handleBulkOnChange)
                    }
                  }}
                />
              </FlexBox>
            }
          />
        )
      },
    },
    ...((areCollapsibleFieldsOpen && interestRateHedgeTypeFields) || []),
    {
      name: 'comment',
      label: t('comment'),
      value: data.comment || '',
      customInfoComponent: (
        <FlexBox
          fitContainer
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          alignItems={FlexBoxAlignItems.Start}
          className={styles.commentWrapper}
        >
          <Label>{t('comment')}</Label>
          <Text className={styles.commentDisplayText}>{data.comment}</Text>
        </FlexBox>
      ),

      isShownInDisplay: true,
      isShownInEdit: true,

      editComponentType: editComponentTypes.TextArea,
      editComponentProps: { rows: 3 },
    },
    ...((areCollapsibleFieldsOpen && collapsibleFields) || []),
  ]
}

export default useInterestRateHedgeFieldDefinitions
