import {
  Input,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  TableRow,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/borrower/DealBorrowerEditRow.module.css'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import SaveAndCancelButton from 'components/domains/deals/tables/SaveAndCancelButton'
import { useShowMessageBox } from 'components/ui/message-box/MessageBox'
import TableCell from 'components/ui/tables/cells/TableCell'
import useBusinessPartnerRoleAssign from 'hooks/services/business-partners/roles/useBusinessPartnerRoleAssign'
import useUpdateDealBorrower from 'hooks/services/deals/borrower/useUpdateDealBorrower'
import useRoleConfig, { RoleConfig } from 'hooks/services/deals/configurations/useRoleConfig'
import { DealContext } from 'routes/deals/DealContext'

const borrowerProps = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
})

const dealBorrowerEditRowProps = {
  dealBorrower: borrowerProps,
  onClose: PropTypes.func,
}

const formatDealBorrower = ({ id, fullName } = {}) => {
  if (!fullName) {
    return ''
  }
  return id ? `${fullName} (${id})` : fullName
}

const DealBorrowerEditRow = ({ dealBorrower, onClose = () => {} }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.borrower' })
  const showToast = Modals.useShowToast()
  const showMessageBox = useShowMessageBox()
  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()

  const {
    deal: { dealUuid },
  } = useContext(DealContext)
  const { data: borrowerGroupRoles = [] } = useRoleConfig(RoleConfig.BORROWER_GROUP)
  const { data: [prospectBorrowerRole] = [] } = useRoleConfig(RoleConfig.BORROWER)

  const [businessPartner, setBusinessPartner] = useState({
    ...dealBorrower,
    isBorrower: true,
  })

  const { mutate: updateDealBorrower, isLoading: isLoadingUpdateDealBorrower } =
    useUpdateDealBorrower({
      onSuccess: () => {
        onClose()
        showToast({ children: t('deal-borrower-update.success') }, document.body)
        queryClient.invalidateQueries(['deals', dealUuid])
      },
      onError: async (error) => {
        const { errors: [errorResponse] = [] } = await error.response.json()
        showErrorMessageBox({
          message: t('deal-borrower-update.error'),
          error: errorResponse,
        })
      },
    })

  const { mutate: assignRole, isLoading: isLoadingAssignRole } = useBusinessPartnerRoleAssign({
    onSuccess: () => {
      updateDealBorrower({ dealUuid, borrowerBpId: businessPartner.id })
    },
    onError: async (error) => {
      const errorResponse = await error.response.json()
      showErrorMessageBox({
        message: t('business-partner-role-add.error'),
        error: errorResponse,
      })
    },
  })

  const changeSelection = (id, { roles = [], name, orgName1 } = {}) => {
    const isBorrower = roles
      ?.flatMap((role) => role.backEndRoles)
      ?.some((backEndRole) => borrowerGroupRoles.includes(backEndRole.id))
    setBusinessPartner({ id, fullName: name || orgName1, roles, isBorrower })
  }

  const saveChanges = () => {
    if (!businessPartner.isBorrower) {
      assignRole({
        businessPartnerId: businessPartner.id,
        roleId: prospectBorrowerRole,
      })
      return
    }
    updateDealBorrower({ dealUuid, borrowerBpId: businessPartner.id })
  }

  const onSaveClick = () => {
    showMessageBox({
      type: MessageBoxTypes.Warning,
      children: t('business-partner-role-add.warning'),
      actions: [MessageBoxActions.OK, MessageBoxActions.Cancel],
      onClose: (e) => e.detail.action === MessageBoxActions.OK && saveChanges(),
    })
  }

  const valueState =
    businessPartner?.id && !businessPartner.isBorrower ? ValueState.Information : ValueState.None

  return (
    <TableRow>
      <TableCell>
        <BusinessPartnerAutocompleteInput
          defaultValue={formatDealBorrower(businessPartner)}
          suggestionItemProps={({ name: fullName, id }) => ({
            text: formatDealBorrower({ id, fullName }),
          })}
          valueForUnknown={(text) => ({ name: text })}
          onBusinessPartnerSelected={changeSelection}
          withCreateOption
          createDialogOptions={{
            title: t('deal-borrower-create'),
            creationRole: prospectBorrowerRole,
          }}
          valueState={valueState}
        />
      </TableCell>
      <TableCell key="partnerFunction">
        <Input className={styles.partnerFunction} readonly value={t('deal-borrower')} />
      </TableCell>
      <TableCell>
        <SaveAndCancelButton
          onSave={onSaveClick}
          onCancel={onClose}
          saveDisabled={!businessPartner.id}
          isLoading={isLoadingUpdateDealBorrower || isLoadingAssignRole}
          showCancelPopover={businessPartner.id !== dealBorrower.id}
        />
      </TableCell>
    </TableRow>
  )
}

DealBorrowerEditRow.propTypes = dealBorrowerEditRowProps

export { borrowerProps }
export default DealBorrowerEditRow
