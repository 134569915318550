import { useTranslation } from 'react-i18next'
import { useDurationTypeCodes } from 'hooks/services/deals/financing/configGetters/useDurationTypeCodes'

const useDurationTypeFields = ({ durationTypeCode, durationTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'durationTypeCode',
      label: t('duration-type'),
      value: durationTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'durationTypeCode',
      label: t('duration-type'),
      value: durationTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useDurationTypeCodes,
        selectionName: 'durationTypeCodes',
      },
    },
  ]
}

export default useDurationTypeFields
