import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useStandardDateField = ({ name, label, value, validationFunction }) => {
  const { format } = useShortDateFormatter()

  return [
    {
      label,
      name,
      value: format(value),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label,
      name,
      value: format(value),

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: editComponentTypes.DatePicker,
      validationFunction,
    },
  ]
}

useStandardDateField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
}

export default useStandardDateField
