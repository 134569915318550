import { Text } from '@fioneer/ui5-webcomponents-react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import styles from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useCoverpoolFields.module.css'

const useCoverpoolFields = ({
  coverpoolEligibleAmount,
  coverpoolEligibleAmountHeadquarter,
  timeToCoverpoolInMonths,
}) => {
  const formattedNumberInputRef = useRef()
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  const renderCoverpoolEligibleAmountEditInput = ({ handleOnChange, fieldValues }) => (
    <EditCardItem
      value={2000}
      label={t('coverpool-eligible-amount')}
      editComponent={
        <FormattedNumberInput
          value={fieldValues?.coverpoolEligibleAmount?.amount}
          icon={
            <Text className={styles.inputPaddingRight}>
              {fieldValues.totalCommitment?.currency}
            </Text>
          }
          iconEnd="true"
          onInput={(number) => {
            const enteredValue = formattedNumberInputRef?.current?.value
            const isValidInput = formattedNumberInputRef?.current?.isValid
            handleOnChange(
              'coverpoolEligibleAmount',
              {
                amount: isValidInput ? number : enteredValue,
                currency: fieldValues.totalCommitment?.currency,
              },
              true,
              false,
              () => isValidInput,
            )
          }}
          onBlur={(event) =>
            handleOnChange(
              'coverpoolEligibleAmount',
              {
                amount: event.target.value,
                currency: fieldValues.totalCommitment?.currency,
              },
              false,
              false,
              () => formattedNumberInputRef?.current?.isValid,
            )
          }
          ref={formattedNumberInputRef}
        />
      }
    />
  )

  return [
    {
      name: 'coverpoolEligibleAmount',
      label: t('coverpool-eligible-amount'),
      value: formatMoney(coverpoolEligibleAmount?.amount, coverpoolEligibleAmount?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: true,
      isShownInEdit: false,

      editComponentType: 'Input',
      editComponentProps: { type: 'Number' },
    },
    {
      name: 'coverpoolEligibleAmount',
      customInfoComponent: ConvertedAmount(
        coverpoolEligibleAmountHeadquarter?.amount,
        coverpoolEligibleAmountHeadquarter?.currency,
      ),

      isShownInDisplay:
        !!coverpoolEligibleAmount &&
        !!coverpoolEligibleAmountHeadquarter &&
        coverpoolEligibleAmount?.currency !== coverpoolEligibleAmountHeadquarter?.currency,
      isShownInEdit: false,
    },
    {
      name: 'coverpoolEligibleAmount',
      value: coverpoolEligibleAmount,

      isShownInDisplay: false,
      isShownInEdit: true,

      renderCustomEditComponent: renderCoverpoolEligibleAmountEditInput,
    },
    {
      name: 'timeToCoverpool',
      label: t('time-to-coverpool'),
      value:
        timeToCoverpoolInMonths &&
        `${timeToCoverpoolInMonths} ${t('month', { count: timeToCoverpoolInMonths })}`,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'timeToCoverpool',
      label: t('time-to-coverpool'),
      value: timeToCoverpoolInMonths,

      isShownInDisplay: false,
      isShownInEdit: true,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        icon: (
          <Text className={styles.inputPaddingRight}>
            {
              t('months_general') // always show plural in edit mode
            }
          </Text>
        ),
      },
    },
  ]
}

export default useCoverpoolFields
