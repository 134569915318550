import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import {
  amountPropTypes,
  AmountWithHeadquarterCurrency,
} from 'components/domains/deals/pricing/AmountWithHeadquarterCurrency'
import styles from 'components/domains/deals/pricing/DealPricingPanel.module.css'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'

const dealPricingGeneralInformationPanelPropTypes = {
  data: PropTypes.shape({
    customer_margin_average: PropTypes.number,
    gross_interest_margin3m_euribor: PropTypes.number,
    gross_interest_margin_on: PropTypes.number,
    gross_revenue_margin3m_euribor: PropTypes.number,
    gross_revenue_margin_on: PropTypes.number,
    net_revenue_margin: PropTypes.number,
    funding_spread_drawdown3m_ref: PropTypes.number,
    funding_spread_drawdown_on: PropTypes.number,
    economic_profit_margin_full_cost: PropTypes.number,
    economic_profit_amount_full_cost: amountPropTypes,
    economic_profit_amount_full_cost_headquarter: amountPropTypes,
    roe_cap_full_cost: PropTypes.number,
    economic_profit_margin_direct_cost: PropTypes.number,
    economic_profit_amount_direct_cost: amountPropTypes,
    economic_profit_amount_direct_cost_headquarter: amountPropTypes,
    economic_profit_margin_lower_cost: PropTypes.number,
    economic_profit_amount_lower_cost: amountPropTypes,
    economic_profit_amount_lower_cost_headquarter: amountPropTypes,
    economic_profit_from_customer_derivative: amountPropTypes,
    economic_profit_from_customer_derivative_headquarter: amountPropTypes,
  }),
}

export const DealPricingGeneralInformationPanel = ({ data = {} }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.pricing' })

  const formatShare = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return (
    <Panel header={<Text>{t('general-info-panel-title')}</Text>}>
      <div className={styles.section}>
        <CardShowItem
          className={styles.highlighted}
          label={t('customerMarginAverage')}
          value={formatShare(data.customer_margin_average)}
        />
        <CardShowItem
          label={t('grossInterestMargin3mEuribor')}
          value={formatShare(data.gross_interest_margin3m_euribor)}
        />
        <CardShowItem
          label={t('grossInterestMarginOn')}
          value={formatShare(data.gross_interest_margin_on)}
        />
        <CardShowItem
          label={t('grossRevenueMargin3mEuribor')}
          value={formatShare(data.gross_revenue_margin3m_euribor)}
        />
        <CardShowItem
          label={t('grossRevenueMarginOn')}
          value={formatShare(data.gross_revenue_margin_on)}
        />
      </div>
      <div className={styles.section}>
        <CardShowItem
          className={styles.highlighted}
          label={t('fundingSpreadDrawdown3mRef')}
          value={formatShare(data.funding_spread_drawdown3m_ref)}
        />
        <CardShowItem
          className={styles.highlighted}
          label={t('fundingSpreadDrawdownOn')}
          value={formatShare(data.funding_spread_drawdown_on)}
        />
        <CardShowItem
          className={styles.highlighted}
          label={t('netRevenueMargin')}
          value={formatShare(data.net_revenue_margin)}
        />
      </div>
      <div className={styles.section}>
        <Text className={styles['label-only']}>{t('full-cost')}</Text>
        <CardShowItem
          label={t('economicProfitMarginFullCost')}
          value={formatShare(data.economic_profit_margin_full_cost)}
        />
        <CardShowItem
          label={t('economicProfitAmountFullCost')}
          value={
            <AmountWithHeadquarterCurrency
              amount={data.economic_profit_amount_full_cost}
              headquarterAmount={data.economic_profit_amount_full_cost_headquarter}
            />
          }
        />
        <CardShowItem label={t('roeCapFullCost')} value={formatShare(data.roe_cap_full_cost)} />
      </div>
      <div className={styles.section}>
        <Text className={styles['label-only']}>{t('direct-cost')}</Text>
        <CardShowItem
          label={t('economicProfitMarginDirectCost')}
          value={formatShare(data.economic_profit_margin_direct_cost)}
        />
        <CardShowItem
          label={t('economicProfitAmountDirectCost')}
          value={
            <AmountWithHeadquarterCurrency
              amount={data.economic_profit_amount_direct_cost}
              headquarterAmount={data.economic_profit_amount_direct_cost_headquarter}
            />
          }
        />
      </div>
      <div className={styles.section}>
        <Text className={styles['label-only']}>{t('lower-cost')}</Text>
        <CardShowItem
          label={t('economicProfitMarginLowerCost')}
          value={formatShare(data.economic_profit_margin_lower_cost)}
        />
        <CardShowItem
          label={t('economicProfitAmountLowerCost')}
          value={
            <AmountWithHeadquarterCurrency
              amount={data.economic_profit_amount_lower_cost}
              headquarterAmount={data.economic_profit_amount_lower_cost_headquarter}
            />
          }
        />
      </div>
      <div className={styles.section}>
        <CardShowItem
          className={styles.highlighted}
          label={t('economicProfitFromCustomerDerivative')}
          value={
            <AmountWithHeadquarterCurrency
              amount={data.economic_profit_from_customer_derivative}
              headquarterAmount={data.economic_profit_from_customer_derivative_headquarter}
            />
          }
        />
      </div>
    </Panel>
  )
}

DealPricingGeneralInformationPanel.propTypes = dealPricingGeneralInformationPanelPropTypes
