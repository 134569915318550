import { Icon, Input, SuggestionItem, ValueState } from '@fioneer/ui5-webcomponents-react'
import '@ui5/webcomponents-icons/dist/AllIcons.js'
import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/input/BusinessPartnerInput.module.css'
import BusinessPartnerSearchDialog from 'components/domains/deals/input/BusinessPartnerSearchDialog'
import { openInputPicker } from 'components/domains/properties/rent-roll/working-version/openInputPicker'
import { useBusinessPartnerById } from 'hooks/services/business-partners/getBusinessPartners'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'

const ValueHelpIcon = ({ slot, id, handleOnClick, ...otherIconProperties }) => (
  <Icon id={id} slot={slot} name="value-help" onClick={handleOnClick} {...otherIconProperties} />
)

ValueHelpIcon.propTypes = {
  slot: PropTypes.any,
  id: PropTypes.string,
  handleOnClick: PropTypes.func,
}

const initialSorting = { columnKey: 'id', orderBy: 'asc' }
const BusinessPartnerInput = ({
  type,
  onChange,
  value,
  className,
  isRequired = false,
  customValueState,
  customValueStateMessage,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.deals.input.business-partner',
  })
  const inputRef = useRef()
  const preselectedRoles = null

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const { data: { fullName: businessPartnerName = value?.name } = {} } = useBusinessPartnerById(
    value?.id,
  )

  // suppressing loading / error state since the auto-selecting is a nice-to-have
  const { data: { businessPartners = [] } = {} } = useMatchingBusinessPartnersByNameOrId({
    searchKey: businessPartnerName,
    minLength: 3,
    roles: preselectedRoles,
    excludeInactive: true,
    sort: translateSortSettingToSortParameter(initialSorting),
  })

  const handleSearchDialogChange = ({ name, id, roles, status }) => {
    onChange({ name: name ?? '', id: id ?? '', roles: roles ?? [], status: status ?? '' })
  }

  const handleOnInput = (e) => {
    setShowSuggestions(true)
    onChange({ name: e.target.value, id: '', roles: [] })
  }

  const handleSelectedSuggestion = (event) => {
    const { id, name } = event.detail.item.dataset
    const { roles, status } = businessPartners.find((bp) => bp.id === id) ?? {}
    onChange({ id, name, roles, status })
  }

  const handleSearchDialogClose = () => {
    setIsSearchDialogOpen(false)
    // needed to show custom value state messages even after the search dialog closed
    // message only shows together with suggestions, therefore temporarily hide suggestions
    setShowSuggestions(false)
  }

  useEffect(() => {
    if (customValueStateMessage && !showSuggestions) {
      inputRef?.current?.focus()
    }
  }, [customValueStateMessage, showSuggestions])

  const handleFocus = (e) => {
    e.stopPropagation()
    if (!businessPartnerName?.length) return
    openInputPicker(inputRef)
  }

  // This is a hack for the React Table. It seems like react table listens to the space event and stops
  // the propagation of this event to other components like the input. With this the event is not handed to
  // other components but the input which solves the behaviour for us.
  const onKeyDown = (e) => {
    if (e.code === 'Space' || e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
      e.stopPropagation()
    }
  }

  const suggestions = businessPartners.map(({ id, name }) => (
    <SuggestionItem key={id} text={name} additionalText={id} data-id={id} data-name={name} />
  ))

  const businessPartnerValueState = useMemo(() => {
    if (customValueState) {
      return customValueState
    } else if (!value?.id && (!isEmpty(value?.name) || isRequired)) {
      return ValueState.Error
    }
    return ValueState.None
  }, [customValueState, isRequired, value?.id, value?.name])

  const businessPartnerValueStateMessage = useMemo(() => {
    if (customValueStateMessage) {
      return customValueStateMessage
    }
    return t(type, { keyPrefix: 'components.deals.input.business-partner.hint.empty' })
  }, [customValueStateMessage, t, type])

  return (
    <>
      <Input
        id={'business-partner-input'}
        ref={inputRef}
        noTypeahead
        showSuggestions
        onSuggestionItemSelect={handleSelectedSuggestion}
        className={[styles.input, className ?? ''].join(' ')}
        value={businessPartnerName}
        valueState={businessPartnerValueState}
        valueStateMessage={<span>{businessPartnerValueStateMessage}</span>}
        onInput={handleOnInput}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        icon={
          <ValueHelpIcon
            className={styles.actionIcon}
            handleOnClick={() => setIsSearchDialogOpen(true)}
          />
        }
      >
        {suggestions && showSuggestions && [...suggestions]}
      </Input>
      {isSearchDialogOpen &&
        createPortal(
          <BusinessPartnerSearchDialog
            open={isSearchDialogOpen}
            initialSearch={businessPartnerName}
            onClose={handleSearchDialogClose}
            initialExcludeInactive={true}
            type={type}
            onChange={handleSearchDialogChange}
          />,
          document.body,
        )}
    </>
  )
}

BusinessPartnerInput.propTypes = {
  type: PropTypes.oneOf(['borrower', 'party', 'guarantor']).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  customValueState: PropTypes.string,
  customValueStateMessage: PropTypes.string,
}

export default BusinessPartnerInput
