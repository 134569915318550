import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateDealBorrower = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, borrowerBpId }) => {
      await put({
        path: `/deals/${dealUuid}/borrower`,
        body: { borrower_bp_id: borrowerBpId },
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateDealBorrower
