import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import DealRiskConformityDeviationMultiComboBox from 'components/domains/deals/risk-conformity/DealRiskConformityDeviationMultiComboBox'
import styles from 'components/domains/deals/risk-conformity/DealRiskConformityEdit.module.css'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import TextAreaWithMaxCharacterRestriction from 'components/ui/input/TextAreaWithMaxCharacterRestriction'

const dealRiskConformityEditProps = {
  formState: PropTypes.object.isRequired,
  businessSegmentCode: PropTypes.string,
  updateFormState: PropTypes.func.isRequired,
  updateDeprecatedDeviations: PropTypes.func.isRequired,
}

const DealRiskConformityEdit = ({
  formState: { compliance, deviationCodes = [], comment } = {},
  businessSegmentCode,
  updateFormState = (_) => {},
  updateDeprecatedDeviations = (_) => {},
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.risk-conformity' })

  const updateCompliance = (event) => {
    const { checked } = event.target
    updateFormState({
      compliance: checked,
      ...(checked ? { deviationCodes: [] } : {}),
    })
  }

  const updateDeviations = (newDeviations) => {
    updateFormState({ deviationCodes: newDeviations })
  }

  const updateComment = (value) => {
    updateFormState({ comment: value })
  }

  return (
    <>
      <CardSection>
        <CardEditItem
          label={t('compliance')}
          value={
            <LabeledSwitch isLoading={false} onChange={updateCompliance} checked={!!compliance} />
          }
        />
        {!compliance && (
          <CardEditItem
            label={t('deviations')}
            required
            value={
              <DealRiskConformityDeviationMultiComboBox
                deviationCodes={deviationCodes}
                businessSegmentCode={businessSegmentCode}
                onDeprecatedDeviationsUpdate={updateDeprecatedDeviations}
                onDeviationsUpdate={updateDeviations}
              />
            }
          />
        )}
      </CardSection>
      <CardSection title={t('comment')} required={!compliance}>
        <TextAreaWithMaxCharacterRestriction
          className={styles.textarea}
          value={comment ?? ''}
          onInput={updateComment}
          maxLength={1000}
        />
      </CardSection>
    </>
  )
}

DealRiskConformityEdit.propTypes = dealRiskConformityEditProps

export default DealRiskConformityEdit
