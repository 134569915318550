import { createContext, useCallback, useContext, useMemo } from 'react'

/**
 * @typedef {Object} DynamicComponentProps
 * @property {unknown} contextData - global context data like query client provided to each component
 * @property {unknown} pageData - data provided by the page component as context
 * @property {Record<string, unknown>} staticData - static data provided in the page component config
 */

/**
 * @typedef {{isLoading?: boolean, isError?: boolean, error?: unknown, renderComponent: boolean}} ComponentDisplayHookResult
 * @typedef {Object} ComponentConfig
 * @property {(props: DynamicComponentProps) => import('react').ReactNode} Component - The Component to render
 * @property {string | undefined} name - An optional name for the component. Will fall back to the Component displayName if not set.
 * @property {string | undefined} description - An opitonal description for the Component
 * @property {'Adapter' | 'Component'} type - the component type. Adapter are plain JS functions returning a JSX element.
 * @property {(props: DynamicComponentProps) => ComponentDisplayHookResult} componentDisplayHook - an optional hook that determines whether the component should be rendered or not
 */

export const ComponentTypeEnum = {
  Adapter: 'Adapter',
  Component: 'Component',
}

class ComponentRegistry {
  /** @type {ComponentRegistry | undefined} */
  #parent

  /** @type {string} */
  #name

  /** @type {Map<string, ComponentConfig>} */
  #components = new Map()
  #cacheKey = 0

  /**
   * @typedef {Object} Params
   * @property {ComponentRegistry | undefined} parent
   * @property {string} name
   * @param {Params}
   */
  constructor({ parent, name }) {
    this.#parent = parent
    this.#name = name
  }

  /**
   * @returns {string}
   */
  get name() {
    return this.#name
  }

  get cacheKey() {
    const parentCacheKey = this.#parent?.cacheKey
    return parentCacheKey ? `${parentCacheKey}-${this.#cacheKey}` : this.#cacheKey
  }

  /**
   * @param {string} code
   * @param {ComponentConfig} componentConfig
   */
  register(code, componentConfig) {
    if (this.#components.has(code)) {
      return
    }
    this.#cacheKey++
    this.#components.set(code, {
      ...componentConfig,
      type: componentConfig.type ?? ComponentTypeEnum.Component,
    })
  }

  /**
   * @param {string} code
   * @returns {ComponentConfig}
   */
  resolve(code) {
    const resolvedComponent = this.#components.get(code) ?? this.#parent?.resolve(code)
    if (!resolvedComponent) {
      return undefined
    }
    return resolvedComponent
  }

  clear() {
    this.#cacheKey++
    this.#components.clear()
  }
}

const ComponentRegistryContext = createContext(new ComponentRegistry({ name: 'root' }))

/**
 * @returns {ComponentRegistry}
 */
export const useGetComponentRegistry = () => useContext(ComponentRegistryContext)

export const useCreateComponentRegistry = (registryName) => {
  const parentCardRegistry = useContext(ComponentRegistryContext)
  const componentRegistry = useMemo(
    () => new ComponentRegistry({ parent: parentCardRegistry, name: registryName }),
    [parentCardRegistry, registryName],
  )
  const RegistryProvider = useCallback(
    ({ children }) => (
      <ComponentRegistryContext.Provider value={componentRegistry}>
        {children}
      </ComponentRegistryContext.Provider>
    ),
    [componentRegistry],
  )
  RegistryProvider.displayName = 'ComponentRegistryProvider'
  return { ComponentRegistryProvider: RegistryProvider, registry: componentRegistry }
}
