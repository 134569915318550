import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import TranchePricingListItem from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingListItem'
import styles from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingAdditionalInformationPanel.module.css'

const TranchePricingAdditionalInformationPanel = ({ pricingData }) => {
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const tranchePricingListItems = [
    {
      label: t('pages.deals.tranches.details.pricing-card.grossInterestMargin3mRef'),
      value: formatShare(pricingData.grossInterestMargin3mRef),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.fundingSpreadTranche3mRef'),
      value: formatShare(pricingData.fundingSpreadTranche3mRef),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.fundingSpreadTrancheOn'),
      value: formatShare(pricingData.fundingSpreadTrancheOn),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.fundingSpreadCustomer'),
      value: formatShare(pricingData.fundingSpreadCustomer),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.irMargin3mRef'),
      value: formatShare(pricingData.irMargin3mRef),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.irMarginOn'),
      value: formatShare(pricingData.irMarginOn),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.transferMargin'),
      value: formatShare(pricingData.transferMargin),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.lossGivenDefaultMarginOfConservatism'),
      value: formatShare(pricingData.lossGivenDefaultMarginOfConservatism),
    },
  ]

  return (
    <Panel
      header={
        <Text>{t('pages.deals.tranches.details.pricing-card.additional-info-panel-title')}</Text>
      }
      collapsed={true}
    >
      <div className={styles.panelContent}>
        {tranchePricingListItems.map((item) => (
          <TranchePricingListItem key={item.label} label={item.label} value={item.value} />
        ))}
      </div>
    </Panel>
  )
}

TranchePricingAdditionalInformationPanel.propTypes = {
  pricingData: PropTypes.shape({
    grossInterestMargin3mRef: PropTypes.number,
    fundingSpreadTranche3mRef: PropTypes.number,
    fundingSpreadTrancheOn: PropTypes.number,
    fundingSpreadCustomer: PropTypes.number,
    irMargin3mRef: PropTypes.number,
    irMarginOn: PropTypes.number,
    transferMargin: PropTypes.number,
    lossGivenDefaultMarginOfConservatism: PropTypes.number,
  }),
}

export default TranchePricingAdditionalInformationPanel
