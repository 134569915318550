import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useBusinessPartnerRelationshipDelete = ({ onSuccess, onError }) => {
  const { delete: deleteRelationship } = useAccessTokenRequest()

  return useMutation(
    async ({ businessPartnerId, ...body }) => {
      await deleteRelationship({
        path: `/businesspartners/${businessPartnerId}/relationships`,
        body: snakecaseKeys(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useBusinessPartnerRelationshipDelete
