import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteTrancheFee = (options) => {
  const { delete: deleteTrancheFee } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, trancheId, feeId }) =>
      await deleteTrancheFee({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/fees/${feeId}`,
        keys: ['deals', dealId, 'tranches', trancheId, 'fees'],
      }),
    options,
  )
}

export default useDeleteTrancheFee
