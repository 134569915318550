import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Option from 'components/ui/input/Option'
import Select from 'components/ui/input/Select'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'

const PROPERTY_KEY = 'currencyCode'

const CurrencySelect = () => {
  const { t } = useTranslation()

  const {
    data: { currencyCodes = [] },
    isFetching,
    isError,
  } = useCurrencyCodes()

  const { register, unregister, formState } = useFormContext()
  const error = formState.errors[PROPERTY_KEY]

  useEffect(() => () => unregister(PROPERTY_KEY), [unregister])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label showColon required for="tranche-currency-code-select">
        {t('pages.deals.tranche-create.basic-information.tranche-currency')}
      </Label>
      <Select
        id="tranche-currency-code-select"
        isFetching={isFetching}
        isFetchingError={isError}
        error={error}
        {...register(PROPERTY_KEY, { required: true })}
      >
        <Option value="" />
        {currencyCodes.map((currencyCode) => (
          <Option key={currencyCode.key} value={currencyCode.key}>
            {currencyCode.display_name}
          </Option>
        ))}
      </Select>
    </FlexBox>
  )
}

export default CurrencySelect
