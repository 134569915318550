import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteDealUse = ({ onSuccess, onError }) => {
  const { delete: deleteUse } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, useUuid }) =>
      await deleteUse({
        path: `/deals/${dealUuid}/uses/${useUuid}`,
      }),
    { onSuccess, onError },
  )
}

export default useDeleteDealUse
