import {
  ROW_TYPE_CONDITION,
  TABLE_ROW_HEIGHT,
} from 'components/domains/business-partners/tile/financial-product/derivatives/constants'

const mapDerivativeConditionsData = (conditions = []) =>
  conditions.map((condition) => {
    const {
      conditionId,
      conditionType,
      counterpartyId,
      amortization,
      startDate,
      maturityDate,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      buyOrSell,
      payOrReceive,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      strikeRate,
      endOfFixedInterestPeriod,
    } = condition ?? {}

    return {
      dealTradeCondition: {
        id: conditionId,
      },
      productSubType: conditionType,
      counterparty: counterpartyId,
      amortization,
      startDate,
      maturityDate,
      buyOrSell,
      payOrReceive,
      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRateStrikeRate: {
        currentRate,
        strikeRate,
      },
      endOfFixedInterestPeriod,
      rowType: ROW_TYPE_CONDITION,
      rowHeight: TABLE_ROW_HEIGHT,
    }
  })

export default mapDerivativeConditionsData
