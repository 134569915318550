import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useAdditionalInterestInformation from 'hooks/services/deals/financing/useAdditionalInterestInformation'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import useUpdateAdditionalInterestInformation from 'hooks/services/deals/financing/useUpdateAdditionalInterestInformation'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import useAdditionalInterestInformationFieldDefinitions from 'routes/deals/financing/trancheDetails/cards/additional-interest-information-card/fields/useAdditionalInterestInformationFieldDefinitions'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

const AdditionalInterestInformationCard = () => {
  const { t } = useTranslation()

  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const { parse: parseDate, localePattern } = useShortDateFormatter()

  const { trancheDisplayId } = useParams()
  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)
  const {
    data: additionalInterestInformationData,
    isLoading,
    isError,
    isFetching,
  } = useAdditionalInterestInformation(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const {
    mutate: updateAdditionalInterestInformation,
    isSuccess: isSuccessUpdateAdditionalInterestInformation,
    isError: isErrorUpdateAdditionalInterestInformation,
  } = useUpdateAdditionalInterestInformation({
    onSuccess: () => {
      setIsWritingToBackend(false)
      queryClient.invalidateQueries([
        'deals',
        deal.dealUuid,
        'tranches',
        resolvedTrancheData.trancheId,
        'additionalInterestInformation',
      ])
      showToast({ children: t('toast.changes-saved') })
    },
    onError: () => {
      setIsWritingToBackend(false)
      showToast({
        children: t(
          'pages.deals.tranches.details.additional-interest-information-card.toast-message.error',
        ),
      })
    },
  })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: additionalInterestInformationData?.lastUpdated?.name,
    timestamp: additionalInterestInformationData?.lastUpdated?.lastUpdatedOn,
  })

  const onSaveChanges = (newValues) => {
    setIsWritingToBackend(true)
    newValues.contractualFloorStrike = convertPercentageToDecimal(newValues.contractualFloorStrike)

    newValues.endOfTheFixedInterestPeriod = parseDate(
      newValues.endOfTheFixedInterestPeriod,
      localePattern,
    )

    updateAdditionalInterestInformation({
      dealId: deal?.dealUuid,
      trancheId: resolvedTrancheData.trancheId,
      ...newValues,
    })
  }

  const fieldDefinitions = useAdditionalInterestInformationFieldDefinitions(
    additionalInterestInformationData,
  )

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('pages.deals.tranches.details.additional-interest-information-card.title')}
      cardHeaderSubtitle={lastEditedText}
      fieldDefinitions={fieldDefinitions}
      additionalEditHeaderProps={{ isSaveLoading: isWritingToBackend }}
      // Overwrites the loading state used for the LoadingStateWrapper after save has been clicked.
      // The above `isSaveLoading` is used instead to set an inert card state
      isHandlingSaveOverwrite={false}
      // we only pass the fetching state as the data for this card is delivered by a prop
      // and the card itself is already wrapped in a LoadingStateWrapper for the initial loading
      isLoading={isFetching || isLoading}
      isEmpty={false}
      isEditable={
        !isExistingBusinessViewSet &&
        allowedOperations.includes(FINANCING_TRANCHE_ADDITIONAL_INTEREST_INFORMATION_UPDATE)
      }
      isError={isError}
      fillEmptyValuesWithPlaceholder={true}
      saveChanges={onSaveChanges}
      saveHookIsError={isErrorUpdateAdditionalInterestInformation}
      saveHookIsSuccess={isSuccessUpdateAdditionalInterestInformation}
    />
  )
}

export default AdditionalInterestInformationCard
