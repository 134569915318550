import { useTranslation } from 'react-i18next'
import { useRevolvingTypeCodes } from 'hooks/services/deals/financing/configGetters/useRevolvingTypeCodes'

const useRevolvingTypeFields = ({ revolvingTypeCode, revolvingTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'revolvingTypeCode',
      label: t('revolving-type'),
      value: revolvingTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'revolvingTypeCode',
      label: t('revolving-type'),
      value: revolvingTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useRevolvingTypeCodes,
        selectionName: 'revolvingTypeCodes',
      },
    },
  ]
}

export default useRevolvingTypeFields
