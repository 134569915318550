import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

export const useUpdateDeal = (options) => {
  const { patch } = useAccessTokenRequest()
  const { parse: parseDate, localePattern } = useShortDateFormatter()

  return useMutation(async ({ dealUuid, dealUpdates }) => {
    dealUpdates.actualSigningDate = parseDate(dealUpdates.actualSigningDate, localePattern)
    delete dealUpdates['businessSegmentCode']
    delete dealUpdates['dealId']

    const { data } = await patch({
      path: `/deals/${dealUuid}`,
      body: snakecaseKeys(dealUpdates, { deep: true }),
    })
    return data
  }, options)
}
