import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCalculateInterestRateHedgingRisks = (options, parameters) => {
  const { post } = useAccessTokenRequest()
  const { dealId, trancheId } = parameters

  return useMutation(
    async ({
      data: { derivativeLineMaturity, requiredHedgeAmountTotal, trancheFirstDrawdownDate },
    }) =>
      await post({
        path: `/pbb-custom-service/deals/${dealId}/tranches/${trancheId}/interest-rate-hedging-requirements/risk-calculation`,
        body: {
          tranche_id: trancheId,
          derivative_line_maturity: derivativeLineMaturity,
          required_hedge_amount_total: requiredHedgeAmountTotal,
          tranche_first_drawdown_date: trancheFirstDrawdownDate,
        },
      }),
    options,
  )
}
