import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateTranche = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  const getStringOrUndefinedIfNotSet = (attribute) => (attribute ? attribute : undefined)
  const getNumberOrUndefinedIfNotSet = (attribute) =>
    attribute && !isNaN(attribute) ? Number(attribute) : undefined

  return useMutation(
    async ({ tranche, deal }) => {
      const { data } = await post({
        path: `/financing-structures/deals/${deal?.dealUuid}/tranches`,
        body: {
          tranche_name: getStringOrUndefinedIfNotSet(tranche?.name),
          product_type_code: getStringOrUndefinedIfNotSet(tranche?.productTypeCode),
          loan_type_code: getStringOrUndefinedIfNotSet(tranche?.loanTypeCode),
          currency: getStringOrUndefinedIfNotSet(tranche?.currencyCode),
          total_commitment: getNumberOrUndefinedIfNotSet(tranche?.totalCommitment),
          own_share: getNumberOrUndefinedIfNotSet(tranche?.ownShare),
          borrower_id: deal?.borrowerBpId,
        },
      })
      return data
    },
    { onSuccess, onError },
  )
}
