import { Option, Select, ValueState } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const dealCustomListCardSelectProps = {
  required: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  optionName: PropTypes.string,
  optionsKey: PropTypes.string,
  onChange: PropTypes.func,
}

const EMPTY_TEXT = ''
const DealCustomListCardSelect = ({
  required,
  value,
  options = [],
  optionName = 'key',
  optionsKey = 'display_name',
  onChange,
}) => {
  const changeSelection = (event) => {
    const { selectedOption } = event.detail
    onChange(selectedOption.value)
  }

  const selectOptions = useMemo(
    () =>
      options.map((option) => (
        <Option
          key={option[optionsKey]}
          selected={option[optionsKey] === value}
          value={option[optionsKey]}
        >
          {option[optionName]}
        </Option>
      )),
    [optionName, options, optionsKey, value],
  )

  const valueState = required && !value ? ValueState.Error : ValueState.None

  return (
    <Select value={value} onChange={changeSelection} valueState={valueState}>
      <Option key={EMPTY_TEXT} selected={!!value}>
        {EMPTY_TEXT}
      </Option>
      {selectOptions}
    </Select>
  )
}

DealCustomListCardSelect.propTypes = dealCustomListCardSelectProps

export default DealCustomListCardSelect
