import PropTypes from 'prop-types'
import styles from 'components/domains/deals/pricing/DealPricingCustomModeStripes.module.css'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'

const dealPricingCustomModeStripeProps = PropTypes.shape({
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
})

const dealPricingCustomModeStripesProps = {
  customSyndication: dealPricingCustomModeStripeProps,
  customFees: dealPricingCustomModeStripeProps,
  customRepayment: dealPricingCustomModeStripeProps,
}

const DealPricingCustomModeStripes = ({ customSyndication, customFees, customRepayment }) => {
  if (!customSyndication?.isVisible && !customFees?.isVisible && !customRepayment?.isVisible) {
    return null
  }

  return (
    <div className={styles.stripesContainer}>
      {customSyndication.isVisible && (
        <CloseableMessageStrip>{customSyndication.text}</CloseableMessageStrip>
      )}
      {customFees.isVisible && <CloseableMessageStrip>{customFees.text}</CloseableMessageStrip>}
      {customRepayment.isVisible && (
        <CloseableMessageStrip>{customRepayment.text}</CloseableMessageStrip>
      )}
    </div>
  )
}

DealPricingCustomModeStripes.propTypes = dealPricingCustomModeStripesProps

export default DealPricingCustomModeStripes
