import PropTypes from 'prop-types'
import CustomAssessments from 'components/domains/custom-assessments/CustomAssessments'

const CustomAssessmentsWrapper = ({ pageData }) => {
  const {
    deal: { dealUuid },
    entityType,
    assessmentPage,
  } = pageData

  return <CustomAssessments entityId={dealUuid} entityType={entityType} page={assessmentPage} />
}

CustomAssessmentsWrapper.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
    entityType: PropTypes.string,
    assessmentPage: PropTypes.string,
  }).isRequired,
}

export default CustomAssessmentsWrapper
