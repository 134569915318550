import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { PropTypes } from 'prop-types'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { LimitCheckRequestHelperDialog } from 'components/domains/deals/limit-check/LimitCheckRequestHelperDialog'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import { getConsolidationPointsKeys } from 'hooks/services/deals/consolidation-points/useConsolidationPoints'
import useExternalLoadConsolidationPointsTrigger from 'hooks/services/pbb-custom-service/limit-management/useExternalLoadConsolidationPointsTrigger'
import { useErrorMessageBoxWithExpandableDetails } from 'hooks/services/useErrorMessageBoxWithExpandableDetails'

export const LoadConsolidationPointsDialog = ({ dealUuid, isDialogOpen, closeDialog }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.dialog',
  })
  const { isErrorMessageOpen, onErrorMessageBoxClose, errorDetails, setError } =
    useErrorMessageBoxWithExpandableDetails()
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const onSuccess = () => {
    showToast({
      children: t('load-consolidation-points.success'),
    })
    queryClient.invalidateQueries(getConsolidationPointsKeys(dealUuid))
    closeDialog()
  }

  const { mutate: loadConsolidationPoints, isLoading } = useExternalLoadConsolidationPointsTrigger({
    onSuccess,
    onError: setError,
  })

  return (
    <>
      <LimitCheckRequestHelperDialog
        dealUuid={dealUuid}
        title={t('load-consolidation-points.title')}
        primaryButtonLabel={t('buttons.load')}
        isDialogOpen={isDialogOpen}
        isLoading={isLoading}
        onSubmit={loadConsolidationPoints}
        closeDialog={closeDialog}
      />
      {createPortal(
        <ErrorMessageBoxWithExpandableDetails
          messageSummary={t('load-consolidation-points.error')}
          messageDetails={errorDetails}
          isOpen={isErrorMessageOpen}
          onClose={onErrorMessageBoxClose}
        />,
        document.body,
      )}
    </>
  )
}

LoadConsolidationPointsDialog.propTypes = {
  dealUuid: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
}
