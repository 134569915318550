import {
  ROW_TYPE_TRADE,
  TABLE_ROW_HEIGHT,
} from 'components/domains/business-partners/tile/financial-product/derivatives/constants'
import mapDerivativeConditionsData from 'components/domains/business-partners/tile/financial-product/derivatives/mapDerivativeConditionsData'

const mapDerivativeTradesData = (trades = []) =>
  trades.map((trade) => {
    const {
      tradeId,
      productType,
      productSubType,
      limitSystemProductClass,
      counterpartyId,
      mtm,
      mtmHeadquarter,
      amortization,
      startDate,
      maturityDate,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      buyOrSell,
      payOrReceive,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      strikeRate,
      endOfFixedInterestPeriod,
      conditions,
    } = trade ?? {}

    return {
      dealTradeCondition: {
        id: tradeId,
      },
      productType,
      productSubType,
      limitSystemProductClass,
      counterparty: counterpartyId,
      amortization,
      startDate,
      maturityDate,
      buyOrSell,
      payOrReceive,
      mtm: {
        original: {
          amount: mtm?.amount,
          currency: mtm?.currency,
        },
        headquarter: {
          amount: mtmHeadquarter?.amount,
          currency: mtmHeadquarter?.currency,
        },
      },
      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRateStrikeRate: {
        currentRate,
        strikeRate,
      },
      endOfFixedInterestPeriod,
      subRows: mapDerivativeConditionsData(conditions),
      rowType: ROW_TYPE_TRADE,
      rowHeight: TABLE_ROW_HEIGHT,
    }
  })

export default mapDerivativeTradesData
