import { BusyIndicator, Select as UI5Select } from '@fioneer/ui5-webcomponents-react'
import pickBy from 'lodash.pickby'
import PropTypes from 'prop-types'
import { forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/ui/input/Select.module.css'
import ValueState, { toUI5ValueState } from 'components/ui/input/ValueState'
import errorToUI5 from 'components/ui/input/errorToUI5'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'

const propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  valueState: PropTypes.oneOf(Object.values(ValueState)),
  valueStateMessage: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  isFetching: PropTypes.bool,
  isFetchingError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

/**
 * @typedef {object} overrides
 * @property {import('react-hook-form').ChangeHandler} [onChange]
 * @property {import('react-hook-form').ChangeHandler} [onBlur]
 * @property {import('react-hook-form').FieldError} [error] Error received from `react-hook-form`. The `type` can be set to a `ValueState` (default: `ValueState.ERROR`)
 */
/**
 * Built for usage with `react-hook-form`
 *
 * If you provide `data` to an `Option`, `Select` will return an object on change (`{value: string} & data`) instead of `value` directly.
 *
 * TODO: When upgrading to UI5 >= 1.17.0, use `onLiveChange` / `onClose` to cover the case where the user opens the dropdown and then closes it without selecting anything.
 *       => should already be in an error state while the element is still focused
 */
const Select = forwardRef(
  /** @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props */ (
    {
      children,
      id,
      className,
      name,
      valueState,
      valueStateMessage,
      error,
      isFetching,
      isFetchingError,
      onChange,
      onBlur,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const lastValue = useRef(/** @type {string | {value: string} & Record<string, string>} */ (''))

    /** @type {Parameters<typeof UI5Select>[0]['onChange']} */
    const handleChange = async (e) => {
      const dataset = e.detail.selectedOption.dataset
      const value =
        Object.keys(dataset).length > 0
          ? { value: e.detail.selectedOption.value, ...dataset }
          : e.detail.selectedOption.value

      await onChange?.({
        target: { value, name },
        type: 'change',
      })

      lastValue.current = value
    }

    /** @type {Parameters<typeof UI5Select>[0]['onBlur']} */
    const handleBlur = async () => {
      // HINT: `react-hook-form` expects the blur event to contain the value, but UI5 doesn't provide it.
      //       => We store the value in a ref and use it here.
      //       The order in which the events are fired doesn't matter, the last value is always the correct one.
      await onBlur?.({
        target: { name, value: lastValue.current },
        type: 'blur',
      })
    }

    return (
      <>
        {isFetching && <BusyIndicator active delay={0} className={styles.loading} />}
        {isFetchingError && (
          <div className={styles.error}>
            <div>{t('select.loading.error')}</div>
          </div>
        )}
        {!isFetching && !isFetchingError && (
          <UI5Select
            id={id ?? undefined}
            className={className ?? undefined}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            {...errorToUI5(error)}
            {...pickBy(
              { valueState: toUI5ValueState(valueState), valueStateMessage },
              (v) => v !== undefined,
            )}
            ref={ref}
          >
            {children}
          </UI5Select>
        )}
      </>
    )
  },
)

Select.displayName = 'Select'

Select.propTypes = propTypes

export default Select
