import { Label, FlexBox, FlexBoxJustifyContent, ValueState } from '@fioneer/ui5-webcomponents-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerInput from 'components/domains/deals/input/BusinessPartnerInput'
import EditCardItem from 'components/ui/card/EditCardItem'
import Entity from 'components/ui/data/Entity'
import { useIsBorrower } from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/useIsBorrower'
import paths from 'routes/paths'

const useBorrowerFields = ({
  borrowerId: initialBusinessPartnerId,
  borrowerName: initialBusinessPartnerName,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  const [businessPartner, setBusinessPartner] = useState({
    id: initialBusinessPartnerId,
    name: initialBusinessPartnerName,
  })

  const businessPartnerId = businessPartner.id
  const {
    data: { isBorrower, fullName: businessPartnerFullNameFromBackend },
  } = useIsBorrower(businessPartnerId)

  const businessPartnerName = businessPartner.name || businessPartnerFullNameFromBackend
  const isBusinessPartnerBorrower = businessPartnerName && isBorrower

  let stateMessage = null
  if (businessPartnerFullNameFromBackend && !isBusinessPartnerBorrower) {
    stateMessage = t('borrower.change-role')
  }

  const borrowerValueState = () => {
    if (stateMessage) return ValueState.Information
    return ValueState.None
  }

  const setBusinessPartnerAndPropagate = ({ id, name }, handleOnChange) => {
    setBusinessPartner({ id, name })
    handleOnChange('borrower', { id, name }, false, false)
  }

  const renderPartnerCell = () => (
    <FlexBox
      fitContainer
      className="display-card-item"
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
    >
      <Label>{t('borrower-name')}</Label>
      <Entity
        name={businessPartnerName}
        id={businessPartnerId}
        link={`/${paths.businessPartners}/${businessPartnerId}`}
        openInNewTab
      />
    </FlexBox>
  )

  const renderEditPartnerCell = (handleOnChange) => (
    <EditCardItem
      label={t('borrower-name')}
      editComponent={
        <BusinessPartnerInput
          id="tranche-general-information-borrower"
          type="borrower"
          value={{ name: businessPartnerName, id: businessPartnerId }}
          onChange={(businessPartnerInput) => {
            const { id, name } = businessPartnerInput
            setBusinessPartnerAndPropagate({ id, name }, handleOnChange)
          }}
          customValueStateMessage={stateMessage}
          customValueState={borrowerValueState()}
        />
      }
    />
  )

  return [
    {
      name: 'borrower',
      label: t('borrower-name'),
      value: { id: businessPartner?.id, name: businessPartner?.name },
      isShownInDisplay: true,
      isShownInEdit: true,
      customInfoComponent: renderPartnerCell(),
      renderCustomEditComponent: ({ handleOnChange }) => renderEditPartnerCell(handleOnChange),
      onCancel: () => {
        setBusinessPartner({ id: initialBusinessPartnerId, name: initialBusinessPartnerName })
      },
    },
  ]
}

export default useBorrowerFields
