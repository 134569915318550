import {
  ROW_TYPE_CONDITION,
  TABLE_ROW_HEIGHT,
} from 'components/domains/business-partners/tile/financial-product/bonds/constants'

const mapBondsConditionData = (conditions = []) =>
  conditions.map((condition) => {
    const {
      conditionId,
      conditionType,
      counterpartyId,
      amortization,
      startDate,
      maturityDate,
      buyOrSell,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      rateType,
      rateIndexName,
      tenor,
      currentRate,
    } = condition ?? {}

    return {
      rowHeight: TABLE_ROW_HEIGHT,
      rowType: ROW_TYPE_CONDITION,

      bondCondition: conditionId,
      productSubType: conditionType,
      issuerCounterparty: counterpartyId,
      amortization,
      startDate,
      maturityDate,
      buyOrSell,

      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      currentCouponRate: currentRate,
    }
  })

export default mapBondsConditionData
