import {
  BusyIndicatorSize,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountsTable.module.css'
import useBankCustomerAccountsColumns from 'components/domains/deals/financing/bank-customer-accounts/overview/useBankCustomerAccountsColumns'
import useBankCustomerAccountsTableData from 'components/domains/deals/financing/bank-customer-accounts/overview/useBankCustomerAccountsTableData'
import useBcaTableDataWithAdditionsAndSorting from 'components/domains/deals/financing/bank-customer-accounts/overview/useBcaTableDataWithAdditionsAndSorting'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'

const BankCustomerAccountsTable = ({ isCreditAccountsTable = false }) => {
  const { deal: { dealUuid, displayId } = {} } = useContext(DealContext) ?? {}
  const navigate = useNavigate()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.accounts-table',
  })
  const { t: tNoPrefix } = useTranslation()

  const { data, isFetching, isError, isFetchingAdditionalData } = useBankCustomerAccountsTableData(
    dealUuid,
    isCreditAccountsTable,
  )

  const columns = useBankCustomerAccountsColumns({
    isFetchingAdditionalData,
  })
  const { sortingDefinitions, tableData } = useBcaTableDataWithAdditionsAndSorting(
    data,
    isCreditAccountsTable,
  )

  const renderEmptyTableComponent = () => (
    <div className={styles.noDataComponentWrapper}>
      <RequestStateResolver
        center
        busyIndicatorSize={BusyIndicatorSize.Medium}
        isLoading={isFetching}
        isError={isError}
        errorToDisplay={<ErrorDataUnavailableInTable />}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.Center}>
            <span>{tNoPrefix('analytical-table.no-data-text')}</span>
          </FlexBox>
        )}
      />
    </div>
  )

  const handleRowClick = useCallback(
    (event) => {
      const bankCustomerAccountNumber = event?.detail?.row?.original?.accountNumber
      if (!bankCustomerAccountNumber) {
        return
      }
      navigate(
        `/${paths.deals}/${displayId}/financing/bank-customer-accounts/${bankCustomerAccountNumber}`,
      )
    },
    [displayId, navigate],
  )

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        className={[styles.analyticalTable, !isCreditAccountsTable && styles.otherAccountsTable]
          .filter(Boolean)
          .join(' ')}
        nrOfEntries={data?.length ?? 0}
        isError={isError}
        columns={columns}
        data={tableData}
        customSorting={sortingDefinitions}
        title={isCreditAccountsTable ? t('credit-accounts-title') : t('other-accounts-title')}
        headerRowHeight={40}
        disableColumnPopover={true}
        NoDataComponent={renderEmptyTableComponent}
        onRowClick={handleRowClick}
        reactTableOptions={{
          autoResetPage: false,
          autoResetSortBy: false,
        }}
        rowHeight={50}
        sortable
      />
    </div>
  )
}

BankCustomerAccountsTable.propTypes = {
  isCreditAccountsTable: PropTypes.bool,
}

export default BankCustomerAccountsTable
