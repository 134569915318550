import {
  ROW_TYPE_REPO,
  TABLE_ROW_HEIGHT,
} from 'routes/deals/financing/non-loan-products/repos/constants'
import mapReposConditionData from 'routes/deals/financing/non-loan-products/repos/mapReposConditionData'

const mapReposData = (backendData) => {
  const trades = backendData?.trades ?? []
  return trades.map((trade) => {
    const {
      tradeId,
      productType,
      productSubType,
      limitSystemProductClass,
      counterpartyId,
      mtm,
      mtmHeadquarter,
      startDate,
      maturityDate,
      currentNotional,
      currentNotionalHeadquarter,
      buyOrSell,
      payOrReceive,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      conditions,
    } = trade ?? {}

    return {
      repoCondition: tradeId,
      productType,
      productSubType,
      limitSystemProductClass,

      startDate,
      maturityDate,
      buyOrSell,
      payOrReceive,
      counterpartyIssuer: counterpartyId,

      mtm: {
        original: {
          amount: mtm?.amount,
          currency: mtm?.currency,
        },
        headquarter: {
          amount: mtmHeadquarter?.amount,
          currency: mtmHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      subRows: mapReposConditionData(conditions),
      rowHeight: TABLE_ROW_HEIGHT,
      rowType: ROW_TYPE_REPO,
    }
  })
}

export default mapReposData
