import { CUSTOM_FIELD_TYPES } from 'routes/deals/financing/financingConstants'
import useStandardFields from 'routes/deals/financing/shared-components/standard-field-definitions/useStandardFields'

const useCustomerSpecificAttributesCardFieldDefinitions = (data) => {
  const {
    renderShareField,
    renderDateField,
    renderInputField,
    renderDropdownField,
    renderSwitchField,
    renderAmountWithCurrencyField,
    renderNumberField,
    renderIntegerField,
  } = useStandardFields()

  const fields = []

  if (data?.customFields) {
    for (const [key, value] of Object.entries(data.customFields)) {
      switch (value.type) {
        case CUSTOM_FIELD_TYPES.DATE: {
          fields.push(
            ...renderDateField({ name: key, label: value.categoryShortText, value: value.value }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.PERCENTAGE: {
          fields.push(
            ...renderShareField({
              name: key,
              label: value.categoryShortText,
              share: value.value,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.STRING: {
          fields.push(
            ...renderInputField({
              name: key,
              label: value.categoryShortText,
              value: value.value,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.NUMBER: {
          fields.push(
            ...renderNumberField({
              name: key,
              label: value.categoryShortText,
              value: value.value,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.INTEGER: {
          fields.push(
            ...renderIntegerField({
              name: key,
              label: value.categoryShortText,
              value: value.value,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.BOOLEAN: {
          fields.push(
            ...renderSwitchField({
              name: key,
              label: value.categoryShortText,
              value: value.value,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.ENUM: {
          fields.push(
            ...renderDropdownField({
              label: value.categoryShortText,
              name: key,
              value: value.selectedShortText,
              code: value.selectedTypeCode,
              selectionName: 'typeCodes',
              selectedKey: value.selectedTypeCode,
              configurationTypeCode: value.configurationCode,
            }),
          )
          break
        }
        case CUSTOM_FIELD_TYPES.CURRENCY:
          fields.push(
            ...renderAmountWithCurrencyField({
              label: value.categoryShortText,
              name: key,
              value: {
                amount: value.value,
                currency: value.currencyCode,
              },
            }),
          )
          break
      }
    }
  }

  return fields
}

export default useCustomerSpecificAttributesCardFieldDefinitions
