import {
  Button,
  ButtonDesign,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import LoadingButton from 'components/ui/button/LoadingButton'
import MessageBox from 'components/ui/message-box/MessageBox'
import { useCreateBasicAgreement } from 'hooks/services/deals/financing/useCreateBasicAgreement'
import useFinancingMini from 'hooks/services/deals/financing/useFinancingMini'
import { DealContext } from 'routes/deals/DealContext'
import TranchesSelectDialog from 'routes/deals/financing/shared-components/TranchesSelectDialog/TranchesSelectDialog'
import showWriteBasicAgreementError from 'routes/deals/financing/tranchesOverview/errorHandling/showWriteBasicAgreementError'

const TrancheWriteBasicAgreementButton = () => {
  const { t } = useTranslation()
  const { deal } = useContext(DealContext)
  const { data: financingData = [] } = useFinancingMini(deal.dealUuid)

  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)
  const showToast = Modals.useShowToast()
  const queryClient = useQueryClient()

  const [newSelectedTrancheIds, setNewSelectedTrancheIds] = useState([])

  const [isWritingToBackend, setIsWritingToBackend] = useState(false)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const [messageBoxCanBeClosed, setMessageBoxCanBeClosed] = useState(false)

  useEffect(() => {
    if (messageBoxCanBeClosed && !isWritingToBackend) {
      setIsMessageBoxOpen(false)
      setMessageBoxCanBeClosed(false)
    }
  }, [isWritingToBackend, messageBoxCanBeClosed])

  const createBasicAgreement = useCreateBasicAgreement({
    onSuccess: () => {
      setIsWritingToBackend(false)
      queryClient.invalidateQueries(['deals', deal.dealUuid, 'tranches'])
      setIsSelectDialogOpen(false)
      setNewSelectedTrancheIds([])
      showToast({
        children: t('pages.deals.role.tranches-table.write-basic-agreement.success.toast-message'),
      })
    },
    onError: async (error) => {
      setIsWritingToBackend(false)
      await showWriteBasicAgreementError(error)
      await queryClient.invalidateQueries(['deals', deal.dealUuid, 'tranches'])
    },
  })

  const openWriteBasicAgreementDialog = () => {
    setIsSelectDialogOpen(true)
  }

  const disabledTrancheIds = financingData?.tranchesMinis
    ?.filter((tranche) => tranche.externalContractIds?.length > 0)
    .map((tranche) => tranche.trancheId)

  const confirmButton = (
    <LoadingButton
      key="confirm"
      design={ButtonDesign.Emphasized}
      data-testid="messageBoxConfirmButton"
      isLoading={isWritingToBackend}
      onClick={() => {
        setIsWritingToBackend(true)
        createBasicAgreement.mutate({ deal, trancheIds: newSelectedTrancheIds })
        setIsSelectDialogOpen(false)
      }}
      renderContent={() => t('buttons.confirm')}
    />
  )

  const onConfirmSelection = (selectedTrancheIds) => {
    if (selectedTrancheIds?.length === 0) {
      return setIsSelectDialogOpen(false)
    }

    setNewSelectedTrancheIds(selectedTrancheIds)
    setIsMessageBoxOpen(true)
  }

  return (
    <>
      <TranchesSelectDialog
        isDialogOpen={isSelectDialogOpen}
        setIsDialogOpen={setIsSelectDialogOpen}
        disabledTrancheIds={disabledTrancheIds ?? []}
        onConfirmSelection={onConfirmSelection}
        dealUuid={deal.dealUuid}
        rememberSelections={false}
      />
      <Button
        id="trancheWriteBasicAgreementButton"
        design={ButtonDesign.Transparent}
        onClick={openWriteBasicAgreementDialog}
        data-testid="trancheWriteBasicAgreementButton"
      >
        {t('pages.deals.role.tranches-table.write-basic-agreement-button')}
      </Button>
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Confirm}
          open={isMessageBoxOpen}
          onClose={() => {
            setMessageBoxCanBeClosed(true)
          }}
          actions={[confirmButton, MessageBoxActions.Cancel]}
        >
          <div>
            {t('pages.deals.role.tranches-table.write-basic-agreement.confirmation-text', {
              selectedTranchesCount: newSelectedTrancheIds.length,
            })}
          </div>
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

export default TrancheWriteBasicAgreementButton
