const useCommentSaveHandler =
  ({ originalComment, newComment, updateComment }) =>
  async () => {
    if (originalComment === newComment) {
      return
    }

    await updateComment({ data: { comment: newComment } })
  }

export default useCommentSaveHandler
