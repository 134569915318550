import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'

const REQUEST_RETRIES = 3

// no `useCamelizedResponse` here as we need the keys in the `range` objects in SCREAMING_SNAKE_CASE
const useCustomerOptions = (dealId, trancheId) => {
  const result = useRequest({
    path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/options`,
    translated: true,
    keys: ['deals', dealId, 'tranches', trancheId, 'customer-options'],
    options: {
      enabled: !!trancheId,
      retry: (failureCount, error) => {
        if (isNotFoundError(error) || isMissingPermissionError(error)) {
          return false
        }
        if (failureCount < REQUEST_RETRIES) {
          return true
        }
        return false
      },
    },
  })

  return {
    ...result,
    data: result?.data,
  }
}

export default useCustomerOptions
