import { useContext } from 'react'
import Card from 'components/ui/card/Card'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import { NON_LOAN_PRODUCT_TABLE_TYPES } from 'routes/deals/financing/non-loan-products/constants'
import DerivativesTable from 'routes/deals/financing/non-loan-products/derivatives/DerivativesTable'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const DerivativesCard = () => {
  const { deal } = useContext(DealContext)
  const { data, isLoading } = useNonLoanProducts(deal.displayId)
  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables } = {} } = {},
  } = useConfig()
  const derivativesMappingValues =
    tradeMappingValuesByTables?.[NON_LOAN_PRODUCT_TABLE_TYPES.DERIVATIVES]
  const filteredData = getNonLoanProductDealDataByConfig({
    tradeMappingAttribute,
    tradeMappingValues: derivativesMappingValues,
    data,
  })

  // only show the card if specific trade data is available
  if (!isLoading && filteredData?.trades?.length > 0) {
    return (
      <Card>
        <DerivativesTable />
      </Card>
    )
  }
}

export default DerivativesCard
