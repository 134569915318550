import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useBcaTableDataWithAdditionsAndSorting = (data = [], displayTotalRow) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.bank-customer-accounts.accounts-table.column',
  })
  const [sortingParameters, setSortingParameters] = useState({
    columnKey: 'accountNumber',
    sortDescending: false,
  })

  const sortingDefinitions = useMemo(() => {
    const sortableColumns = [
      { title: t('account-number'), columnKey: 'accountNumber' },
      { title: t('product-type'), columnKey: 'productType' },
      { title: t('description'), columnKey: 'description' },
      { title: t('book-balance'), columnKey: 'bookBalance' },
      { title: t('external-limit'), columnKey: 'externalLimit' },
      { title: t('remaining'), columnKey: 'remaining' },
      { title: t('utilization'), columnKey: 'utilization' },
      { title: t('maturity'), columnKey: 'maturityDate' },
    ]
    return {
      columnKey: sortingParameters.columnKey,
      // as the summary row needs to be the last element in the data array, the native functionality is not used
      isSortingAscending: !sortingParameters.sortDescending,
      sortableColumns: sortableColumns,
      onUpdateSorting: (event) => {
        const { sortBy, sortDescending } = event
        // the event only contains the title of the column
        const { columnKey } = sortableColumns.find((column) => column.title === sortBy) ?? {}
        setSortingParameters({ columnKey, sortDescending })
      },
    }
  }, [sortingParameters.columnKey, sortingParameters.sortDescending, t])

  const totalRow = useMemo(() => {
    if (data.length < 2 || !displayTotalRow) {
      return []
    }
    const summarizableColumns = ['bookBalance', 'externalLimit', 'remaining']
    const headquarterCurrency = data[0][summarizableColumns[0]].headquarterCurrency.currency
    const originalCurrency = data[0][summarizableColumns[0]].original.currency
    const hasUniqueOriginalCurrency = summarizableColumns.every((columnName) =>
      data.every((row) => row[columnName].original.currency === originalCurrency),
    )

    const initialSingleValue = {
      headquarterCurrency: { value: 0, currency: headquarterCurrency },
    }
    if (hasUniqueOriginalCurrency) {
      initialSingleValue.original = { value: 0, currency: originalCurrency }
    }
    const initialValue = {
      accountNumber: 'Total',
      bookBalance: cloneDeep(initialSingleValue),
      externalLimit: cloneDeep(initialSingleValue),
      remaining: cloneDeep(initialSingleValue),
    }
    return [
      data.reduce((acc, tableRow) => {
        summarizableColumns.forEach((columnKey) => {
          const value = tableRow?.[columnKey]?.headquarterCurrency?.value
          if (value) {
            acc[columnKey].headquarterCurrency.value += value
          }
          const originalValue = tableRow?.[columnKey]?.original?.value
          if (hasUniqueOriginalCurrency && originalValue) {
            acc[columnKey].original.value += originalValue
          }
        })
        return acc
      }, initialValue),
    ]
  }, [data, displayTotalRow])

  const sortMoney = (tableRows, columnKey) => {
    const getHeadquarterValue = (element, colKey) => {
      const obj = get(element, colKey)
      return obj.headquarterCurrency?.value ?? obj.original?.value // This ok?
    }
    tableRows.sort((a, b) =>
      getHeadquarterValue(a, columnKey) < getHeadquarterValue(b, columnKey) ? 1 : -1,
    )
  }

  const tableData = useMemo(() => {
    const sortData = (bcas, { columnKey, sortDescending }) => {
      const copy = cloneDeep(bcas)
      if (['bookBalance', 'externalLimit', 'remaining'].includes(columnKey)) {
        sortMoney(copy, columnKey)
      } else {
        copy.sort((a, b) => (get(a, columnKey) < get(b, columnKey) ? 1 : -1))
      }
      return sortDescending ? copy : copy.reverse()
    }

    if (!isEmpty(data)) {
      if (data.length > 1) {
        return [...sortData(data, sortingParameters), ...totalRow]
      }
    }
    return data
  }, [data, sortingParameters, totalRow])

  return { sortingDefinitions, tableData }
}

export default useBcaTableDataWithAdditionsAndSorting
