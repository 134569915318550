import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/risk-conformity/DealRiskConformityDisplay.module.css'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import { useBooleanToTextFormatter } from 'hooks/i18n/useI18n'
import useRiskConformityDeviations from 'hooks/services/deals/configurations/useRiskConformityDeviations'

const dealRiskConformityDisplayProps = {
  riskConformity: PropTypes.object.isRequired,
  businessSegmentCode: PropTypes.string,
}

const DealRiskConformityDisplay = ({
  riskConformity: { compliance, deviationCodes = [], comment } = {},
  businessSegmentCode,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.risk-conformity' })
  const formatBoolean = useBooleanToTextFormatter()
  const {
    data: { deviations = [] } = {},
    isLoading,
    isError,
  } = useRiskConformityDeviations(businessSegmentCode)

  const deviationNames = useMemo(
    () => (
      <div className={styles.deviationsList}>
        {deviationCodes.map((deviationCode, index) => (
          <span key={index} className={styles.deviation}>
            {deviations.find(({ code }) => code === deviationCode)?.name}
          </span>
        ))}
      </div>
    ),
    [deviationCodes, deviations],
  )

  return (
    <>
      <CardSection>
        <CardShowItem label={t('compliance')} value={formatBoolean(compliance)} />
        {!compliance && (
          <CardShowItem
            label={t('deviations')}
            value={deviationNames}
            isLoading={isLoading}
            isError={isError}
          />
        )}
      </CardSection>
      <CardSection title={t('comment')}>
        <TextEllipsis
          content={comment}
          maxShortenedContentDisplaySize={300}
          maxContentDisplaySize={1000}
        />
      </CardSection>
    </>
  )
}

DealRiskConformityDisplay.propTypes = dealRiskConformityDisplayProps

export default DealRiskConformityDisplay
