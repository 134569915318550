import camelize from 'camelize'
import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { nonLoanProductsDealSchema } from 'hooks/services/deals/financing/nonLoanProductsSchema'

const useNonLoanProducts = (dealId, options = {}) => {
  const result = useRequest({
    path: `/financing-structures/deals/${dealId}/non-loan-products`,
    keys: ['deals', dealId, 'nonLoanProducts'],
    translated: true,
    options: {
      enabled: !!dealId,
      ...options,
    },
  })

  const parsed = useMemo(
    () => nonLoanProductsDealSchema.optional().safeParse(camelize(result.data)),
    [result.data],
  )

  if (!parsed.success) {
    // eslint-disable-next-line no-console
    console.error('Non Loan Products Financing parsing error', parsed.error)
    return { ...result, data: undefined, isError: true, error: parsed.error }
  }

  return {
    ...result,
    data: parsed.data,
  }
}

export default useNonLoanProducts
