import { useTranslation } from 'react-i18next'
import { useMainCollateralTypeCodes } from 'hooks/services/deals/financing/configGetters/useMainCollateralTypeCodes'

const useMainCollateralTypeFields = ({ mainCollateralTypeCode, mainCollateralTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'mainCollateralTypeCode',
      label: t('main-collateral-type'),
      value: mainCollateralTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },

    {
      name: 'mainCollateralTypeCode',
      label: t('main-collateral-type'),
      value: mainCollateralTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useMainCollateralTypeCodes,
        selectionName: 'mainCollateralTypeCodes',
      },
    },
  ]
}

export default useMainCollateralTypeFields
