import { FlexBox, FlexBoxDirection, FlexBoxAlignItems } from '@fioneer/ui5-webcomponents-react'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'

import styles from 'routes/deals/financing/shared-components/ConvertedAmount.module.css'

const ConvertedAmount = (amount, headquarterCurrency) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  return (
    <FlexBox
      fitContainer
      direction={FlexBoxDirection.Column}
      alignItems={FlexBoxAlignItems.End}
      className={styles.convertedAmountWrapper}
    >
      <div
        id="info-in-headquarter-currency"
        className={styles.textLabelColor}
        data-testid="info-in-headquarter-currency"
      >
        {formatMoney(amount, headquarterCurrency, { currencyDisplay: 'code' })}
      </div>
    </FlexBox>
  )
}

export default ConvertedAmount
