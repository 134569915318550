import { t } from 'i18next'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

export const defaultValidationFunction = (value) => isNil(value) || value === '' || value >= 0

const useStandardNumberField = ({ name, label, value, validationFunction }) => {
  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })

  return [
    {
      label,
      name,
      value: formatNumber(value),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label,
      name,
      value,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        valueStateMessage: (
          <span>
            {t('pages.deals.tranches.details.customer-specific-attributes-card.number.error')}
          </span>
        ),
      },

      validationFunction: validationFunction ?? defaultValidationFunction,
    },
  ]
}

useStandardNumberField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
}

export default useStandardNumberField
