import isEmpty from 'lodash.isempty'
import omit from 'lodash.omit'
import mapMoneyMarketsTradeData from 'components/domains/business-partners/tile/financial-product/moneyMarkets/mapMoneyMarketsTradeData'

const mapMoneyMarketsData = (trades = []) => {
  if (!isEmpty(trades)) {
    const mappedRowData = mapMoneyMarketsTradeData(trades)

    return mappedRowData.map((row) => ({
      ...omit(row, ['dealTradeCondition']),
      tradeCondition: row?.dealTradeCondition,
    }))
  }
}

export default mapMoneyMarketsData
