import {
  FlexBoxJustifyContent,
  IllustratedMessage,
  ObjectStatus,
  TableMode,
  TableGrowingMode,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BusinessPartnerRolesView from 'components/domains/business-partners/roles/BusinessPartnerRolesView'
import styles from 'components/domains/deals/input/BusinessPartnerTable.module.css'
import IndustryCellComponent from 'components/domains/rentroll/IndustryCellComponent'
import Entity from 'components/ui/data/Entity'
import SortedTable from 'components/ui/tables/sorted-tables/SortedTable'
import SortedTableErrorMessage from 'components/ui/tables/sorted-tables/SortedTableErrorMessage'
import determineValueStateBasedOnStatus from 'routes/business-partners/determineValueState'

const BusinessPartnerTable = ({
  businessPartners,
  onChange,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  total,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.business-partner-search' })
  const { t: noPrefixT } = useTranslation()

  const columnDefinitions = [
    {
      title: t('table.toolbar.title', { count: 1 }),
      columnKey: 'businessPartner',
    },
    {
      title: t('table.country.label'),
      columnKey: 'countries',
    },
    {
      title: t('table.postal-code.label'),
      columnKey: 'postalCode',
      alignment: FlexBoxJustifyContent.End,
    },
    {
      title: t('table.city.label'),
      columnKey: 'city',
    },
    {
      title: t('table.street.label'),
      columnKey: 'streetName',
    },
    {
      title: t('table.roles.label'),
      columnKey: 'roles',
    },
    {
      title: t('table.industry.label'),
      columnKey: 'industry',
    },
    {
      title: t('table.status.label'),
      columnKey: 'status',
    },
  ]

  const tableData = businessPartners.map(
    ({ id, name, address: { countries, postalCode, city, streetName }, status, roles }) => ({
      rowKey: id,
      businessPartner: {
        cellComponent: (
          <Entity name={name} id={id} link={`/business-partners/${id}`} openInNewTab />
        ),
        value: id + name,
      },
      countries: {
        cellComponent: <>{countries?.name}</>,
        value: countries?.name,
      },
      postalCode: {
        cellComponent: <>{postalCode}</>,
        value: postalCode,
      },
      city: {
        cellComponent: <>{city}</>,
        value: city,
      },
      streetName: {
        cellComponent: <>{streetName}</>,
        value: streetName,
      },
      rowProperties: {
        name,
        id,
      },
      roles: {
        cellComponent: (
          <BusinessPartnerRolesView
            roles={
              roles?.map(({ name: roleName, ...role }) => ({
                ...role,
                name: noPrefixT(`components.business-partner.roles.${roleName}`),
              })) ?? []
            }
          />
        ),
      },
      industry: { cellComponent: <IndustryCellComponent id={id} /> },
      status: {
        cellComponent: (
          <ObjectStatus inverted state={determineValueStateBasedOnStatus(status)}>
            {t(status, { keyPrefix: 'pages.business-partner.general-information.status' })}
          </ObjectStatus>
        ),
        value: status,
      },
    }),
  )

  const handleSelect = (event) => {
    const id = event.detail.selectedRows[0]?.attributes?.id.value
    const { name, roles, status } = businessPartners.find((bp) => bp.id === id) ?? {}
    onChange?.(event.detail.selectedRows[0]?.attributes && { id, name, roles, status })
  }

  return (
    <>
      {isError ? (
        <SortedTableErrorMessage />
      ) : (
        <SortedTable
          columnDefinitions={columnDefinitions}
          tableData={tableData}
          renderEmptyTableComponent={() => (
            <IllustratedMessage
              className={styles.noData}
              name="NoData"
              titleText={t('no-results.title')}
              subtitleText={t('no-results.subtitle')}
            />
          )}
          additionalTableProperties={{
            onSelectionChange: handleSelect,
            mode: TableMode.SingleSelect,
            onFocus: (event) => event.stopPropagation(),
            busy: isLoading,
          }}
          toolbarConfig={{
            title: t('table.toolbar.title', { count: tableData.length }),
            sorting: {
              columnKey: 'id',
            },
          }}
          paginationConfig={{
            growing: hasNextPage ? TableGrowingMode.Button : undefined,
            growingButtonText: t('growing-button-text'),
            growingButtonSubtext: '[ ' + businessPartners.length + ' / ' + total + ' ]',
            totalNumberOfItems: total,
            loadMore: () => fetchNextPage(),
          }}
        />
      )}
    </>
  )
}

BusinessPartnerTable.propTypes = {
  businessPartners: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

export default BusinessPartnerTable
