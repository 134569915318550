import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

/**
 * @returns {*|(*&{data: *|[]})}
 */
const useCustomEntityFields = (entityType, options = {}) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (entityType) urlSearchParams.append('entity-type', entityType)

    return urlSearchParams
  }, [entityType])

  return useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/custom-entities?${queryParams.toString()}`,
      useCache: true,
      translated: true,
      keys: ['deals', 'configurations', 'custom-entities', entityType],
      options: {
        enabled: !!entityType,
        ...options,
      },
    }),
  )
}

export default useCustomEntityFields
