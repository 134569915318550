import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const useRiskConformityDeviations = (businessSegmentCode) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (businessSegmentCode) urlSearchParams.append('businessSegment', businessSegmentCode)

    return urlSearchParams
  }, [businessSegmentCode])

  return useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/risk-conformity-deviations?${queryParams.toString()}`,
      useCache: true,
      translated: true,
      keys: ['deals', 'configurations', 'risk-conformity-deviations', businessSegmentCode],
    }),
  )
}

export default useRiskConformityDeviations
