import { useContext } from 'react'
import Card from 'components/ui/card/Card'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import { NON_LOAN_PRODUCT_TABLE_TYPES } from 'routes/deals/financing/non-loan-products/constants'
import OtherLendingTable from 'routes/deals/financing/non-loan-products/other-lending/OtherLendingTable'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const OtherLendingCard = () => {
  const { deal } = useContext(DealContext)
  const { data, isLoading } = useNonLoanProducts(deal.displayId)
  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables } = {} } = {},
  } = useConfig()
  const otherLendingMappingValues =
    tradeMappingValuesByTables?.[NON_LOAN_PRODUCT_TABLE_TYPES.OTHER_LENDING]
  const filteredData = getNonLoanProductDealDataByConfig({
    tradeMappingAttribute,
    tradeMappingValues: otherLendingMappingValues,
    data,
  })

  // only show the card if specific trade data is available
  if (!isLoading && filteredData?.trades?.length > 0) {
    return (
      <Card>
        <OtherLendingTable />
      </Card>
    )
  }
}

export default OtherLendingCard
