import { ValueState } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import isIntegerValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isIntegerValid'

const CustomerOptionsIntegerInput = ({ handleOnChange, value }) => {
  const handleInputUpdate = (number) => {
    const newValue = number ? Number(number) : ''
    handleOnChange({ value: newValue, isValid: isIntegerValid(newValue) })
  }

  return (
    <FormattedNumberInput
      onInput={handleInputUpdate}
      onBlur={(e) => handleInputUpdate(e.target.value)}
      value={value}
      valueState={isIntegerValid(value) ? ValueState.None : ValueState.Error}
      valueStateMessage={
        <span>{t('pages.deals.customerOptions-table.integer-label.hint.isInteger')}</span>
      }
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  )
}

CustomerOptionsIntegerInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleOnChange: PropTypes.func.isRequired,
}

export default CustomerOptionsIntegerInput
