import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateAdditionalInterestInformation = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, trancheId, ...body }) => {
      await put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/additional-interest-information`,
        body: snakecaseKeys(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateAdditionalInterestInformation
