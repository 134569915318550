import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCardHeaderTableButtons.module.css'

const MultiSelectCardHeaderTableButtons = ({ inEditMode, onAdd, onDelete, selectedRows }) => {
  if (!inEditMode) {
    return
  }

  return (
    <FlexBox className={styles.buttonContainer}>
      <Button id={'multiselect-card-add-action'} onClick={onAdd} design={ButtonDesign.Transparent}>
        {t('buttons.add')}
      </Button>
      <Button
        id={'multiselect-card-delete-action'}
        disabled={selectedRows.length < 1}
        onClick={onDelete}
        design={ButtonDesign.Transparent}
      >
        {t('buttons.delete')}
      </Button>
    </FlexBox>
  )
}

MultiSelectCardHeaderTableButtons.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
}

export default MultiSelectCardHeaderTableButtons
