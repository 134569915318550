import useStandardDateField from 'routes/deals/financing/shared-components/useStandardDateField'
import useStandardDropdownField from 'routes/deals/financing/shared-components/useStandardDropdownField'
import useStandardInputField from 'routes/deals/financing/shared-components/useStandardInputField'
import useStandardIntegerField from 'routes/deals/financing/shared-components/useStandardIntegerField'
import useStandardMoneyWithCurrencyDropdownField from 'routes/deals/financing/shared-components/useStandardMoneyWithCurrencyDropdownField'
import useStandardNumberField from 'routes/deals/financing/shared-components/useStandardNumberField'
import useStandardShareField from 'routes/deals/financing/shared-components/useStandardShareField'
import useStandardSwitchField from 'routes/deals/financing/shared-components/useStandardSwitchField'

const useStandardFields = () => ({
  renderDateField: useStandardDateField,
  renderInputField: useStandardInputField,
  renderShareField: useStandardShareField,
  renderDropdownField: useStandardDropdownField,
  renderSwitchField: useStandardSwitchField,
  renderAmountWithCurrencyField: useStandardMoneyWithCurrencyDropdownField,
  renderNumberField: useStandardNumberField,
  renderIntegerField: useStandardIntegerField,
})

export default useStandardFields
