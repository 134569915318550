import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useFeeTypeCodes } from 'hooks/services/deals/financing/configGetters/useFeeTypeCodes'
import styles from 'routes/deals/financing/trancheDetails/cards/tranche-fees/TrancheFeesCard.module.css'

const FeeTypeCodeSelect = ({
  trancheFee,
  rowKey,
  bulkUpdateEditRow,
  setInteractedWithRowState,
  isValid,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.fees-card',
  })

  return (
    <FlexBox>
      <LoadingSelect
        id="feeTypeCodesLoadingSelect"
        className={styles.noMinWidth}
        loadingHook={useFeeTypeCodes}
        selectionName="feeTypeCodes"
        valueState={isValid ? 'None' : 'Error'}
        valueStateMessage={<span>{t('fee-type.required-hint')}</span>}
        optionKeyName="key"
        optionDisplayName="display_name"
        selectedKey={trancheFee.feeTypeCode}
        onChange={(event) => {
          bulkUpdateEditRow(rowKey, [
            { parameter: 'feeTypeCode', value: event.detail.selectedOption._state.value },
            { parameter: 'amountShare', value: '' },
            { parameter: 'amountTotal', value: '' },
          ])
          setInteractedWithRowState(rowKey, 'feeTypeCode')
        }}
        onBlur={() => {
          setInteractedWithRowState(rowKey, 'feeTypeCode')
        }}
      />
    </FlexBox>
  )
}

FeeTypeCodeSelect.propTypes = {
  trancheFee: PropTypes.shape({
    feeTypeCode: PropTypes.string,
  }).isRequired,
  rowKey: PropTypes.string.isRequired,
  bulkUpdateEditRow: PropTypes.func.isRequired,
  setInteractedWithRowState: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
}

export default FeeTypeCodeSelect
