import { Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useCommentField } from 'components/domains/deals/information/useCommentField'
import styles from 'components/domains/deals/information/useDealInformationFieldDefinitions.module.css'
import useDealTypeField from 'components/domains/deals/information/useDealTypeField'
import useOriginationTeamFields from 'components/domains/deals/information/useOriginationTeamFields'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import {
  useNumberFormatter,
  usePercentageFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import useStaffMemberByObjectIdOrEmail from 'hooks/services/business-partners/staff-members/useStaffMemberByObjectIdOrEmail'
import useChineseWall from 'hooks/services/deals/chinese-wall/useChineseWall'
import { useApplicationTypes } from 'hooks/services/deals/configurations/useApplicationTypes'
import { useComplexityLevels } from 'hooks/services/deals/configurations/useComplexityLevels'
import { useTypeCodesAllValues } from 'hooks/services/deals/configurations/useTypeCodesAllValues'
import useOnHold from 'hooks/services/deals/useOnHold'
import { useCountryCodes } from 'hooks/services/properties/useCountryCodes'
import { UserProfileContext } from 'routes/UserProfileContext'

const getValueOrError = (isHookError, value) =>
  isHookError ? <ErrorDataUnavailableInCell /> : value

const useDealInformationFieldDefinitions = (deal = {}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })

  const { t: tNoPrefix } = useTranslation()
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}
  const { format: formatDate } = useShortDateFormatter({ timeZone })
  const { format: formatDateAndTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })
  const formatShare = usePercentageFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const Currency = 'EUR'
  const formatCurrency = useNumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: Currency,
    currencyDisplay: 'code',
  })

  const {
    data: isOnHold,
    isFetching: isFetchingOnHold,
    isError: isErrorOnHold,
  } = useOnHold(deal.dealUuid)
  const {
    data: isChineseWall,
    isLoading: isLoadingChineseWall,
    isError: isErrorChineseWall,
  } = useChineseWall(deal.dealUuid)
  const {
    isFetching: isFetchingApplicationTypes,
    isError: isErrorApplicationTypes,
    data: applicationTypesConfig = [],
  } = useApplicationTypes({ enabled: !!deal.applicationTypeCode })
  const {
    isFetching: isFetchingComplexityLevels,
    isError: isErrorComplexityLevels,
    data: complexityLevelsConfig = [],
  } = useComplexityLevels({ enabled: !!deal.complexityLevelCode })
  const {
    isLoading: isLoadingCountryCodes,
    isError: isErrorCountryCodes,
    data: { country_codes = [] } = {},
  } = useCountryCodes()
  const {
    data: staffMember,
    isFetching: isFetchingStaffMember,
    isError: isErrorStaffMember,
  } = useStaffMemberByObjectIdOrEmail({
    objectIdOrEmail: deal.createdBy,
  })

  const { isLoading: isLoadingDealTypeField, data: dealTypeField } = useDealTypeField(
    deal,
    getValueOrError,
  )

  const {
    isLoading: isLoadingOriginationTeamFields,
    data: { originationTeamField, originationPlatformField },
  } = useOriginationTeamFields(deal, getValueOrError)
  const bookingLocationMapping = 'S4_BOOKING_LOCATION_MAPPING'

  const {
    isLoading: isLoadingBookingLocations,
    isError: isErrorBookingLocations,
    data: { typeCodes: bookingLocations },
  } = useTypeCodesAllValues(bookingLocationMapping)

  const getNameByCode = (code, config) =>
    config.find((configItem) => configItem.code === code)?.name

  const getBookingLocationByKey = (key) =>
    bookingLocations?.find((location) => location.key === key)?.additional_properties_textValue

  const dealStatus = isOnHold ? `${deal.status} ${t('status.onHold')}` : deal.status
  const chineseWallValue = isChineseWall ? t('chineseWall.enabled') : t('chineseWall.disabled')

  const fieldDefinitions = [
    {
      label: t('deal-name'),
      name: 'name',
      value: deal.name,
      isMandatory: true,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { maxlength: 75 },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('deal-id'),
      name: 'dealId',
      value: deal.dealId,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('status'),
      name: 'status',
      value: getValueOrError(isErrorOnHold, dealStatus),
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('business-segment'),
      name: 'businessSegmentCode',
      value: deal.businessSegmentCode,
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    dealTypeField,
    {
      label: t('chineseWall'),
      name: 'chineseWall',
      value: getValueOrError(isErrorChineseWall, chineseWallValue),
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('application-type'),
      name: 'applicationTypeCode',
      value: deal.applicationTypeCode,
      isMandatory: true,
      formattedValue: getValueOrError(
        isErrorApplicationTypes,
        getNameByCode(deal.applicationTypeCode, applicationTypesConfig),
      ),
      editComponentType: editComponentTypes.LoadingSelect,
      editComponentProps: {
        loadingHook: useApplicationTypes,
        optionKeyName: 'code',
        optionDisplayName: 'name',
      },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    originationTeamField,
    originationPlatformField,
    {
      label: t('booking-location'),
      name: 'bookingLocationCode',
      value: deal.bookingLocationCode,
      formattedValue: getValueOrError(
        isErrorBookingLocations,
        getBookingLocationByKey(deal.bookingLocationCode),
      ),
      editComponentProps: {
        loadingHook: useTypeCodesAllValues,
        loadingHookParams: bookingLocationMapping,
        selectionName: 'typeCodes',
        optionDisplayName: 'additional_properties_textValue',
      },
      isMandatory: true,
      editComponentType: editComponentTypes.LoadingSelect,
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('complexity-level'),
      name: 'complexityLevelCode',
      value: deal.complexityLevelCode,
      formattedValue: getValueOrError(
        isErrorComplexityLevels,
        getNameByCode(deal.complexityLevelCode, complexityLevelsConfig),
      ),
      editComponentType: editComponentTypes.LoadingSelect,
      editComponentProps: {
        loadingHook: useComplexityLevels,
        optionKeyName: 'code',
        optionDisplayName: 'name',
      },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('riskWeight'),
      name: 'riskWeight',
      value: deal.riskWeight,
      formattedValue: !isNil(deal.riskWeight) ? formatShare(deal.riskWeight / 100) : null,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        icon: <Label className={styles.inputLabel}>{tNoPrefix('unit.percent')}</Label>,
      },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('riskWeightedAsset'),
      name: 'riskWeightedAsset',
      value: deal.riskWeightedAsset,
      formattedValue: !isNil(deal.riskWeightedAsset)
        ? formatCurrency(deal.riskWeightedAsset)
        : null,
      editComponentType: editComponentTypes.FormattedNumberInput,
      editComponentProps: {
        icon: <Label className={styles.inputLabel}>{Currency}</Label>,
      },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('contractLaw'),
      name: 'contractLaw',
      value: deal.contractLaw,
      formattedValue: getValueOrError(
        isErrorCountryCodes,
        country_codes.find(({ key }) => key === deal.contractLaw)?.display_name,
      ),
      editComponentType: editComponentTypes.LoadingSelect,
      editComponentProps: {
        loadingHook: useCountryCodes,
        selectionName: 'country_codes',
      },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('actual-signing-date'),
      name: 'actualSigningDate',
      value: formatDate(deal.actualSigningDate),
      editComponentType: editComponentTypes.DatePicker,
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('createdAt'),
      name: 'createdAt',
      value: formatDateAndTime(deal.createdAt),
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    {
      label: t('createdBy'),
      name: 'createdBy',
      value: deal.createdBy,
      formattedValue: getValueOrError(isErrorStaffMember, staffMember?.fullName),
      editComponentType: editComponentTypes.Input,
      editComponentProps: { readonly: true },
      isShownInEdit: true,
      isShownInDisplay: true,
    },
    useCommentField(deal),
  ]

  return {
    isLoading:
      isFetchingOnHold ||
      isLoadingDealTypeField ||
      isLoadingChineseWall ||
      isFetchingApplicationTypes ||
      isLoadingOriginationTeamFields ||
      isFetchingComplexityLevels ||
      isLoadingCountryCodes ||
      isFetchingStaffMember ||
      isLoadingBookingLocations,
    data: { fieldDefinitions },
  }
}

export default useDealInformationFieldDefinitions
