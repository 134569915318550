import isNil from 'lodash.isnil'

const isAmountValid = (value, { isMandatory = false, isGreaterZero = false } = {}) => {
  if (isMandatory) {
    return value !== '' && !isNil(value) && (isGreaterZero ? value > 0 : value >= 0)
  } else {
    return value === '' || isNil(value) || (isGreaterZero ? value > 0 : value >= 0)
  }
}

export default isAmountValid
