// @ts-check
import { ObjectPageSection } from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useConfigurableComponentsPageState } from 'components/ui/configurable-components-page/useConfigurableComponentsPageState'
import { wrapComponentWithLayout } from 'components/ui/configurable-components-page/wrapComponentWithLayout'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

/** @typedef {{parent?: EntryScope, value: unknown}} EntryScope */

/**
 *
 * @param {object} params
 * @param {unknown} params.pageData
 * @param {EntryScope | undefined} params.entryScope
 * @param {string} params.accessor
 */
const getData = ({ pageData, entryScope, accessor }) => {
  if (!accessor) {
    return
  }
  let path = accessor
  let object = entryScope?.value ?? pageData
  if (accessor.startsWith('$parent')) {
    return getData({
      pageData,
      entryScope: entryScope?.parent,
      accessor: accessor.slice('$parent.'.length),
    })
  }
  if (accessor.startsWith('$global')) {
    object = pageData
    path = accessor.slice('$global.'.length)
  }
  return get(object, path)
}

/**
 *
 * @param {object} params
 * @param {unknown} params.pageData
 * @param {EntryScope | undefined} params.entryScope
 * @param {unknown} params.mappingObject
 * @returns {unknown}
 */
const getDynamicData = ({ pageData, entryScope, mappingObject }) => {
  if (!mappingObject || typeof mappingObject === 'number' || typeof mappingObject === 'boolean') {
    return mappingObject
  }
  if (typeof mappingObject === 'string') {
    return getData({ pageData, entryScope, accessor: mappingObject })
  }
  if (Array.isArray(mappingObject)) {
    return mappingObject.map((subMappingObject) =>
      getDynamicData({ pageData, entryScope, mappingObject: subMappingObject }),
    )
  }
  const obj = {}
  for (const key of Object.keys(mappingObject)) {
    const value = mappingObject[key]
    obj[key] = getDynamicData({ pageData, entryScope, mappingObject: value })
  }
  return obj
}

/**
 *
 * @param {import('components/ui/configurable-components-page/useConfigurableComponentsPageState').DynamicPageEntry | undefined} element
 * @param {object} options
 * @param {unknown| undefined} options.contextData
 * @param {unknown | undefined} options.pageData
 * @param {EntryScope | undefined} options.entryScope
 * @returns {import('react').ReactNode[]}
 */
const renderElement = (element, { contextData, pageData, entryScope }) => {
  if (isNil(element)) {
    return []
  }

  if (element.forEach) {
    const forEachValue = getData({ pageData, entryScope, accessor: element.forEach })
    if (!Array.isArray(forEachValue)) {
      return []
    }
    return forEachValue.flatMap((entryData) => {
      const newEntryScope = { parent: entryScope, value: entryData }
      return renderElement(
        { ...element, forEach: undefined },
        { contextData, pageData, entryScope: newEntryScope },
      )
    })
  }
  if (element.if && !getData({ pageData, accessor: element.if, entryScope })) {
    return []
  }

  const renderedGrandChildren = element.children?.flatMap((child) =>
    renderElement(child, { contextData, pageData, entryScope }),
  )
  const dynamicData = getDynamicData({ pageData, entryScope, mappingObject: element.dynamicData })
  return [
    wrapComponentWithLayout({
      id: element.id,
      Component: element.resolvedComponent?.Component,
      componentType: element.resolvedComponent?.type,
      span: element.span,
      contextData,
      entryData: entryScope?.value,
      dynamicData,
      pageData,
      staticData: element.staticData,
      children: renderedGrandChildren,
    }),
  ]
}

const propTypes = {
  pageCode: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contextData: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  pageData: PropTypes.any,
  renderContent: PropTypes.func,
}

/**
 *
 * @param {import('prop-types').InferProps<propTypes>} props
 * @returns
 */
const ConfigurableComponentsPage = ({ pageCode, pageData, renderContent }) => {
  const contextData = useMemo(() => ({}), [])

  const { isAnyLoading, isAnyError, loadingComponents, pageConfig } =
    useConfigurableComponentsPageState({
      pageCode,
      contextData,
    })

  const children = useMemo(() => {
    if (isAnyLoading || isAnyError) {
      return (
        <ObjectPageSection id={pageConfig?.defaultTab}>
          <RequestStateResolver
            isLoading={isAnyLoading}
            isError={isAnyError}
            renderContent={() => null}
            errorToDisplay={<ErrorDataUnavailableInContent />}
          />
        </ObjectPageSection>
      )
    }
    return pageConfig?.children?.map((element) =>
      renderElement(element, { contextData, pageData, entryScope: undefined }),
    )
  }, [
    contextData,
    isAnyError,
    isAnyLoading,
    pageConfig?.children,
    pageConfig?.defaultTab,
    pageData,
  ])

  const content = useMemo(
    () => renderContent?.({ pageConfig, children }),
    [children, pageConfig, renderContent],
  )

  return (
    <>
      {loadingComponents}
      {content}
    </>
  )
}

ConfigurableComponentsPage.propTypes = propTypes

export default ConfigurableComponentsPage
