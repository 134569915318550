import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Grid,
  Label,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isAmountValid from 'components/domains/business-partners/tile/authority-level-calculator/utils/validation/isAmountValid'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import styles from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/totalCommitment/TotalCommitmentEditInput.module.css'

const TotalCommitmentEditInput = ({ handleOnChange, fieldValues }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  const formattedNumberInputRef = useRef()
  const [value, setValue] = useState(fieldValues.totalCommitment.amount)
  const [isInternallyValidNumber, setIsInternallyValidNumber] = useState(true)

  const validationFunction = (value) =>
    isAmountValid(value.amount, { isMandatory: true, isGreaterZero: true })

  const handleOnChangeAmount = (newValue, isValid) => {
    const numberValidationFunction = (numberValue) => isValid && validationFunction(numberValue)
    setIsInternallyValidNumber(isValid)
    if (isValid) {
      setValue(newValue)
    }

    handleOnChange(
      'totalCommitment',
      { amount: newValue, currency: fieldValues.totalCommitment?.currency },
      true,
      true,
      numberValidationFunction,
    )
  }

  return (
    <Grid position="Left" defaultIndent="XL0 L0 M0 S0" defaultSpan="XL6 L6 M6 S6">
      <FlexBox className={styles.labelWrapper} alignItems={FlexBoxAlignItems.Center}>
        <Label required={true}>{t('total-commitment')}</Label>
      </FlexBox>
      <FlexBox className={styles.inputWrapper} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <FormattedNumberInput
          id="input-total-commitment-amount"
          required
          className={styles.inputField}
          value={value}
          valueState={
            isAmountValid(value, { isMandatory: true, isGreaterZero: true })
              ? ValueState.None
              : ValueState.Error
          }
          valueStateMessage={<span>{t('hint.greater-than-zero')}</span>}
          onInput={(parsedValue) =>
            handleOnChangeAmount(parsedValue, formattedNumberInputRef?.current?.isValid)
          }
          ref={formattedNumberInputRef}
        />
        <LoadingSelect
          id="select-total-commitment-currency"
          required
          className={styles.currencyInputSelect}
          onChange={(event) => {
            const newValues = {
              amount: value,
              currency: event?.detail?.selectedOption?.dataset?.id,
            }
            const numberValidationFunction = () =>
              validationFunction(newValues) && isInternallyValidNumber
            handleOnChange('totalCommitment', newValues, false, true, numberValidationFunction)
          }}
          loadingHook={useCurrencyCodes}
          selectionName="currencyCodes"
          optionKeyName="key"
          optionDisplayName="display_name"
          selectedKey={fieldValues.totalCommitment?.currency}
          showEmptyOption={false}
        />
      </FlexBox>
    </Grid>
  )
}

TotalCommitmentEditInput.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  fieldValues: PropTypes.object,
}

export default TotalCommitmentEditInput
