import { MessageBoxTypes, Modals } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/risk-conformity/useDeprecatedDeviationsErrorMessage.module.css'

const useDeprecatedDeviationsErrorMessage = (deprecatedDeviations) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.risk-conformity' })
  const showMessageBox = Modals.useShowMessageBox()

  const renderContent = () => (
    <>
      <span>{t('deviations.error.top-text')}</span>
      <ul className={styles['error-message-list']}>
        {deprecatedDeviations.map(({ code, name }) => (
          <li key={code}>{name}</li>
        ))}
      </ul>
      <span>{t('deviations.error.bottom-text')}</span>
    </>
  )

  return () => showMessageBox({ type: MessageBoxTypes.Error, children: renderContent() })
}

export default useDeprecatedDeviationsErrorMessage
