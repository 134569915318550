import { useContext, useMemo } from 'react'
import Card from 'components/ui/card/Card'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import OtherTable from 'routes/deals/financing/non-loan-products/other/OtherTable'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const OtherLendingCard = () => {
  const { deal } = useContext(DealContext)
  const { data, isLoading } = useNonLoanProducts(deal.displayId)
  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables = {} } = {} } = {},
  } = useConfig()
  const allMappingValues = useMemo(
    () => Object.values(tradeMappingValuesByTables).flatMap((e) => e) ?? [],
    [tradeMappingValuesByTables],
  )
  const filteredData = getNonLoanProductDealDataByConfig({
    tradeMappingAttribute,
    tradeMappingValues: allMappingValues,
    data,
    options: { excludeTradeMappingValues: true },
  })

  // only show the card if specific trade data is available
  if (!isLoading && filteredData?.trades?.length > 0) {
    return (
      <Card>
        <OtherTable />
      </Card>
    )
  }
}

export default OtherLendingCard
