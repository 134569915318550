import {
  FlexBox,
  Button,
  ButtonDesign,
  Popover,
  FlexBoxAlignItems,
  FlexBoxDirection,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import LoadingButton from 'components/ui/button/LoadingButton'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCardHeaderButtons.module.css'

const MultiSelectCardHeaderButtons = ({
  idPrefix,
  isUpdateAllowed,
  isSaveDisabled,
  onEdit,
  onSave,
  onCancel,
  inEditMode,
  isWaitingForRequests = false,
}) => {
  const [isCancelPopoverOpen, setIsCancelPopoverOpen] = useState(false)

  const handleCancel = () => {
    setIsCancelPopoverOpen(false)
    onCancel()
  }

  if (!isUpdateAllowed) {
    return
  }

  if (!inEditMode && !isWaitingForRequests) {
    return (
      <Button
        id={`${idPrefix}-multiselect-card-edit-action`}
        onClick={onEdit}
        design={ButtonDesign.Transparent}
      >
        {t('buttons.edit')}
      </Button>
    )
  }

  return (
    <>
      <FlexBox className={styles.buttonContainer}>
        <LoadingButton
          id={`${idPrefix}-multiselect-card-save-action`}
          disabled={isSaveDisabled}
          onClick={onSave}
          design={ButtonDesign.Emphasized}
          renderContent={() => t('buttons.save')}
          isLoading={isWaitingForRequests}
        />
        <Button
          id={`${idPrefix}-multiselect-card-cancel-action`}
          onClick={() => setIsCancelPopoverOpen(true)}
          design={ButtonDesign.Transparent}
          disabled={isWaitingForRequests}
        >
          {t('buttons.cancel')}
        </Button>
      </FlexBox>
      {createPortal(
        <Popover
          data-testid={'multiselect-card-cancel-action'}
          opener={`${idPrefix}-multiselect-card-cancel-action`}
          placementType="Top"
          open={isCancelPopoverOpen}
          onAfterClose={() => setIsCancelPopoverOpen(false)}
        >
          <FlexBox
            alignItems={FlexBoxAlignItems.Center}
            direction={FlexBoxDirection.Column}
            fitContainer
          >
            <Text className={styles.popoverText}>
              {t('components.ui.card.display-edit-card.button.discard-message')}
            </Text>
            <Button
              id={`${idPrefix}-multiselect-card-popover-discard-changes-action`}
              onClick={handleCancel}
              className={styles.popoverButton}
            >
              {t('components.ui.card.display-edit-card.button.discard')}
            </Button>
          </FlexBox>
        </Popover>,
        document.body,
      )}
    </>
  )
}

MultiSelectCardHeaderButtons.propTypes = {
  idPrefix: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isUpdateAllowed: PropTypes.bool,
  inEditMode: PropTypes.bool,
  isWaitingForRequests: PropTypes.bool,
}

export default MultiSelectCardHeaderButtons
