import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing mainCollateral type ecode keys and their localized display name
 * The mainCollateralTypeCodes structure is as follows:
 * {
 *   "mainCollateralTypeCodes": [
 *       {
 *          display_name: 'Barmittel',
            key: 'Cash',
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { mainCollateralTypeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const useMainCollateralTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/MAIN_COLLATERAL_TYPE`,
    translated: true,
  })

  const transformedData = {
    mainCollateralTypeCodes: result?.data?.configuration?.MAIN_COLLATERAL_TYPE.map((element) => ({
      key: element.code,
      display_name: element.short_text,
    })),
  }

  return {
    ...result,
    data: transformedData,
  }
}
