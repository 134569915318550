import isEmpty from 'lodash.isempty'
import { ADDED } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/constants'

export const useRowsTableSaveHandler = ({
  rows,
  rowsInEdit,
  createDataEntry,
  updateDataEntry,
  deleteDataEntries,
}) => {
  const identifyEdits = (originalData, editedData) => {
    const result = {
      added: [],
      deleted: [],
      changed: [],
    }

    const originalIds = new Set(originalData.map((s) => s.id))
    const editedIds = new Set(editedData.map((s) => s.id))

    editedData.forEach((row) => {
      if (row.id.startsWith(ADDED)) {
        result.added.push(row.id)
      } else if (originalIds.has(row.id)) {
        result.changed.push(row.id)
      }
    })

    originalData.forEach((row) => {
      if (!editedIds.has(row.id)) {
        result.deleted.push(row.id)
      }
    })

    return result
  }

  return async () => {
    const { added, deleted, changed } = identifyEdits(rows, rowsInEdit)

    // TODO: CWP-3804: currently there is no error handling

    const promises = []

    for (const id of added) {
      const row = rowsInEdit.find((s) => s.id === id)
      promises.push(createDataEntry({ data: row }))
    }

    for (const id of changed) {
      const row = rowsInEdit.find((s) => s.id === id)
      promises.push(updateDataEntry({ id, data: row }))
    }
    if (!isEmpty(deleted)) {
      promises.push(deleteDataEntries(deleted))
    }
    await Promise.all(promises)
  }
}
