import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  Switch,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import styles from 'routes/deals/syndication/syndicationIntention/SyndicationIntentionCard.module.css'

const useStandardSwitchField = ({ label, value, name }) => {
  const { t: tSwitch } = useTranslation('translation', {
    keyPrefix: 'components.ui.input.labeled-switch',
  })

  return [
    {
      name,
      value,

      isShownInDisplay: true,
      isShownInEdit: false,
      customInfoComponent: (
        <FlexBox
          fitContainer
          direction={FlexBoxDirection.Row}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
          className={styles.requiredDisplayFieldWrapper}
        >
          <Label>{label}</Label>
          <Text>{value ? tSwitch('yes') : tSwitch('no')}</Text>
        </FlexBox>
      ),
    },
    {
      label,
      name,
      value,

      isShownInDisplay: false,
      isShownInEdit: true,

      renderCustomEditComponent: ({ handleBulkOnChange, fieldValues }) => (
        <EditCardItem
          label={label}
          editComponent={
            <FlexBox justifyContent={FlexBoxJustifyContent.End}>
              <Switch
                checked={fieldValues[name]}
                onChange={(event) => {
                  const newValue = event.target.checked
                  handleBulkOnChange({ [name]: newValue })
                }}
              />
            </FlexBox>
          }
        />
      ),
    },
  ]
}

useStandardSwitchField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  // TODO: validation to be implemented in CWP-7238
  // validationFunction: PropTypes.func,
}

export default useStandardSwitchField
