import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import errorToUI5 from 'components/ui/input/errorToUI5'

const PROPERTY_KEY = 'totalCommitment'

const TotalCommitmentInput = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.deals.tranche-create.basic-information',
  })

  const { register, formState, watch, setValue, trigger, unregister } = useFormContext()
  const error = formState.errors[PROPERTY_KEY]
  const ownShare = watch('ownShare')

  const { valueState, valueStateMessage } = errorToUI5(error)

  const fields = register(PROPERTY_KEY, {
    required: true,
    setValueAs: (val) => (val ? parseFloat(val) : undefined),
    onChange: (e) => {
      if (isNil(ownShare)) {
        setValue('ownShare', String(e.target.value))
      } else {
        trigger('ownShare')
      }
    },
  })

  useEffect(() => () => unregister(PROPERTY_KEY), [unregister])

  const handleChange = async (newValue) => {
    await fields.onChange({
      target: { value: String(newValue), name: PROPERTY_KEY },
      type: 'change',
    })
  }

  const handleBlur = async (newValue) => {
    await fields.onBlur({ target: { value: String(newValue), name: PROPERTY_KEY }, type: 'blur' })
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label showColon required for="tranche-total-commitment-input">
        {t('tranche-total-commitment')}
      </Label>
      <FormattedNumberInput
        id="tranche-total-commitment-input"
        {...fields}
        onChange={handleChange}
        onBlur={handleBlur}
        valueState={valueState}
        valueStateMessage={valueStateMessage}
      />
    </FlexBox>
  )
}

export default TotalCommitmentInput
