import { CardHeader, MessageStripDesign } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/borrower/DealBorrowerCard.module.css'
import DealBorrowerTable from 'components/domains/deals/borrower/DealBorrowerTable'
import Card from 'components/ui/card/Card'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import useTranches from 'hooks/services/deals/covenants/useTranches'
import { DealContext } from 'routes/deals/DealContext'

const DealBorrowerCard = ({ pageData }) => {
  const {
    deal: { borrowerBpId },
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.borrower',
  })
  const {
    deal: { dealUuid },
  } = useContext(DealContext)

  const {
    isLoading: isLoadingTranches,
    isError: isErrorTranches,
    data: { tranches = [] } = {},
  } = useTranches({ dealUuid })

  const borrowerIds = useMemo(() => {
    if (isLoadingTranches) return []
    if (isErrorTranches) return [borrowerBpId]
    return tranches.reduce(
      (ids, tranche) => {
        const { borrower: { id } = {} } = tranche
        if (id && !ids.includes(id)) ids.push(id)
        return ids
      },
      [borrowerBpId],
    )
  }, [borrowerBpId, isErrorTranches, isLoadingTranches, tranches])

  const {
    isLoading: isLoadingBusinessPartners,
    isError: isErrorBusinessPartners,
    data: { businessPartnerMinis: businessPartners = [] } = {},
  } = useBusinessPartnerMiniByIds(borrowerIds)

  const { dealBorrower, trancheBorrowers } = useMemo(
    () =>
      businessPartners.reduce(
        (acc, businessPartner) => {
          if (businessPartner.id === borrowerBpId) {
            acc.dealBorrower = businessPartner
          } else {
            acc.trancheBorrowers.push(businessPartner)
          }
          return acc
        },
        { dealBorrower: {}, trancheBorrowers: [] },
      ),
    [borrowerBpId, businessPartners],
  )

  const renderContent = () => (
    <>
      {isErrorTranches && (
        <div className={styles.container}>
          <CloseableMessageStrip design={MessageStripDesign.Negative}>
            {t('deviating-tranche-borrower.error')}
          </CloseableMessageStrip>
        </div>
      )}
      <div className={styles.container}>
        <DealBorrowerTable dealBorrower={dealBorrower} trancheBorrowers={trancheBorrowers} />
      </div>
    </>
  )

  return (
    <Card header={<CardHeader titleText={t('title', { count: businessPartners.length })} />}>
      <RequestStateResolver
        isLoading={isLoadingTranches || isLoadingBusinessPartners}
        isError={isErrorBusinessPartners}
        errorToDisplay={
          <div className={styles.container}>
            <CloseableMessageStrip design={MessageStripDesign.Negative}>
              {t('deviating-tranche-borrower.error')}
            </CloseableMessageStrip>
          </div>
        }
        renderContent={renderContent}
      />
    </Card>
  )
}

DealBorrowerCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      borrowerBpId: PropTypes.string,
    }),
  }).isRequired,
}

export default DealBorrowerCard
