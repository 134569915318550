import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import React from 'react'
import BusinessPartnerLink from 'components/domains/business-events-and-tasks/BusinessPartnerLink'
import BpsCell from 'components/ui/tables/cells/BpsCell'
import DateCell from 'components/ui/tables/cells/DateCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import PercentageCell from 'components/ui/tables/cells/PercentageCell'
import TextCell from 'components/ui/tables/cells/TextCell'
import { ROW_TYPE_CONDITION } from 'routes/deals/financing/non-loan-products/constants'

const renderCell = (cell, row) => {
  const columnKey = cell.column.id

  const renderMoneyCell = () => {
    const { original, headquarter } = cell.value ?? {}
    return <MoneyCell primary={original} secondary={headquarter} />
  }

  switch (columnKey) {
    case 'tradeCondition':
      return (
        <EntityCell
          id={cell.value?.id}
          options={{
            overflowMarginTreeLevel: row.original?.rowType === ROW_TYPE_CONDITION ? 2 : 1,
          }}
        />
      )
    case 'counterparty':
      return !isNil(cell.value) ? (
        <BusinessPartnerLink
          businessPartnerId={cell.value}
          CustomComponent={EntityCell}
          customComponentProperties={{
            options: {
              openInNewTab: false,
            },
          }}
        />
      ) : (
        <></>
      )
    case 'mtm':
    case 'initialNotional':
    case 'currentNotional':
      return renderMoneyCell()
    case 'startDate':
    case 'maturityDate':
    case 'endOfFixedInterestPeriod':
      return <DateCell date={cell.value} />
    case 'spread':
      return (
        <BpsCell
          value={cell.value}
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      )
    case 'currentRate':
      return (
        <PercentageCell
          value={cell.value}
          options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }}
        />
      )
    default:
      return <TextCell text={cell.value} />
  }
}

const MoneyMarketsTableCell = ({ cell, row }) => renderCell(cell, row)

MoneyMarketsTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

export default MoneyMarketsTableCell
