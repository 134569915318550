import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import ShareInput from 'routes/deals/financing/shared-components/ShareInput'
import { convertDecimalToPercentage } from 'routes/deals/financing/utils/convertPercentages'
import isPercentageValid from 'routes/deals/financing/utils/isPercentageValid'

const useStandardShareField = ({ name, label, share, validationFunction }) => {
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  validationFunction = validationFunction ?? isPercentageValid

  return [
    {
      name,
      label,
      value: formatShare(share),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name,
      label,
      value: convertDecimalToPercentage(share),

      editComponentType: editComponentTypes.Input,
      renderCustomEditComponent: ({ handleOnChange, fieldValues }) => (
        <ShareInput
          name={name}
          label={label}
          handleOnChange={handleOnChange}
          fieldValues={fieldValues}
          validationFunction={validationFunction}
        />
      ),

      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useStandardShareField
