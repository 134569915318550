import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

export const useCreateAmortizationCondition = (options, parameters) => {
  const { post } = useAccessTokenRequest()
  const { dealId, trancheId } = parameters

  return useMutation(
    async ({
      data: {
        amortizationConditionItemTypeCode,
        validFrom,
        ratePerAnnum,
        amountPerInterval,
        frequency,
      },
    }) =>
      await post({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/amortizations/items`,
        body: {
          amortization_condition_item_type_code: amortizationConditionItemTypeCode,
          valid_from: validFrom,
          rate_per_annum: convertPercentageToDecimal(ratePerAnnum),
          amount_per_interval: amountPerInterval,
          frequency,
        },
      }),
    options,
  )
}
