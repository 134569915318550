import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import { NON_LOAN_PRODUCT_TABLE_TYPES } from 'routes/deals/financing/non-loan-products/constants'
import styles from 'routes/deals/financing/non-loan-products/derivatives/DerivativesTable.module.css'
import mapDerivativesData from 'routes/deals/financing/non-loan-products/derivatives/mapDerivativesData'
import useDerivativesTableColumnDefinitions from 'routes/deals/financing/non-loan-products/derivatives/useDerivativesTableColumnDefinitions'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const DerivativesTable = () => {
  const { deal } = useContext(DealContext)
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.derivatives-table' })
  const tableRef = useRef(null)

  const { data, isLoading, isFetching, isError } = useNonLoanProducts(deal.displayId)
  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables } = {} } = {},
  } = useConfig()

  const derivativesMappingValues =
    tradeMappingValuesByTables?.[NON_LOAN_PRODUCT_TABLE_TYPES.DERIVATIVES]

  const tableData = useMemo(() => {
    if (!isEmpty(data?.trades)) {
      const filteredData = getNonLoanProductDealDataByConfig({
        tradeMappingAttribute,
        tradeMappingValues: derivativesMappingValues,
        data,
      })
      const sortedData = {
        trades: sortBy(filteredData?.trades, ['counterpartyId', 'productType', 'productSubType']),
      }
      return isEmpty(sortedData?.trades) ? [] : mapDerivativesData(sortedData?.trades)
    }
    return []
  }, [data, derivativesMappingValues, tradeMappingAttribute])

  const columns = useDerivativesTableColumnDefinitions()

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  // expand rows as default
  useEffect(() => {
    expandOrCollapseSubRows(true)
    // expandOrCollapseSubRows cannot be added, because this is then also triggered upon collapsing the whole table
    // making it impossible to collapse all rows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const countRows = useCallback(
    (tableRows) =>
      !isEmpty(tableRows)
        ? tableRows.length + tableRows.reduce((acc, row) => countRows(row.subRows) + acc, 0)
        : 0,
    [],
  )

  // Counts all parent rows and each of their child's sub-rows to provide a correct number of visible rows needed to correctly render the AnalyticalTable.
  // If this number would be lower than the actual number of displayed rows, the table would not show any data rows.
  // However, the UI5 AnalyticalTable sometimes does not seem to be happy with accuracy, and likes to just have a high number here,
  // so we use the tried and trusted 99 lower bound default
  const visibleRowCount = Math.max(
    countRows(tableData),
    // eslint-disable-next-line no-magic-numbers
    99,
  )

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        loading={!isLoading && isFetching}
        ref={tableRef}
        nrOfEntries={tableData?.length ?? 0}
        additionalActions={collapseExpandActions}
        title={t('header')}
        sortable={false}
        id="derivatives-table"
        minRows={0}
        columns={columns}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={visibleRowCount}
        overscanCountHorizontal={visibleRowCount}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={50}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        data={tableData}
        isTreeTable={true}
        showColumnSelection={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={false}
            isError={isError}
            tableId={'derivatives-table'}
          />
        )}
      />
    </div>
  )
}

DerivativesTable.propTypes = {}

export default DerivativesTable
