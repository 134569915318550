import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const dealBorrowerUnitRelationshipSelectProps = {
  relationshipTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      modifiable: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func.isRequired,
}

const DealBorrowerUnitRelationshipSelect = ({
  relationshipTypes = [],
  onChange = (_) => {},
  ...props
}) => {
  const changeRelationshipType = (event) => {
    onChange(event.detail.selectedOption.value)
  }

  const options = relationshipTypes
    .filter((relationshipType) => relationshipType.id && relationshipType.modifiable)
    .map((relationshipType) => (
      <Option key={relationshipType.id} value={relationshipType.id}>
        {relationshipType.name}
      </Option>
    ))

  return (
    <Select {...props} disabled={!options.length} onChange={changeRelationshipType}>
      <Option key={''} />
      {options}
    </Select>
  )
}

DealBorrowerUnitRelationshipSelect.propTypes = dealBorrowerUnitRelationshipSelectProps

export default DealBorrowerUnitRelationshipSelect
