import { useTypeCodes } from 'hooks/services/deals/financing/configGetters/useTypeCodes'
/**
 * Returns all deal financing creditRisk type ecode keys and their localized display name
 * The typeCodes structure is as follows:
 * {
 *   "typeCodes": [
 *       {
 *          "category": "INTEREST_REFERENCE_RATE_TYPE",
            "code": "1M",
            "short_text": "1M",
            "additional_properties": {}
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { typeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const useInterestReferenceRateTypeCodes = () => useTypeCodes('INTEREST_REFERENCE_RATE_TYPE')
