import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input, { InputTypes } from 'components/ui/input/Input'

const PROPERTY_KEY = 'name'

const NameInput = () => {
  const { t } = useTranslation()

  const { register, formState, unregister } = useFormContext()
  const error = formState.errors[PROPERTY_KEY]

  useEffect(() => () => unregister(PROPERTY_KEY), [unregister])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label showColon required for="tranche-name-input">
        {t('pages.deals.tranche-create.basic-information.tranche-name')}
      </Label>
      <Input
        type={InputTypes.TEXT}
        id="tranche-name-input"
        error={error}
        {...register(PROPERTY_KEY, {
          required: true,
        })}
      />
    </FlexBox>
  )
}

export default NameInput
