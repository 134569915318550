import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing duration type code keys and their localized display name
 * The durationTypeCodes structure is as follows:
 * {
 *   "durationTypeCodes": [
 *       {
 *           "key": "Term",
 *           "display_name": "Laufzeit"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { durationTypeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const useDurationTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/DURATION_TYPE`,
    translated: true,
  })

  const transformedData = {
    durationTypeCodes: result?.data?.configuration?.DURATION_TYPE.map((element) => ({
      key: element.code,
      display_name: element.short_text,
    })),
  }

  return {
    ...result,
    data: transformedData,
  }
}
