import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useUpdateAmortizationsComment = (options, parameters) => {
  const { put } = useAccessTokenRequest()
  const { dealId, trancheId } = parameters

  return useMutation(
    async ({ data: { comment } }) =>
      await put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/amortizations`,
        body: {
          comment,
        },
      }),
    options,
  )
}
