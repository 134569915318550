import {
  DatePicker,
  Text,
  FlexBoxDirection,
  FlexBox,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import isNil from 'lodash.isnil'
import { useRef } from 'react'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useShortDateFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'
import { useInterestCalculationMethodTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestCalculationMethodTypeCodes'
import { useInterestIndexNameTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestIndexNameTypeCodes'
import { useInterestIndexTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestIndexTypeCodes'
import { useInterestPaymentFrequencyTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestPaymentFrequencyTypeCodes'
import { useInterestReferenceRateTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestReferenceRateTypeCodes'
import styles from 'routes/deals/financing/trancheDetails/cards/amortization-card/useAmortizationColumnDefinitions.module.css'
import { ColumnHideOptions } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/MultiSelectCardTable'
import { SelectAndUnselectAllTableCellEdit } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/SelectAndUnselectAllTableCellEdit'
import { SelectAndUnselectTableCellEdit } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/SelectAndUnselectTableCellEdit'
import {
  StandardCellView,
  StandardCellViewPosition,
} from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/StandardCellView'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const useInterestConditionsColumnDefinitions = ({
  isRowSelected,
  areAllRowsSelected,
  onSelectChange,
  onSelectAllChange,
  onValueChange,
}) => {
  const { format: formatDate, parse: parseDate, localePattern } = useShortDateFormatter()
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const customerMarginInputRef = useRef()
  const allInInterestRateInputRef = useRef()

  const isValidFromValid = (value) => !!value
  const isInterestRateTypeCodeValid = (value) => !!value

  // TODO: CWP-4609: Adjust all LoadingSelect components in this file to load the correct configCodes and use the right data

  return [
    {
      columnKey: 'checkboxSelector',
      minWidthDisplay: 0,
      minWidthEdit: 25,
      title: () => (
        <SelectAndUnselectAllTableCellEdit
          isSelected={areAllRowsSelected}
          onSelectChangeAll={onSelectAllChange}
        />
      ),
      cellValueEdit: (dataRow, options) => (
        <SelectAndUnselectTableCellEdit
          isSelectable={options.isSelectable}
          isSelected={isRowSelected(dataRow)}
          onSelectChange={(e) => onSelectChange(dataRow, e)}
        />
      ),
      hideOption: ColumnHideOptions.hideInView,
    },

    {
      columnKey: 'interestRateTypeCode',
      key: 'interestRateTypeCode',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      needsValidation: true,
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.Start}
          content={t(
            'pages.deals.tranches.details.interest-conditions-card.interest-rate-type-code',
          )}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.interestRateTypeCode}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <FlexBox direction={FlexBoxDirection.Row}>
          <LoadingSelect
            loadingHook={useInterestIndexTypeCodes}
            selectionName={'typeCodes'}
            selectedKey={item.interestRateTypeCode}
            onChange={(event) => {
              const selectedOptionId = event.detail.selectedOption.dataset.id
              onValueChange(
                item.id,
                'interestRateTypeCode',
                selectedOptionId,
                isInterestRateTypeCodeValid,
              )
            }}
            isErrorState={!isInterestRateTypeCodeValid(item.interestRateTypeCode)}
            valueStateMessage={
              <span>
                {t(
                  'pages.deals.tranches.details.interest-conditions-card.interest-rate-type-code.hint',
                )}
              </span>
            }
          />
        </FlexBox>
      ),
    },
    {
      columnKey: 'validFrom',
      key: 'validFrom',
      minWidthDisplay: 80,
      minWidthEdit: 190,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.valid-from'),
      needsValidation: true,
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.End}
          content={t('pages.deals.tranches.details.interest-conditions-card.valid-from')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={formatDate(item.validFrom)}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <FlexBox direction={FlexBoxDirection.Row}>
          <DatePicker
            className={styles.validFromDatePicker}
            value={item.validFrom ? formatDate(item.validFrom) : ''}
            formatPattern={localePattern}
            onChange={(event) => {
              const selectedOptionId = parseDate(event.detail.value, localePattern)
              onValueChange(item.id, 'validFrom', selectedOptionId, isValidFromValid)
            }}
            valueState={isValidFromValid(item.validFrom) ? ValueState.None : ValueState.Error}
            valueStateMessage={
              <span>
                {t('pages.deals.tranches.details.interest-conditions-card.valid-from.hint')}
              </span>
            }
          />
        </FlexBox>
      ),
    },
    {
      columnKey: 'paymentFrequency',
      key: 'paymentFrequency',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.payment-frequency'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.Start}
          content={t('pages.deals.tranches.details.interest-conditions-card.payment-frequency')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.paymentFrequencyShortText}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <LoadingSelect
          loadingHook={useInterestPaymentFrequencyTypeCodes}
          selectionName={'typeCodes'}
          selectedKey={item.paymentFrequency}
          onChange={(event) => {
            const selectedOptionId = event.detail.selectedOption.dataset.id
            onValueChange(item.id, 'paymentFrequency', selectedOptionId)
          }}
        />
      ),
    },
    {
      columnKey: 'interestIndexName',
      key: 'interestIndexName',
      minWidthDisplay: 70,
      minWidthEdit: 80,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.interest-index-name'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.Start}
          content={t('pages.deals.tranches.details.interest-conditions-card.interest-index-name')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.interestIndexNameShortText}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <LoadingSelect
          loadingHook={useInterestIndexNameTypeCodes}
          selectionName={'typeCodes'}
          selectedKey={item.interestIndexName}
          onChange={(event) => {
            const selectedOptionId = event.detail.selectedOption.dataset.id
            onValueChange(item.id, 'interestIndexName', selectedOptionId)
          }}
        />
      ),
    },
    {
      columnKey: 'referenceRate',
      key: 'referenceRate',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.reference-rate'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.Start}
          content={t('pages.deals.tranches.details.interest-conditions-card.reference-rate')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.referenceRateShortText}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <LoadingSelect
          loadingHook={useInterestReferenceRateTypeCodes}
          selectionName={'typeCodes'}
          selectedKey={item.referenceRate}
          onChange={(event) => {
            const selectedOptionId = event.detail.selectedOption.dataset.id
            onValueChange(item.id, 'referenceRate', selectedOptionId)
          }}
        />
      ),
    },
    {
      columnKey: 'customerMargin',
      key: 'customerMargin',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.customer-margin'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.End}
          content={t('pages.deals.tranches.details.interest-conditions-card.customer-margin')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={!isNil(item.customerMargin) ? formatShare(item.customerMargin / 100) : ''}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <FormattedNumberInput
          value={convertToNumberIfValid(item.customerMargin)}
          icon={<Text>{'%'}</Text>}
          iconEnd="true"
          onChange={(number) => {
            const isInternallyValid = customerMarginInputRef?.current?.isValid
            onValueChange(
              item.id,
              'customerMargin',
              convertToNumberIfValid(number),
              () => isInternallyValid,
            )
          }}
          ref={customerMarginInputRef}
        />
      ),
    },
    {
      columnKey: 'allInInterestRate',
      key: 'allInInterestRate',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.all-in-interest-rate'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.End}
          content={t('pages.deals.tranches.details.interest-conditions-card.all-in-interest-rate')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={!isNil(item.allInInterestRate) ? formatShare(item.allInInterestRate / 100) : ''}
          position={StandardCellViewPosition.End}
        />
      ),
      cellValueEdit: (item) => (
        <FormattedNumberInput
          value={convertToNumberIfValid(item.allInInterestRate)}
          icon={<Text>{'%'}</Text>}
          iconEnd="true"
          onChange={(number) => {
            const isInternallyValid = allInInterestRateInputRef?.current?.isValid
            onValueChange(
              item.id,
              'allInInterestRate',
              convertToNumberIfValid(number),
              () => isInternallyValid,
            )
          }}
          ref={allInInterestRateInputRef}
        />
      ),
    },
    {
      columnKey: 'calculationMethod',
      key: 'calculationMethod',
      minWidthDisplay: 80,
      minWidthEdit: 100,
      popinText: t('pages.deals.tranches.details.interest-conditions-card.calculation-method'),
      title: () => (
        <StandardCellView
          position={StandardCellViewPosition.Start}
          content={t('pages.deals.tranches.details.interest-conditions-card.calculation-method')}
        />
      ),
      cellValue: (item) => (
        <StandardCellView
          content={item.calculationMethodShortText}
          position={StandardCellViewPosition.Start}
        />
      ),
      cellValueEdit: (item) => (
        <LoadingSelect
          loadingHook={useInterestCalculationMethodTypeCodes}
          selectionName={'typeCodes'}
          selectedKey={item.calculationMethod}
          onChange={(event) => {
            const selectedOptionId = event.detail.selectedOption.dataset.id
            onValueChange(item.id, 'calculationMethod', selectedOptionId)
          }}
        />
      ),
    },
  ]
}

export default useInterestConditionsColumnDefinitions
