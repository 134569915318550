import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DerivativesTableCell from 'routes/deals/financing/non-loan-products/derivatives/DerivativesTableCell'

const useColumnDefinitions = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.derivatives-table' })
  return useMemo(
    () =>
      [
        {
          Header: t('trade-condition-label'),
          accessor: 'tradeCondition',
          minWidth: 300,
        },
        {
          Header: t('product-type-label'),
          accessor: 'productType',
          minWidth: 200,
        },
        {
          Header: t('product-sub-type-label'),
          accessor: 'productSubType',
          minWidth: 200,
        },
        {
          Header: () => (
            <Trans i18nKey="pages.deals.derivatives-table.limit-system-product-class-label" />
          ),
          title: t('limit-system-product-class-label'),
          accessor: 'limitSystemProductClass',
          minWidth: 150,
          isVisible: false,
        },
        {
          Header: t('counterparty-label'),
          accessor: 'counterparty',
          minWidth: 250,
        },
        {
          Header: t('mtm-label'),
          accessor: 'mtm',
          minWidth: 180,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('amortization-label'),
          accessor: 'amortization',
          minWidth: 180,
        },
        {
          Header: t('start-date-label'),
          accessor: 'startDate',
          minWidth: 120,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('maturity-date-label'),
          accessor: 'maturityDate',
          minWidth: 120,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('buy-or-sell-label'),
          accessor: 'buyOrSell',
          minWidth: 100,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('pay-or-receive-label'),
          accessor: 'payOrReceive',
          minWidth: 100,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('initial-notional-label'),
          accessor: 'initialNotional',
          minWidth: 180,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('current-notional-label'),
          accessor: 'currentNotional',
          minWidth: 180,
          hAlign: TextAlign.Right,
        },
        {
          Header: t('rate-type-label'),
          accessor: 'rateType',
          minWidth: 100,
        },
        {
          Header: () => <Trans i18nKey="pages.deals.derivatives-table.rate-index-name-label" />,
          title: t('rate-index-name-label'),
          accessor: 'rateIndexName',
          minWidth: 150,
        },
        {
          Header: t('tenor-label'),
          accessor: 'tenor',
          minWidth: 100,
        },
        {
          Header: t('spread-label'),
          accessor: 'spread',
          minWidth: 120,
          hAlign: TextAlign.Right,
        },
        {
          Header: () => (
            <Trans i18nKey="pages.deals.derivatives-table.current-rate-strike-rate-label" />
          ),
          title: t('current-rate-strike-rate-label'),
          accessor: 'currentRateStrikeRate',
          minWidth: 120,
          hAlign: TextAlign.Right,
        },
        {
          Header: () => (
            <Trans i18nKey="pages.deals.derivatives-table.end-of-fixed-interest-period-label" />
          ),
          title: t('end-of-fixed-interest-period-label'),
          accessor: 'endOfFixedInterestPeriod',
          minWidth: 160,
          hAlign: TextAlign.Right,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: DerivativesTableCell,
        vAlign: VerticalAlign.Middle,
        isSelectableForHiding: true,
        isVisible: true,
        columnKey: element.accessor,
        ...element,
        title: element.title ? element.title.replace('<br/>', ' ') : element.Header,
      })),
    [t],
  )
}

export default useColumnDefinitions
