import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import AmountWithCurrencyEditInput from 'routes/deals/financing/shared-components/AmountWithCurrencyEditInput'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'

const useStandardMoneyWithCurrencyDropdownField = ({
  name,
  label,
  value,
  valueHeadquarter,
  validationFunction,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { data, isLoading, isError } = useCurrencyCodes()

  validationFunction = validationFunction ?? (({ amount }) => isAmountValid(amount))

  return [
    {
      name,
      label,
      value: formatMoney(value?.amount, value?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name,
      label,
      value: value,
      editComponentType: editComponentTypes.Input,
      renderCustomEditComponent: ({ handleOnChange, fieldValues }) => (
        <AmountWithCurrencyEditInput
          name={name}
          label={label}
          handleOnChange={handleOnChange}
          fieldValues={fieldValues}
          currencyCodes={(!isLoading && !isError && data.currencyCodes) || []}
          validationFunction={validationFunction}
        />
      ),

      isShownInDisplay: false,
      isShownInEdit: true,
    },
    {
      name,
      label,
      customInfoComponent: ConvertedAmount(valueHeadquarter?.amount, valueHeadquarter?.currency),

      isShownInDisplay:
        !!valueHeadquarter && !!value.amount && value.currency !== valueHeadquarter?.currency,
      isShownInEdit: false,
    },
  ]
}

export default useStandardMoneyWithCurrencyDropdownField
