import {
  CustomListItem,
  FlexBox,
  FlexBoxJustifyContent,
  Label,
  Text,
  ListItemType,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingListItem.module.css'

const TranchePricingListItem = ({
  label,
  value,
  highlight,
  isSectionTitle,
  isConvertedAmount,
  labelOnly,
}) => (
  <CustomListItem
    className={isSectionTitle && styles.isSectionTitle}
    type={ListItemType.Inactive}
    style={{ minHeight: '0' }}
  >
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.boxWrapper}
    >
      <Label className={highlight && styles.highlighted}>{label}</Label>
      {!labelOnly && (
        <div className={styles.valueWrapper}>
          <Text
            className={`${highlight ? styles.highlighted : ''} ${
              isConvertedAmount ? styles.textLabelColor : ''
            }`}
          >
            {!value ? '-' : value}
          </Text>
        </div>
      )}
    </FlexBox>
  </CustomListItem>
)

TranchePricingListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  highlight: PropTypes.bool,
  isSectionTitle: PropTypes.bool,
  isConvertedAmount: PropTypes.bool,
  labelOnly: PropTypes.bool,
}

export default TranchePricingListItem
