import { useMutation } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateOrUpdateOtherFinancingSource = ({ onSuccess, onError }) => {
  const { put, post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, itemId, ...body }) =>
      isNil(itemId)
        ? await post({
            path: `/financing-structures/deals/${dealId}/other-financing-sources/items`,
            body: snakecaseKeys(body),
          })
        : await put({
            path: `/financing-structures/deals/${dealId}/other-financing-sources/items/${itemId}`,
            body: snakecaseKeys(body),
          }),
    { onSuccess, onError },
  )
}

export default useCreateOrUpdateOtherFinancingSource
