import { useAreaMeasurementUnitFormatter } from 'hooks/i18n/useI18n'
import { useAreaMeasureUnitCodes } from 'hooks/services/properties/useAreaUnitOfMeasureCodes'

export const useAreaMeasureUnitCodesShort = () => {
  const result = useAreaMeasureUnitCodes()
  const formatUnit = useAreaMeasurementUnitFormatter()

  if (result.isLoading || result.isError) return result
  const measure_unit_codes = result.data.measure_unit_codes?.map((unit) => ({
    ...unit,
    display_name_short: formatUnit(unit.key),
  }))
  return { ...result, data: { measure_unit_codes } }
}
