import '@ui5/webcomponents/dist/features/InputSuggestions.js'
import { IllustratedMessage } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BusinessPartnerFilterBar from 'components/domains/business-partners/filterbar/BusinessPartnerFilterBar'
import styles from 'components/domains/deals/input/BusinessPartnerSearchDialog.module.css'
import BusinessPartnerTable from 'components/domains/deals/input/BusinessPartnerTable'
import Dialog, { DialogPrimaryButton, DialogSecondaryButton } from 'components/ui/dialog/Dialog'
import { useMatchingBusinessPartners } from 'hooks/services/business-partners/searchBusinessPartners'

const initialSorting = { columnKey: 'id', orderBy: 'asc' }
const BusinessPartnerSearchDialog = ({
  open,
  type,
  initialSearch,
  onClose,
  onChange,
  translatedTypeName,
  initialRoles = [],
  initialExcludeInactive = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.deals.input.business-partner.dialog-search',
  })

  const translatedType =
    translatedTypeName ?? t(type, { keyPrefix: 'components.deals.input.business-partner.type' })

  const [filterParams, setFilterParams] = useState({
    q: initialSearch || '',
    id: '',
    street: '',
    city: '',
    countries: [],
    roles: initialRoles,
    excludeInactive: initialExcludeInactive,
    sort: initialSorting,
  })
  const [isBeforeSearch, setIsBeforeSearch] = useState(!initialSearch)
  const [selection, setSelection] = useState(undefined)

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const {
    data: { businessPartners: suggestions = [], total } = {},
    isFetching: isSuggestionsLoading,
    isError: isSuggestionsError,
    fetchNextPage,
    hasNextPage,
  } = useMatchingBusinessPartners({
    ...filterParams,
    sort: translateSortSettingToSortParameter(filterParams.sort),
  })

  const updateFilters = ({ id, q, street, city, countries, roles, excludeInactive, sort }) => {
    setFilterParams({ id, q, street, city, countries, roles, excludeInactive, sort })
  }

  const handleSelect = (newSelection) => {
    onChange?.(newSelection)
    onClose?.()
    setIsBeforeSearch(true)
  }

  const handleCancel = () => {
    setIsBeforeSearch(true)
    onClose?.()
  }

  const handleFilterChange = (newFilterParams) => {
    setIsBeforeSearch(false)
    updateFilters(newFilterParams)
  }

  return (
    <>
      <Dialog
        open={open}
        size="XL"
        headerText={t('title', {
          type: translatedType,
        })}
        initialFocus="business-partner-filterbar-initial-focus"
        onBeforeClose={(e) => e.detail.escPressed && handleCancel()}
        primaryButton={
          <DialogPrimaryButton onClick={() => handleSelect(selection)} disabled={!selection}>
            {t('select-button')}
          </DialogPrimaryButton>
        }
        closeButton={
          <DialogSecondaryButton onClick={handleCancel}>{t('cancel-button')}</DialogSecondaryButton>
        }
      >
        <BusinessPartnerFilterBar initialValues={filterParams} onGo={handleFilterChange} />
        {isBeforeSearch ? (
          <IllustratedMessage
            className={styles.beforeSearchMessage}
            name="BeforeSearch"
            titleText={t('title', {
              keyPrefix: 'pages.business-partner-search.perform-search',
            })}
            subtitleText={t('subtitle', {
              keyPrefix: 'pages.business-partner-search.perform-search',
            })}
          />
        ) : (
          <BusinessPartnerTable
            businessPartners={suggestions ?? []}
            total={total ?? 0}
            isLoading={isSuggestionsLoading}
            isError={isSuggestionsError}
            onChange={setSelection}
            hasNextPage={!!hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        )}
      </Dialog>
    </>
  )
}

BusinessPartnerSearchDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(['borrower', 'party']),
  initialSearch: PropTypes.string,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  translatedTypeName: PropTypes.string,
  initialExcludeInactive: PropTypes.bool,
}

export default BusinessPartnerSearchDialog
