import { FlexBox } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import styles from 'routes/deals/financing/otherFinancingSources/fields/currency/CurrencyInputSelect.module.css'

const CurrencyInputSelect = ({
  rowKey,
  selectedKey,
  handleOnChange,
  setInteractedWithRowState,
  isValid,
}) => (
  <FlexBox className={styles.currencyWrapper}>
    <LoadingSelect
      id="otherFinancingSourceCurrencyCodesLoadingSelect"
      className={styles.currencyInputSelect}
      loadingHook={useCurrencyCodes}
      selectionName="currencyCodes"
      optionKeyName="key"
      optionDisplayName="display_name"
      selectedKey={selectedKey}
      valueState={isValid ? ValueState.None : ValueState.Error}
      valueStateMessage={
        <span>
          {t('pages.deals.financing.other-financing-sources.validation.currency-required')}
        </span>
      }
      onChange={(event) => {
        const newValue = event.detail.selectedOption.dataset.id
        handleOnChange({ rowKey, newValue })
        setInteractedWithRowState(rowKey, 'currency')
      }}
      onBlur={() => {
        setInteractedWithRowState(rowKey, 'currency')
      }}
    />
  </FlexBox>
)

CurrencyInputSelect.propTypes = {
  rowKey: PropTypes.string.isRequired,
  selectedKey: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  setInteractedWithRowState: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
}

export default CurrencyInputSelect
