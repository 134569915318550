import PropTypes from 'prop-types'
import { useMemo } from 'react'
import TextEllipsis from 'components/ui/text/TextEllipsis'
import {
  useBooleanToTextFormatter,
  useCustomizableCurrencyFormatter,
  usePercentageFormatter,
} from 'hooks/i18n/useI18n'
import { TranchePricingCustomFieldTypes } from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingCustomFieldTypes'
import TranchePricingListItem from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingListItem'

export const TranchePricingCustomFieldListItem = ({ customField }) => {
  const { value, type, categoryShortText, currencyCode } = customField

  const formatBoolean = useBooleanToTextFormatter()
  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formatCurrency = useCustomizableCurrencyFormatter()

  const formattedValue = useMemo(() => {
    switch (type) {
      case TranchePricingCustomFieldTypes.String:
        return <TextEllipsis content={value} />
      case TranchePricingCustomFieldTypes.Bool:
        return formatBoolean(value)
      case TranchePricingCustomFieldTypes.Percentage:
        return formatPercentage(value)
      case TranchePricingCustomFieldTypes.Currency:
        return formatCurrency(value, currencyCode)
      default:
        return value
    }
  }, [type, value, formatBoolean, formatPercentage, formatCurrency, currencyCode])

  return (
    <TranchePricingListItem
      key={categoryShortText}
      label={categoryShortText}
      value={formattedValue}
    />
  )
}

TranchePricingCustomFieldListItem.propTypes = {
  customField: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    type: PropTypes.string,
    categoryShortText: PropTypes.string,
    currencyCode: PropTypes.string,
  }),
}
