import {
  ROW_TYPE_TRADE,
  TABLE_ROW_HEIGHT,
} from 'components/domains/business-partners/tile/financial-product/moneyMarkets/constants'
import mapMoneyMarketsConditionData from 'components/domains/business-partners/tile/financial-product/moneyMarkets/mapMoneyMarketsConditionData'

const mapMoneyMarketsTradeData = (trades = []) =>
  trades.map((trade) => {
    const {
      tradeId,
      productType,
      productSubType,
      limitSystemProductClass,
      counterpartyId,
      mtm,
      mtmHeadquarter,
      amortization,
      startDate,
      maturityDate,
      payOrReceive,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      endOfFixedInterestPeriod,
      conditions,
    } = trade ?? {}
    return {
      dealTradeCondition: { id: tradeId },
      productType,
      productSubType,
      limitSystemProductClass,
      counterparty: counterpartyId,
      startDate,
      maturityDate,
      payOrReceive,
      amortization,
      mtm: {
        original: {
          amount: mtm?.amount,
          currency: mtm?.currency,
        },
        headquarter: {
          amount: mtmHeadquarter?.amount,
          currency: mtmHeadquarter?.currency,
        },
      },
      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      endOfFixedInterestPeriod,
      subRows: mapMoneyMarketsConditionData(conditions),
      rowType: ROW_TYPE_TRADE,
      rowHeight: TABLE_ROW_HEIGHT,
    }
  })

export default mapMoneyMarketsTradeData
