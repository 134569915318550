import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateCustomEntities = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, ...body }) => {
      await post({
        path: `/deals/${dealUuid}/custom-entities`,
        body: snakecaseKeys(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useCreateCustomEntities
