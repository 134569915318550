import isEmpty from 'lodash.isempty'
import { useCallback, useMemo } from 'react'
import useSyndicationsExistingBusiness from 'hooks/services/deals/syndication/useSyndicationsExistingBusiness'

export const useExpandTrancheWithExistingBusinessData = () => {
  const mapOwnShareInformation = (
    originalOwnShare = {},
    originalOwnShareHq = {},
    ownShareInformationToExpand,
  ) => ({
    ownShare: {
      ...originalOwnShare,
      commitment: ownShareInformationToExpand?.amount?.amountOriginalCurrency?.amount,
      available: ownShareInformationToExpand?.availableAmount?.amountOriginalCurrency?.amount,
      outstanding: ownShareInformationToExpand?.outstandingAmount?.amountOriginalCurrency?.amount,
      currency:
        ownShareInformationToExpand?.amount?.amountOriginalCurrency?.currency ||
        ownShareInformationToExpand?.availableAmount?.amountOriginalCurrency?.currency ||
        ownShareInformationToExpand?.outstandingAmount?.amountOriginalCurrency?.currency,
    },
    ownShareHeadquarter: {
      ...originalOwnShareHq,
      commitment: ownShareInformationToExpand?.amount?.amountHeadQuarterCurrency?.amount,
      available: ownShareInformationToExpand?.availableAmount?.amountHeadQuarterCurrency?.amount,
      outstanding:
        ownShareInformationToExpand?.outstandingAmount?.amountHeadQuarterCurrency?.amount,
      currency:
        ownShareInformationToExpand?.amount?.amountHeadQuarterCurrency?.currency ||
        ownShareInformationToExpand?.availableAmount?.amountHeadQuarterCurrency?.currency ||
        ownShareInformationToExpand?.outstandingAmount?.amountHeadQuarterCurrency?.currency,
    },
    ownCommitmentShare: ownShareInformationToExpand?.amountShare,
    availableShare: ownShareInformationToExpand?.availableAmountShare,
    outstandingShare: ownShareInformationToExpand?.outstandingAmountShare,
  })

  const findSyndicationInDrawdowns = (syndications, drawdown) => {
    const drawdowns = syndications?.drawDowns ?? []
    for (const syndicationDrawdown of drawdowns) {
      const syndication = syndicationDrawdown?.syndications?.find(
        ({ accountNumber }) => drawdown.externalContractId === accountNumber,
      )
      if (syndication) return syndication
    }
  }

  const expandDrawDowns = useCallback((tranche, trancheSyndicationExistingBusiness) => {
    const drawdowns = tranche?.drawdowns ?? []
    if (isEmpty(drawdowns)) return drawdowns
    const expandedDrawdowns = tranche.drawdowns.map((drawdown) => {
      const trancheDrawdown = trancheSyndicationExistingBusiness?.drawDowns?.find(
        ({ id: drawdownIdExistingBusiness }) =>
          drawdown.externalContractId === drawdownIdExistingBusiness,
      )
      const syndicationDrawdown = findSyndicationInDrawdowns(
        trancheSyndicationExistingBusiness,
        drawdown,
      )
      const mappedExistingBusinessDrawdown = syndicationDrawdown && {
        ...syndicationDrawdown,
        ownShare: {
          ...(syndicationDrawdown.syndicationAmount ?? {}),
        },
      }
      const drawdownSyndicationExistingBusiness = syndicationDrawdown
        ? mappedExistingBusinessDrawdown
        : trancheDrawdown

      if (!drawdownSyndicationExistingBusiness) return drawdown
      return {
        ...drawdown,
        ...mapOwnShareInformation(
          drawdown.ownShare,
          drawdown.ownShareHeadquarter,
          drawdownSyndicationExistingBusiness.ownShare,
        ),
      }
    })
    return expandedDrawdowns
  }, [])

  const findSyndicationInTranches = (syndicationsExistingBusiness, tranche) => {
    const tranches = syndicationsExistingBusiness?.tranches ?? []
    for (const syndicationTranche of tranches) {
      const syndication = syndicationTranche?.syndications?.find(({ accountNumber }) =>
        tranche.externalContractId.some((id) => id === accountNumber),
      )
      if (syndication) return { ...syndication, drawDowns: syndicationTranche?.drawDowns ?? [] }
    }
  }

  const expandTranches = useCallback(
    ({ tranches = [], syndicationsExistingBusiness }) =>
      tranches.map((tranche) => {
        const existingBusinessSyndication = findSyndicationInTranches(
          syndicationsExistingBusiness,
          tranche,
        )
        const mappedExistingBusinessSyndication = existingBusinessSyndication && {
          ...existingBusinessSyndication,
          ownShare: {
            ...(existingBusinessSyndication.syndicationAmount ?? {}),
          },
        }

        const trancheSyndicationExistingBusiness = syndicationsExistingBusiness?.tranches?.find(
          ({ id: trancheIdExistingBusiness }) =>
            tranche.externalContractId.some((id) => id === trancheIdExistingBusiness),
        )
        const resetOwnShareInformation = () => ({
          ownCommitmentShare: undefined,
          outstandingShare: undefined,
          availableShare: undefined,
          ownShare: {},
          ownShareHeadquarter: {},
        })

        const reducedTranche = {
          ...tranche,
          ...resetOwnShareInformation(),
          drawdowns: tranche?.drawdowns?.map((drawdown) => ({
            ...drawdown,
            ...resetOwnShareInformation(),
          })),
        }
        const trancheOrSyndicationToMap = existingBusinessSyndication
          ? mappedExistingBusinessSyndication
          : trancheSyndicationExistingBusiness
        if (trancheOrSyndicationToMap) {
          return {
            ...reducedTranche,
            ...mapOwnShareInformation(
              reducedTranche.ownShare,
              reducedTranche.ownShareHeadquarter,
              trancheOrSyndicationToMap.ownShare,
            ),
            drawdowns: expandDrawDowns(reducedTranche, trancheOrSyndicationToMap),
          }
        } else {
          return reducedTranche
        }
      }),
    [expandDrawDowns],
  )

  const expandSummary = useCallback(
    ({ summary, syndicationsExistingBusinessOwnShare }) => ({
      ...(summary || {}),
      ownCommitment: syndicationsExistingBusinessOwnShare?.amount?.amountOriginalCurrency?.amount,
      available:
        syndicationsExistingBusinessOwnShare?.availableAmount?.amountOriginalCurrency?.amount,
      outstanding:
        syndicationsExistingBusinessOwnShare?.outstandingAmount?.amountOriginalCurrency?.amount,
      ownCommitmentShare: syndicationsExistingBusinessOwnShare?.amountShare,
    }),
    [],
  )
  const expandHeadquarterSummary = useCallback(
    ({ summary, syndicationsExistingBusinessOwnShare }) => ({
      ...(summary || {}),
      ownCommitment:
        syndicationsExistingBusinessOwnShare?.amount?.amountHeadQuarterCurrency?.amount,
      available:
        syndicationsExistingBusinessOwnShare?.availableAmount?.amountHeadQuarterCurrency?.amount,
      outstanding:
        syndicationsExistingBusinessOwnShare?.outstandingAmount?.amountHeadQuarterCurrency?.amount,
      ownCommitmentShare: syndicationsExistingBusinessOwnShare?.amountShare,
    }),
    [],
  )

  return { expandTranches, expandSummary, expandHeadquarterSummary }
}

const useExpandTranchesDataWithExistingBusinessInformation = ({
  isExistingBusiness,
  data,
  dealUuid,
} = {}) => {
  const { data: syndicationsExistingBusiness, isFetching } = useSyndicationsExistingBusiness(
    dealUuid,
    {
      enabled: isExistingBusiness,
    },
  )
  const { expandTranches, expandSummary, expandHeadquarterSummary } =
    useExpandTrancheWithExistingBusinessData()

  const expandedData = useMemo(() => {
    if (!isExistingBusiness || isFetching || !data) {
      return data
    }
    return {
      ...data,
      tranches: expandTranches({ tranches: data?.tranches, syndicationsExistingBusiness }),
      summary: expandSummary({
        summary: data?.summary,
        syndicationsExistingBusinessOwnShare: syndicationsExistingBusiness?.ownShare,
      }),
      summaryHeadquarter: expandHeadquarterSummary({
        summary: data?.summaryHeadquarter,
        syndicationsExistingBusinessOwnShare: syndicationsExistingBusiness?.ownShare,
      }),
    }
  }, [
    data,
    expandHeadquarterSummary,
    expandSummary,
    expandTranches,
    isExistingBusiness,
    isFetching,
    syndicationsExistingBusiness,
  ])

  return { data: expandedData, isFetching }
}

export default useExpandTranchesDataWithExistingBusinessInformation
