import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'

const useAvailableAmountFields = ({
  ownShare,
  ownShareHeadquarter,
  totalCommitmentHeadquarter,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })
  const formatMoney = useCustomizableCurrencyFormatter()

  return [
    {
      name: 'availableAmount',
      label: t('available-own-share'),
      value: formatMoney(ownShare?.available, ownShare?.currency, { currencyDisplay: 'code' }),

      editComponentType: 'Input',
      editComponentProps: { type: 'Number', readonly: true },

      isShownInDisplay: true,
      isShownInEdit: true,
    },

    {
      name: 'availableAmount',
      label: t('available-own-share'),
      customInfoComponent: ConvertedAmount(
        ownShareHeadquarter?.available,
        totalCommitmentHeadquarter?.currency,
      ),

      isShownInDisplay:
        !!ownShareHeadquarter &&
        !isNil(ownShareHeadquarter?.available) &&
        ownShare?.currency !== ownShareHeadquarter?.currency,
      isShownInEdit: false,
    },
  ]
}

export default useAvailableAmountFields
