import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import DealRiskConformityDisplay from 'components/domains/deals/risk-conformity/DealRiskConformityDisplay'
import DealRiskConformityEdit from 'components/domains/deals/risk-conformity/DealRiskConformityEdit'
import useDeprecatedDeviationsErrorMessage from 'components/domains/deals/risk-conformity/useDeprecatedDeviationsErrorMessage'
import useCancelPopover from 'components/domains/deals/useCancelPopover'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useRiskConformity from 'hooks/services/deals/risk-conformity/useRiskConformity'
import useUpdateRiskConformity from 'hooks/services/deals/risk-conformity/useUpdateRiskConformity'

const HEADER_ID_PREFIX = 'deal-risk-conformity'

const DealRiskConformityCard = ({ pageData }) => {
  const { deal, isAllowedDealRiskStrategyUpdate } = pageData
  const { dealUuid } = deal
  const businessSegmentCode = deal?.businessSegmentCode
  const isAllowedToUpdateDealRiskStrategy = isAllowedDealRiskStrategyUpdate

  const headerRef = useRef(document.body)
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.risk-conformity' })
  const showErrorMessageBox = useShowErrorMessageBox()
  const {
    data: { lastUpdatedBy, lastUpdatedAt, ...riskConformity } = {},
    isLoading,
    isError,
  } = useRiskConformity(dealUuid)
  const [isEditMode, setIsEditMode] = useState(false)
  const [formStateInitialized, setFormStateInitialized] = useState(false)
  const [formState, setFormState] = useState({
    compliance: null,
    deviationCodes: [],
    comment: null,
  })
  const [isFormValid, setIsFormValid] = useState(true)
  const [deprecatedDeviations, setDeprecatedDeviations] = useState([])
  const queryClient = useQueryClient()

  const showDeprecationErrorMessage = useDeprecatedDeviationsErrorMessage(deprecatedDeviations)
  const { show: showCancelPopover } = useCancelPopover({
    onConfirmCancel: () => {
      setFormState(riskConformity)
      setIsEditMode(false)
    },
    opener: `${HEADER_ID_PREFIX}-editModeCancelButton`,
    container: headerRef.current,
  })

  useEffect(() => {
    if (isLoading || isError || formStateInitialized) return
    setFormState(riskConformity)
    setFormStateInitialized(true)
  }, [formStateInitialized, isError, isLoading, riskConformity])

  useEffect(() => {
    if (formState.compliance) {
      setIsFormValid(true)
      setDeprecatedDeviations([])
      return
    }
    setIsFormValid(formState.deviationCodes?.length > 0 && formState.comment)
  }, [formState, setIsFormValid, setDeprecatedDeviations])

  const updateFormState = (newState) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  const onUpdateSuccess = async () => {
    await queryClient.invalidateQueries(['deals', dealUuid, 'risk-conformity'])
    setIsEditMode(false)
  }

  const onUpdateError = async (error) => {
    const { errors: [errorResponse] = [] } = await error.response.json()
    showErrorMessageBox({ message: t('update-error'), error: errorResponse })
  }

  const updateRiskConformity = useUpdateRiskConformity({
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  })

  const onSaveChanges = async () => {
    if (deprecatedDeviations.length) {
      showDeprecationErrorMessage()
      return
    }
    await updateRiskConformity.mutateAsync({ dealUuid, body: formState })
  }

  const onCancelChanges = () => {
    if (!isEqual(formState, riskConformity)) {
      showCancelPopover()
      return
    }
    setIsEditMode(false)
  }

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('title')}
          email={lastUpdatedBy}
          timestamp={lastUpdatedAt}
          actions={
            !isLoading &&
            !isError &&
            isAllowedToUpdateDealRiskStrategy && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={onSaveChanges}
                onCancel={onCancelChanges}
                buttonIdPrefix={HEADER_ID_PREFIX}
                checkSaveDisabled={() => !isFormValid}
              />
            )
          }
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        renderContent={() =>
          isEditMode ? (
            <DealRiskConformityEdit
              formState={formState}
              businessSegmentCode={businessSegmentCode}
              updateFormState={updateFormState}
              updateDeprecatedDeviations={setDeprecatedDeviations}
            />
          ) : (
            <DealRiskConformityDisplay
              riskConformity={riskConformity}
              businessSegmentCode={businessSegmentCode}
            />
          )
        }
      />
    </Card>
  )
}

DealRiskConformityCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string.isRequired,
      businessSegmentCode: PropTypes.string,
    }).isRequired,
    isAllowedDealRiskStrategyUpdate: PropTypes.bool.isRequired,
  }).isRequired,
}

export default DealRiskConformityCard
