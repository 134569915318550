import { Link, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import ConfirmationDialog from 'components/ui/dialog/ConfirmationDialog'
import { MessageBoxActions, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import useFreezeDecisionPaperFlow from 'hooks/services/business-events-and-tasks/decision-papers/routes/useFreezeDecisionPaperFlow'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionPaperFreezeVersionDialog = ({
  isOpen,
  setIsOpen,
  setIsPopoverOpen,
  setIsFreezeLoading,
  synchronizationStatus,
  isMinorVersion,
}) => {
  const keyPrefix = 'pages.business-events-and-tasks.decision-paper'
  const { t } = useTranslation('translation', {
    keyPrefix,
  })

  const showMessageBox = useShowMessageBox()
  const { id: eventId } = useContext(BusinessEventsAndTasksContext).event
  const isSyncOn = synchronizationStatus === decisionPaperSynchronizationStatus.live

  const handleClickOnDocumentLink = (id) => {
    window.open(`/documents/${id}`, '_blank')
  }

  const onFreezeSuccess = useCallback(
    (decisionPaper) => {
      const { currentVersion, availableVersions } = decisionPaper
      const currentArchivedDocumentId = find(availableVersions, {
        version: currentVersion,
      })?.archivedDocumentId
      setIsFreezeLoading(false)
      showMessageBox({
        type: MessageBoxTypes.Success,
        children: (
          <Trans
            i18nKey={`${keyPrefix}.freeze.success`}
            components={[
              <Link
                target="_blank"
                key="archived-document-link"
                rel="noopener noreferrer"
                onClick={() => handleClickOnDocumentLink(currentArchivedDocumentId)}
              />,
            ]}
          />
        ),
        actions: [MessageBoxActions.OK],
      })
    },
    [setIsFreezeLoading, showMessageBox],
  )

  const onFreezeError = useCallback(() => {
    setIsFreezeLoading(false)
    showMessageBox({
      type: MessageBoxTypes.Error,
      children: t('freeze.error'),
      actions: [MessageBoxActions.Close],
      emphasizedAction: MessageBoxActions.Close,
    })
  }, [setIsFreezeLoading, showMessageBox, t])

  const { handleFreezeClicked } = useFreezeDecisionPaperFlow({
    eventId,
    onFreezeSuccess,
    onFreezeError,
    isSyncOn,
    isMinorVersion,
  })

  const handleConfirmation = useCallback(() => {
    setIsFreezeLoading(true)
    handleFreezeClicked()
  }, [handleFreezeClicked, setIsFreezeLoading])

  const handleConfirmationCancel = useCallback(() => {
    setIsPopoverOpen(false)
    setIsOpen(false)
  }, [setIsOpen, setIsPopoverOpen])

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleConfirmation={handleConfirmation}
        handleCancel={handleConfirmationCancel}
        confirmationMessage={t('confirmation-message.description')}
        confirmationTitle={t('confirmation-message.title')}
        confirmButtonText={t('buttons.confirm-freeze')}
        draggable
        resizable
      />
    </>
  )
}

DecisionPaperFreezeVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
  setIsFreezeLoading: PropTypes.func.isRequired,
  synchronizationStatus: PropTypes.string.isRequired,
  isMinorVersion: PropTypes.bool.isRequired,
}

export default DecisionPaperFreezeVersionDialog
