import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import { useLimitManagementConfigurations } from 'hooks/services/deals/limit-management/useLimitManagementConfigurations'
import useDealProperties from 'hooks/services/deals/properties/useDealProperties'
import useDeal from 'hooks/services/deals/useDeal'
import usePropertyUuids from 'hooks/services/properties/usePropertyUuids'
import { getItemsWithKey } from 'utils/getItemsWithKey'

export const NEW_BUSINESS_DEALS_RANK = 100
export const SUPPORTED_BUSINESS_SEGMENT_CODE = 'REF'

export const useCheckCreditLimitReservationSupportStatus = (dealUuid) => {
  const {
    isLoading: isLoadingDeal,
    isError: isErrorDeal,
    data: { businessSegmentCode, dealRank } = {},
  } = useDeal(dealUuid)

  const {
    isLoading: isLoadingDealProperties,
    isError: isErrorDealProperties,
    data: { dealProperties = [] } = {},
  } = useDealProperties({ dealUuid })

  const propertyUuids = getItemsWithKey(dealProperties, 'propertyUuid')

  const {
    isLoading: isLoadingPropertyInfo,
    isFetching: isFetchingPropertyInfo,
    isError: isErrorPropertyInfo,
    data: { data: { properties = [] } = {} } = {},
  } = usePropertyUuids(propertyUuids, { enabled: !isEmpty(propertyUuids) }, true)

  const {
    isLoading: isLoadingConfig,
    isError: isErrorConfig,
    data: { propertyTypeMappings = [] } = {},
  } = useLimitManagementConfigurations()

  if (
    businessSegmentCode !== SUPPORTED_BUSINESS_SEGMENT_CODE ||
    dealRank > NEW_BUSINESS_DEALS_RANK
  ) {
    return { isLimitCheckSupported: false, isLoading: isLoadingDeal, isError: isErrorDeal }
  }

  const isPropertiesLoading =
    isLoadingDeal ||
    isLoadingDealProperties ||
    (isLoadingPropertyInfo && isFetchingPropertyInfo) ||
    isLoadingConfig
  const isPropertiesError =
    isErrorDeal || isErrorDealProperties || isErrorPropertyInfo || isErrorConfig

  if (isEmpty(properties)) {
    return {
      isLimitCheckSupported: false,
      isLoading: isPropertiesLoading,
      isError: isPropertiesError,
    }
  }

  const dealPropertiesType = uniq(getItemsWithKey(properties, 'typeCode'))
  const propertiesCountry = getItemsWithKey(properties, 'address', 'countryCode')

  const getAllExternalPropertyTypes = (propertiesType, mappings) => {
    const filteredPropertyTypes = mappings.filter((type) => type.deprecated === false)

    const mappingPropertiesType = (propertyType) =>
      filteredPropertyTypes.find((type) => type.cwpPropertyTypeCode === propertyType)
        ?.externalPropertyTypeCode

    return propertiesType.map((propertyType) => mappingPropertiesType(propertyType)).filter(Boolean)
  }

  const isUniqPropertyCountry = uniq(propertiesCountry).length === 1
  const isUniqPropertyType =
    uniq(getAllExternalPropertyTypes(dealPropertiesType, propertyTypeMappings)).length === 1

  return {
    isLimitCheckSupported: isUniqPropertyType && isUniqPropertyCountry,
    isLoading: isPropertiesLoading,
    isError: isPropertiesError,
  }
}
