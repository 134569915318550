import { DateTime } from 'luxon'

const getIsoDate = (originalDateValue, localePattern) => {
  if (originalDateValue === 0 || originalDateValue === '0') return undefined
  if (typeof originalDateValue === 'number') {
    const excelUnixEpochDifference = 25569
    const secondsFactor = 86400
    return DateTime.fromSeconds(
      (originalDateValue - excelUnixEpochDifference) * secondsFactor,
    ).toISODate()
  }
  /**
   * Bring the date value from the excel AND the localePattern into a unified pattern
   * Reason: We only care for the order of the date pattern, not the separator
   * Example: Excel Value is 01.02.2024, localePattern is dd/MM/yyy --> the excel value should be resolved
   * to the 01. Feburary 2024
   * Additionally handle dates without days correctly --> 2024/02 or 02/2024 should be resolved to 2024-02-01
   */
  const unifiedDateString = String(originalDateValue).replaceAll('-', '/').replaceAll('.', '/')
  const unifiedLocalePattern = String(localePattern).replaceAll('-', '/').replaceAll('.', '/')
  const isoPatternWithoutDay = 'yyyy/MM'
  const reversedIsoPatternWithoutDay = 'MM/yyyy'
  return (
    DateTime.fromFormat(unifiedDateString, unifiedLocalePattern).toISODate() ||
    DateTime.fromFormat(unifiedDateString, isoPatternWithoutDay).toISODate() ||
    DateTime.fromFormat(unifiedDateString, reversedIsoPatternWithoutDay).toISODate() ||
    originalDateValue
  )
}

export default getIsoDate
