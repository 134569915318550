import {
  Button,
  Modals,
  PopoverPlacementType,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DealBorrowerUnitsEditRow from 'components/domains/deals/borrower-units/DealBorrowerUnitsEditRow'
import styles from 'components/domains/deals/borrower-units/DealBorrowerUnitsTable.module.css'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import TableRowActionButtons from 'components/domains/deals/tables/TableRowActionButtons'
import Entity from 'components/ui/data/Entity'
import useBusinessPartnerRelationshipDelete from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationshipDelete'
import paths from 'routes/paths'

const dealBorrowerUnitsTableProps = {
  borrowerBpId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isRelatedBusinessPartnerBp2: PropTypes.bool,
      relationshipTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          modifiable: PropTypes.bool,
        }),
      ),
      heads: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          fullName: PropTypes.string,
          relationshipType: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          isDeletable: PropTypes.bool,
        }),
      ),
    }),
  ),
  isEditMode: PropTypes.bool,
  onCloseEditMode: PropTypes.func,
  isDeleteEnabled: PropTypes.bool,
}

const deleteUnitButtonIdPrefix = 'delete-unit-button'

const DealBorrowerUnitsTable = ({
  borrowerBpId,
  units,
  isEditMode = false,
  onCloseEditMode = (_) => {},
  isDeleteEnabled = false,
}) => {
  const { t } = useTranslation()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const showPopover = Modals.useShowPopover()
  const queryClient = useQueryClient()

  const { mutate: deleteRelationship } = useBusinessPartnerRelationshipDelete({
    onSuccess: () => {
      showToast({ children: t('components.deals.borrower-units.delete.success') }, document.body)
      queryClient.invalidateQueries(['businesspartners', borrowerBpId, 'relationships'])
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({
        message: t('components.deals.borrower-units.delete.error'),
        error: errorResponse,
      })
    },
  })

  const deleteUnit = (unit, head) => {
    const payload = {
      relatedBusinessPartnerId: head.id,
      relationshipId: unit.id,
      relationshipType: head.relationshipType.id,
      isRelatedBusinessPartnerBp2: unit.isRelatedBusinessPartnerBp2,
    }

    const { close } = showPopover({
      opener: `${deleteUnitButtonIdPrefix}-${unit.id}-${head.id}-delete-button`,
      placementType: PopoverPlacementType.Top,
      children: (
        <div className={styles.popoverContent}>
          <Text wrapping>{t('components.deals.borrower-units.delete.confirm')}</Text>
          <Button
            onClick={() => {
              deleteRelationship({ businessPartnerId: borrowerBpId, ...payload })
              close()
            }}
          >
            {t('buttons.remove')}
          </Button>
        </div>
      ),
    })
  }

  return (
    <Table
      className={styles.table}
      columns={[
        <TableColumn key="unit">{t('components.deals.borrower-units.unit')}</TableColumn>,
        <TableColumn key="unitHead">{t('components.deals.borrower-units.unit-head')}</TableColumn>,
        <TableColumn key="relationship">
          {t('components.deals.borrower-units.relationship')}
        </TableColumn>,
        <TableColumn key="delete" />,
      ]}
      noDataText={t('components.table.no-data')}
    >
      {isEditMode && (
        <DealBorrowerUnitsEditRow
          units={units}
          onClose={onCloseEditMode}
          borrowerBpId={borrowerBpId}
        />
      )}
      {units.map((unit) =>
        unit.heads.map((head) => (
          <TableRow key={`${unit.id}-${head.id}`}>
            <TableCell className={styles.unitCol}>{unit.name}</TableCell>
            <TableCell className={styles.unitHeadCol}>
              <Entity
                id={head.id}
                name={head.fullName}
                link={`/${paths.businessPartners}/${head.id}`}
                openInNewTab
              />
            </TableCell>
            <TableCell>{head.relationshipType.name}</TableCell>
            <TableCell>
              <TableRowActionButtons
                id={`${deleteUnitButtonIdPrefix}-${unit.id}-${head.id}`}
                isDeletable={isDeleteEnabled && head.isDeletable}
                onDelete={() => deleteUnit(unit, head)}
              />
            </TableCell>
          </TableRow>
        )),
      )}
    </Table>
  )
}

DealBorrowerUnitsTable.propTypes = dealBorrowerUnitsTableProps

export default DealBorrowerUnitsTable
