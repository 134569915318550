import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

const useUpdateAccounting = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, trancheId, ...body }) => {
      body.partToBeSyndicated = convertPercentageToDecimal(body.partToBeSyndicated)

      await put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/accounting`,
        body: snakecaseKeys(body),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateAccounting
