import { Button, ButtonDesign, CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/borrower-units/DealBorrowerUnitsCard.module.css'
import DealBorrowerUnitsMessageStripes from 'components/domains/deals/borrower-units/DealBorrowerUnitsMessageStripes'
import DealBorrowerUnitsTable from 'components/domains/deals/borrower-units/DealBorrowerUnitsTable'
import useBorrowerUnitsLoader from 'components/domains/deals/borrower-units/useBorrowerUnitsLoader'
import useBorrowerUnitsReadOnlyForDealAdjustments from 'components/domains/deals/borrower-units/useBorrowerUnitsReadOnlyForDealAdjustment'
import Card from 'components/ui/card/Card'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'

const AllowedOperations = {
  RELATIONSHIP_CREATE: 'UNIT_RELATIONSHIP_CREATE',
  RELATIONSHIP_DELETE: 'UNIT_RELATIONSHIP_DELETE',
}

const DealBorrowerUnitsCard = ({ pageData }) => {
  const {
    deal: { borrowerBpId },
  } = pageData

  const { t } = useTranslation('translation')
  const {
    isLoading,
    isError,
    data: { units = [], unitsCount, allowedOperations = [] } = {},
  } = useBorrowerUnitsLoader(borrowerBpId)

  const [isEditMode, setIsEditMode] = useState(false)

  const { isReadOnlyForDealAdjustment, isWorkingVersion } =
    useBorrowerUnitsReadOnlyForDealAdjustments(borrowerBpId)
  const isDeleteAllowed =
    allowedOperations.includes(AllowedOperations.RELATIONSHIP_DELETE) &&
    !isReadOnlyForDealAdjustment
  const isAddAllowed = units.some((unit) => unit.isAddable) && !isReadOnlyForDealAdjustment

  const headerAction = useMemo(() => {
    if (
      isLoading ||
      isError ||
      !isAddAllowed ||
      !allowedOperations.includes(AllowedOperations.RELATIONSHIP_CREATE)
    ) {
      return <></>
    }
    return (
      <Button
        design={ButtonDesign.Transparent}
        onClick={() => setIsEditMode(true)}
        disabled={isEditMode}
      >
        {t('buttons.add')}
      </Button>
    )
  }, [allowedOperations, isEditMode, isError, isLoading, t, units])

  return (
    <Card
      header={
        <CardHeader
          titleText={t('components.deals.borrower-units.title', { count: unitsCount })}
          action={headerAction}
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading}
        isError={isError}
        renderContent={() => (
          <>
            <DealBorrowerUnitsMessageStripes
              units={units}
              isReadOnlyForDealAdjustment={isReadOnlyForDealAdjustment}
              isWorkingVersion={isWorkingVersion}
            />
            <div className={styles.container}>
              <DealBorrowerUnitsTable
                borrowerBpId={borrowerBpId}
                units={units}
                isEditMode={isEditMode}
                onCloseEditMode={() => setIsEditMode(false)}
                isDeleteEnabled={isDeleteAllowed}
              />
            </div>
          </>
        )}
      />
    </Card>
  )
}

DealBorrowerUnitsCard.propTypes = {
  pageData: PropTypes.object.isRequired,
}

export default DealBorrowerUnitsCard
