import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateDealUse = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, dealUse }) =>
      await put({
        path: `/deals/${dealUuid}/uses/${dealUse.useUuid}`,
        body: snakecaseKeys(dealUse),
      }),
    { onSuccess, onError },
  )
}

export default useUpdateDealUse
