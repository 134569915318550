import { DatePicker } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import styles from 'components/ui/card/EditCardView.module.css'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useDerivativeLineMaturityDateFields = ({
  derivativeLineMaturity,
  setDerivativeLineMaturityForRisksCalculation,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.interest-rate-hedging-requirement-card',
  })
  const { format: formatDate, parse: parseDate, localePattern } = useShortDateFormatter()

  const renderDerivativeMaturityDatePicker = ({ handleOnChange, item, fieldValues, isInvalid }) => {
    const datePickerComponent = (
      <DatePicker
        value={fieldValues[item.name]}
        data-testid="derivativeLineMaturityDate-datePicker"
        onChange={(event) => {
          handleOnChange(
            item.name,
            event.detail.valid ? event.detail.value : '',
            false,
            item.isMandatory,
          )
          setDerivativeLineMaturityForRisksCalculation(parseDate(event.detail.value, localePattern))
        }}
        formatPattern={localePattern}
        className={styles.datePicker}
        valueState={isInvalid}
      />
    )
    return <EditCardItem key={item.label} editComponent={datePickerComponent} label={item.label} />
  }

  return [
    {
      name: 'derivativeLineMaturity',
      label: t('derivative-line-maturity-date'),
      value: formatDate(derivativeLineMaturity),
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'derivativeLineMaturity',
      label: t('derivative-line-maturity-date'),
      value: formatDate(derivativeLineMaturity),
      renderCustomEditComponent: renderDerivativeMaturityDatePicker,
      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useDerivativeLineMaturityDateFields
