import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

export const SelectAndUnselectAllTableCellEdit = ({ isSelected, onSelectChangeAll }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
    <CheckBox checked={isSelected} onChange={onSelectChangeAll} />
  </FlexBox>
)

SelectAndUnselectAllTableCellEdit.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelectChangeAll: PropTypes.func.isRequired,
}
