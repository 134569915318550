import { Input } from '@fioneer/ui5-webcomponents-react'
import { useTranslation } from 'react-i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useOriginationTeams } from 'hooks/services/deals/configurations/useOriginationTeams'

const useOriginationTeamFields = ({ originationTeamCode, businessSegmentCode }, dataFormatter) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })

  const {
    isFetching,
    isError,
    data = [],
  } = useOriginationTeams(businessSegmentCode, {
    enabled: !!originationTeamCode && !!businessSegmentCode,
  })

  const getOriginationTeam = (code) => data.find((originationTeam) => originationTeam.code === code)

  const originationTeam = getOriginationTeam(originationTeamCode)

  const options = [{ key: '', display_name: '' }].concat(
    data.map(({ code, name }) => ({
      key: code,
      display_name: name,
    })),
  )

  const getOriginationPlatformDisplayName = (team) => {
    if (team === undefined) {
      return ''
    }
    return team.platform
  }

  const renderOriginationPlatformEditField = ({ item, fieldValues }) => (
    <EditCardItem
      key={item.label}
      isMandatory={item.isMandatory}
      label={item.label}
      editComponent={
        <Input
          readonly={true}
          value={getOriginationPlatformDisplayName(getOriginationTeam(fieldValues[item.name]))}
          className="edit-input-field"
          valueStateMessage={
            <span>
              {t('components.ui.card.display-edit-card.input-error-message', {
                label: item.label,
              })}
            </span>
          }
        />
      }
    />
  )

  const originationTeamField = {
    label: t('origination-team'),
    name: 'originationTeamCode',
    value: originationTeamCode,
    isMandatory: true,
    formattedValue: dataFormatter(isError, originationTeam?.name),
    editComponentType: editComponentTypes.Select,
    editComponentSelectOptions: options,
    isShownInEdit: true,
    isShownInDisplay: true,
  }

  const originationPlatformField = {
    label: t('origination-platform'),
    name: 'originationTeamCode',
    value: originationTeamCode,
    renderCustomEditComponent: renderOriginationPlatformEditField,
    formattedValue: dataFormatter(isError, originationTeam?.platform),
    isShownInEdit: true,
    isShownInDisplay: true,
  }

  return {
    isLoading: isFetching,
    isError,
    data: { originationTeamField, originationPlatformField },
  }
}

export default useOriginationTeamFields
