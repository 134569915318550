import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useRowsTableResetEditState = ({ rows, setRowsInEdit, resetInvalidEditState }) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    setRowsInEdit((_) => [...rows.map((row) => ({ ...row }))])
    dispatch(resetInvalidEditState())
  }, [dispatch, resetInvalidEditState, rows, setRowsInEdit])
}
