import { useTranslation } from 'react-i18next'
import { useInterestRateHedgeTypeCodes } from 'hooks/services/deals/financing/configGetters/useInterestRateHedgeTypeCodes'

const useInterestRateHedgeTypeField = ({
  interestRateHedgeTypeCode,
  interestRateHedgeTypeShortText,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.interest-rate-hedging-requirement-card',
  })

  return [
    {
      label: t('interest-rate-hedge-type'),
      value: interestRateHedgeTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'interestRateHedgeTypeCode',
      label: t('interest-rate-hedge-type'),
      value: interestRateHedgeTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useInterestRateHedgeTypeCodes,
        selectionName: 'typeCodes',
        'data-testid': 'interestRateHedgeType-loadingSelect',
      },
    },
  ]
}

export default useInterestRateHedgeTypeField
