import { useMemo } from 'react'
import { useRequest } from 'hooks/services/baseService'

/**
 @returns {*|(*&{data: *|[]})}
 */
const useCustomEntities = ({ dealUuid, entityType, linkedEntityId }, options) => {
  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams()

    if (entityType) urlSearchParams.append('entity-type', entityType)
    if (linkedEntityId) urlSearchParams.append('linked-entity-id', linkedEntityId)

    return urlSearchParams
  }, [entityType, linkedEntityId])

  return useRequest({
    path: `/deals/${dealUuid}/custom-entities?${queryParams}`,
    useCache: true,
    translated: true,
    keys: ['deals', dealUuid, 'custom-entities', entityType, linkedEntityId],
    options: {
      enabled: !!dealUuid && !!entityType && !!linkedEntityId,
      ...options,
    },
  })
}

export default useCustomEntities
