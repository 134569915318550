import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import sortBy from 'lodash.sortby'
import { useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import { useCreateAmortizationCondition } from 'hooks/services/deals/financing/amortization/useCreateAmortizationCondition'
import { useDeleteAmortizationConditions } from 'hooks/services/deals/financing/amortization/useDeleteAmortizationConditions'
import useTrancheAmortizationConditions from 'hooks/services/deals/financing/amortization/useTrancheAmortizationConditions'
import { useUpdateAmortizationCondition } from 'hooks/services/deals/financing/amortization/useUpdateAmortizationCondition'
import { useUpdateAmortizationsComment } from 'hooks/services/deals/financing/amortization/useUpdateAmortizationsComment'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import {
  addInvalidEditState,
  removeIdsFromInvalidEditStates,
  removeInvalidEditState,
  resetInvalidEditState,
} from 'redux/slices/deals/financing/tranches/amortizations/amortizationsTableSlice'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import useAmortizationColumnDefinitions from 'routes/deals/financing/trancheDetails/cards/amortization-card/useAmortizationColumnDefinitions'
import MultiSelectCard from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCard'
import { convertDecimalToPercentage } from 'routes/deals/financing/utils/convertPercentages'

const AmortizationCard = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const queryClient = useQueryClient()

  const { trancheDisplayId } = useParams()
  const {
    data: resolvedTrancheData,
    isLoading: isLoadingDisplayNameResolution,
    isError: isErrorDisplayNameResolution,
    isFetching: isFetchingDisplayNameResolution,
  } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const dealId = deal.dealUuid
  const trancheId = resolvedTrancheData.trancheId

  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const mapTrancheAmortizationConditionsData = (items) =>
    sortBy(
      items.map((element) => ({
        ...element,
        ratePerAnnum: convertDecimalToPercentage(element.ratePerAnnum),
        amountPerInterval: element.amountPerInterval?.amount,
        amountPerIntervalCurrency: element.amountPerInterval?.currency,
        amountPerIntervalHeadquarter: element.amountPerIntervalHeadquarter?.amount,
        amountPerIntervalHeadquarterCurrency: element.amountPerIntervalHeadquarter?.currency,
      })),
      'validFrom',
    )

  const {
    data: trancheAmortizationConditionsData,
    isLoading: isLoadingTrancheAmortizationConditions,
    isError: isErrorTrancheAmortizationConditions,
    isFetching: isFetchingTrancheAmortizationConditions,
  } = useTrancheAmortizationConditions(
    dealId,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const { lastEditedText } = useLastEditedTextByEmail({
    email: trancheAmortizationConditionsData?.lastUpdated?.name,
    timestamp: trancheAmortizationConditionsData?.lastUpdated?.lastUpdatedOn,
  })

  const { mutateAsync: createAmortizationCondition } = useCreateAmortizationCondition(
    {},
    { dealId, trancheId },
  )
  const { mutateAsync: updateAmortizationCondition } = useUpdateAmortizationCondition(
    {},
    { dealId, trancheId },
  )
  const { mutateAsync: deleteAmortizationConditions } = useDeleteAmortizationConditions(
    {},
    { dealId, trancheId },
  )

  const { mutateAsync: updateAmortizationsComment } = useUpdateAmortizationsComment(
    {},
    { dealId, trancheId },
  )

  const refreshAmortizationConditions = async () => {
    await queryClient.invalidateQueries(['deals', dealId, 'tranches', trancheId, 'amortizations'])
  }

  const useColumnDefinitions = (props) =>
    useAmortizationColumnDefinitions({
      ...props,
      trancheCurrency: resolvedTrancheData?.totalCommitment?.currency,
    })

  return (
    <MultiSelectCard
      mainTitle={t('pages.deals.tranches.details.amortization-card.main-title')}
      mainSubtitle={lastEditedText}
      tableTitle={t('pages.deals.tranches.details.amortization-card.table-title')}
      noDataText={t('components.table.no-data')}
      columnDefinitions={useColumnDefinitions}
      comment={trancheAmortizationConditionsData?.comment ?? '<p></p>'}
      updateComment={updateAmortizationsComment}
      dataRows={
        trancheAmortizationConditionsData?.items
          ? mapTrancheAmortizationConditionsData(trancheAmortizationConditionsData.items)
          : []
      }
      userIsAllowedToEdit={
        !isExistingBusinessViewSet &&
        allowedOperations.includes(FINANCING_TRANCHE_AMORTIZATION_CONDITIONS_UPDATE)
      }
      isLoading={isLoadingDisplayNameResolution || isLoadingTrancheAmortizationConditions}
      isFetching={isFetchingDisplayNameResolution || isFetchingTrancheAmortizationConditions}
      isError={isErrorDisplayNameResolution || isErrorTrancheAmortizationConditions}
      reduxTableSliceActions={{
        addInvalidEditState,
        removeInvalidEditState,
        removeIdsFromInvalidEditStates,
        resetInvalidEditState,
      }}
      reduxStatePath="deals.amortizationsTable"
      createDataEntry={createAmortizationCondition}
      updateDataEntry={updateAmortizationCondition}
      deleteDataEntries={deleteAmortizationConditions}
      refreshData={refreshAmortizationConditions}
    />
  )
}

export default AmortizationCard
