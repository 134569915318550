import { useTranslation } from 'react-i18next'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useDealTypes } from 'hooks/services/deals/configurations/useBusinessSegments'

const useDealTypeField = ({ dealTypeCode, businessSegmentCode }, dataFormatter) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })

  const { isLoading, isError, data = [] } = useDealTypes(businessSegmentCode)
  const dealType = data.find(({ code }) => code === dealTypeCode)

  const fieldDefinition = {
    label: t('deal-type'),
    name: 'dealTypeCode',
    value: dealTypeCode,
    isMandatory: true,
    formattedValue: dataFormatter(isError, dealType?.name),
    editComponentType: editComponentTypes.LoadingSelect,
    editComponentProps: {
      loadingHook: useDealTypes,
      loadingHookParams: businessSegmentCode,
      optionKeyName: 'code',
      optionDisplayName: 'name',
    },
    isShownInEdit: true,
    isShownInDisplay: true,
  }

  return { isLoading, isError, data: fieldDefinition }
}

export default useDealTypeField
