import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BusinessPartnerLink from 'components/domains/business-events-and-tasks/BusinessPartnerLink'
import DateCell from 'components/ui/tables/cells/DateCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import useCreateCell from 'components/ui/tables/cells/useCreateCell'
import { ROW_TYPE_CONDITION } from 'routes/deals/financing/non-loan-products/bonds/constants'

const columnKeyPrefix = 'pages.deals.bonds-table.columns'

const getDefaultColumnDefinition = (columnKey, tColumns) => ({
  Header: () => <Trans i18nKey={`${columnKeyPrefix}.${columnKey}`} />,
  title: tColumns(columnKey).replace('<br/>', ' '),
  accessor: columnKey,
})

const renderBusinessPartnerCell = (businessPartnerId) =>
  businessPartnerId ? (
    <BusinessPartnerLink
      businessPartnerId={businessPartnerId}
      CustomComponent={EntityCell}
      customComponentProperties={{
        options: {
          openInNewTab: false,
        },
      }}
    />
  ) : (
    <></>
  )

const useBondsTableColumnDefinitions = () => {
  const { t: tColumns } = useTranslation('translation', {
    keyPrefix: columnKeyPrefix,
  })
  const allColumns = useMemo(
    () => ({
      bondCondition: {
        ...getDefaultColumnDefinition('bondCondition', tColumns),
        minWidth: 300,
        Cell: ({ cell, row }) =>
          useCreateCell('entity', {
            id: cell.value,
            options: {
              overflowMarginTreeLevel: row.original.rowType === ROW_TYPE_CONDITION ? 2 : 1,
            },
          })(),
      },
      productType: {
        ...getDefaultColumnDefinition('productType', tColumns),
        width: 200,
        Cell: ({ cell }) => cell.value,
      },
      productSubType: {
        ...getDefaultColumnDefinition('productSubType', tColumns),
        width: 200,
        Cell: ({ cell }) => cell.value,
      },
      limitSystemProductClass: {
        ...getDefaultColumnDefinition('limitSystemProductClass', tColumns),
        width: 120,
        isVisible: false,
        Cell: ({ cell }) => cell.value,
      },
      issuerCounterparty: {
        ...getDefaultColumnDefinition('issuerCounterparty', tColumns),
        minWidth: 250,
        Cell: ({ cell }) => {
          const businessPartnerId = cell.value
          return renderBusinessPartnerCell(businessPartnerId)
        },
      },
      mtm: {
        ...getDefaultColumnDefinition('mtm', tColumns),
        width: 180,
        hAlign: TextAlign.End,
        Cell: ({ cell }) => {
          const { original, headquarter } = cell.value ?? {}
          return useCreateCell('money', {
            primary: original,
            secondary: headquarter,
            options: { maximumFractionDigits: 3, minimumFractionDigits: 3 },
          })()
        },
      },
      amortization: {
        ...getDefaultColumnDefinition('amortization', tColumns),
        hAlign: TextAlign.Start,
        width: 180,
        Cell: ({ cell }) => cell.value,
      },
      startDate: {
        ...getDefaultColumnDefinition('startDate', tColumns),
        width: 120,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell.value }),
      },
      maturityDate: {
        ...getDefaultColumnDefinition('maturityDate', tColumns),
        width: 120,
        hAlign: TextAlign.Right,
        Cell: ({ cell }) => DateCell({ date: cell.value }),
      },
      buyOrSell: {
        ...getDefaultColumnDefinition('buyOrSell', tColumns),
        width: 120,
        Cell: ({ cell }) => cell.value,
      },
      initialNotional: {
        ...getDefaultColumnDefinition('initialNotional', tColumns),
        width: 180,
        hAlign: TextAlign.End,
        Cell: ({ cell }) => {
          const { original, headquarter } = cell.value ?? {}
          return useCreateCell('money', {
            primary: original,
            secondary: headquarter,
            options: { maximumFractionDigits: 3, minimumFractionDigits: 3 },
          })()
        },
      },
      currentNotional: {
        ...getDefaultColumnDefinition('currentNotional', tColumns),
        width: 180,
        hAlign: TextAlign.End,
        Cell: ({ cell }) => {
          const { original, headquarter } = cell.value ?? {}
          return useCreateCell('money', {
            primary: original,
            secondary: headquarter,
            options: { maximumFractionDigits: 3, minimumFractionDigits: 3 },
          })()
        },
      },
      rateType: {
        ...getDefaultColumnDefinition('rateType', tColumns),
        width: 100,
        Cell: ({ cell }) => cell.value,
      },
      rateIndexName: {
        ...getDefaultColumnDefinition('rateIndexName', tColumns),
        width: 100,
        Cell: ({ cell }) => cell.value,
      },
      tenor: {
        ...getDefaultColumnDefinition('tenor', tColumns),
        width: 100,
        Cell: ({ cell }) => cell.value,
      },
      currentCouponRate: {
        ...getDefaultColumnDefinition('currentCouponRate', tColumns),
        width: 160,
        hAlign: TextAlign.End,
        Cell: ({ cell }) =>
          useCreateCell('percentage', {
            value: cell.value,
            options: { maximumFractionDigits: 3, minimumFractionDigits: 3 },
          })(),
      },
    }),
    [tColumns],
  )

  const columns = useMemo(
    () =>
      Object.keys(allColumns).map((columnKey) => ({
        disableDragAndDrop: true,
        isVisible: true,
        isSelectableForHiding: true,
        disableResizing: false,
        vAlign: VerticalAlign.Middle,
        disableSortBy: true,
        ...allColumns[columnKey],
      })),
    [allColumns],
  )

  return useMemo(() => ({ columns }), [columns])
}

export default useBondsTableColumnDefinitions
