import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Text,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/standard-cells/StandardCellView.module.css'

export const StandardCellViewPosition = {
  Start: FlexBoxAlignItems.Start,
  End: FlexBoxAlignItems.End,
}

export const StandardCellView = ({ position = StandardCellViewPosition.End, content }) => (
  <FlexBox
    direction={FlexBoxDirection.Column}
    justifyContent={FlexBoxJustifyContent.Center}
    alignItems={position}
  >
    <Text
      className={
        position === StandardCellViewPosition.End ? styles.textAlignEnd : styles.textAlignStart
      }
    >
      {content}
    </Text>
  </FlexBox>
)

StandardCellView.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string]),
  position: PropTypes.string,
}
