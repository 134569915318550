import { useContext } from 'react'
import Card from 'components/ui/card/Card'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import { NON_LOAN_PRODUCT_TABLE_TYPES } from 'routes/deals/financing/non-loan-products/constants'
import ReposTable from 'routes/deals/financing/non-loan-products/repos/ReposTable'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const ReposCard = () => {
  const { deal } = useContext(DealContext)
  const { data, isLoading } = useNonLoanProducts(deal.displayId)
  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables } = {} } = {},
  } = useConfig()
  const reposMappingValues = tradeMappingValuesByTables?.[NON_LOAN_PRODUCT_TABLE_TYPES.REPOS]
  const filteredData = getNonLoanProductDealDataByConfig({
    tradeMappingAttribute,
    tradeMappingValues: reposMappingValues,
    data,
  })

  // only show the card if specific trade data is available
  if (!isLoading && filteredData?.trades?.length > 0) {
    return (
      <Card>
        <ReposTable />
      </Card>
    )
  }
}

export default ReposCard
