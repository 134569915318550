import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import WatcherButton from 'components/ui/watcher/WatcherButton'

const propTypes = {
  /** A unique string identifier for the button and the corresponding menu popover opener. */
  id: PropTypes.string,
  /**
   * An array of watchers (i.e., subscribers) for the currently referenced row entry.
   *
   * - `id` An identifier for the watcher entry (`id`) used to remove a watcher with `onUserUnsubscribe`.
   * - `userId` A `userId` to reference the watching user.
   * */
  watchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    }),
  ),
  /** The id of the user logged into the current session. Usually acquired using `useStaffMemberSelf`. */
  ownUserId: PropTypes.string,
  /** The function executed when adding a subscriber. This takes a `userId` as input parameter. */
  onUserSubscribe: PropTypes.func,
  /** The function executed when removing a subscriber. This takes a watcher `id` as input parameter. */
  onUserUnsubscribe: PropTypes.func,
  /** The date value to format and display. */
  date: PropTypes.string,
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Watcher Cell for usage within the Responsive and Analytical Table.
 *
 * @typedef Props
 * @property {String} id A unique string identifier for the button and the corresponding menu popover opener.
 * @property {Array.<Object>} watchers An array of watchers (i.e., subscribers) for the currently referenced row entry.
 * @property {String} ownUserId The id of the user logged into the current session. Usually acquired using `useStaffMemberSelf`.
 * @property {Function} onUserSubscribe The function executed when adding a subscriber. This takes a `userId` as input parameter.
 * @property {Function} onUserUnsubscribe The function executed when removing a subscriber. This takes a watcher `id` as input parameter.
 * @property {String} date The status value to display as the component's label.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const WatcherCell = ({
  id,
  watchers,
  ownUserId,
  onUserSubscribe,
  onUserUnsubscribe,
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const renderContent = () => {
    if (!id || !watchers || !ownUserId) {
      return <div />
    }
    return (
      <div className={className ?? ''}>
        <WatcherButton
          id={id}
          watchers={watchers}
          userId={ownUserId}
          onUserSubscribe={onUserSubscribe}
          onUserUnsubscribe={onUserUnsubscribe}
        />
      </div>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

WatcherCell.propTypes = propTypes

export default WatcherCell
