import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const getConsolidationPointsKeys = (dealUuid) => ['deals', dealUuid, 'consolidation-points']

const useConsolidationPoints = (dealUuid, options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/${dealUuid}/consolidation-points`,
      translated: true,
      keys: getConsolidationPointsKeys(dealUuid),
      options: { enabled: !!dealUuid, ...options },
    }),
  )

export default useConsolidationPoints
