import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import {
  amountPropTypes,
  AmountWithHeadquarterCurrency,
} from 'components/domains/deals/pricing/AmountWithHeadquarterCurrency'
import styles from 'components/domains/deals/pricing/DealPricingPanel.module.css'
import { usePercentageFormatter } from 'hooks/i18n/useI18n'

const dealPricingAdditionalInformationPanelPropTypes = {
  data: PropTypes.shape({
    gross_interest_margin3m_ref: PropTypes.number,
    funding_spread_tranche3m_ref: PropTypes.number,
    funding_spread_tranche_on: PropTypes.number,
    funding_spread_customer: PropTypes.number,
    ir_margin3m_ref: PropTypes.number,
    ir_margin_on: PropTypes.number,
    transfer_margin: PropTypes.number,
    loss_given_default_margin_of_conservatism: PropTypes.number,
    manual_direct_deal_cost: amountPropTypes,
    manual_direct_deal_cost_headquarter: amountPropTypes,
  }),
}

export const DealPricingAdditionalInformationPanel = ({
  data: {
    gross_interest_margin3m_ref,
    funding_spread_tranche3m_ref,
    funding_spread_tranche_on,
    funding_spread_customer,
    ir_margin3m_ref,
    ir_margin_on,
    transfer_margin,
    loss_given_default_margin_of_conservatism,
    manual_direct_deal_cost,
    manual_direct_deal_cost_headquarter,
  } = {},
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.pricing' })

  const formatPercentage = usePercentageFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return (
    <Panel header={<Text>{t('additional-info-panel-title')}</Text>} collapsed>
      <div className={styles.section}>
        <CardShowItem
          label={t('grossInterestMargin3mRef')}
          value={formatPercentage(gross_interest_margin3m_ref)}
        />
        <CardShowItem
          label={t('fundingSpreadTranche3mRef')}
          value={formatPercentage(funding_spread_tranche3m_ref)}
        />
        <CardShowItem
          label={t('fundingSpreadTrancheOn')}
          value={formatPercentage(funding_spread_tranche_on)}
        />
        <CardShowItem
          label={t('fundingSpreadCustomer')}
          value={formatPercentage(funding_spread_customer)}
        />
        <CardShowItem label={t('irMargin3mRef')} value={formatPercentage(ir_margin3m_ref)} />
        <CardShowItem label={t('irMarginOn')} value={formatPercentage(ir_margin_on)} />
        <CardShowItem label={t('transferMargin')} value={formatPercentage(transfer_margin)} />
        <CardShowItem
          label={t('lgdMoC')}
          value={formatPercentage(loss_given_default_margin_of_conservatism)}
        />
        <CardShowItem
          label={t('manualDirectDealCost')}
          value={
            <AmountWithHeadquarterCurrency
              amount={manual_direct_deal_cost}
              headquarterAmount={manual_direct_deal_cost_headquarter}
            />
          }
        />
      </div>
    </Panel>
  )
}

DealPricingAdditionalInformationPanel.propTypes = dealPricingAdditionalInformationPanelPropTypes
