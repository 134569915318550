import { Table, TableCell, TableColumn, TableRow, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import DealBorrowerEditRow, {
  borrowerProps,
} from 'components/domains/deals/borrower/DealBorrowerEditRow'
import styles from 'components/domains/deals/borrower/DealBorrowerTable.module.css'
import TableRowActionButtons from 'components/domains/deals/tables/TableRowActionButtons'
import Entity from 'components/ui/data/Entity'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'

const dealBorrowerDisplayAndEditTableProps = {
  dealBorrower: borrowerProps,
  trancheBorrowers: PropTypes.arrayOf(borrowerProps),
}

const DealBorrowerTable = ({ dealBorrower, trancheBorrowers }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.borrower' })
  const { allowedOperations = [] } = useContext(DealContext)
  const [isEditMode, setIsEditMode] = useState(false)
  const isAllowedUpdateDealBorrower = allowedOperations.includes(
    dealsPermissions.updateCounterParty,
  )

  const renderShowRow = (borrower, partnerFunction, editable) => (
    <TableRow key={borrower.id}>
      <TableCell>
        <Entity
          id={borrower.id}
          name={borrower.fullName}
          link={`/${paths.businessPartners}/${borrower.id}`}
          openInNewTab
        />
      </TableCell>
      <TableCell>
        <Text>{partnerFunction}</Text>
      </TableCell>
      <TableCell>
        <TableRowActionButtons isEditable={editable} onEdit={() => setIsEditMode(true)} />
      </TableCell>
    </TableRow>
  )

  return (
    <Table
      className={styles.table}
      columns={[
        <TableColumn key="businessPartner">{t('business-partner')}</TableColumn>,
        <TableColumn key="partnerFunction">{t('partner-function')}</TableColumn>,
        <TableColumn key="editDeleteButtons" />,
      ]}
    >
      {isEditMode ? (
        <DealBorrowerEditRow dealBorrower={dealBorrower} onClose={() => setIsEditMode(false)} />
      ) : (
        renderShowRow(dealBorrower, t('deal-borrower'), isAllowedUpdateDealBorrower)
      )}
      {trancheBorrowers.map((trancheBorrower) =>
        renderShowRow(trancheBorrower, t('deviating-tranche-borrower'), false),
      )}
    </Table>
  )
}

DealBorrowerTable.propTypes = dealBorrowerDisplayAndEditTableProps

export default DealBorrowerTable
