import {
  FlexBox,
  FlexBoxDirection,
  Modals,
  Text,
  ButtonDesign,
  BusyIndicatorSize,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FINANCING_TRANCHE_OPTIONS_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import LoadingButton from 'components/ui/button/LoadingButton'
import Card from 'components/ui/card/Card'
import CardHeaderWithEditMode from 'components/ui/card/CardHeaderWithEditMode'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useCustomerOptions from 'hooks/services/deals/financing/customerOptions/useCustomerOptions'
import useUpdateCustomerOptions from 'hooks/services/deals/financing/customerOptions/useUpdateCustomerOptions'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import { DealContext } from 'routes/deals/DealContext'
import styles from 'routes/deals/financing/trancheDetails/cards/customer-options-card/CustomerOptionsCard.module.css'
import CustomerOptionsTable from 'routes/deals/financing/trancheDetails/cards/customer-options-card/CustomerOptionsTable'
import mapCustomerOptionsBackendData from 'routes/deals/financing/trancheDetails/cards/customer-options-card/mappers/mapCustomerOptionsBackendData'
import mergeBackendAndEditData from 'routes/deals/financing/trancheDetails/cards/customer-options-card/mappers/mergeBackendAndEditData'
import applyChangesToAllTranches from 'routes/deals/financing/trancheDetails/cards/customer-options-card/utils/applyChangesToAllTranches'
import createUpdateCustomerOptionDTOs from 'routes/deals/financing/trancheDetails/cards/customer-options-card/utils/createUpdateCustomerOptionDTOs'

const CustomerOptionsCard = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.customerOptions-card' })

  const [isEditMode, setEditMode] = useState(false)
  const [editOptions, setEditOptions] = useState({})
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)
  const [isWritingMultipleToBackend, setIsWritingMultipleToBackend] = useState(false)

  const { trancheDisplayId } = useParams()
  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)
  const { data, isLoading, isError, isFetching } = useCustomerOptions(
    deal.dealUuid,
    resolvedTrancheData.trancheId,
  )

  const { lastEditedText } = useLastEditedTextByEmail({
    email: data?.last_updated?.name,
    timestamp: data?.last_updated?.last_updated_on,
  })

  const isAllowedToUpdateFinancingTrancheOptions =
    allowedOperations.includes(FINANCING_TRANCHE_OPTIONS_UPDATE) ?? false

  const invalidateCustomerOptionsData = useCallback(
    () =>
      queryClient.invalidateQueries([
        'deals',
        deal.dealUuid,
        'tranches',
        resolvedTrancheData.trancheId,
        'customer-options',
      ]),
    [deal.dealUuid, queryClient, resolvedTrancheData.trancheId],
  )

  const [erroneousSavingOfCustomerOptions, setErroneousSavingOfCustomerOptions] = useState([])
  const [isLastUpdateDone, setIsLastUpdateDone] = useState(false)

  const backendTableData = useMemo(
    () => mapCustomerOptionsBackendData(data, isEditMode),
    [data, isEditMode],
  )

  const mergedTableData = useMemo(
    () => mergeBackendAndEditData(backendTableData, editOptions),
    [backendTableData, editOptions],
  )

  const handleSaveCustomerOptionsFinished = useCallback(() => {
    setEditMode(false)
    setEditOptions({})
    if (!isEmpty(erroneousSavingOfCustomerOptions)) {
      showMessageBox({
        type: MessageBoxTypes.Error,
        children: (
          // TODO: Adapt to designers' guidelines
          <FlexBox direction={FlexBoxDirection.Column}>
            <Text>{t('saving.error')}</Text>
            {erroneousSavingOfCustomerOptions.map((option) => (
              <Text key={option}>{option}</Text>
            ))}
          </FlexBox>
        ),
      })
    } else {
      Modals.showToast({ children: t('saving.success') })
    }
    setErroneousSavingOfCustomerOptions([])
    invalidateCustomerOptionsData()
  }, [erroneousSavingOfCustomerOptions, invalidateCustomerOptionsData, showMessageBox, t])

  useEffect(() => {
    if (isLastUpdateDone) {
      handleSaveCustomerOptionsFinished()
      setIsLastUpdateDone(false)
      setIsWritingMultipleToBackend(false)
    }
  }, [handleSaveCustomerOptionsFinished, isLastUpdateDone])

  const { mutate: saveCustomerOption } = useUpdateCustomerOptions({
    onSuccess: (data, params) => {
      setIsWritingToBackend(false)
      if (params.isLastDTO) {
        setIsLastUpdateDone(true)
      }
    },
    onError: (error, params) => {
      setIsWritingToBackend(false)
      setErroneousSavingOfCustomerOptions((currentState) => [
        ...currentState,
        params.optionTypeCodeShortText,
      ])
      if (params.isLastDTO) {
        setIsLastUpdateDone(true)
      }
    },
  })

  const onCancelClicked = () => {
    setEditMode(false)
    setEditOptions({})
  }

  const onSave = () => {
    setIsWritingToBackend(true)
    const updateCustomerOptionDTOs = createUpdateCustomerOptionDTOs({
      backendData: data,
      editOptions,
    })

    updateCustomerOptionDTOs.forEach((dto, index) => {
      saveCustomerOption({
        dealId: deal.dealUuid,
        trancheId: resolvedTrancheData.trancheId,
        optionId: dto.optionId,
        shouldApplyToAllTranches: dto.shouldApplyToAllTranches,
        isActive: dto.isActive,
        attributes: dto.attributes,

        isLastDTO: index === updateCustomerOptionDTOs.length - 1,
        optionTypeCodeShortText: dto.optionTypeCodeShortText,
      })
    })
  }

  const isSaveButtonDisabled = useMemo(
    () =>
      Object.keys(editOptions).some((optionKey) =>
        Object.keys(editOptions[optionKey]).some(
          (attributeKey) => editOptions[optionKey][attributeKey].isValid === false,
        ),
      ) ||
      isEmpty(editOptions) ||
      isWritingMultipleToBackend,
    [editOptions, isWritingMultipleToBackend],
  )

  const additionalActions = [
    <LoadingButton
      key="customer-options-apply-to-all-tranches-button"
      data-testid="customer-options-apply-to-all-tranches-button"
      disabled={isEmpty(editOptions) || isWritingToBackend}
      design={ButtonDesign.Transparent}
      isLoading={isWritingMultipleToBackend}
      onClick={() => {
        setIsWritingMultipleToBackend(true)
        applyChangesToAllTranches({
          mergedTableData,
          saveCustomerOption,
          dealId: deal.dealUuid,
          trancheId: resolvedTrancheData.trancheId,
        })
      }}
      renderContent={() => t('button.apply-to-all-tranches')}
    />,
  ]

  return (
    <Card
      id="customerOptionsCard"
      header={
        <CardHeaderWithEditMode
          isEditMode={isEditMode}
          onToggle={() => setEditMode(true)}
          titleText={t('title')}
          subtitleText={!isLoading || !isError ? lastEditedText : ''}
          showActions={isAllowedToUpdateFinancingTrancheOptions}
          onCancel={onCancelClicked}
          onSave={onSave}
          checkSaveDisabled={() => isSaveButtonDisabled}
          additionalActions={additionalActions}
          isSaveLoading={isWritingToBackend}
        />
      }
    >
      <div className={styles.tableWrapper}>
        <LoadingStateWrapper
          isLoading={isLoading && !isFetching}
          isError={isError}
          loadingSize={BusyIndicatorSize.Medium}
          loadingClassName={styles.loadingStateMargin}
        >
          <CustomerOptionsTable
            isEditMode={isEditMode}
            editOptions={editOptions}
            setEditOptions={setEditOptions}
            backendTableData={backendTableData}
            mergedTableData={mergedTableData}
            isFetching={isFetching}
            isLoading={isLoading}
            isError={isError}
          />
        </LoadingStateWrapper>
      </div>
    </Card>
  )
}

export default CustomerOptionsCard
