import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Option from 'components/ui/input/Option'
import Select from 'components/ui/input/Select'
import { useLoanTypeCodes } from 'hooks/services/deals/financing/configGetters/useLoanTypeCodes'

const PROPERTY_KEY = 'loanTypeCode'

const LoanTypeSelect = () => {
  const { t } = useTranslation()

  const {
    data: { loanTypeCodes = [] },
    isFetching,
    isError,
  } = useLoanTypeCodes()

  const { register, formState, unregister } = useFormContext()
  const error = formState.errors[PROPERTY_KEY]

  useEffect(() => () => unregister(PROPERTY_KEY), [unregister])

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label showColon required for="tranche-loan-type-code-select">
        {t('pages.deals.tranche-create.basic-information.tranche-loan-type')}
      </Label>
      <Select
        id="tranche-loan-type-code-select"
        isFetching={isFetching}
        isFetchingError={isError}
        error={error}
        {...register(PROPERTY_KEY, { required: true })}
      >
        <Option value="" />
        {loanTypeCodes.map((loanTypeCode) => (
          <Option key={loanTypeCode.key} value={loanTypeCode.key}>
            {loanTypeCode.display_name}
          </Option>
        ))}
      </Select>
    </FlexBox>
  )
}

export default LoanTypeSelect
