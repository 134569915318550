import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import sortBy from 'lodash.sortby'
import { useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_INTEREST_CONDITIONS_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import { useCreateInterestCondition } from 'hooks/services/deals/financing/interest-conditions/useCreateInterestCondition'
import { useDeleteInterestConditions } from 'hooks/services/deals/financing/interest-conditions/useDeleteInterestConditions'
import useTrancheInterestConditions from 'hooks/services/deals/financing/interest-conditions/useTrancheInterestConditions'
import { useUpdateInterestCondition } from 'hooks/services/deals/financing/interest-conditions/useUpdateInterestCondition'
import { useUpdateInterestConditionsComment } from 'hooks/services/deals/financing/interest-conditions/useUpdateInterestConditionsComment'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import {
  addInvalidEditState,
  removeIdsFromInvalidEditStates,
  removeInvalidEditState,
  resetInvalidEditState,
} from 'redux/slices/deals/financing/tranches/interestConditions/interestConditionsTableSlice'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import useInterestConditionsColumnDefinitions from 'routes/deals/financing/trancheDetails/cards/interest-conditions/useInterestConditionsColumnDefinitions'
import MultiSelectCard from 'routes/deals/financing/trancheDetails/cards/multi-select-card/MultiSelectCard'
import { convertDecimalToPercentage } from 'routes/deals/financing/utils/convertPercentages'

const InterestConditionsCard = () => {
  const queryClient = useQueryClient()
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const { trancheDisplayId } = useParams()
  const {
    data: resolvedTrancheData,
    isLoading: isLoadingDisplayNameResolution,
    isError: isErrorDisplayNameResolution,
    isFetching: isFetchingDisplayNameResolution,
  } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const dealId = deal.dealUuid
  const trancheId = resolvedTrancheData.trancheId

  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const convertRatesToPercentage = (items) =>
    sortBy(
      items.map((element) => ({
        ...element,
        allInInterestRate: convertDecimalToPercentage(element.allInInterestRate),
        customerMargin: convertDecimalToPercentage(element.customerMargin),
      })),
      'validFrom',
    )

  const {
    data: trancheInterestConditionsData,
    isLoading: isLoadingTrancheInterestConditions,
    isError: isErrorTrancheInterestConditions,
    isFetching: isFetchingTrancheInterestConditions,
  } = useTrancheInterestConditions(
    dealId,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const { lastEditedText } = useLastEditedTextByEmail({
    email: trancheInterestConditionsData?.lastUpdated?.name,
    timestamp: trancheInterestConditionsData?.lastUpdated?.lastUpdatedOn,
  })

  const { mutateAsync: createInterestCondition } = useCreateInterestCondition(
    {},
    { dealId, trancheId },
  )
  const { mutateAsync: deleteInterestConditions } = useDeleteInterestConditions(
    {},
    { dealId, trancheId },
  )
  const { mutateAsync: updateInterestCondition } = useUpdateInterestCondition(
    {},
    { dealId, trancheId },
  )
  const { mutateAsync: updateInterestConditionsComment } = useUpdateInterestConditionsComment(
    {},
    { dealId, trancheId },
  )

  const refreshInterestConditions = async () => {
    await queryClient.invalidateQueries([
      'deals',
      dealId,
      'tranches',
      trancheId,
      'interest-conditions',
    ])
  }

  const dataRows = trancheInterestConditionsData?.items
    ? convertRatesToPercentage(trancheInterestConditionsData.items)
    : []

  return (
    <MultiSelectCard
      mainTitle={t('pages.deals.tranches.details.interest-conditions-card.title')}
      mainSubtitle={lastEditedText}
      tableTitle={t('pages.deals.tranches.details.interest-conditions-card.table-title')}
      noDataText={t('components.table.no-data')}
      columnDefinitions={useInterestConditionsColumnDefinitions}
      dataRows={dataRows}
      userIsAllowedToEdit={
        !isExistingBusinessViewSet &&
        allowedOperations.includes(FINANCING_TRANCHE_INTEREST_CONDITIONS_UPDATE)
      }
      comment={trancheInterestConditionsData?.comment ?? '<p></p>'}
      isLoading={isLoadingDisplayNameResolution || isLoadingTrancheInterestConditions}
      isFetching={isFetchingDisplayNameResolution || isFetchingTrancheInterestConditions}
      isError={isErrorDisplayNameResolution || isErrorTrancheInterestConditions}
      // data modification connections
      createDataEntry={createInterestCondition}
      updateDataEntry={updateInterestCondition}
      deleteDataEntries={deleteInterestConditions}
      updateComment={updateInterestConditionsComment}
      refreshData={refreshInterestConditions}
      // Invalidation logic
      reduxStatePath="deals.interestConditionsTable"
      reduxTableSliceActions={{
        addInvalidEditState,
        removeInvalidEditState,
        removeIdsFromInvalidEditStates,
        resetInvalidEditState,
      }}
    />
  )
}

export default InterestConditionsCard
