import { useMutation } from '@tanstack/react-query'
import { round } from 'lodash'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { useShortDateFormatter, useFormattedNumberParser, useSeparators } from 'hooks/i18n/useI18n'

const maximumFractionDigits = 2

const useEditTranche = (mutationOptions) => {
  const { put } = useAccessTokenRequest()
  const { parse: parseDate, localePattern } = useShortDateFormatter()
  const parseNumber = useFormattedNumberParser()
  const separators = useSeparators()

  const parseAndRound = (number) => {
    const numberToString = typeof number === 'string' ? number : number?.toString()
    const regPattern = new RegExp(`\\${separators?.thousandsSeparator}`, 'g')
    const strippedNumber = numberToString?.replace(regPattern, '')
    const parsed = parseNumber(strippedNumber)

    if (parsed === null || isNaN(parsed) || strippedNumber === '') {
      return null
    }

    const decimals = 10 ** maximumFractionDigits
    return round(parsed * decimals) / decimals
  }

  return useMutation(
    ({
      dealId,
      trancheId,
      borrower,
      productNumber,
      coverpoolEligibleAmount,
      creditRiskTypeCode,
      rank,
      durationTypeCode,
      firstDrawdownDate,
      latestDrawingDate,
      loanTypeCode,
      mainCollateralTypeCode,
      maturity,
      productTypeCode,
      revolvingTypeCode,
      timeToCoverpool,
      trancheName,
      totalCommitment,
    }) => {
      if (coverpoolEligibleAmount?.amount) {
        coverpoolEligibleAmount.amount = parseAndRound(coverpoolEligibleAmount.amount)
      }

      return put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/details`,
        body: {
          tranche_name: trancheName,
          borrower_id: borrower?.id,
          loan_type_code: loanTypeCode,
          product_type_code: productTypeCode,
          main_collateral_type_code: mainCollateralTypeCode,
          credit_risk_type_code: creditRiskTypeCode,
          rank,
          revolving_type_code: revolvingTypeCode,
          duration_type_code: durationTypeCode,
          total_commitment: totalCommitment,
          first_drawdown_date: parseDate(firstDrawdownDate, localePattern),
          latest_drawing_date: parseDate(latestDrawingDate, localePattern),
          maturity_date: parseDate(maturity, localePattern),
          time_to_coverpool_in_months: timeToCoverpool,
          coverpool_eligible_amount: !coverpoolEligibleAmount?.amount
            ? null
            : coverpoolEligibleAmount,
          product_number: productNumber,
        },
      })
    },
    mutationOptions,
  )
}

export default useEditTranche
