import { FlexBoxJustifyContent, Input, Modals, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { uniq, isNil } from 'lodash'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { defaultPopoverStaticConfig } from 'components/ui/tables/display-and-edit-table/DisplayAndEditTablePopover'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import useCreateDealUse from 'hooks/services/deals/deal-uses/useCreateDealUse'
import useDealUses from 'hooks/services/deals/deal-uses/useDealUses'
import useDeleteDealUse from 'hooks/services/deals/deal-uses/useDeleteDealUse'
import useUpdateDealUse from 'hooks/services/deals/deal-uses/useUpdateDealUse'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'

const DealUsesCard = ({ pageData }) => {
  const {
    deal: { dealUuid },
    isAllowedDealUsesUpdate,
  } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.uses',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const formatNumber = useNumberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 })
  const queryClient = useQueryClient()
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()

  const {
    data: { uses = [], lastUpdatedBy, lastUpdatedAt } = {},
    isLoading: isLoadingUses,
    isError: isErrorUses,
  } = useDealUses(dealUuid)

  const { lastEditedText } = useLastEditedTextByEmail({
    email: lastUpdatedBy,
    timestamp: lastUpdatedAt,
  })

  const formattedNumberInputRef = useRef()
  const [rowsWithInvalidValues, setRowsWithInvalidValues] = useState([])
  const updateNumberValidities = (rowKey, isValidNumber) => {
    if (isNil(isValidNumber) || isValidNumber) {
      setRowsWithInvalidValues((oldState) => oldState.filter((e) => e !== rowKey))
    } else {
      setRowsWithInvalidValues((oldState) => uniq([...oldState, rowKey]))
    }
  }

  const [editRows, setEditRows] = useState([])
  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })
  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
    setRowsWithInvalidValues((oldState) => oldState.filter((e) => e !== rowKey))
  }
  const getNewEditRow = ({ rowKey, parameter, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[parameter] = value
    return newRow
  }
  const updateEditRow = (rowKey, parameter, value) => {
    if (isEmpty(findEditRow(rowKey))) {
      const newEditRow = getNewEditRow({ rowKey, parameter, value })
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const deleteDealUse = useDeleteDealUse({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'uses'])
      showToast({ children: t('remove-success') }, document.body)
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('remove-error'), error: errorResponse })
    },
  })

  const createDealUse = useCreateDealUse({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'uses'])
      showToast({ children: t('use-saved-success') }, document.body)
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('use-saved-error'), error: errorResponse })
    },
  })

  const updateDealUse = useUpdateDealUse({
    onSuccess: () => {
      queryClient.invalidateQueries(['deals', dealUuid, 'uses'])
      showToast({ children: t('use-update-success') }, document.body)
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('use-update-error'), error: errorResponse })
    },
  })

  const handleSaveRow = (rowKey) => {
    let changedRow = findEditRow(rowKey)
    if (rowKey !== rowKeyNewRow) {
      changedRow = {
        useUuid: uses[rowKey].useUuid,
        name: uses[rowKey].name,
        value: uses[rowKey].value.number,
        currencyCode: uses[rowKey].value.currencyCode,
        ...changedRow,
      }
    }
    const mappedToDealUse = {
      useUuid: changedRow.useUuid ?? undefined,
      name: changedRow.name,
      value: {
        number: changedRow.value,
        currencyCode: changedRow.currencyCode,
      },
    }
    if (rowKey === rowKeyNewRow) {
      createDealUse.mutateAsync({ dealUuid: dealUuid, dealUse: mappedToDealUse })
    } else {
      updateDealUse.mutateAsync({ dealUuid: dealUuid, dealUse: mappedToDealUse })
    }
    removeChangeFromEditRows(rowKey)
  }
  const handleDeleteRow = (rowKey) => {
    deleteDealUse.mutateAsync({ dealUuid: dealUuid, useUuid: uses[rowKey].useUuid })
  }
  const handleCancelEditRow = (rowKey) => {
    removeChangeFromEditRows(rowKey)
  }

  const isRowValid = (rowKey) => {
    let changedRow = findEditRow(rowKey)
    if (rowKey !== rowKeyNewRow) {
      changedRow = {
        name: uses[rowKey].name,
        value: uses[rowKey].value.number,
        currencyCode: uses[rowKey].value.currencyCode,
        ...changedRow,
      }
    }

    return (
      !!changedRow?.name?.trim()?.length &&
      !!changedRow?.name &&
      !!changedRow?.value &&
      !rowsWithInvalidValues.includes(rowKey) &&
      !!changedRow?.currencyCode
    )
  }

  const columnDefinitions = [
    {
      columnKey: 'name',
      title: t('use'),
    },
    {
      columnKey: 'value',
      title: t('value'),
      alignment: FlexBoxJustifyContent.End,
    },
    {
      columnKey: 'currency',
      title: t('currency'),
    },
  ]

  const renderNameInput = (rowKey, value) => (
    <Input
      maxLength={75}
      value={value}
      onChange={(event) => updateEditRow(rowKey, 'name', event.target.value)}
    />
  )
  const renderValueInput = (rowKey, value) => (
    <FormattedNumberInput
      value={value}
      onChange={(parsedValue) => {
        updateEditRow(rowKey, 'value', parsedValue)
        updateNumberValidities(rowKey, formattedNumberInputRef?.current?.isValid)
      }}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      ref={formattedNumberInputRef}
    />
  )

  const renderCurrencySelect = (rowKey, value) => (
    <LoadingSelect
      id="deal-uses-currency-select"
      loadingHook={useCurrencyCodes}
      selectionName="currency_codes"
      optionKeyName="key"
      optionDisplayName="key"
      selectedKey={value}
      onChange={(event) =>
        updateEditRow(rowKey, 'currencyCode', event.detail.selectedOption.dataset.id)
      }
    />
  )

  const tableData = uses.map(({ name, value: { number, currencyCode } }, index) => ({
    rowKey: String(index),
    isValid: isRowValid(String(index)),
    name: {
      cellContentReadMode: (
        <Text>
          <b>{name}</b>
        </Text>
      ),
      cellContentEditMode: renderNameInput(String(index), name),
    },
    value: {
      cellContentReadMode: <Text>{formatNumber(number)}</Text>,
      cellContentEditMode: renderValueInput(String(index), number),
    },
    currency: {
      cellContentReadMode: <Text>{currencyCode}</Text>,
      cellContentEditMode: renderCurrencySelect(String(index), currencyCode),
    },
  }))

  const newRow = {
    rowKey: rowKeyNewRow,
    isValid: isRowValid(rowKeyNewRow),
    name: { cellContentEditMode: renderNameInput(rowKeyNewRow) },
    value: { cellContentEditMode: renderValueInput(rowKeyNewRow) },
    currency: { cellContentEditMode: renderCurrencySelect(rowKeyNewRow) },
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={t('title', { usesCount: tableData.length })}
        subTitle={lastEditedText}
        columnDefinitions={columnDefinitions}
        tableData={tableData}
        handleSaveRow={handleSaveRow}
        handleDeleteRow={handleDeleteRow}
        handleCancelEditRow={handleCancelEditRow}
        isError={isErrorUses}
        isLoading={isLoadingUses}
        newRow={newRow}
        userIsAllowedToEdit={isAllowedDealUsesUpdate}
        popoverStaticConfig={{
          ...defaultPopoverStaticConfig,
          delete: {
            message: t('remove-use'),
            button: tNoPrefix('buttons.remove'),
          },
        }}
      />
    </>
  )
}

DealUsesCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
    isAllowedDealUsesUpdate: PropTypes.bool,
  }).isRequired,
}

export default DealUsesCard
