import { Input as UI5Input, InputType as UI5InputType } from '@fioneer/ui5-webcomponents-react'
import pickBy from 'lodash.pickby'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import ValueState, { toUI5ValueState } from 'components/ui/input/ValueState'
import errorToUI5 from 'components/ui/input/errorToUI5'

export const InputTypes = Object.freeze({
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
})

/** @param {typeof InputTypes[keyof typeof InputTypes]?} [type] */
export const toUI5InputType = (type) => {
  switch (type) {
    case undefined:
    case null:
      return type
    case InputTypes.NUMBER:
      return UI5InputType.Number
    case InputTypes.TEXT:
    default:
      return UI5InputType.Text
  }
}

/** @param {typeof InputTypes[keyof typeof InputTypes]?} [type] */
const toHTMLInputType = (type) => {
  switch (type) {
    case InputTypes.NUMBER:
      return 'number'
    case InputTypes.TEXT:
    default:
      return 'text'
  }
}

const propTypes = {
  type: PropTypes.oneOf(Object.values(InputTypes)),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  /**
   * Override the value state if automatically determining it based on the error isn't working.
   *
   * Set this to `null` to disable the value state.
   */
  valueState: PropTypes.oneOf(Object.values(ValueState)),
  /**
   * Override the value state message if automatically determining it based on the error isn't working.
   *
   * Set this to `null` to disable the value state message.
   */
  valueStateMessage: PropTypes.node,
  /** react-hook-form error */
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    PropTypes.shape({ type: PropTypes.string.isRequired }),
  ]),
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
}
/**
 * @typedef {object} overrides
 * @property {typeof InputTypes[keyof typeof InputTypes]} [type]
 * @property {import('react-hook-form').ChangeHandler} [onChange]
 * @property {(newValue?: string) => void} [onInput]
 * @property {import('react-hook-form').ChangeHandler} [onBlur]
 * @property {import('react-hook-form').FieldError} [error] Error received from `react-hook-form`. The `type` can be set to a `ValueState` (default: `ValueState.ERROR`)
 */
/**
 * Built for usage with `react-hook-form`
 */
const Input = forwardRef(
  /** @param {Omit<PropTypes.InferProps<typeof propTypes>, keyof overrides> & overrides} props */ (
    {
      type,
      placeholder,
      value,
      required,
      id,
      className,
      name,
      valueState,
      valueStateMessage,
      error,
      onChange,
      onInput,
      onBlur,
    },
    ref,
  ) => {
    /** @type {Parameters<typeof UI5Input>[0]['onChange']} */
    const handleChange = async (e) => {
      await onChange?.({
        target: { value: e.target.value, name, type: toHTMLInputType(type) },
        type: 'change',
      })
    }

    /** @type {Parameters<typeof UI5Input>[0]['onInput']} */
    const handleInput = (e) => {
      onInput?.(e.target.value)
    }

    /** @type {Parameters<typeof UI5Input>[0]['onBlur']} */
    const handleBlur = async (e) => {
      await onBlur?.({
        target: { value: e.target.value, name, type: toHTMLInputType(type) },
        type: 'blur',
      })
    }

    return (
      <UI5Input
        type={toUI5InputType(type) ?? undefined}
        placeholder={placeholder ?? undefined}
        value={value ?? undefined}
        required={required ?? undefined}
        id={id ?? undefined}
        className={className ?? undefined}
        onChange={handleChange}
        onInput={handleInput}
        onBlur={handleBlur}
        {...errorToUI5(error)}
        {...pickBy(
          { valueState: toUI5ValueState(valueState), valueStateMessage },
          (v) => v !== undefined,
        )}
        ref={ref}
      />
    )
  },
)

Input.displayName = 'Input'

Input.propTypes = propTypes

export default Input
