import {
  Button,
  ButtonDesign,
  CardHeader,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  MessageBox,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import React, { useMemo, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import styles from 'components/domains/deals/limit-check/reservations/DealReservationCard.module.css'
import DealReservationWarningSection from 'components/domains/deals/limit-check/reservations/DealReservationWarningSection'
import { RequestReservationDialog } from 'components/domains/deals/limit-check/reservations/RequestReservationDialog'
import { useDealReservationCardData } from 'components/domains/deals/limit-check/reservations/useDealReservationCardData'
import useDealReservationConfigMaps from 'components/domains/deals/limit-check/reservations/useDealReservationConfigMaps'
import LoadingButton from 'components/ui/button/LoadingButton'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import { MessageBoxTypes } from 'components/ui/message-box/MessageBox'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'

const PRE_DEAL_LIMIT_CHECK_STATUS = {
  approved: 'PDLC approved',
  declined: 'PDLC declined',
}

const ReservationStatusBox = ({ renderContent, translate, isLoading, isError }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center}>
    <FlexBox direction={FlexBoxDirection.Column} fitContainer>
      <span className={styles.customHeaderSubtitle}>{translate('reservations.label.status')}</span>
    </FlexBox>
    <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.Center} fitContainer>
      <SmallLoadingWrapper
        isLoading={isLoading}
        isError={isError}
        error={translate('reservations.loading.error')}
        renderContent={renderContent}
      />
    </FlexBox>
    <FlexBox fitContainer />
  </FlexBox>
)

ReservationStatusBox.propTypes = {
  renderContent: PropTypes.func,
  translate: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

const DealReservationCard = ({ pageData }) => {
  const { deal: { dealUuid } = {} } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check',
  })
  const { t: tNoPrefix } = useTranslation()

  const { data: { allowedOperations: allowedOperations = [] } = {} } =
    usePbbCustomServiceAllowedOperations()

  const checkIfOperationIsAllowed = (operation) =>
    allowedOperations.includes(dealsPermissions[operation])

  const isAllowedToCreateReservation = checkIfOperationIsAllowed('createReservationPBB')
  const isAllowedToRequestReservation = checkIfOperationIsAllowed('requestReservationPBB')
  const isAllowedToDeleteReservation = checkIfOperationIsAllowed('deleteReservationPBB')
  const isAllowedToSynchronizeReservation = checkIfOperationIsAllowed('synchronizeReservationPBB')

  const {
    tableData,
    lastUpdatedReservation,
    actions = {},
    newRow,
    columnDefinitions,
    reservationUuids,
    calculatedReservationStatus,
    noOfReservations,
    preDealLimitCheckResult,
    indicators = {},
    warningMessages,
    cellStyles,
    error = {},
  } = useDealReservationCardData(dealUuid)

  const { lastEditedText } = useLastEditedTextByEmail({
    email: lastUpdatedReservation.lastUpdatedBy,
    timestamp: lastUpdatedReservation.lastUpdatedAt,
  })

  const {
    onCancel,
    onClose,
    onCancelReservation,
    onConfirmReservation,
    onDelete,
    onSave,
    onSynchronize,
  } = actions
  const {
    isCreatingSupported,
    isStatusCalculationSupported,
    isPreDealLimitCheckFinished,
    isLoading,
    isError,
    isLoadingExternalSynchronization,
    isLoadingReservations,
    isErrorReservations,
  } = indicators

  const { isErrorMessageOpen, onErrorMessageBoxClose, errorDetails, errorSummary } = error

  const { statusConfigMap, warningConfigMap } = useDealReservationConfigMaps()

  const localWarningMessages = useMemo(
    () =>
      warningMessages.filter((warningMessage) =>
        [
          warningConfigMap.NO_RESERVATIONS.message,
          warningConfigMap.OUTDATED_OR_OUT_OF_SYNC_RESERVATIONS.message,
        ].every((localWarning) => warningMessage !== localWarning),
      ),
    [warningConfigMap, warningMessages],
  )

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const customActionElements = useMemo(() => {
    const actionElements = []

    if (isAllowedToRequestReservation && isStatusCalculationSupported)
      actionElements.push(
        <span key="separator" className={styles.buttonSeparator} />,
        <Button
          key="request-reservation"
          design={ButtonDesign.Transparent}
          onClick={() => setIsDialogOpen(true)}
        >
          {t('reservations.buttons.request')}
        </Button>,
      )
    if (isAllowedToSynchronizeReservation)
      actionElements.push(
        <LoadingButton
          key="synchronize-reservation"
          design={ButtonDesign.Transparent}
          isLoading={isLoadingExternalSynchronization}
          renderContent={() => t('reservations.buttons.synchronize')}
          onClick={() => onSynchronize(reservationUuids)}
        />,
      )

    return actionElements
  }, [
    isAllowedToRequestReservation,
    isStatusCalculationSupported,
    t,
    isAllowedToSynchronizeReservation,
    isLoadingExternalSynchronization,
    onSynchronize,
    reservationUuids,
  ])

  const renderStatusContent = useCallback(
    () => (
      <ObjectStatus inverted state={statusConfigMap[calculatedReservationStatus]?.style}>
        {t(
          `reservations.label.status.${statusConfigMap[calculatedReservationStatus]?.displayName}`,
        )}
      </ObjectStatus>
    ),

    [calculatedReservationStatus, statusConfigMap, t],
  )

  const customHeaderWithStatus = useMemo(
    () => (
      <React.Fragment>
        <FlexBox className={styles.borderBottom}>
          <CardHeader titleText={t('reservations.title')} subtitleText={lastEditedText} />
        </FlexBox>
        <FlexBox direction={FlexBoxDirection.Column} className={styles.statusContainer}>
          <DealReservationWarningSection messages={localWarningMessages} />
          <ReservationStatusBox
            translate={t}
            renderContent={renderStatusContent}
            isError={isError}
            isLoading={isLoading}
          />
        </FlexBox>
      </React.Fragment>
    ),
    [isError, isLoading, lastEditedText, renderStatusContent, t, localWarningMessages],
  )

  const confirmMessageBoxActions = useMemo(
    () => [
      <Button key="button-confirm" design={ButtonDesign.Emphasized} onClick={onConfirmReservation}>
        {tNoPrefix('buttons.confirm')}
      </Button>,
      <Button key="button-cancel" design={ButtonDesign.Transparent} onClick={onCancelReservation}>
        {tNoPrefix('buttons.cancel')}
      </Button>,
    ],
    [onCancelReservation, onConfirmReservation, tNoPrefix],
  )

  const errorMessageBoxActions = useMemo(
    () => [
      <Button key="button-close" design={ButtonDesign.Transparent} onClick={onCancelReservation}>
        {tNoPrefix('buttons.close')}
      </Button>,
    ],
    [onCancelReservation, tNoPrefix],
  )

  const pdlcMessageBoxProps = useMemo(() => {
    switch (preDealLimitCheckResult) {
      case PRE_DEAL_LIMIT_CHECK_STATUS.approved: {
        return {
          actions: confirmMessageBoxActions,
          content: t('reservations.label.save-reservation.success'),
          titleText: tNoPrefix('buttons.confirm'),
          type: MessageBoxTypes.Confirm,
        }
      }
      case PRE_DEAL_LIMIT_CHECK_STATUS.declined: {
        return {
          actions: confirmMessageBoxActions,
          content: t('reservations.label.save-reservation.failure'),
          titleText: tNoPrefix('buttons.confirm'),
          type: MessageBoxTypes.Confirm,
        }
      }
      default: {
        return {
          actions: errorMessageBoxActions,
          content: t('dialog.request-reservation.error'),
          type: MessageBoxTypes.Error,
        }
      }
    }
  }, [confirmMessageBoxActions, errorMessageBoxActions, preDealLimitCheckResult, t, tNoPrefix])

  return (
    <React.Fragment>
      <RequestReservationDialog
        dealUuid={dealUuid}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <CardWithDisplayAndEditTable
        cardTitle={t('reservations.title-with-count', { reservations: noOfReservations })}
        isAllowedToCreate={isAllowedToCreateReservation && isCreatingSupported}
        userIsAllowedToEdit={isAllowedToDeleteReservation}
        isLoading={isLoadingReservations}
        isError={isErrorReservations}
        tableData={tableData}
        additionalTableStyles={styles.tableTopMargin}
        newRow={newRow}
        columnDefinitions={columnDefinitions}
        headerClassName={styles.header}
        titleClassName={styles.headerTitle}
        customActionElements={customActionElements}
        additionalHeader={customHeaderWithStatus}
        actionCellStyles={{
          readModeCell: cellStyles,
          editModeCell: cellStyles,
        }}
        handleDeleteRow={onDelete}
        handleSaveRow={onSave}
        handleCancelEditRow={onCancel}
      />
      {createPortal(
        <ErrorMessageBoxWithExpandableDetails
          messageSummary={errorSummary}
          messageDetails={errorDetails}
          isOpen={isErrorMessageOpen}
          onClose={onErrorMessageBoxClose}
        />,
        document.body,
      )}
      {createPortal(
        <MessageBox
          type={pdlcMessageBoxProps.type}
          titleText={pdlcMessageBoxProps.titleText}
          open={isPreDealLimitCheckFinished}
          actions={pdlcMessageBoxProps.actions}
          onClose={onClose}
        >
          {pdlcMessageBoxProps.content}
        </MessageBox>,
        document.body,
      )}
    </React.Fragment>
  )
}

DealReservationCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
  }),
}

export default DealReservationCard
