import { ValueState as UI5ValueState } from '@fioneer/ui5-webcomponents-react'
import ValueState from 'components/ui/input/ValueState'

/** @param {import('react-hook-form').FieldError} [error] */
const errorToUI5 = (error) => {
  if (!error) return { valueState: UI5ValueState.None, valueStateMessage: <span /> }

  const { message, type } = error

  switch (type) {
    case ValueState.NONE:
      return { valueState: UI5ValueState.None, valueStateMessage: <span>{message}</span> }
    case ValueState.SUCCESS:
      return { valueState: UI5ValueState.Success, valueStateMessage: <span>{message}</span> }
    case ValueState.INFORMATION:
      return { valueState: UI5ValueState.Information, valueStateMessage: <span>{message}</span> }
    case ValueState.WARNING:
      return { valueState: UI5ValueState.Warning, valueStateMessage: <span>{message}</span> }
    case ValueState.ERROR:
    default:
      return { valueState: UI5ValueState.Error, valueStateMessage: <span>{message}</span> }
  }
}

export default errorToUI5
