import { AnalyticalTableScaleWidthMode } from '@fioneer/ui5-webcomponents-react'
import isEmpty from 'lodash.isempty'
import sortBy from 'lodash.sortby'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import { useCollapseExpandTableActions } from 'components/ui/tables/analytical/useCollapseExpandTableActions'
import { useConfig } from 'hooks/config/useConfig'
import useNonLoanProducts from 'hooks/services/deals/financing/useNonLoanProducts'
import { DealContext } from 'routes/deals/DealContext'
import { NON_LOAN_PRODUCT_TABLE_TYPES } from 'routes/deals/financing/non-loan-products/constants'
import styles from 'routes/deals/financing/non-loan-products/other-lending/OtherLendingTable.module.css'
import mapOtherLendingTradesData from 'routes/deals/financing/non-loan-products/other-lending/mapOtherLendingTradesData'
import useOtherLendingTableColumnDefinitions from 'routes/deals/financing/non-loan-products/other-lending/useOtherLendingTableColumnDefinitions'
import { getNonLoanProductDealDataByConfig } from 'routes/deals/financing/non-loan-products/utils/getNonLoanProductDealDataByConfig'

const OtherLendingTable = () => {
  const { deal } = useContext(DealContext)

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.other-lending-table',
  })
  const tableRef = useRef(null)

  const {
    data: { nonLoanProducts: { tradeMappingAttribute, tradeMappingValuesByTables } = {} } = {},
  } = useConfig()

  const { data, isLoading, isError, isFetching } = useNonLoanProducts(deal.displayId)

  const otherLendingMappingValues =
    tradeMappingValuesByTables?.[NON_LOAN_PRODUCT_TABLE_TYPES.OTHER_LENDING]

  const {
    handleOnRowExpand,
    additionalTableActions: collapseExpandActions = [],
    expandOrCollapseSubRows,
  } = useCollapseExpandTableActions(tableRef)

  const columnSelection = useOtherLendingTableColumnDefinitions()

  // expand rows as default
  useEffect(() => {
    expandOrCollapseSubRows(true)
    // expandOrCollapseSubRows cannot be added, because this is then also triggered upon collapsing the whole table
    // making it impossible to collapse all rows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const tableData = useMemo(() => {
    if (!isEmpty(data?.trades)) {
      const filteredData = getNonLoanProductDealDataByConfig({
        tradeMappingAttribute,
        tradeMappingValues: otherLendingMappingValues,
        data,
      })
      const mappedData = isEmpty(filteredData?.trades)
        ? []
        : mapOtherLendingTradesData(filteredData?.trades)

      // sorting needs to happen after the mapping, since the data object is flattened by one level (3 -> 2),
      // and the sorting keys are right in the second/middle level
      return sortBy(mappedData, ['counterpartyId', 'productType', 'productSubType'])
    }
    return []
  }, [data, otherLendingMappingValues, tradeMappingAttribute])

  const otherLendingRowCount = tableData?.length

  const countRows = useCallback(
    (tableRows) =>
      !isEmpty(tableRows)
        ? tableRows.length + tableRows.reduce((acc, row) => countRows(row.subRows) + acc, 0)
        : 0,
    [],
  )

  // Counts all parent rows and each of their child's sub-rows to provide a correct number of visible rows needed to correctly render the AnalyticalTable.
  // If this number would be lower than the actual number of displayed rows, the table would not show any data rows.
  // However, the UI5 AnalyticalTable sometimes does not seem to be happy with accuracy, and likes to just have a high number here,
  // so we use the tried and trusted 99 lower bound default
  const visibleRowCount = Math.max(
    countRows(tableData),
    // eslint-disable-next-line no-magic-numbers
    99,
  )

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        loading={!isLoading && isFetching}
        ref={tableRef}
        nrOfEntries={otherLendingRowCount ?? 0}
        title={t('header')}
        sortable={false}
        id="other-lending-table"
        minRows={0}
        columns={columnSelection}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={visibleRowCount}
        overscanCountHorizontal={visibleRowCount}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={50}
        data={tableData}
        isTreeTable
        showColumnSelection={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={false}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
        additionalActions={collapseExpandActions}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={isLoading}
            isError={isError}
            tableId={'other-lending-table'}
          />
        )}
      />
    </div>
  )
}
export default OtherLendingTable
