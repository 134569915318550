import { Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import {
  convertDecimalToPercentage,
  convertPercentageToDecimal,
} from 'routes/deals/financing/utils/convertPercentages'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const useRequiredHedgeAmountShareFields = ({
  name,
  label,
  share,
  trancheOwnShareCommitment,
  setRequiredHedgeAmountTotal,
  setRequiredHedgeAmountShareForRisksCalculation,
  validationFunction,
}) => {
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const renderRequiredHedgeAmountShareInput = ({ handleBulkOnChange, fieldValues, item }) => {
    const inputComponent = (
      <FormattedNumberInput
        id={`requiredHedgeAmountShare-input`}
        icon={<Text>{'%'}</Text>}
        value={convertToNumberIfValid(convertDecimalToPercentage(share))}
        valueState={validationFunction(fieldValues[name]) ? ValueState.None : ValueState.Error}
        valueStateMessage={
          <span>
            {t(
              'pages.deals.tranches.details.interest-rate-hedging-requirement-card.error.non-negative-number',
            )}
          </span>
        }
        data-testid={`requiredHedgeAmountShare-input`}
        onInput={(newValue) => {
          setRequiredHedgeAmountShareForRisksCalculation(newValue)
          const newAmountTotal = newValue
            ? convertPercentageToDecimal(newValue) * trancheOwnShareCommitment
            : ''
          handleBulkOnChange({
            requiredHedgeAmountTotal: newAmountTotal,
            requiredHedgeAmountShare: newValue,
          })
          setRequiredHedgeAmountTotal(newAmountTotal)
        }}
      />
    )
    return <EditCardItem key={item.label} editComponent={inputComponent} label={item.label} />
  }

  return [
    {
      name,
      label,
      value: formatShare(share),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name,
      label,
      value: convertDecimalToPercentage(share),

      renderCustomEditComponent: renderRequiredHedgeAmountShareInput,

      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useRequiredHedgeAmountShareFields
