import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { convertPercentageToDecimal } from 'routes/deals/financing/utils/convertPercentages'

export const useUpdateInterestCondition = (options, parameters) => {
  const { put } = useAccessTokenRequest()
  const { dealId, trancheId } = parameters

  return useMutation(
    async ({
      id: interestConditionItemId,
      data: {
        interestRateTypeCode,
        validFrom,
        allInInterestRate,
        calculationMethod,
        customerMargin,
        interestIndexName,
        paymentFrequency,
        referenceRate,
      },
    }) =>
      await put({
        path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/interest-conditions/items/${interestConditionItemId}`,
        body: {
          interest_condition_item_type_code: interestRateTypeCode,
          valid_from: validFrom,
          all_in_interest_rate: convertPercentageToDecimal(allInInterestRate),
          customer_margin: convertPercentageToDecimal(customerMargin),
          calculation_method: calculationMethod,
          interest_index_name: interestIndexName,
          payment_frequency: paymentFrequency,
          reference_rate: referenceRate,
        },
      }),
    options,
  )
}
