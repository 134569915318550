import { useTranslation } from 'react-i18next'
import { useLoanTypeCodes } from 'hooks/services/deals/financing/configGetters/useLoanTypeCodes'

const useLoanTypeFields = ({ loanTypeCode, loanTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'loanTypeCode',
      label: t('loan-type'),
      value: loanTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'loanTypeCode',
      label: t('loan-type'),
      value: loanTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      isMandatory: true,
      editComponentType: 'LoadingSelect',
      editComponentProps: { loadingHook: useLoanTypeCodes, selectionName: 'loanTypeCodes' },
    },
  ]
}

export default useLoanTypeFields
