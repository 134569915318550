import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetComponentRegistry } from 'components/ui/configurable-components-page/useComponentRegistry'
import { usePageComponents } from 'components/ui/configurable-components-page/usePageComponents'

/**
 * @param {import('prop-types').InferProps<ConfigurableComponentLoader.propTypes>} props
 * @returns
 */
const ConfigurableComponentLoader = ({
  id,
  useLoadingHook,
  onLoadingResult,
  contextData,
  pageData,
  staticData,
}) => {
  const { renderComponent, isLoading, isError, error } = useLoadingHook({
    contextData,
    pageData,
    staticData,
  })
  useEffect(() => {
    onLoadingResult(id, { renderComponent, isLoading, isError, error })
  }, [error, id, isError, isLoading, onLoadingResult, renderComponent])
  return null
}
ConfigurableComponentLoader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.any,
  useLoadingHook: PropTypes.func.isRequired,
  onLoadingResult: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contextData: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  pageData: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  staticData: PropTypes.any,
}

/**
 *
 * @param {Object} componentRegistry
 * @param {import('components/ui/configurable-components-page/usePageComponents').DynamicPageEntryData[] | undefined} _children
 * @returns {DynamicPageEntry[] | undefined}
 */
const resolveChildren = (componentRegistry, _children) => {
  let currentId = 0
  /**
   * @param {import('components/ui/configurable-components-page/usePageComponents').DynamicPageEntryData[] | undefined} children
   * @param {number} id
   * @returns {[DynamicPageEntry[] | undefined, number]}
   */
  const _resolveChildren = (children) => {
    if (!children) {
      return undefined
    }
    /** @type {DynamicPageEntry[]} */
    const resolvedChildren = children.map(
      (child) =>
        /** @satisfies {DynamicPageEntry} */
        ({
          id: currentId++,
          if: child.if,
          dynamicData: child.dynamicData,
          forEach: child.forEach,
          code: child.code,
          span: child.span ?? 1,
          position: child.position ?? 0,
          resolvedComponent: componentRegistry.resolve(child.code),
          staticData: child.data ?? {},
          children: _resolveChildren(child.children),
        }),
    )
    return resolvedChildren.sort((a, b) => a.position - b.position)
  }
  return _resolveChildren(_children)
}

/**
 *
 * @param {Record<number, import('components/ui/configurable-components-page/useComponentRegistry').ComponentDisplayHookResult} visibilityMap
 * @param {DynamicPageEntry[] | undefined} children
 * @returns {DynamicPageEntry[] | undefined}
 */
const filterHiddenEntries = (visibilityMap, children) => {
  if (!children) {
    return undefined
  }
  return children
    .filter((child) => visibilityMap[child.id]?.renderComponent ?? true)
    .map((child) => ({
      ...child,
      children: filterHiddenEntries(visibilityMap, child.children),
    }))
}

/**
 * @param {DynamicPageEntry[] | undefined} children
 * @returns {DynamicPageEntry[]}
 */
const getComponentsWithHooks = (children) => {
  if (isNil(children)) {
    return []
  }
  /** @type {DynamicPageEntry[]} */
  const componentsWithHooks = []
  children.forEach((child) => {
    if (child.resolvedComponent?.componentDisplayHook) {
      componentsWithHooks.push(child)
    }
    componentsWithHooks.push(...getComponentsWithHooks(child.children))
  })
  return componentsWithHooks
}

/**
 * @typedef {Object} DynamicPageEntry
 * @property {number| undefined} id
 * @property {string | undefined} if
 * @property {string | undefined} forEach
 * @property {unknown | undefined} dynamicData
 * @property {import('components/ui/configurable-components-page/useComponentRegistry').ComponentConfig} resolvedComponent
 * @property {number| undefined} position
 * @property {string} code
 * @property {number| undefined} span
 * @property {unknown| undefined} staticData
 * @property {DynamicPageEntry[] | undefined} children

 * @typedef {Object} DynamicPageConfig
 * @property {DynamicPageEntry[]} children
 * @property {string} defaultTab
 * @property {string} name
 */

/**
 * @typedef {Object} Params
 * @property {string} pageCode
 * @property {unknown} contextData
 *
 * @typedef {Object} ReturnType
 * @property {DynamicPageConfig} pageConfig
 * @property {import('react').ReactElement} loadingComponents
 * @property {boolean} isAnyLoading
 * @property {boolean} isAnyError
 *
 * @param {Params} params
 * @returns {ReturnType}
 */
export const useConfigurableComponentsPageState = ({ pageCode, contextData, pageData }) => {
  /** @type {ReturnType<typeof useState<Record<number, import('components/ui/configurable-components-page/useComponentRegistry').ComponentDisplayHookResult>>>} */
  const [dynamicComponentVisibility, setDynamicComponentVisibility] = useState({})
  const componentRegistry = useGetComponentRegistry()
  const { page: rawPageConfig, isLoading: isPageConfigLoading } = usePageComponents(pageCode)

  /** @satisfies {DynamicPageConfig | undefined} */
  const pageConfig = useMemo(() => {
    if (isNil(rawPageConfig)) {
      return undefined
    }
    return {
      ...rawPageConfig,
      children: resolveChildren(componentRegistry, rawPageConfig.children),
    }
    // componentRegistry.cacheKey has to be included to make the hook aware
    // of changes to the registry after initially rendering the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRegistry, componentRegistry.cacheKey, rawPageConfig])

  /** @satisfies {DynamicPageConfig | undefined} */
  const visiblePageConfig = useMemo(
    () => ({
      ...pageConfig,
      children: filterHiddenEntries(dynamicComponentVisibility, pageConfig?.children),
    }),
    [dynamicComponentVisibility, pageConfig],
  )

  const { isAnyLoading, isAnyError } = useMemo(() => {
    let isError = false
    let isLoading = false
    Array.from(Object.values(dynamicComponentVisibility)).forEach(
      ({ isLoading: isComponentLoading, isError: isComponentError }) => {
        isError ||= isComponentError
        isLoading ||= isComponentLoading
      },
    )
    return { isAnyLoading: isLoading || isPageConfigLoading, isAnyError: isError }
  }, [dynamicComponentVisibility, isPageConfigLoading])

  const componentsWithHooks = useMemo(
    () => getComponentsWithHooks(pageConfig?.children),
    [pageConfig?.children],
  )

  const handleLoadingResult = useCallback(
    (id, loadingResult) =>
      setDynamicComponentVisibility((visibilityMap) => ({ ...visibilityMap, [id]: loadingResult })),
    [],
  )

  const loadingComponents = useMemo(
    () => (
      <>
        {componentsWithHooks.map(({ id, resolvedComponent, staticData }) => (
          <ConfigurableComponentLoader
            key={id}
            id={id}
            useLoadingHook={resolvedComponent?.componentDisplayHook}
            staticData={staticData}
            contextData={contextData}
            pageData={pageData}
            onLoadingResult={handleLoadingResult}
          />
        ))}
      </>
    ),
    [componentsWithHooks, contextData, pageData, handleLoadingResult],
  )

  return useMemo(
    () => ({
      pageConfig: visiblePageConfig,
      loadingComponents,
      isAnyLoading,
      isAnyError,
    }),
    [isAnyError, isAnyLoading, loadingComponents, visiblePageConfig],
  )
}
