import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing product type code keys and their localized display name
 * The productTypeCodes structure is as follows:
 * {
 *   "productTypeCodes": [
 *       {
 *         "key": "B01",
 *         "displayName": "Hypothekendarlehen"
 *       },
 *       [...]
 *   ]
 * }
 */
export const useProductTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/f4/category/PRODUCT_TYPE`,
    translated: true,
  })

  const transformedData = {
    productTypeCodes: result?.data?.configuration?.PRODUCT_TYPE.map((element) => ({
      key: element.code,
      display_name: element.short_text,
    })),
  }

  return {
    ...result,
    data: transformedData,
  }
}
