import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalLoadConsolidationPointsTrigger = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({
      dealUuid,
      externalProductType,
      externalMainPropertyType,
      country,
      businessSegment,
    }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/load-consolidation-points`,
        body: snakecaseKeys({
          externalProductType,
          externalMainPropertyType,
          country,
          businessSegment,
        }),
      })
    },
    { onSuccess, onError },
  )
}

export default useExternalLoadConsolidationPointsTrigger
