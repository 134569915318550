import { t } from 'i18next'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'

export const defaultValidationFunction = (value) =>
  isNil(value) || value === '' || (value >= 0 && Number.isInteger(Number(value)))

const useStandardIntegerField = ({ name, label, value, validationFunction }) => [
  {
    label,
    name,
    value,

    isShownInDisplay: true,
    isShownInEdit: false,
  },
  {
    label,
    name,
    value,

    isShownInDisplay: false,
    isShownInEdit: true,

    editComponentType: editComponentTypes.FormattedNumberInput,
    editComponentProps: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      valueStateMessage: (
        <span>
          {t('pages.deals.tranches.details.customer-specific-attributes-card.integer.error')}
        </span>
      ),
    },

    validationFunction: validationFunction ?? defaultValidationFunction,
  },
]

useStandardIntegerField.defaultProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validationFunction: PropTypes.func,
}

export default useStandardIntegerField
