import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useExternalRequestReservationTrigger = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()
  return useMutation(
    async ({ dealUuid, externalProductType, externalMainPropertyType }) => {
      await post({
        path: `/pbb-custom-service/deals/${dealUuid}/limit-management/request-reservation`,
        body: snakecaseKeys({ externalProductType, externalMainPropertyType }),
      })
    },
    { onSuccess, onError },
  )
}

export default useExternalRequestReservationTrigger
