import {
  Input,
  Text,
  TextArea,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  FlexBox,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { FINANCING_OTHER_FINANCING_SOURCES_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import useCreateOrUpdateOtherFinancingSource from 'hooks/services/deals/financing/useCreateOrUpdateOtherFinancingSource'
import useDeleteOtherFinancingSource from 'hooks/services/deals/financing/useDeleteOtherFinancingSource'
import useOtherFinancingSource from 'hooks/services/deals/financing/useOtherFinancingSource'
import styles from 'routes/deals/financing/otherFinancingSources/OtherFinancingSourcesCard.module.css'
import AmountInput from 'routes/deals/financing/otherFinancingSources/fields/amount/AmountInput'
import CurrencyInputSelect from 'routes/deals/financing/otherFinancingSources/fields/currency/CurrencyInputSelect'
import RankInput from 'routes/deals/financing/otherFinancingSources/fields/rank/RankInput'
import TypeInputSelect from 'routes/deals/financing/otherFinancingSources/fields/type/TypeInputSelect'

const OtherFinancingSourcesCard = ({ pageData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.other-financing-sources',
  })

  const { deal, financingAllowedOperations: allowedOperations } = pageData
  const queryClient = useQueryClient()

  const formatCurrency = useCustomizableCurrencyFormatter()

  const [isItemDeleteErrorDialogOpen, setIsItemDeleteErrorDialogOpen] = useState(false)
  const [isItemCreateOrUpdateErrorDialogOpen, setIsItemCreateOrUpdateErrorDialogOpen] =
    useState(false)
  const [editRows, setEditRows] = useState([])
  const [interactedWithRows, setInteractedWithRows] = useState([])
  const [rowKeyForRequest, setRowKeyForRequest] = useState(undefined)
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })

  const findInteractedWithRow = (rowKey) => ({
    ...interactedWithRows.find((interactedWithRow) => interactedWithRow.rowKey === rowKey),
  })

  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
  }

  const {
    data: otherFinancingSourceData,
    isLoading: isLoadingOtherFinancingSourceData,
    isError: isErrorOtherFinancingSourceData,
    isFetching: isFetchingOtherFinancingSourceData,
  } = useOtherFinancingSource(deal.dealUuid)

  const invalidateOtherFinancingSource = () => {
    queryClient.invalidateQueries(['deals', deal.dealUuid, 'otherFinancingSource'])
  }

  const createOrUpdateOtherFinancingSource = useCreateOrUpdateOtherFinancingSource({
    onSuccess: (data, params) => {
      setIsWritingToBackend(false)
      Modals.showToast({
        children: (
          <Text>
            {params.rowKey === 'rowKeyNewRow'
              ? t('create-item-successful')
              : t('update-item-successful')}
          </Text>
        ),
      })

      invalidateOtherFinancingSource()
      removeChangeFromEditRows(params.rowKey)
    },
    onError: () => {
      setIsWritingToBackend(false)
      setIsItemCreateOrUpdateErrorDialogOpen(true)
    },
  })

  const deleteOtherFinancingSource = useDeleteOtherFinancingSource({
    onSuccess: (data, params) => {
      setIsWritingToBackend(false)
      Modals.showToast({
        children: <Text>{t('delete-item-successful')}</Text>,
      })
      invalidateOtherFinancingSource()
      removeChangeFromEditRows(params.rowKey)
    },
    onError: () => {
      setIsWritingToBackend(false)
      setIsItemDeleteErrorDialogOpen(true)
    },
  })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: otherFinancingSourceData?.lastUpdated?.name,
    timestamp: otherFinancingSourceData?.lastUpdated?.lastUpdatedOn,
  })

  const removeInteractedStateFromInteractedRows = (rowKey) => {
    setInteractedWithRows([
      ...interactedWithRows.filter((interactedRow) => interactedRow.rowKey !== rowKey),
    ])
  }

  const handleDeleteRow = (rowKey) => {
    setIsWritingToBackend(true)
    setRowKeyForRequest(rowKey)
    const itemId = otherFinancingSourceData?.items[rowKey]?.id

    deleteOtherFinancingSource.mutate({
      dealId: deal.dealUuid,
      itemId,
    })
  }

  const handleCancelEditRow = (rowKey) => {
    removeChangeFromEditRows(rowKey)
    removeInteractedStateFromInteractedRows(rowKey)
  }

  const getNewEditRow = ({ rowKey, parameter, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[parameter] = value
    return newRow
  }

  const setInteractedWithRowState = (rowKey, parameter) => {
    const oldInteractedWithRowState = findInteractedWithRow(rowKey)
    const newInteractedWithRowState = isEmpty(oldInteractedWithRowState)
      ? { rowKey: rowKey }
      : { ...oldInteractedWithRowState }
    newInteractedWithRowState[parameter] = true
    setInteractedWithRows([
      ...interactedWithRows.filter((element) => element.rowKey !== rowKey),
      newInteractedWithRowState,
    ])
  }

  const isBeingEdited = (rowKey) => isEmpty(findEditRow(rowKey))

  const updateEditRow = (rowKey, parameter, value) => {
    if (isBeingEdited(rowKey)) {
      const newEditRow = getNewEditRow({ rowKey, parameter, value })
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const isItemValid = (item, rowKey, fieldName) => {
    const isNewRow = isNaN(rowKey)

    if (isNewRow) {
      return !!findEditRow(rowKey)[fieldName]
    } else {
      const wasValid = !!item[fieldName]
      const hasNotChanged = isNil(findEditRow(rowKey)[fieldName])

      const row = findEditRow(rowKey)[fieldName]

      let hasChangedToValid
      if (fieldName === 'amount') {
        hasChangedToValid = row !== '' && row > 0
      } else {
        hasChangedToValid = row !== ''
      }

      return (hasNotChanged && wasValid) || hasChangedToValid
    }
  }

  const areRequiredFieldsValid = (item, rowKey) => {
    const isNewRow = isNaN(rowKey)

    if (isNewRow) {
      let changedRow = findEditRow(rowKey)

      if (isEmpty(changedRow)) {
        return false
      }

      changedRow = {
        financingSourcesItemTypeCode:
          otherFinancingSourceData[rowKey]?.financingSourcesItemTypeCode,
        amount: otherFinancingSourceData[rowKey]?.amount,
        currency: otherFinancingSourceData[rowKey]?.currency,
        ...changedRow,
      }

      return (
        !!changedRow?.financingSourcesItemTypeCode && !!changedRow?.amount && !!changedRow?.currency
      )
    } else {
      return (
        isItemValid(item, rowKey, 'financingSourcesItemTypeCode') &&
        isItemValid(item, rowKey, 'amount') &&
        isItemValid(item, rowKey, 'currency')
      )
    }
  }

  const handleSaveRow = (rowKey) => {
    setIsWritingToBackend(true)
    setRowKeyForRequest(rowKey)
    const originalRow = otherFinancingSourceData?.items[rowKey]
    const changedRow = findEditRow(rowKey)
    const itemId = originalRow?.id

    const mappedFields = {
      financingSourcesItemTypeCode:
        changedRow?.financingSourcesItemTypeCode ?? originalRow?.financingSourcesItemTypeCode,
      amount: changedRow?.amount ?? originalRow?.amount.amount,
      currency: changedRow?.currency ?? originalRow?.amount.currency,
      creditor: changedRow?.creditor ?? originalRow?.creditor,
      rank: changedRow?.rank ?? originalRow?.rank,
      description: changedRow?.description ?? originalRow?.description,
    }

    createOrUpdateOtherFinancingSource.mutate({
      dealId: deal.dealUuid,
      itemId,
      ...mappedFields,
      rowKey: rowKey, // needed for invalidation on successful save
    })
  }

  const onCloseItemDeleteErrorDialog = (event) => {
    setIsItemDeleteErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleDeleteRow(rowKeyForRequest)
    }
  }

  const onCloseItemCreateOrUpdateErrorDialog = (event) => {
    setIsItemCreateOrUpdateErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleSaveRow(rowKeyForRequest)
    }
  }

  const renderInputField = (item, rowKey, fieldName) => (
    <Input
      id={`${fieldName}Input`}
      value={item[fieldName]}
      className={styles.input}
      onChange={(event) => {
        updateEditRow(rowKey, fieldName, event.target.value)
      }}
    />
  )

  const renderAmountInputWithCurrencyDropdownField = (item, rowKey) => (
    <FlexBox className={styles.amountInputCurrencyDropdownWrapper}>
      <AmountInput
        rowKey={rowKey}
        className={styles.amountInput}
        value={item?.amount?.amount}
        isValid={isItemValid(item, rowKey, 'amount')}
        handleOnChange={(event) => {
          updateEditRow(rowKey, 'amount', event.newValue)
        }}
        setInteractedWithRowState={setInteractedWithRowState}
      />
      <CurrencyInputSelect
        rowKey={rowKey}
        selectedKey={item?.amount?.currency}
        isValid={isItemValid(item, rowKey, 'currency')}
        handleOnChange={(event) => {
          updateEditRow(rowKey, 'currency', event.newValue)
        }}
        setInteractedWithRowState={setInteractedWithRowState}
      />
    </FlexBox>
  )

  const renderTypeOptions = (item, rowKey) => (
    <TypeInputSelect
      rowKey={rowKey}
      selectedKey={item?.financingSourcesItemTypeCode}
      isValid={isItemValid(item, rowKey, 'financingSourcesItemTypeCode')}
      handleOnChange={(event) => {
        updateEditRow(rowKey, 'financingSourcesItemTypeCode', event.newValue)
      }}
      setInteractedWithRowState={setInteractedWithRowState}
    />
  )

  const renderDescriptionTextArea = (item, rowKey) => (
    <TextArea
      id={'descriptionInput'}
      rows={3}
      value={item?.description}
      className={styles.descriptionInput}
      maxlength={255}
      onChange={(event) => {
        updateEditRow(rowKey, 'description', event.target._state.value)
      }}
    />
  )

  const renderRankInputField = (item, rowKey) => (
    <RankInput
      id="otherFinancingSourcesRankInput"
      rowKey={rowKey}
      value={item?.rank}
      handleOnChange={(event) => {
        updateEditRow(rowKey, 'rank', event.newValue)
      }}
      setInteractedWithRowState={setInteractedWithRowState}
    />
  )

  const renderAmountWithCurrency = (item) => {
    const amount = item?.amount?.amount
    const amountHeadquarter = item?.amountHeadquarter?.amount
    const currency = item?.amount?.currency
    const currencyHeadquarter = item?.amountHeadquarter?.currency

    return (
      <FlexBox direction={FlexBoxDirection.Column}>
        <span>{formatCurrency(amount, currency, { currencyDisplay: 'code' })}</span>
        {amountHeadquarter && item?.amount?.currency !== item?.amountHeadquarter?.currency && (
          <span className={styles.headquarterText} data-testid="amountHeadquarter">
            {formatCurrency(amountHeadquarter, currencyHeadquarter, { currencyDisplay: 'code' })}
          </span>
        )}
      </FlexBox>
    )
  }

  const initialOtherFinancingSources = {
    financingSourcesItemTypeCode: undefined,
    amount: {
      amount: undefined,
      currency: undefined,
    },
    amountHeadquarter: {
      amount: undefined,
      currency: undefined,
    },
    creditor: undefined,
    rank: undefined,
    description: undefined,
    lastUpdated: {
      name: undefined,
      lastUpdatedOn: undefined,
    },
  }

  const columnDefinitions = [
    {
      columnKey: 'financingSourcesItem',
      title: t('type'),
      alignment: 'Start',
      style: styles.typeColumn,
      minWidth: 160,
      hasPopin: false,
    },
    {
      columnKey: 'rank',
      title: t('rank'),
      alignment: 'End',
      style: styles.rankColumn,
      minWidth: 40,
      hasPopin: true,
    },
    {
      columnKey: 'creditor',
      title: t('creditor'),
      alignment: 'Start',
      style: styles.creditorColumn,
      minWidth: 110,
      hasPopin: true,
    },
    {
      columnKey: 'amount',
      title: t('amount'),
      alignment: 'End',
      style: styles.amountColumn,
      minWidth: 110,
      hasPopin: true,
    },
    {
      columnKey: 'description',
      title: t('description'),
      alignment: 'Start',
      style: styles.descriptionColumn,
      minWidth: 200,
      hasPopin: true,
    },
  ]

  const tableData = (items) =>
    items
      ?.sort((a, b) => a.rank - b.rank)
      .map((item, index) => {
        const rowKey = String(index)
        return {
          rowKey: String(index),
          isValid: areRequiredFieldsValid(item, rowKey),
          rank: {
            cellContentReadMode: item.rank,
            cellReadModeProps: {
              className: styles.verticalAlignTop,
            },
            cellContentEditMode: renderRankInputField(item, rowKey),
            cellEditModeProps: {
              className: styles.verticalAlignTop,
            },
          },
          financingSourcesItem: {
            cellContentReadMode: item.financingSourcesItemTypeShortText,
            cellReadModeProps: {
              className: styles.verticalAlignTop,
            },
            cellContentEditMode: renderTypeOptions(item, rowKey),
            cellEditModeProps: {
              className: styles.verticalAlignTop,
            },
          },
          creditor: {
            cellContentReadMode: item.creditor,
            cellReadModeProps: {
              className: styles.verticalAlignTop,
            },
            cellContentEditMode: renderInputField(item, rowKey, 'creditor'),
            cellEditModeProps: {
              className: styles.verticalAlignTop,
            },
          },
          amount: {
            cellContentReadMode: renderAmountWithCurrency(item),
            cellReadModeProps: {
              className: styles.verticalAlignTop,
            },
            cellContentEditMode: renderAmountInputWithCurrencyDropdownField(item, rowKey),
            cellEditModeProps: {
              className: styles.verticalAlignTop,
            },
          },
          description: {
            cellContentReadMode: item.description,
            cellReadModeProps: {
              className: styles.verticalAlignTop,
            },
            cellContentEditMode: renderDescriptionTextArea(item, rowKey),
            cellEditModeProps: {
              className: styles.verticalAlignTop,
            },
          },
        }
      })

  const addNewDataRow = () => {
    const rowKey = rowKeyNewRow
    return {
      rowKey: rowKeyNewRow,
      isValid: areRequiredFieldsValid(initialOtherFinancingSources, rowKey),
      rank: {
        cellContentEditMode: renderRankInputField(initialOtherFinancingSources, rowKey),
        cellEditModeProps: {
          className: styles.verticalAlignTop,
        },
      },
      financingSourcesItem: {
        cellContentEditMode: renderTypeOptions(initialOtherFinancingSources, rowKey),
        cellEditModeProps: {
          className: styles.verticalAlignTop,
        },
      },
      creditor: {
        cellContentEditMode: renderInputField(initialOtherFinancingSources, rowKey, 'creditor'),
        cellEditModeProps: {
          className: styles.verticalAlignTop,
        },
      },
      amount: {
        cellContentEditMode: renderAmountInputWithCurrencyDropdownField(
          initialOtherFinancingSources,
          rowKey,
        ),
        cellEditModeProps: {
          className: styles.verticalAlignTop,
        },
      },
      description: {
        cellContentEditMode: renderDescriptionTextArea(initialOtherFinancingSources, rowKey),
        cellEditModeProps: {
          className: styles.verticalAlignTop,
        },
      },
    }
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={t('title')}
        nrOfEntries={otherFinancingSourceData?.items?.length ?? 0}
        subTitle={otherFinancingSourceData?.items?.length ? lastEditedText : null}
        columnDefinitions={columnDefinitions}
        tableData={tableData(otherFinancingSourceData?.items) ?? []}
        isLoading={!!isLoadingOtherFinancingSourceData || !!isFetchingOtherFinancingSourceData}
        isError={isErrorOtherFinancingSourceData}
        userIsAllowedToEdit={allowedOperations.includes(FINANCING_OTHER_FINANCING_SOURCES_UPDATE)}
        isWritingToBackend={isWritingToBackend}
        newRow={addNewDataRow()}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={handleSaveRow}
        handleCancelEditRow={handleCancelEditRow}
      />
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Error}
          open={isItemCreateOrUpdateErrorDialogOpen}
          onClose={onCloseItemCreateOrUpdateErrorDialog}
          actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
          emphasizedAction={MessageBoxActions.Retry}
          id={'item-create-or-update-error-dialog'}
          titleText={t('error-dialog.title')}
          className={styles.errorDialog}
        >
          <div className={styles.errorDialogDescription}>
            {t('error-dialog.description', {
              returnObjects: true,
            }).map((element) => (
              <div key={element}>{element}</div>
            ))}
          </div>
        </MessageBox>,
        document.body,
      )}
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Error}
          open={isItemDeleteErrorDialogOpen}
          onClose={onCloseItemDeleteErrorDialog}
          actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
          emphasizedAction={MessageBoxActions.Retry}
          id={'item-delete-error-dialog'}
          titleText={t('error-dialog.title')}
          className={styles.errorDialog}
        >
          <div className={styles.errorDialogDescription}>
            {t('error-dialog.description', {
              returnObjects: true,
            }).map((element) => (
              <div key={element}>{element}</div>
            ))}
          </div>
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

OtherFinancingSourcesCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
    financingAllowedOperations: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default OtherFinancingSourcesCard
