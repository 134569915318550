import { TextArea } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import styles from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsTextInput.module.css'
const CustomerOptionsTextInput = ({ handleOnChange, value, valueState, valueStateMessage }) => {
  const textAreaRef = useRef(null)

  const handleInputUpdate = (event) => {
    event.stopPropagation()
    const newValue = event.target.value
    handleOnChange({ value: newValue, isValid: true })
  }

  // Fixes error "Cannot read properties of null (reading 'querySelector')" from ui5's TextArea._onfocusout
  // In this case no, shadow-root is available so undefined is used as fallback instead and checked by lodash.isNil
  // Otherwise, functionality is the same.
  useEffect(() => {
    if (typeof textAreaRef.current._onfocusout === 'function') {
      textAreaRef.current._onfocusout = (e) => {
        const eTarget = e?.relatedTarget
        const focusedOutToValueStateMessage =
          eTarget?.shadowRoot?.querySelector('.ui5-valuestatemessage-root') ?? undefined
        textAreaRef.current.focused = false
        if (!isNil(focusedOutToValueStateMessage)) {
          textAreaRef.current._openValueStateMsgPopover = false
        }
      }
    }
  }, [textAreaRef?.current?._onfocusout])

  return (
    <TextArea
      ref={textAreaRef}
      className={styles.textArea}
      onBlur={handleInputUpdate}
      value={value}
      maxlength={255}
      valueState={valueState}
      valueStateMessage={valueStateMessage}
      onKeyDown={(e) => e.stopPropagation()} // To prevent issue with events fired get hijacked by parent when using React Portals
    />
  )
}

CustomerOptionsTextInput.propTypes = {
  value: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  valueState: PropTypes.string,
  valueStateMessage: PropTypes.string,
}

export default CustomerOptionsTextInput
