import {
  ROW_TYPE_CONDITION,
  TABLE_ROW_HEIGHT,
} from 'routes/deals/financing/non-loan-products/other-lending/constants'

const mapOtherLendingConditionsData = (conditions = []) =>
  conditions.map((condition) => {
    const {
      conditionId,
      conditionType,
      counterpartyId,
      amortization,
      startDate,
      maturityDate,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      payOrReceive,
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      endOfFixedInterestPeriod,
    } = condition ?? {}

    return {
      tradeCondition: {
        id: conditionId,
      },
      productSubType: conditionType,
      counterparty: counterpartyId,
      amortization,
      startDate,
      maturityDate,
      payOrReceive,
      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      spread,
      currentRate,
      endOfFixedInterestPeriod,
      rowType: ROW_TYPE_CONDITION,
      rowHeight: TABLE_ROW_HEIGHT,
    }
  })

export default mapOtherLendingConditionsData
