import { t } from 'i18next'

const mapErrorCodes = (errorCode) => {
  switch (errorCode) {
    case 'SYNDICATION_COUNTERPARTY_SHARE_COMMITMENT_TOO_HIGH':
    case 'TRANCHE_TOTAL_COMMITMENT_TOO_LOW':
      return t('pages.deals.financing.error.own-share')
    case 'TRANCHE_EXISTS_IN_CML':
      return t(
        'pages.deals.role.tranches-table.write-basic-agreement.error.tranche-already-exists-in-cml',
      )
  }
}

export default mapErrorCodes
