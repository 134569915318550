import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FINANCING_CUSTOM_FIELDS_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import useUpdateCustomerSpecificAttributes from 'hooks/services/deals/financing/customer-specific-attributes/useUpdateCustomerSpecificAttributes'
import { DealContext } from 'routes/deals/DealContext'
import useCustomerSpecificAttributesCardFieldDefinitions from 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/fields/useCustomerSpecificAttributesCardFieldDefinitions'
import useMapCustomerSpecificAttributeChanges from 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/useMapCustomerSpecificAttributeChanges'
import useDataSourceQueryParams from 'routes/deals/financing/utils/useDataSourceQueryParams'

const CustomerSpecificAttributesCard = ({
  customerSpecificAttributesData,
  lastEditedText,
  title,
  trancheId,
  dealId,
  isFetching,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const { isExistingBusinessViewSet } = useDataSourceQueryParams()
  const showToast = Modals.useShowToast()
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const editCustomerSpecificAttributes = useUpdateCustomerSpecificAttributes({
    onSuccess: () => {
      setIsWritingToBackend(false)
      showToast({ children: t('toast.changes-saved') })
      queryClient.invalidateQueries(['deals', dealId, 'tranches', trancheId, 'custom-fields'])
    },
  })

  const fieldDefinitions = useCustomerSpecificAttributesCardFieldDefinitions(
    customerSpecificAttributesData,
  )

  const mapChangesToBackend = useMapCustomerSpecificAttributeChanges()

  const saveChanges = (newValues) => {
    setIsWritingToBackend(true)
    const mappedValues = mapChangesToBackend(newValues, customerSpecificAttributesData.customFields)

    editCustomerSpecificAttributes.mutate({
      dealId,
      trancheId,
      body: mappedValues,
    })
  }

  return (
    <DisplayAndEditCard
      cardHeaderTitle={title}
      cardHeaderSubtitle={lastEditedText}
      fieldDefinitions={fieldDefinitions}
      additionalEditHeaderProps={{ isSaveLoading: isWritingToBackend }}
      // Overwrites the loading state used for the LoadingStateWrapper after save has been clicked.
      // The above `isSaveLoading` is used instead to set an inert card state
      isHandlingSaveOverwrite={false}
      // we only pass the fetching state as the data for this card is delivered by a prop
      // and the card itself is already wrapped in a LoadingStateWrapper for the initial loading
      isLoading={!!isFetching}
      isEmpty={false}
      isEditable={
        !isExistingBusinessViewSet && allowedOperations.includes(FINANCING_CUSTOM_FIELDS_UPDATE)
      }
      fillEmptyValuesWithPlaceholder={true}
      saveChanges={saveChanges}
      saveHookIsSuccess={editCustomerSpecificAttributes?.isSuccess}
      saveHookIsError={editCustomerSpecificAttributes?.isError}
    />
  )
}

CustomerSpecificAttributesCard.propTypes = {
  lastEditedText: PropTypes.string,
  title: PropTypes.string.isRequired,
  customerSpecificAttributesData: PropTypes.object.isRequired,
  trancheId: PropTypes.string,
  dealId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
}

export default CustomerSpecificAttributesCard
