import {
  DatePicker,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
  TextArea,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import find from 'lodash.find'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import uniq from 'lodash.uniq'
import { useContext, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { FINANCING_TRANCHE_FEE_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { useFeeTypeCodes } from 'hooks/services/deals/financing/configGetters/useFeeTypeCodes'
import { usePaymentFrequencyTypeCodes } from 'hooks/services/deals/financing/configGetters/usePaymentFrequencyTypeCodes'
import { useCreateOrUpdateTrancheFee } from 'hooks/services/deals/financing/useCreateOrUpdateTrancheFee'
import useDeleteTrancheFee from 'hooks/services/deals/financing/useDeleteTrancheFee'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import { useTrancheFees } from 'hooks/services/deals/financing/useTrancheFees'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/trancheDetails/cards/tranche-fees/TrancheFeesCard.module.css'
import FeeTypeCodeSelect from 'routes/deals/financing/trancheDetails/cards/tranche-fees/fields/FeeTypeCodeSelect'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'
import isShareValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isShareValid'

const TrancheFeesCardTable = () => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.fees-card',
  })
  const { format: formatDate, parse: parseDate, localePattern } = useShortDateFormatter()

  const [invalidFormState, setInvalidFormState] = useState([])

  const totalAmountInputRef = useRef()
  const shareInputRef = useRef()

  const queryClient = useQueryClient()

  const [searchParams] = useSearchParams()
  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  const { trancheDisplayId } = useParams()
  const {
    data: resolvedTrancheData,
    isLoading: isLoadingDisplayNameResolution,
    isError: isErrorDisplayNameResolution,
    isFetching: isFetchingDisplayNameResolution,
  } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)

  const {
    data: trancheFeesData,
    isLoading: isLoadingTrancheFees,
    isError: isErrorTrancheFees,
    isFetching: isFetchingTrancheFees,
  } = useTrancheFees(
    deal.dealUuid,
    isExistingBusinessViewSet ? trancheDisplayId : resolvedTrancheData.trancheId,
  )

  const formatMoney = useCustomizableCurrencyFormatter()
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const { lastEditedText } = useLastEditedTextByEmail({
    email: trancheFeesData?.lastUpdated?.name,
    timestamp: trancheFeesData?.lastUpdated?.lastUpdatedOn,
  })

  const {
    data: { feeTypeCodes },
  } = useFeeTypeCodes()

  const isTotalAndSharedDependent = (feeTypeCode) =>
    find(feeTypeCodes, ['key', feeTypeCode])?.isTotalAndSharedDependent ?? false

  const userIsAllowedToEdit =
    !isExistingBusinessViewSet && allowedOperations.includes(FINANCING_TRANCHE_FEE_UPDATE)

  const handleFormState = (isValid, parameter) => {
    if (!isValid) {
      setInvalidFormState((oldState) => uniq([...oldState, parameter]))
    } else {
      setInvalidFormState((oldState) => oldState.filter((element) => element !== parameter))
    }
  }

  const initialTrancheFee = {
    amountShare: undefined,
    amountTotal: {
      amount: undefined,
      currency: resolvedTrancheData?.totalCommitment?.currency,
    },
    comment: undefined,

    isTotalAndSharedDependent: true,
    feeTypeCode: undefined,
    feeTypeShortText: undefined,
    paymentFrequencyType: undefined,
    firstPaymentDate: undefined,
    lastUpdated: {
      name: undefined,
      lastUpdatedOn: undefined,
    },
  }

  const [editRows, setEditRows] = useState([])
  const [interactedWithRows, setInteractedWithRows] = useState([])
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const [isFeeDeleteErrorDialogOpen, setIsFeeDeleteErrorDialogOpen] = useState(false)
  const [isFeeCreateOrUpdateErrorDialogOpen, setIsFeeCreateOrUpdateErrorDialogOpen] =
    useState(false)

  // Stores the row key of the last open (!= successful) request to reuse it on retry
  const [rowKeyForRequest, setRowKeyForRequest] = useState(undefined)

  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })
  const findInteractedWithRow = (rowKey) => ({
    ...interactedWithRows.find((interactedWithRow) => interactedWithRow.rowKey === rowKey),
  })

  const invalidateFees = () => {
    queryClient.invalidateQueries([
      'deals',
      deal.dealUuid,
      'tranches',
      resolvedTrancheData.trancheId,
      'fees',
    ])
  }

  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
  }

  const removeInteractedStateFromInteractedRows = (rowKey) => {
    setInteractedWithRows([
      ...interactedWithRows.filter((interactedRow) => interactedRow.rowKey !== rowKey),
    ])
  }

  const deleteTrancheFees = useDeleteTrancheFee({
    onSuccess: () => {
      setIsWritingToBackend(false)
      Modals.showToast({
        children: <Text>{t('fee-delete.delete-fee-successful')}</Text>,
      })
      invalidateFees()
      setRowKeyForRequest(undefined)
    },
    onError: () => {
      setIsWritingToBackend(false)
      setIsFeeDeleteErrorDialogOpen(true)
    },
  })

  const handleDeleteRow = (rowKey) => {
    setIsWritingToBackend(true)
    setRowKeyForRequest(rowKey)
    const feeId = trancheFeesData?.fees[rowKey]?.id
    deleteTrancheFees.mutate({
      dealId: deal.dealUuid,
      trancheId: resolvedTrancheData.trancheId,
      feeId,
    })
  }

  const onCloseFeeDeleteErrorDialog = (event) => {
    setIsFeeDeleteErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleDeleteRow(rowKeyForRequest)
    }
  }

  const createOrUpdateTrancheFees = useCreateOrUpdateTrancheFee({
    onSuccess: (data, params) => {
      setIsWritingToBackend(false)
      Modals.showToast({
        children: <Text>{t('fee-create-or-update.create-or-update-fee-successful')}</Text>,
      })
      invalidateFees()
      removeChangeFromEditRows(params.rowKey)
      removeInteractedStateFromInteractedRows(params.rowKey)
      setRowKeyForRequest(undefined)
    },
    onError: () => {
      setIsWritingToBackend(false)
      setIsFeeCreateOrUpdateErrorDialogOpen(true)
    },
  })

  const createBodyForCreateOrUpdateTrancheFees = (originalRow, changedRow) => {
    const convertAmountShare = (original, updated) => {
      if (
        updated?.feeTypeCode
          ? isTotalAndSharedDependent(updated.feeTypeCode)
          : isTotalAndSharedDependent(original?.feeTypeCode)
      ) {
        return undefined
      }
      if (!isNil(updated?.amountShare)) {
        // share is a number between 0-1, to prevent floating point errors, we round to 4
        // eslint-disable-next-line no-magic-numbers
        return updated.amountShare !== '' ? +(updated.amountShare / 100).toFixed(4) : undefined
      }
      return original?.amountShare
    }

    const convertFirstPaymentDate = (original, updated) => {
      if (!isNil(changedRow?.firstPaymentDate)) {
        return changedRow?.firstPaymentDate
          ? parseDate(updated.firstPaymentDate, localePattern)
          : undefined
      } else {
        return original?.firstPaymentDate
      }
    }

    return {
      feeTypeCode: changedRow?.feeTypeCode ?? originalRow?.feeTypeCode,
      firstPaymentDate: convertFirstPaymentDate(originalRow, changedRow),
      paymentFrequencyType: changedRow?.paymentFrequencyType ?? originalRow?.paymentFrequencyType,
      amountTotal: +(changedRow?.amountTotal ?? originalRow?.amountTotal?.amount) || undefined,
      amountShare: convertAmountShare(originalRow, changedRow),
      comment: changedRow?.comment ?? originalRow?.comment,
    }
  }

  const handleSaveRow = (rowKey) => {
    setIsWritingToBackend(true)
    setRowKeyForRequest(rowKey)
    const originalRow = trancheFeesData?.fees[rowKey]
    const changedRow = findEditRow(rowKey)
    const feeId = originalRow?.id
    const rowToSave = createBodyForCreateOrUpdateTrancheFees(originalRow, changedRow)
    createOrUpdateTrancheFees.mutate({
      ...rowToSave,
      dealId: deal.dealUuid,
      trancheId: resolvedTrancheData.trancheId,
      feeId: feeId,
      rowKey: rowKey, // only needed for onSuccess handler
    })
  }

  const handleCancelEditRow = (rowKey) => {
    removeChangeFromEditRows(rowKey)
    removeInteractedStateFromInteractedRows(rowKey)
  }

  const onCloseFeeCreateOrUpdateErrorDialog = (event) => {
    setIsFeeCreateOrUpdateErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleSaveRow(rowKeyForRequest)
    }
  }

  const getNewEditRow = ({ rowKey, parameter, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[parameter] = value
    return newRow
  }

  const setInteractedWithRowState = (rowKey, parameter) => {
    const oldInteractedWithRowState = findInteractedWithRow(rowKey)
    const newInteractedWithRowState = isEmpty(oldInteractedWithRowState)
      ? { rowKey: rowKey }
      : { ...oldInteractedWithRowState }
    newInteractedWithRowState[parameter] = true
    setInteractedWithRows([
      ...interactedWithRows.filter((element) => element.rowKey !== rowKey),
      newInteractedWithRowState,
    ])
  }

  const isBeingEdited = (rowKey) => isEmpty(findEditRow(rowKey))

  const updateEditRow = (rowKey, parameter, value) => {
    if (isBeingEdited(rowKey)) {
      const newEditRow = getNewEditRow({ rowKey, parameter, value })
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const bulkUpdateEditRow = (rowKey, parameterValuePairs) => {
    if (isBeingEdited(rowKey)) {
      const newEditRows = parameterValuePairs.map(({ parameter, value }) =>
        getNewEditRow({ rowKey, parameter, value }),
      )
      const newEditRow = newEditRows.reduce((result, current) => Object.assign(result, current), {})
      setEditRows([...editRows, newEditRow])
    } else {
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        const newEntries = parameterValuePairs.reduce(
          (result, { parameter, value }) => Object.assign(result, { [parameter]: value }),
          {},
        )
        return { ...oldRow, ...newEntries }
      })
      setEditRows([...editRowsUpdated])
    }
  }

  const isFeeTypeCodeValid = (trancheFee, rowKey) => {
    const isNewRow = isNaN(rowKey)
    if (isNewRow) {
      return !!findEditRow(rowKey).feeTypeCode
    } else {
      const wasValid = !!trancheFee.feeTypeCode
      const hasNotChanged = isNil(findEditRow(rowKey).feeTypeCode)
      const hasChangedToValid = findEditRow(rowKey).feeTypeCode !== ''
      return (hasNotChanged && wasValid) || hasChangedToValid
    }
  }

  const isAmountPerIntervalValid = () => !invalidFormState.includes('amountTotal')

  const isRateValid = () => !invalidFormState.includes('amountShare')

  const renderFeeTypeEditOptions = (trancheFee, rowKey) => (
    <FeeTypeCodeSelect
      trancheFee={trancheFee}
      rowKey={rowKey}
      bulkUpdateEditRow={bulkUpdateEditRow}
      setInteractedWithRowState={setInteractedWithRowState}
      isValid={isFeeTypeCodeValid(trancheFee, rowKey)}
    />
  )

  const renderPaymentFrequencyEditOptions = (trancheFee, rowKey) => (
    <FlexBox>
      <LoadingSelect
        id="paymentFrequencyTypeCodesLoadingSelect"
        className={styles.frequencyInput}
        loadingHook={usePaymentFrequencyTypeCodes}
        selectionName="paymentFrequencyTypeCodes"
        optionKeyName="key"
        optionDisplayName="display_name"
        selectedKey={trancheFee.paymentFrequencyType}
        onChange={(event) => {
          updateEditRow(rowKey, 'paymentFrequencyType', event.detail.selectedOption._state.value)
        }}
      />
    </FlexBox>
  )

  const renderTotalAmountView = (trancheFee) => (
    <FlexBox direction={FlexBoxDirection.Column} alignItems={FlexBoxAlignItems.End}>
      <Text className={styles.paddingTop}>
        {formatMoney(trancheFee.amountTotal?.amount, trancheFee.amountTotal?.currency, {
          currencyDisplay: 'code',
        })}
      </Text>
      {trancheFee?.amountTotalHeadquarter?.currency !== trancheFee?.amountTotal?.currency && (
        <div className={styles.subInfo} data-testid="fees-amountTotalHeadquarter">
          {formatMoney(
            trancheFee.amountTotalHeadquarter?.amount,
            trancheFee.amountTotalHeadquarter?.currency,
            {
              currencyDisplay: 'code',
            },
          )}
        </div>
      )}
    </FlexBox>
  )

  const updateTotalAmount = (trancheFee, rowKey, value, isInternallyValidInput) => {
    const currentFee = { ...trancheFee, ...findEditRow(rowKey) }
    const feeTotalAmount = value

    handleFormState(isAmountValid(feeTotalAmount) && isInternallyValidInput, 'amountTotal')

    if (isTotalAndSharedDependent(currentFee.feeTypeCode) && isInternallyValidInput) {
      const trancheTotalAmount = resolvedTrancheData.totalCommitment.amount
      const feeShare =
        !isNil(feeTotalAmount) && feeTotalAmount !== ''
          ? ((feeTotalAmount / trancheTotalAmount) * 100).toFixed(2)
          : ''
      bulkUpdateEditRow(rowKey, [
        { parameter: 'amountShare', value: convertToNumberIfValid(feeShare) },
        { parameter: 'amountTotal', value: feeTotalAmount },
      ])
      handleFormState(isShareValid(feeShare), 'amountShare')
    } else {
      updateEditRow(rowKey, 'amountTotal', feeTotalAmount)
    }
  }

  const renderTotalAmountEditMode = (trancheFee, rowKey) => {
    const editedAmount = findEditRow(rowKey)?.amountTotal
    const amountTotal = !isNil(editedAmount) ? editedAmount : trancheFee.amountTotal?.amount
    const trancheCurrency = resolvedTrancheData?.totalCommitment?.currency
    const trancheFeeCurrency = trancheFee.amountTotal?.currency
    return (
      <FormattedNumberInput
        value={convertToNumberIfValid(amountTotal)}
        valueState={isAmountValid(amountTotal) ? ValueState.None : ValueState.Error}
        valueStateMessage={<span>{t('hint.amount-total')}</span>}
        className={styles.totalAmountInput}
        icon={
          <Text className={styles.inputPaddingRight}>{trancheFeeCurrency ?? trancheCurrency}</Text>
        }
        onInput={(number) => {
          const isValidInput = totalAmountInputRef?.current?.isValid
          updateTotalAmount(trancheFee, rowKey, convertToNumberIfValid(number) ?? '', isValidInput)
        }}
        ref={totalAmountInputRef}
      />
    )
  }

  const updateShare = (trancheFee, rowKey, value, isInternallyValidInput) => {
    const currentFee = { ...trancheFee, ...findEditRow(rowKey) }
    const feeShare = value

    handleFormState(isShareValid(feeShare) && isInternallyValidInput, 'amountShare')
    if (isTotalAndSharedDependent(currentFee.feeTypeCode) && isInternallyValidInput) {
      const trancheTotalAmount = resolvedTrancheData.totalCommitment.amount
      const feeTotalAmount =
        !isNil(feeShare) && feeShare !== ''
          ? ((feeShare * trancheTotalAmount) / 100).toFixed(2)
          : ''
      bulkUpdateEditRow(rowKey, [
        { parameter: 'amountShare', value: feeShare },
        { parameter: 'amountTotal', value: convertToNumberIfValid(feeTotalAmount) },
      ])
      handleFormState(isAmountValid(feeTotalAmount), 'amountTotal')
    } else {
      updateEditRow(rowKey, 'amountShare', feeShare)
    }
  }

  const renderShareEditMode = (trancheFee, rowKey) => {
    const editedShare = findEditRow(rowKey)?.amountShare
    let share
    if (!isNil(editedShare)) {
      share = editedShare
    } else if (!isNil(trancheFee.amountShare)) {
      share = (trancheFee.amountShare * 100).toFixed(2)
    } else {
      share = undefined
    }
    return (
      <FormattedNumberInput
        className={styles.shareInput}
        value={convertToNumberIfValid(share)}
        valueState={isShareValid(share) ? ValueState.None : ValueState.Error}
        valueStateMessage={<span>{t('hint.amount-share')}</span>}
        icon={<Text className={styles.inputPaddingRight}>{'%'}</Text>}
        onInput={(number) => {
          const isValidInput = shareInputRef?.current?.isValid
          updateShare(trancheFee, rowKey, convertToNumberIfValid(number) ?? '', isValidInput)
        }}
        ref={shareInputRef}
      />
    )
  }

  const renderDateInput = (trancheFee, rowKey) => (
    <DatePicker
      className={styles.datePickerInput}
      value={trancheFee.firstPaymentDate}
      formatPattern={localePattern}
      onChange={(event) => {
        updateEditRow(rowKey, 'firstPaymentDate', event.detail.value)
      }}
    />
  )

  const renderCommentTextArea = (trancheFee, rowKey) => (
    <TextArea
      rows={3}
      value={trancheFee.comment}
      className={styles.commentInput}
      onChange={(event) => {
        updateEditRow(rowKey, 'comment', event.target._state.value)
      }}
    />
  )

  const columnDefinitions = [
    {
      columnKey: 'fee-type-code',
      title: t('fee-type'),
      style: styles.feeColumn,
      minWidth: 180,
      hasPopin: false,
    },
    {
      columnKey: 'first-payment-date',
      title: t('first-payment-date'),
      alignment: 'End',
      style: styles.firstPaymentDateColumn,
      minWidth: 180,
      hasPopin: true,
    },
    {
      columnKey: 'frequency',
      title: t('frequency'),
      alignment: 'Start',
      style: styles.frequencyColumn,
      minWidth: 140,
      hasPopin: true,
    },
    {
      columnKey: 'amount-total',
      title: t('amount-total'),
      alignment: 'End',
      style: styles.totalAmountColumn,
      minWidth: 150,
      hasPopin: true,
    },
    {
      columnKey: 'amount-share',
      title: t('amount-share'),
      alignment: 'End',
      style: styles.shareColumn,
      minWidth: 130,
      hasPopin: true,
    },
    {
      columnKey: 'comment',
      title: t('comment'),
      alignment: 'Start',
      style: styles.commentColumn,
      minWidth: 200,
      hasPopin: true,
    },
  ]

  const tableData = (trancheFees) =>
    trancheFees
      ?.sort((a, b) => {
        const dateA = new Date(a?.firstPaymentDate)
        const dateB = new Date(b?.firstPaymentDate)
        return dateA - dateB
      })
      ?.map((trancheFee, index) => {
        const rowKey = String(index)
        return {
          rowKey: String(index),
          isValid:
            isFeeTypeCodeValid(trancheFee, rowKey) && isAmountPerIntervalValid() && isRateValid(),
          'fee-type-code': {
            cellContentReadMode: trancheFee.feeTypeShortText,
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderFeeTypeEditOptions(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
          'first-payment-date': {
            cellContentReadMode: formatDate(trancheFee.firstPaymentDate),
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderDateInput(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
          frequency: {
            cellContentReadMode: trancheFee.paymentFrequencyTypeShortText,
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderPaymentFrequencyEditOptions(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
          'amount-total': {
            cellContentReadMode: renderTotalAmountView(trancheFee),
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderTotalAmountEditMode(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
          'amount-share': {
            cellContentReadMode: `${formatShare(trancheFee.amountShare)}`,
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderShareEditMode(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
          comment: {
            cellContentReadMode: trancheFee.comment,
            cellReadModeProps: {
              className: styles.vAlignTop,
            },
            cellContentEditMode: renderCommentTextArea(trancheFee, rowKey),
            cellEditModeProps: {
              className: styles.vAlignTop,
            },
          },
        }
      })

  const addNewDataRow = () => {
    const rowKey = rowKeyNewRow
    return {
      rowKey: rowKeyNewRow,
      isValid:
        isFeeTypeCodeValid(initialTrancheFee, rowKey) &&
        isAmountPerIntervalValid() &&
        isRateValid(),
      'fee-type-code': {
        cellContentEditMode: renderFeeTypeEditOptions(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
      'first-payment-date': {
        cellContentEditMode: renderDateInput(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
      frequency: {
        cellContentEditMode: renderPaymentFrequencyEditOptions(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
      'amount-total': {
        cellContentEditMode: renderTotalAmountEditMode(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
      'amount-share': {
        cellContentEditMode: renderShareEditMode(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
      comment: {
        cellContentEditMode: renderCommentTextArea(initialTrancheFee, rowKey),
        cellEditModeProps: {
          className: styles.vAlignTop,
        },
      },
    }
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={t('title')}
        nrOfEntries={tableData(trancheFeesData?.fees)?.length ?? 0}
        subTitle={trancheFeesData?.fees?.length ? lastEditedText : null}
        columnDefinitions={columnDefinitions}
        tableData={tableData(trancheFeesData?.fees) ?? []}
        isLoading={
          !!isLoadingDisplayNameResolution ||
          !!isLoadingTrancheFees ||
          !!isFetchingDisplayNameResolution ||
          !!isFetchingTrancheFees
        }
        isError={isErrorDisplayNameResolution || isErrorTrancheFees}
        isWritingToBackend={isWritingToBackend}
        userIsAllowedToEdit={userIsAllowedToEdit}
        newRow={addNewDataRow()}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={handleSaveRow}
        handleCancelEditRow={handleCancelEditRow}
        checkIsValidReturnErrorMessage={() => ({
          isError: !(invalidFormState.length === 0),
          errorMessage: t('hint.invalid'),
        })}
        className={styles.feesTable}
      />
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Error}
          open={isFeeCreateOrUpdateErrorDialogOpen}
          onClose={onCloseFeeCreateOrUpdateErrorDialog}
          actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
          emphasizedAction={MessageBoxActions.Retry}
          id={'fee-create-or-update-error-dialog'}
          titleText={t('fee-create-or-update.error-dialog.title')}
          className={styles.errorDialog}
        >
          <div className={styles.errorDialogDescription}>
            {t('fee-create-or-update.error-dialog.description', {
              returnObjects: true,
            }).map((element) => (
              <div key={element}>{element}</div>
            ))}
          </div>
        </MessageBox>,
        document.body,
      )}
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Error}
          open={isFeeDeleteErrorDialogOpen}
          onClose={onCloseFeeDeleteErrorDialog}
          actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
          emphasizedAction={MessageBoxActions.Retry}
          id={'fee-delete-error-dialog'}
          titleText={t('fee-delete.error-dialog.title')}
          className={styles.errorDialog}
        >
          <div className={styles.errorDialogDescription}>
            {t('fee-delete.error-dialog.description', {
              returnObjects: true,
            }).map((element) => (
              <div key={element}>{element}</div>
            ))}
          </div>
        </MessageBox>,
        document.body,
      )}
    </>
  )
}

export default TrancheFeesCardTable
