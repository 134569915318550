import { useSearchParams } from 'react-router-dom'
import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'

const REQUEST_RETRIES = 3

export const useTrancheFees = (dealId, trancheId) => {
  const [searchParams] = useSearchParams()

  const isExistingBusinessViewSet =
    searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  return useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/fees?dataSource=${
        isExistingBusinessViewSet ? DATA_SOURCES.EXISTING_BUSINESS : DATA_SOURCES.NEW_BUSINESS
      }`,
      keys: [
        'deals',
        dealId,
        'tranches',
        trancheId,
        'fees',
        'dataSource',
        isExistingBusinessViewSet ? DATA_SOURCES.EXISTING_BUSINESS : DATA_SOURCES.NEW_BUSINESS,
      ],
      options: {
        enabled: !!trancheId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < REQUEST_RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )
}
