import mapSummaryData from 'routes/deals/financing/utils/mapSummaryData'
import mapTrancheData from 'routes/deals/financing/utils/mapTrancheData'

const mapTranchesData = (backendData) => {
  let tranches = [...backendData.tranches.map((tranche) => mapTrancheData(tranche))]
  if ((backendData.summary || backendData.summaryHeadquarter) && backendData.tranches.length > 1)
    tranches = [
      ...tranches,
      mapSummaryData({
        summary: backendData.summary,
        summaryHeadquarter: backendData.summaryHeadquarter,
      }),
    ]
  return tranches
}

export default mapTranchesData
