import { Text, Label, Icon } from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'
import { DATA_SOURCES } from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/tranchesOverview/TranchesOverviewTableCell.module.css'
import { ROW_TYPE_SUMMARY } from 'routes/deals/financing/tranchesOverview/constants'

const renderFooterCell = (props, formatMoney, formatFraction, t) => {
  const columnKey = props.cell.column.id

  const renderMoneyCell = () => {
    const { value: primaryValue, currency: primaryCurrency } = props.cell.value.primary ?? {}
    const { value: secondaryValue, currency: secondaryCurrency } = props.cell.value.secondary ?? {}

    if (isNil(primaryValue)) return <div className={styles.caretCursor} />

    return (
      <div>
        <div className={styles.caretCursor}>
          <Text className={styles.textBold}>
            {formatMoney(primaryValue, primaryCurrency, {
              currencyDisplay: 'code',
            })}
          </Text>
        </div>
        {primaryCurrency !== secondaryCurrency && (
          <div className={`${styles.caretCursor} ${styles.textLabelColor}`}>
            {formatMoney(secondaryValue, secondaryCurrency, {
              currencyDisplay: 'code',
            })}
          </div>
        )}
      </div>
    )
  }

  const renderCommitmentCell = () => {
    const { value: primaryValue, currency: primaryCurrency } = props.cell.value.primary ?? {}
    const { value: secondaryValue, currency: secondaryCurrency } = props.cell.value.secondary ?? {}
    const { share } = props.cell.value

    if (isNil(primaryValue)) return <div className={styles.caretCursor} />

    return (
      <div className={styles.twoLineCell}>
        <div>
          <Text className={`${styles.caretCursor}, ${styles.textBold}`}>
            {formatMoney(primaryValue, primaryCurrency, {
              currencyDisplay: 'code',
            })}
          </Text>
          {!isNil(secondaryValue) && primaryCurrency !== secondaryCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(secondaryValue, secondaryCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>
        <div className={styles.caretCursor}>
          <Label showColon>{t('pages.deals.tranches-table.share-label')}</Label>&nbsp;
          <Text>{`${formatFraction(share)}`}</Text>
        </div>
      </div>
    )
  }

  switch (columnKey) {
    case 'tranche':
      return <Text className={styles.textBold}>{'Total'}</Text>
    case 'totalCommitment':
    case 'outstanding':
    case 'available':
      return renderMoneyCell()
    case 'syndicatedAmountTotal':
    case 'commitment':
      return renderCommitmentCell()
    case 'rank':
    case 'loanTypeCode':
    case 'productTypeCode':
    case 'maturity':
    case 'repayment':
    case 'customerMargin':
    case 'interestRateTypeCode':
    case 'navigation':
      return <div />
    default:
      throw new TypeError('Unknown column key')
  }
}

const renderTableCell = (props, formatMoney, formatFraction, t, formatDate, isExistingBusiness) => {
  const columnKey = props.cell.column.id

  const renderTrancheCell = () => {
    const { name, displayId, externalContractId } = props.cell.value
    const extractedExternalContractId = Array.isArray(externalContractId)
      ? externalContractId[0]
      : externalContractId

    const renderId =
      isExistingBusiness && !isNil(extractedExternalContractId)
        ? extractedExternalContractId
        : displayId

    return <EntityCell name={name} id={renderId} />
  }

  const renderCell = () => <div className={styles.caretCursor}>{props.cell.value ?? ''}</div>

  const renderDateCell = () => <Text>{formatDate(props.cell.value)}</Text>

  const renderMoneyCell = () => {
    const { value: originalValue, currency: originalCurrency } = props.cell.value.original ?? {}
    const { value: convertedValue, currency: convertedCurrency } = props.cell.value.converted ?? {}

    if (isNil(originalValue)) return <div className={styles.caretCursor} />

    return (
      <div>
        <div className={styles.caretCursor}>
          {formatMoney(originalValue, originalCurrency, {
            currencyDisplay: 'code',
          })}
        </div>
        {originalCurrency !== convertedCurrency && (
          <div className={`${styles.caretCursor} ${styles.textLabelColor}`}>
            {formatMoney(convertedValue, convertedCurrency, {
              currencyDisplay: 'code',
            })}
          </div>
        )}
      </div>
    )
  }

  const renderCommitmentCell = () => {
    const { value: originalValue, currency: originalCurrency } = props.cell.value.original ?? {}
    const { value: convertedValue, currency: convertedCurrency } = props.cell.value.converted ?? {}
    const { share } = props.cell.value ?? {}
    return (
      <div className={styles.twoLineCell}>
        <div>
          <div className={styles.caretCursor}>
            {formatMoney(originalValue, originalCurrency, {
              currencyDisplay: 'code',
            })}
          </div>
          {originalCurrency !== convertedCurrency && (
            <div className={`${styles.textLabelColor} ${styles.caretCursor}`}>
              {formatMoney(convertedValue, convertedCurrency, {
                currencyDisplay: 'code',
              })}
            </div>
          )}
        </div>
        {!isNil(share) && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('pages.deals.tranches-table.share-label')}</Label>&nbsp;
            <Text>{formatFraction(share)}</Text>
          </div>
        )}
      </div>
    )
  }

  const renderCustomerMarginCell = () => (
    <div className={styles.caretCursor}>
      {!isNil(props.cell.value) ? formatFraction(props.cell.value) : ''}
    </div>
  )

  const renderEnumCell = () => (
    <div className={styles.caretCursor}>{props.cell.value.text ?? ''}</div>
  )

  const renderRepayment = () => {
    const rate = props.cell.value.rate

    return (
      <div className={styles.twoLineCell}>
        {renderEnumCell()}
        {!isNil(rate) && (
          <div className={styles.caretCursor}>
            <Label showColon>{t('pages.deals.tranches-table.rate-label')}</Label>&nbsp;
            <Text>{formatFraction(rate)}</Text>
          </div>
        )}
      </div>
    )
  }

  const renderCellWithWordBreak = () => (
    <div className={styles.wordBreak}>{props.cell.value.text ?? ''}</div>
  )

  const renderNavigationArrow = () => (
    <Icon
      // allows click through to use the onClick handler of the underlying row
      style={{ pointerEvents: 'none' }}
      slot="icon"
      name="navigation-right-arrow"
    />
  )

  switch (columnKey) {
    case 'tranche':
      return renderTrancheCell()
    case 'totalCommitment':
    case 'outstanding':
    case 'available':
      return renderMoneyCell()
    case 'commitment':
      return renderCommitmentCell()
    case 'syndicatedAmountTotal':
      return renderCommitmentCell()
    case 'navigation':
      return renderNavigationArrow()
    case 'customerMargin':
      return renderCustomerMarginCell()
    case 'loanTypeCode':
      return renderCellWithWordBreak()
    case 'productTypeCode':
    case 'repayment':
      return renderRepayment()
    case 'interestRateTypeCode':
      return renderEnumCell()
    case 'maturity':
      return renderDateCell()
    case 'rank':
    default:
      return renderCell()
  }
}

const TranchesOverviewTableCell = (props) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const formatFraction = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })
  const { format: formatDate } = useShortDateFormatter()

  const { t } = useTranslation()

  const rowIndex = props.row.index
  const dataAmount = props.data.length

  const [searchParams] = useSearchParams()
  const isExistingBusiness = searchParams.get('dataSource') === DATA_SOURCES.EXISTING_BUSINESS

  if (
    dataAmount >= 2 &&
    rowIndex === dataAmount - 1 &&
    props.row.original?.rowType === ROW_TYPE_SUMMARY
  ) {
    return renderFooterCell(props, formatMoney, formatFraction, t)
  } else {
    return renderTableCell(props, formatMoney, formatFraction, t, formatDate, isExistingBusiness)
  }
}

TranchesOverviewTableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
}

export default TranchesOverviewTableCell
