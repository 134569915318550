import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import documentPermissions from 'api/documents/DocumentPermissions'
import { useDealWorkingVersionToolbarButtons } from 'components/domains/deals/deal-adjustment/useDealWorkingVersionToolbarButtons'
import CreateDealEventAction from 'components/domains/deals/header-actions/CreateDealEventAction'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import ConfigurableComponentsPage from 'components/ui/configurable-components-page/ConfigurableComponentsPage'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import createSegmentedHeaderActions from 'components/ui/page/createSegmentedHeaderActions'
import UserFavoriteIcon from 'components/ui/user-favorite/UserFavoriteIcon'
import { cwpEntityTypes } from 'constants/cwpEntityTypes'
import { availableDynamicPagesEnum } from 'hooks/page-config/useGetPageConfig'
import useDocuments from 'hooks/services/documents/useDocuments'
import useDocumentsAllowedOperations from 'hooks/services/documents/useDocumentsAllowedOperations'
import { DealContext } from 'routes/deals/DealContext'
import DealDetailsWrapper from 'routes/deals/DealDetailsWrapper'

const DealDocumentsDynamic = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.detail.documents' })
  const { deal } = useContext(DealContext)

  const {
    data: documentRoot = {},
    isLoading: isLoadingDocuments,
    isError: isErrorDocuments,
  } = useDocuments({ entityId: deal.dealUuid, entityType: DocumentTypes.Deal })
  const { toggleWorkingVersionButton, manageWorkingVersionButton } =
    useDealWorkingVersionToolbarButtons()

  const breadcrumb = { text: t('title') }

  const {
    data: allowedOperationsData,
    isLoading: isLoadingAllowedOperations,
    isError: isAllowedOperationsError,
  } = useDocumentsAllowedOperations()

  const hasDocumentReadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Read) ?? false
  const hasDocumentUploadPermission =
    allowedOperationsData?.allowedOperations.includes(documentPermissions.Create) ?? false

  const markFavoriteAction = useMemo(
    () => (
      <UserFavoriteIcon
        key="deal-overview-user-favorite-icon"
        entityId={deal.dealUuid}
        entityType={cwpEntityTypes.DEAL}
      />
    ),
    [deal.dealUuid],
  )

  const headerActions = useMemo(
    () =>
      createSegmentedHeaderActions(
        [toggleWorkingVersionButton, manageWorkingVersionButton],
        [<CreateDealEventAction key="create-event-action" />, markFavoriteAction],
      ),
    [manageWorkingVersionButton, markFavoriteAction, toggleWorkingVersionButton],
  )

  if (!hasDocumentReadPermission) return <></>
  return (
    <ConfigurableComponentsPage
      pageData={{
        documentRoot,
        isLoadingDocuments,
        isErrorDocuments,
        hasDocumentUploadPermission,
        isAllowedOperationsError,
        isLoadingAllowedOperations,
        deal,
      }}
      pageCode={availableDynamicPagesEnum.DealsDocuments}
      renderContent={({ children }) => (
        <DealDetailsWrapper childBreadcrumbs={breadcrumb} actions={headerActions}>
          <RequestStateResolver
            isLoading={isLoadingDocuments || isLoadingAllowedOperations}
            isError={isErrorDocuments || isAllowedOperationsError}
            errorToDisplay={<ErrorDataUnavailableInContent />}
            renderContent={() => children}
          />
        </DealDetailsWrapper>
      )}
    />
  )
}

export default DealDocumentsDynamic
