import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import { DealPricingAdditionalInformationPanel } from 'components/domains/deals/pricing/DealPricingAdditionalInformationPanel'
import DealPricingCustomModeStripes from 'components/domains/deals/pricing/DealPricingCustomModeStripes'
import { DealPricingGeneralInformationPanel } from 'components/domains/deals/pricing/DealPricingGeneralInformationPanel'
import { DealPricingHeaderActions } from 'components/domains/deals/pricing/DealPricingHeaderActions'
import { DealPricingStatusInformationPanel } from 'components/domains/deals/pricing/DealPricingStatusInformationPanel'
import Card from 'components/ui/card/Card'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useGetPricing from 'hooks/services/deals/pricing/useGetPricing'
import { DealContext } from 'routes/deals/DealContext'

const DealPricingCard = ({ isAllowedToUpdatePricing }) => {
  const { deal } = useContext(DealContext)
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.pricing' })

  const {
    data: { last_updated_by, last_updated_at, values = {}, meta = {} } = {},
    isLoading: isLoadingPricing,
    isError: isErrorPricing,
  } = useGetPricing({ dealUuid: deal.dealUuid })
  const { custom_fields: { custom_syndication, custom_fees, custom_repayment } = {} } = meta

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('card-title')}
          email={last_updated_by}
          timestamp={last_updated_at}
          actions={<DealPricingHeaderActions isAllowedUpdatingPricing={isAllowedToUpdatePricing} />}
        />
      }
    >
      <LoadingStateWrapper isError={isErrorPricing} isLoading={isLoadingPricing}>
        <DealPricingCustomModeStripes
          customSyndication={{ text: t('custom-syndication'), isVisible: custom_syndication }}
          customFees={{ text: t('custom-fees'), isVisible: custom_fees }}
          customRepayment={{ text: t('custom-repayment'), isVisible: custom_repayment }}
        />
        <DealPricingGeneralInformationPanel data={values} />
        <DealPricingAdditionalInformationPanel data={values} />
        <DealPricingStatusInformationPanel data={meta} />
      </LoadingStateWrapper>
    </Card>
  )
}

DealPricingCard.propTypes = {
  isAllowedToUpdatePricing: PropTypes.bool,
}

export default DealPricingCard
