import {
  CheckBox,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

export const SelectAndUnselectTableCellEdit = ({ isSelectable, isSelected, onSelectChange }) => (
  <FlexBox alignItems={FlexBoxAlignItems.Center} justifyContent={FlexBoxJustifyContent.Center}>
    <CheckBox disabled={!isSelectable} checked={isSelected} onChange={onSelectChange} />
  </FlexBox>
)

SelectAndUnselectTableCellEdit.propTypes = {
  isSelectable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
}
