import { useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import { useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { decisionPaperSynchronizationStatus } from 'api/decision-paper/decisionPaperApi'
import {
  errorTypes,
  mapTileDataToSaveCall,
} from 'hooks/services/business-events-and-tasks/decision-papers/routes/syncDecisionPaperUtilities'
import useEditDecisionPaper from 'hooks/services/business-events-and-tasks/decision-papers/useEditDecisionPaper'
import { resetTiles } from 'redux/slices/decision-paper/tilesOverviewSlice'

const tileDataStateSelector = (state) => state.decisionPaper.tilesOverview.tiles
const tileImagesStateSelector = (state) => state.decisionPaper.imagesForTiles.tiles

const useFreezeDecisionPaperFlow = ({
  eventId,
  onFreezeSuccess,
  isSyncOn,
  onFreezeError,
  isMinorVersion,
}) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { mutate: editDecisionPaper } = useEditDecisionPaper()
  const reduxStore = useStore()

  const onDecisionPaperFreezeSuccess = useCallback(
    (response) => {
      dispatch(resetTiles())
      queryClient.invalidateQueries(['events', eventId])
      onFreezeSuccess(camelize(response.data))
    },
    [onFreezeSuccess, queryClient, eventId, dispatch],
  )

  const onSyncStatusChangedError = useCallback(() => {
    onFreezeError({ type: errorTypes.technical, failingTileIds: [] })
  }, [onFreezeError])

  const handleFreezeClicked = useCallback(() => {
    const currentState = reduxStore.getState()
    const tileDataState = tileDataStateSelector(currentState)
    const imageDataState = tileImagesStateSelector(currentState)
    const {
      data: tileData,
      loadingTileIds,
      errorTileIds,
      hasLoadingTiles,
      hasErrorTiles,
    } = mapTileDataToSaveCall(tileDataState, imageDataState, isMinorVersion)

    if (hasLoadingTiles) {
      onFreezeError({
        type: errorTypes.tilesLoading,
        failingTileIds: loadingTileIds,
      })
      return
    }

    if (hasErrorTiles) {
      onFreezeError({
        type: errorTypes.tilesError,
        failingTileIds: errorTileIds,
      })
      return
    }

    if (isSyncOn) {
      editDecisionPaper(
        {
          eventId,
          synchronizationStatus: decisionPaperSynchronizationStatus.frozen,
          tileData,
        },
        {
          onSuccess: onDecisionPaperFreezeSuccess,
          onError: onSyncStatusChangedError,
        },
      )
      return
    }
    editDecisionPaper(
      {
        eventId,
        synchronizationStatus: decisionPaperSynchronizationStatus.frozen,
      },
      {
        onSuccess: onDecisionPaperFreezeSuccess,
        onError: onSyncStatusChangedError,
      },
    )
  }, [
    reduxStore,
    isSyncOn,
    editDecisionPaper,
    eventId,
    onDecisionPaperFreezeSuccess,
    onSyncStatusChangedError,
    onFreezeError,
    isMinorVersion,
  ])

  return { handleFreezeClicked }
}

export default useFreezeDecisionPaperFlow
