import { Option, Select } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const dealBorrowerUnitSelectProps = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isAddable: PropTypes.bool,
      relationshipTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          modifiable: PropTypes.bool,
        }),
      ),
      heads: PropTypes.arrayOf(
        PropTypes.shape({
          relationshipType: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  onChange: PropTypes.func,
}

const DealBorrowerUnitSelect = ({ units, onChange = (_) => {}, ...props }) => {
  const changeUnit = (event) => {
    const { value } = event.detail.selectedOption
    onChange(value)
  }

  const selectOptions = useMemo(
    () =>
      units
        .filter((unit) => unit.isAddable)
        .map((unit) => (
          <Option key={unit.id} value={unit.id}>
            {unit.name}
          </Option>
        )),
    [units],
  )

  return (
    <Select {...props} onChange={changeUnit}>
      <Option key="" />
      {selectOptions}
    </Select>
  )
}

DealBorrowerUnitSelect.propTypes = dealBorrowerUnitSelectProps

export default DealBorrowerUnitSelect
