import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useBorrowerRolesType } from 'hooks/services/deals/financing/useBorrowerRolesType'

export const useIsBorrower = (businessPartnerId, options) => {
  const {
    data: businessPartnerData,
    isLoading: isBusinessPartnerDataLoading,
    isError: isBusinessPartnerDataError,
  } = useBusinessPartnerMiniByIds([businessPartnerId])

  const roles = businessPartnerData?.businessPartnerMinis?.[0]?.roles || []
  const fullName = businessPartnerData?.businessPartnerMinis?.[0]?.fullName || ''

  const {
    data: borrowerRolesConfig = [],
    isLoading: isLoadingBorrowerRolesConfiguration,
    isError: isErrorBorrowerRolesConfiguration,
  } = useBorrowerRolesType(options)
  const isLoading = isBusinessPartnerDataLoading || isLoadingBorrowerRolesConfiguration
  const isError = isBusinessPartnerDataError || isErrorBorrowerRolesConfiguration

  const technicalBusinessPartnerRoles = roles.flatMap((role) => role.backEndRoles)
  const borrowerRoles = borrowerRolesConfig.flatMap((role) => role.additionalProperties.bpRoleId)
  const isBorrower = technicalBusinessPartnerRoles.some(({ id }) => borrowerRoles.includes(id))

  return { isLoading, isError, data: { isBorrower, fullName } }
}
