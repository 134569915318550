import PropTypes from 'prop-types'

const CustomFieldTypes = {
  String: 'STRING',
  Number: 'NUMBER',
  Bool: 'BOOLEAN',
  Date: 'DATE',
  Datetime: 'DATE_TIME',
  Percentage: 'PERCENTAGE',
  Measurement: 'MEASUREMENT',
  Currency: 'CURRENCY',
  SingleSelect: 'SINGLE_SELECT',
  MultiSelect: 'MULTI_SELECT',
}

const visibilityProps = PropTypes.shape({
  displayMode: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
})

const selectOptionProps = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    optionUuid: PropTypes.string.isRequired,
  }),
)

const validationProps = PropTypes.shape({
  required: PropTypes.bool.isRequired,
  pattern: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
})

const customFieldConfigProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(CustomFieldTypes)).isRequired,
  displayName: PropTypes.string.isRequired,
  visibility: visibilityProps,
  select_options: selectOptionProps,
  validation: validationProps,
})

export {
  CustomFieldTypes,
  customFieldConfigProps,
  visibilityProps,
  selectOptionProps,
  validationProps,
}
