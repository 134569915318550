import { Button, ButtonDesign, CardHeader } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import DealConsolidationPointsTable from 'components/domains/deals/limit-check/consolidation-points/DealConsolidationPointsTable'
import styles from 'components/domains/deals/limit-check/consolidation-points/DealConsolidationPointsTable.module.css'
import { LoadConsolidationPointsDialog } from 'components/domains/deals/limit-check/consolidation-points/LoadConsolidationPointsDialog'
import { useCheckCreditLimitReservationSupportStatus } from 'components/domains/deals/limit-check/consolidation-points/useCheckCreditLimitReservationSupportStatus'
import Card from 'components/ui/card/Card'
import { ErrorDataUnavailableInTable } from 'components/ui/errors/ErrorDataUnavailableInTable'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useConsolidationPoints from 'hooks/services/deals/consolidation-points/useConsolidationPoints'
import usePbbCustomServiceAllowedOperations from 'hooks/services/pbb-custom-service/usePbbCustomServiceAllowedOperations'

const DealConsolidationPointsCard = ({ pageData }) => {
  const { deal: { dealUuid } = {} } = pageData
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.limit-check.consolidation-points-card',
  })
  const {
    data: { allowedOperations: allowedOperations = [] } = {},
    isLoading: isLoadingAllowedOperations,
    isError: isErrorAllowedOperations,
  } = usePbbCustomServiceAllowedOperations()

  const isAllowedToLoadConsolidationPoints = allowedOperations.includes(
    dealsPermissions.loadConsolidationPointsPBB,
  )

  const {
    isLoading: isLoadingConsolidationPoints,
    isError: isErrorConsolidationPoints,
    data: { consolidationPoints = [] } = {},
  } = useConsolidationPoints(dealUuid)

  const {
    isLimitCheckSupported,
    isLoading: isLoadingSupportStatus,
    isError: isErrorSupportStatus,
  } = useCheckCreditLimitReservationSupportStatus(dealUuid)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const loadButton = (
    <Button design={ButtonDesign.Transparent} onClick={() => setIsDialogOpen(true)}>
      {t('load-button.title')}
    </Button>
  )

  const isShowLoadButton =
    isAllowedToLoadConsolidationPoints &&
    !isLoadingSupportStatus &&
    !isErrorSupportStatus &&
    !isLoadingAllowedOperations &&
    !isErrorAllowedOperations &&
    isLimitCheckSupported

  const titleTextWithCount = isLimitCheckSupported
    ? `${t('title')} (${consolidationPoints.length})`
    : t('title')

  const isDataAvailable = !!consolidationPoints.length

  const renderContent = () => (
    <div className={styles.tableWrapper}>
      {isLimitCheckSupported && isDataAvailable && <CardHeader titleText={titleTextWithCount} />}
      <LoadConsolidationPointsDialog
        dealUuid={dealUuid}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <DealConsolidationPointsTable
        isLimitCheckSupported={isLimitCheckSupported}
        consolidationPoints={consolidationPoints}
      />
    </div>
  )

  return (
    <Card
      header={<CardHeader action={isShowLoadButton ? loadButton : null} titleText={t('title')} />}
    >
      <RequestStateResolver
        isLoading={isLoadingConsolidationPoints || isLoadingSupportStatus}
        isError={isErrorConsolidationPoints || isErrorSupportStatus}
        errorToDisplay={<ErrorDataUnavailableInTable />}
        renderContent={renderContent}
        center
      />
    </Card>
  )
}

DealConsolidationPointsCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
    }),
  }),
}

export default DealConsolidationPointsCard
