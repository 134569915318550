import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  Grid,
  Label,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import styles from 'routes/deals/financing/shared-components/AmountWithCurrencyEditInput.module.css'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'
import isPercentageValid from 'routes/deals/financing/utils/isPercentageValid'

const ShareInput = ({ name, label, handleOnChange, fieldValues, validationFunction }) => {
  const formattedNumberInputRef = useRef()

  validationFunction = validationFunction ?? isPercentageValid

  return (
    <Grid position="Left" defaultIndent="XL0 L0 M0 S0" defaultSpan="XL6 L6 M6 S6">
      <FlexBox className={styles.labelWrapper} alignItems={FlexBoxAlignItems.Center}>
        <Label>{label}</Label>
      </FlexBox>
      <FlexBox className={styles.inputWrapper} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <FormattedNumberInput
          id={`input-${name}-share`}
          value={convertToNumberIfValid(fieldValues[name])}
          valueState={validationFunction(fieldValues[name]) ? ValueState.None : ValueState.Error}
          className="edit-input-field"
          icon={<Text>{'%'}</Text>}
          data-testid={`standardShareField-${name}`}
          valueStateMessage={
            <span>
              {t('pages.deals.tranches.details.customer-specific-attributes-card.percentage.error')}
            </span>
          }
          onInput={(number) => {
            const isInternallyValid = formattedNumberInputRef?.current?.isValid
            const numberInputValidationFunction = (val) =>
              isInternallyValid && validationFunction(val)
            handleOnChange(name, number, true, false, numberInputValidationFunction)
          }}
          ref={formattedNumberInputRef}
        />
      </FlexBox>
    </Grid>
  )
}

ShareInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  fieldValues: PropTypes.object,
  validationFunction: PropTypes.func,
}

export default ShareInput
