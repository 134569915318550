import { Button, ButtonDesign, Label, Text } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decisionStageVoteOptions } from 'api/decision-process/decisionProcessApi'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'
import { handleNewDecisionDateChanged } from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/DecisionStageApprovalOverarchingButtonUtils'
import showDocumentLinkMessageBox from 'components/domains/business-events-and-tasks/decision-stage/approval/overarching/buttons/showDocumentLinkMessageBox'
import SemanticButton, { SemanticButtonDesign } from 'components/ui/button/SemanticButton'
import EditableDatePickerComponent from 'components/ui/input/EditableDatePickerComponent'
import MessageBox, {
  MessageBoxActions,
  MessageBoxTypes,
  useShowMessageBox,
} from 'components/ui/message-box/MessageBox'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useOverarchingVoteError from 'hooks/services/business-events-and-tasks/decision-process/stages/error-checks/useOverarchingVoteError'
import useEditDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStage'
const byWayOfAttendance = 'BY_WAY_OF_ATTENDANCE'

const DecisionStageApprovalOverarchingApproveButton = ({
  decisionStageId,
  eventId,
  decisionStageType,
}) => {
  const keyPrefix = 'decision-stage.vote-options.overarching.approve'
  const { t } = useTranslation('translation', {
    keyPrefix,
  })
  const { mutate: editDecisionStage } = useEditDecisionStage()
  const showMessageBox = useShowMessageBox()
  const queryClient = useQueryClient()
  const today = new Date().toISOString().substring(0, 10)
  const { localePattern } = useShortDateFormatter()
  const [newDecisionDate, setNewDecisionDate] = useState(today)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const [isDateValid, setIsDateValid] = useState(true)
  const { get } = useAccessTokenRequest()
  const { onError } = useOverarchingVoteError()

  const onApproveSuccess = useCallback(
    () =>
      showDocumentLinkMessageBox({
        eventId,
        decisionStageId,
        titleText: t('success.title'),
        i18nKey: `${keyPrefix}.success.text`,
        queryClient,
        get,
        showMessageBox,
      }),

    [queryClient, eventId, get, decisionStageId, showMessageBox, t],
  )

  const onApproveConfirm = useCallback(() => {
    editDecisionStage(
      {
        decisionStageId,
        eventId,
        decisionDate: newDecisionDate,
        voteStatusCode: decisionStageVoteOptions.approve,
      },
      { onSuccess: onApproveSuccess, onError },
    )
  }, [editDecisionStage, decisionStageId, eventId, newDecisionDate, onApproveSuccess, onError])

  const onApprove = useCallback(() => {
    setIsMessageBoxOpen(true)
  }, [])

  const handleDateChange = useCallback((event) => {
    handleNewDecisionDateChanged(event, setNewDecisionDate)
    setIsDateValid(event.detail.valid)
  }, [])

  return (
    <>
      <SemanticButton
        id="semantic-approve-button"
        design={SemanticButtonDesign.Success}
        onClick={onApprove}
      >
        {t('action')}
      </SemanticButton>
      <MessageBox
        open={isMessageBoxOpen}
        onClose={() => setIsMessageBoxOpen(false)}
        type={MessageBoxTypes.Confirm}
        titleText={t('action')}
        actions={[
          <Button
            id="confirmation-approve-button"
            key="approve-button"
            design={ButtonDesign.Emphasized}
            onClick={onApproveConfirm}
            disabled={!isDateValid}
          >
            {t('action')}
          </Button>,
          MessageBoxActions.Cancel,
        ]}
      >
        <p>{t('confirmation.text')}</p>
        {decisionStageType === byWayOfAttendance && (
          <>
            <Label showColon for="decision-date-picker" required>
              {t('label.decision-date')}
            </Label>
            <EditableDatePickerComponent
              style={{ width: '100%' }}
              id="decision-date-picker"
              value={newDecisionDate}
              maxDate={today}
              onChange={handleDateChange}
              placeholder={localePattern}
              formatPattern={localePattern}
              valueState={newDecisionDate && isDateValid ? ValueState.None : ValueState.Error}
              valueStateMessage={<Text>{t('error-states.decision-date')}</Text>}
              editable
            />
          </>
        )}
      </MessageBox>
    </>
  )
}

DecisionStageApprovalOverarchingApproveButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  decisionStageId: PropTypes.string.isRequired,
  decisionStageType: PropTypes.string.isRequired,
}

export default DecisionStageApprovalOverarchingApproveButton
