import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { useCallback, useMemo } from 'react'
import useEventsConfig from 'hooks/services/business-events-and-tasks/events/useEventsConfig'

const FINANCIAL_INDICATORS_TILE_MAPPING_KEY = 'financialIndicators'

const useFinancialIndicatorsHelper = (tileCode) => {
  const { isLoading, isError: isErrorEventsConfig, data: eventsConfig = {} } = useEventsConfig()

  const financialIndicatorMapping = useMemo(
    () =>
      eventsConfig?.decisionPapers?.tiles
        ?.find((tile) => tile.code === tileCode)
        ?.tileMappings?.find((mapping) =>
          Object.keys(mapping)?.some((key) => key === FINANCIAL_INDICATORS_TILE_MAPPING_KEY),
        )?.financialIndicators ?? {},
    [eventsConfig?.decisionPapers?.tiles, tileCode],
  )

  const isError = useMemo(
    () => !isLoading && (isErrorEventsConfig || isEmpty(financialIndicatorMapping)),
    [financialIndicatorMapping, isErrorEventsConfig, isLoading],
  )

  const getFinancialIndicatorValues = useCallback(
    (fiscalFigures) => {
      const result = cloneDeep(financialIndicatorMapping)
      Object.keys(result).forEach((key) => {
        if (result[key]) {
          result[key] = {
            label: result[key].label,
            value: get(fiscalFigures, result[key].key),
          }
        }
      })
      return result
    },
    [financialIndicatorMapping],
  )

  const getFinancialIndicators = useCallback(
    (fiscalData) => {
      if (isNil(fiscalData?.fiscal_figures) || isError) {
        return undefined
      }
      return {
        keyDate: fiscalData.start_date,
        currency: fiscalData.currency,
        ...getFinancialIndicatorValues(fiscalData.fiscal_figures),
      }
    },
    [getFinancialIndicatorValues, isError],
  )

  return useMemo(
    () => ({
      isLoading,
      isError,
      getFinancialIndicators,
    }),
    [getFinancialIndicators, isError, isLoading],
  )
}

export default useFinancialIndicatorsHelper
