import uniq from 'lodash.uniq'
import { useTranslation } from 'react-i18next'
import RichTextTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/manual/rich-text/RichTextTile'
import CardSection from 'components/domains/deals/card/CardSection'
import styles from 'components/domains/deals/information/useCommentField.module.css'

const commentMaxLength = 1000
const isMandatory = false

export const useCommentField = ({ comment }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })
  const isValidLength = (charCount) => !charCount || charCount <= commentMaxLength

  const renderRichTextEditComponent = ({ handleOnChange, isInvalid, setInvalidFormState }) => (
    <CardSection className={styles.editSection} title={t('comment')}>
      <RichTextTile
        isEditMode={true}
        maxCharacterAmount={commentMaxLength}
        currentContent={comment}
        onUsedCharactersChanged={(usedCharactersExcludingHTML) => {
          if (isValidLength(usedCharactersExcludingHTML)) {
            setInvalidFormState((oldState) => oldState.filter((e) => e !== 'comment'))
          } else {
            setInvalidFormState((oldState) => uniq([...oldState, 'comment']))
          }
        }}
        onChange={(value) => {
          handleOnChange('comment', value, isInvalid, isMandatory)
        }}
        isPdfView={false}
      />
    </CardSection>
  )

  return {
    name: 'comment',
    value: comment,
    renderCustomEditComponent: renderRichTextEditComponent,
    validationFunction: isValidLength,
    customInfoComponent: (
      <CardSection className={styles.displaySection} title={t('comment')}>
        <div className={styles.richTextWrapper}>
          <RichTextTile
            isEditMode={false}
            currentContent={comment}
            isPdfView={true}
            isPdfViewCollapsible={true}
            onChange={() => {}}
          />
        </div>
      </CardSection>
    ),
    isShownInEdit: true,
    isShownInDisplay: true,
  }
}
