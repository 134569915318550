import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { TranchePricingCustomFieldListItem } from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingCustomFieldListItem'
import styles from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingAdditionalInformationPanel.module.css'

const TrancheStatusInformationPanel = ({ customFields = {} }) => (
  <Panel
    header={<Text>{t('pages.deals.tranches.details.pricing-card.status-info-panel-title')}</Text>}
    collapsed={false}
  >
    <div className={styles.panelContent}>
      {Object.values(customFields)
        .sort((a, b) => a.order - b.order)
        .filter((customField) => customField.shown === true)
        .map((customField) => (
          <TranchePricingCustomFieldListItem
            customField={customField}
            key={customField.categoryShortText}
          />
        ))}
    </div>
  </Panel>
)

TrancheStatusInformationPanel.propTypes = {
  pricingData: PropTypes.object,
}

export default TrancheStatusInformationPanel
