import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReposTableCell from 'routes/deals/financing/non-loan-products/repos/ReposTableCell'

const columnKeyPrefix = 'pages.deals.repos-table.columns'

const useColumnDefinitions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: columnKeyPrefix,
  })

  const getDefaultColumnDefinition = ({
    columnKey,
    minWidth = 100,
    hAlign = TextAlign.Left,
    isVisible = true,
  }) => ({
    Header: () => <Trans i18nKey={`${columnKeyPrefix}.${columnKey}`} />,
    title: t(columnKey).replace('<br/>', ' '),
    accessor: columnKey,
    hAlign,
    isVisible,
    minWidth,
    columnKey,
  })

  const tableColumns = [
    getDefaultColumnDefinition({ columnKey: 'repoCondition', minWidth: 300 }),
    getDefaultColumnDefinition({ columnKey: 'productType', minWidth: 200 }),
    getDefaultColumnDefinition({ columnKey: 'productSubType', minWidth: 200 }),
    getDefaultColumnDefinition({
      columnKey: 'limitSystemProductClass',
      minWidth: 120,
      isVisible: false,
    }),
    getDefaultColumnDefinition({
      columnKey: 'counterpartyIssuer',
      minWidth: 250,
    }),
    getDefaultColumnDefinition({ columnKey: 'mtm', hAlign: TextAlign.Right, minWidth: 180 }),
    getDefaultColumnDefinition({ columnKey: 'startDate', hAlign: TextAlign.Right, minWidth: 120 }),
    getDefaultColumnDefinition({
      columnKey: 'maturityDate',
      hAlign: TextAlign.Right,
      minWidth: 120,
    }),
    getDefaultColumnDefinition({ columnKey: 'buyOrSell' }),
    getDefaultColumnDefinition({ columnKey: 'payOrReceive' }),
    getDefaultColumnDefinition({
      columnKey: 'currentNotional',
      minWidth: 200,
      hAlign: TextAlign.Right,
    }),
    getDefaultColumnDefinition({ columnKey: 'rateType' }),
    getDefaultColumnDefinition({ columnKey: 'rateIndexName' }),
    getDefaultColumnDefinition({ columnKey: 'tenor' }),
    getDefaultColumnDefinition({ columnKey: 'spread', hAlign: TextAlign.Right }),
    getDefaultColumnDefinition({ columnKey: 'currentRate', hAlign: TextAlign.Right }),
  ].map((element) => ({
    disableDragAndDrop: true,
    disableResizing: false,
    isVisible: true,
    isSelectableForHiding: true,
    Cell: ReposTableCell,
    vAlign: VerticalAlign.Middle,
    ...element,
  }))

  return useMemo(() => tableColumns, [tableColumns])
}

export default useColumnDefinitions
