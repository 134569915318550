import { useMemo } from 'react'
import { useExternalTypeMappingMethods } from 'components/domains/deals/limit-check/reservations/external-type/useExternalTypeMappingMethods'
import useAggregatedPortfolio from 'hooks/services/deals/portfolio/useAggregatedPortfolio'
import useDeal from 'hooks/services/deals/useDeal'

export const useLimitCheckRequestHelperDialogData = ({ dealUuid }) => {
  const { data: { getExternalPropertyType } = {} } = useExternalTypeMappingMethods(dealUuid)
  const {
    data: { businessSegmentCode } = {},
    isLoading: isDealLoading,
    isError: isDealError,
  } = useDeal(dealUuid)
  const {
    data: { countryCodes = [], mainPropertyTypeCode } = {},
    isLoading: isPortfolioLoading,
    isError: isPortfolioError,
  } = useAggregatedPortfolio(dealUuid)

  const data = useMemo(
    () => ({
      businessSegment: businessSegmentCode,
      externalMainPropertyType: getExternalPropertyType(mainPropertyTypeCode),
      country: countryCodes[0],
    }),
    [businessSegmentCode, countryCodes, getExternalPropertyType, mainPropertyTypeCode],
  )

  return {
    data,
    isError: isPortfolioError || isDealError,
    isLoading: isPortfolioLoading || isDealLoading,
  }
}
