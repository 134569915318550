export const TABLE_TEXT_DEFAULT_HEIGHT = 16
export const TABLE_TEXT_DEFAULT_PADDING = 8

const TABLE_CELL_DEFAULT_ITEMS_GAP = 8
const NUMBER_OF_TEXT_LINES_IN_ROW = 2

export const TABLE_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * NUMBER_OF_TEXT_LINES_IN_ROW +
  TABLE_TEXT_DEFAULT_PADDING * 2 +
  TABLE_CELL_DEFAULT_ITEMS_GAP

export const TABLE_HEADER_ROW_HEIGHT =
  TABLE_TEXT_DEFAULT_HEIGHT * 2 + TABLE_TEXT_DEFAULT_PADDING * 2

export const ROW_TYPE_REPO = 'repo'
export const ROW_TYPE_CONDITION = 'condition'
