import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useUpdateRiskConformity = ({ onSuccess, onError }) => {
  const { put } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, body }) => {
      await put({
        path: `/deals/${dealUuid}/risk-conformity`,
        body: snakecaseKeys({
          ...body,
          compliance: !!body?.compliance,
        }),
      })
    },
    { onSuccess, onError },
  )
}

export default useUpdateRiskConformity
