import { t } from 'i18next'
import {
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'routes/deals/financing/tranchesOverview/constants'

const mapTrancheDrawdownData = ({
  trancheId,
  externalContractId,
  loanTypeCode,
  loanTypeShortText,
  productTypeCode,
  productTypeShortText,
  totalCommitment,
  ownShare,
  totalCommitmentHeadquarter,
  maturity,
  repaymentTypeCode,
  repaymentTypeShortText,
  repaymentRate,
  customerMargin,
  interestRateTypeCode,
  interestRateTypeShortText,
  creationTimestamp,
  ownCommitmentShare,
  ownShareHeadquarter,
}) => {
  const hasHeadquarterCurrencyEntry =
    totalCommitmentHeadquarter?.currency !== totalCommitment?.currency

  return {
    isDrawdown: true,
    tranche: {
      name: t('pages.deals.tranches.details.drawdown'),
      externalContractId: externalContractId,
    },
    loanTypeCode: {
      key: loanTypeCode,
      text: loanTypeShortText,
    },
    productTypeCode: {
      key: productTypeCode,
      text: productTypeShortText,
    },
    totalCommitment: {
      original: {
        value: totalCommitment?.amount,
        currency: totalCommitment?.currency,
      },
      converted: {
        value: totalCommitmentHeadquarter?.amount,
        currency: totalCommitmentHeadquarter?.currency,
      },
    },
    commitment: {
      original: {
        value: ownShare?.commitment,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.commitment,
        currency: ownShareHeadquarter?.currency,
      },
      share: ownCommitmentShare,
    },
    syndicatedAmountTotal: {
      original: {
        value:
          totalCommitment?.amount &&
          ownShare?.commitment &&
          totalCommitment?.amount - ownShare?.commitment,
        currency: ownShare?.currency,
      },
      converted: {
        value:
          totalCommitmentHeadquarter?.amount &&
          ownShareHeadquarter?.commitment &&
          totalCommitmentHeadquarter?.amount - ownShareHeadquarter?.commitment,
        currency: ownShareHeadquarter?.currency,
      },
      share: ownCommitmentShare && 1 - ownCommitmentShare,
    },
    outstanding: {
      original: {
        value: ownShare?.outstanding,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.outstanding,
        currency: ownShareHeadquarter?.currency,
      },
    },
    available: {
      original: {
        value: ownShare?.available,
        currency: ownShare?.currency,
      },
      converted: {
        value: ownShareHeadquarter?.available,
        currency: ownShareHeadquarter?.currency,
      },
    },
    maturity: maturity,
    repayment: {
      key: repaymentTypeCode,
      text: repaymentTypeShortText,
      rate: repaymentRate,
    },
    customerMargin: customerMargin,
    interestRateTypeCode: {
      key: interestRateTypeCode,
      text: interestRateTypeShortText,
    },
    creationTimestamp: creationTimestamp,
    trancheId: trancheId,
    rowHeight: hasHeadquarterCurrencyEntry
      ? TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
      : TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
  }
}

export default mapTrancheDrawdownData
