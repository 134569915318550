import { useMutation } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

export const useCreateOrUpdateTrancheFee = (mutationOptions) => {
  const { put, post } = useAccessTokenRequest()

  return useMutation(
    ({
      dealId,
      trancheId,
      feeId,
      feeTypeCode,
      firstPaymentDate,
      paymentFrequencyType,
      amountTotal,
      amountShare,
      comment,
    }) => {
      const body = {
        fee_type_code: feeTypeCode,
        first_payment_date: firstPaymentDate,
        payment_frequency_type: paymentFrequencyType,
        amount_total: amountTotal,
        amount_share: amountShare,
        comment,
      }
      return isNil(feeId)
        ? post({
            path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/fees`,
            body,
          })
        : put({
            path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/fees/${feeId}`,
            body,
          })
    },
    mutationOptions,
  )
}
