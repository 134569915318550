import { t } from 'i18next'
import PropTypes from 'prop-types'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { useAccountingContractualCashFlowCriterionTypeCodes } from 'hooks/services/deals/financing/configGetters/useAccountingContractualCashFlowCriterionTypeCodes'

const useContractualCashFlowCriterionFields = ({ value, code }) => {
  const label = t('pages.deals.tranches.details.accounting-card.contractual-cash-flow-criterion')

  return [
    {
      label,
      name: 'contractualCashFlowCriterionTypeCode',
      value: value,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      label,
      name: 'contractualCashFlowCriterionTypeCode',
      value: code,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: editComponentTypes.LoadingSelect,
      editComponentProps: {
        loadingHook: useAccountingContractualCashFlowCriterionTypeCodes,
        selectionName: 'typeCodes',
        selectedKey: code,
      },
    },
  ]
}

useContractualCashFlowCriterionFields.defaultProps = {
  value: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

export default useContractualCashFlowCriterionFields
