import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerOptionsTableCell from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/CustomerOptionsTableCell'

const useCustomerOptionsTableColumnDefinitions = ({ isEditMode }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.customerOptions-table',
  })

  return useMemo(
    () =>
      [
        {
          Header: t('label.clusterOptionAttribute'),
          accessor: 'clusterOptionAttribute',
        },
        {
          Header: t('label.value'),
          accessor: 'value',
          hAlign: TextAlign.Right,
          minWidth: 400,
        },
      ].map((element) => ({
        disableDragAndDrop: true,
        disableResizing: false,
        Cell: (cellProps) =>
          CustomerOptionsTableCell({
            props: cellProps,
            isEditMode,
          }),
        vAlign: VerticalAlign.Middle,
        ...element,
      })),
    [isEditMode, t],
  )
}

export default useCustomerOptionsTableColumnDefinitions
