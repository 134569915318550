import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CardShowItem } from 'components/domains/deals/card/CardItem'
import DealCustomListCardDisplayItem from 'components/domains/deals/custom-list-card/DealCustomListCardDisplayItem'
import styles from 'components/domains/deals/pricing/DealPricingPanel.module.css'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import useCustomEntityFields from 'hooks/services/deals/configurations/useCustomEntityFields'

const ENTITY_TYPE = 'PRICING'
export const DealPricingStatusInformationPanel = ({
  data: { transaction_id, calculation_id, custom_fields },
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.pricing' })

  const {
    data: { fieldConfigs = [] } = {},
    isLoading = false,
    isError = false,
  } = useCustomEntityFields(ENTITY_TYPE)

  const renderCustomFields = useCallback(
    () =>
      fieldConfigs.map((fieldConfig, index) => (
        <DealCustomListCardDisplayItem
          key={`${fieldConfig.displayName}-${index}`}
          fieldConfig={fieldConfig}
          data={custom_fields}
        />
      )),
    [custom_fields, fieldConfigs],
  )

  return (
    <Panel className={styles.panel} header={<Text>{t('status-info-panel-title')}</Text>} collapsed>
      <div className={styles.section}>
        <CardShowItem label={t('transaction-id')} value={transaction_id} />
        <CardShowItem label={t('calculation-id')} value={calculation_id} />
        <SmallLoadingWrapper
          isLoading={isLoading}
          isError={isError}
          error="Error"
          renderContent={renderCustomFields}
        />
      </div>
    </Panel>
  )
}

DealPricingStatusInformationPanel.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}
