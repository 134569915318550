import { registerCards as registerCwpCustomCards } from '@fioneer/cl-cwp-pbb-frontend-custom'
import { useEffect } from 'react'
import BusinessPartnerAssociatedBorrowers from 'components/domains/business-partners/related-documents/BusinessPartnerAssociatedBorrowers'
import DealBorrowerCard from 'components/domains/deals/borrower/DealBorrowerCard'
import DealBorrowerUnitsCard from 'components/domains/deals/borrower-units/DealBorrowerUnitsCard'
import DealChangeOverviewCard from 'components/domains/deals/change-overview/change-overview-card/DealChangeOverviewCard'
import CovenantCard from 'components/domains/deals/covenants/covenant-card/CovenantCard'
import DealCustomListCard from 'components/domains/deals/custom-list-card/DealCustomListCard'
import DealUsesCard from 'components/domains/deals/deal-uses/DealUsesCard'
import DealDocumentsTableCard from 'components/domains/deals/documents/DealDocumentsTableCard'
import BankCustomerAccountsCard from 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountsCard'
import FinancingCard from 'components/domains/deals/financing/financing-card/FinancingCard'
import CustomAssessmentsWrapper from 'components/domains/deals/general-information/custom-assessments/CustomAssessmentsWrapper'
import DealInformationCard from 'components/domains/deals/information/DealInformationCard'
import DealConsolidationPointsCard from 'components/domains/deals/limit-check/consolidation-points/DealConsolidationPointsCard'
import DealReservationCard from 'components/domains/deals/limit-check/reservations/DealReservationCard'
import DealOverviewInformationCard from 'components/domains/deals/overview/DealOverviewInformationCard'
import BusinessEventsSummaryWrapper from 'components/domains/deals/overview/business-events-summary/BusinessEventsSummaryWrapper'
import DealOverviewCashflowMainScenarioChartCard from 'components/domains/deals/overview/cashflow/DealOverviewCashflowMainScenarioChartCard'
import DealOverviewCollateralsCard from 'components/domains/deals/overview/collaterals/DealOverviewCollateralsCard'
import DealOverviewConditionsCard from 'components/domains/deals/overview/conditions/DealOverviewConditionsCard'
import ExternalTeamMembers from 'components/domains/deals/overview/external-team-members/ExternalTeamMembers'
import InternalTeamMembers from 'components/domains/deals/overview/internal-team-members/InternalTeamMembers'
import MilestonesCard from 'components/domains/deals/overview/milestones/MilestonesCard'
import DealPropertiesOverviewInformationCard from 'components/domains/deals/overview/properties/DealPropertiesOverviewInformationCard'
import StatusHistory from 'components/domains/deals/overview/status-history/StatusHistory'
import DealOverviewSyndicationCard from 'components/domains/deals/overview/syndication/DealOverviewSyndicationCard'
import DealPricingCard from 'components/domains/deals/pricing/DealPricingCard'
import DealRiskConformityCard from 'components/domains/deals/risk-conformity/DealRiskConformityCard'
import { useGetComponentRegistry } from 'components/ui/configurable-components-page/useComponentRegistry'
import i18next from 'i18n/setupI18n'
import DealSyndicationInfoMessageStrip from 'routes/deals/financing/DealSyndicationInfoMessageStrip'
import BondsCard from 'routes/deals/financing/non-loan-products/bonds/BondsCard'
import DerivativesCard from 'routes/deals/financing/non-loan-products/derivatives/DerivativesCard'
import MoneyMarketsCard from 'routes/deals/financing/non-loan-products/moneyMarkets/MoneyMarketsCard'
import OtherCard from 'routes/deals/financing/non-loan-products/other/OtherCard'
import OtherLendingCard from 'routes/deals/financing/non-loan-products/other-lending/OtherLendingCard'
import ReposCard from 'routes/deals/financing/non-loan-products/repos/ReposCard'
import OtherFinancingSourcesCard from 'routes/deals/financing/otherFinancingSources/OtherFinancingSourcesCard'
import AccountingCard from 'routes/deals/financing/trancheDetails/cards/accounting-card/AccountingCard'
import AdditionalInterestInformationCard from 'routes/deals/financing/trancheDetails/cards/additional-interest-information-card/AdditionalInterestInformationCard'
import AmortizationCard from 'routes/deals/financing/trancheDetails/cards/amortization-card/AmortizationCard'
import CustomerOptionsCard from 'routes/deals/financing/trancheDetails/cards/customer-options-card/CustomerOptionsCard'
import BasicAgreementsCustomFieldsCard from 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/BasicAgreementsCustomFieldsCard'
import CustomerSpecificAttributesCardLoader from 'routes/deals/financing/trancheDetails/cards/customer-specific-attributes-card/CustomerSpecificAttributesCardLoader'
import InterestConditionsCard from 'routes/deals/financing/trancheDetails/cards/interest-conditions/InterestConditionsCard'
import InterestRateHedgingRequirementsCard from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/InterestRateHedgingRequirementsCard'
import TranchePricingCard from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingCard'
import TrancheFeesCard from 'routes/deals/financing/trancheDetails/cards/tranche-fees/TrancheFeesCard'
import TrancheGeneralInformationCard from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/TrancheGeneralInformationCard'
import TranchesOverviewCard from 'routes/deals/financing/tranchesOverview/TranchesOverviewCard'
import {
  useOverviewBusinessEventsDisplayHook,
  useFinancingSyndicationInfoMessageStripDisplayHook,
  useFinancingTranchesOverviewDisplayHook,
  useFinancingOtherFinancingSourcesDisplayHook,
  useFinancingBankCustomerAccountsDisplayHook,
  useFinancingDealSignedCheckDisplayHook,
  useFinancingDealConsolidationPointsDisplayHook,
  useDealTranchePricingDisplayHook,
  useDealTrancheInterestConditionsDisplayHook,
  useDealTrancheAdditionalInterestInformationDisplayHook,
  useDealTrancheFeesDisplayHook,
  useDealTrancheAmortizationDisplayHook,
  useDealTrancheAccountingDisplayHook,
  useDealTrancheCustomerSpecificAttributesDisplayHook,
  useDealTrancheBasicAgreementsCustomFieldsDisplayHook,
  useDealTrancheInterestRateHedgingRequirementsDisplayHook,
  useDealTrancheCustomerOptionsDisplayHook,
} from 'routes/deals/useDisplayHooks'

/**
 * @enum
 * @type {{DealOverviewGeneralInformation: string}}
 * @description Deal card codes
 * @readonly
 */
export const DealCardCodesEnum = {
  DealOverviewGeneralInformation: 'DEAL_GENERAL_INFORMATION',
  DealOverviewFinancing: 'DEAL_FINANCING',
  DealOverviewCashflow: 'DEAL_CASH_FLOW',
  DealOverviewConditions: 'DEAL_CONDITIONS',
  DealOverviewFinancedObjects: 'DEAL_FINANCED_OBJECTS',
  DealOverviewCollaterals: 'DEAL_COLLATERALS',
  DealOverviewSyndication: 'DEAL_SYNDICATION',
  DealOverviewCovenants: 'DEAL_COVENANTS',
  DealOverviewChangeOverview: 'DEAL_CHANGE_OVERVIEW',
  DealOverviewStatusHistory: 'DEAL_STATUS_HISTORY',
  DealOverviewMilestones: 'DEAL_MILESTONES',
  DealOverviewBusinessEvents: 'BUSINESS_EVENTS_EVENTS_SUMMARY',
  DealOverviewInternalTeamMembers: 'DEAL_INTERNAL_TEAM_MEMBERS',
  DealOverviewExternalTeamMembers: 'DEAL_EXTERNAL_TEAM_MEMBERS',
  DealGeneralInformationInfo: 'DEAL_INFORMATION',
  DealGeneralInformationReporting: 'DEAL_REPORTING',
  DealGeneralInformationRiskConformity: 'DEAL_RISK_CONFORMITY',
  DealGeneralInformationBorrowers: 'DEAL_BORROWERS',
  DealGeneralInformationUnits: 'DEAL_UNITS',
  DealGeneralInformationUses: 'DEAL_USES',
  DealGeneralInformationDealPricing: 'DEAL_DEAL_PRICING',
  DealGeneralInformationCustomAssessments: 'DEAL_CUSTOM_ASSESSMENTS',
  DealFinancingSyndicationInfoMessageStrip: 'DEAL_SYNDICATION_INFO_MESSAGE_STRIP',
  DealFinancingTranchesOverview: 'DEAL_FINANCING_TRANCHES_OVERVIEW',
  DealFinancingOtherFinancingSources: 'DEAL_FINANCING_OTHER_FINANCING_SOURCES',
  DealFinancingDerivatives: 'DEAL_FINANCING_DERIVATIVES',
  DealFinancingMoneyMarkets: 'DEAL_FINANCING_MONEY_MARKETS',
  DealFinancingBankCustomerCreditAccounts: 'DEAL_FINANCING_BANK_CUSTOMER_CREDIT_ACCOUNTS',
  DealFinancingBankCustomerOtherAccounts: 'DEAL_FINANCING_BANK_CUSTOMER_OTHER_ACCOUNTS',
  DealFinancingBonds: 'DEAL_FINANCING_BONDS',
  DealFinancingRepos: 'DEAL_FINANCING_REPOS',
  DealFinancingOtherLending: 'DEAL_FINANCING_OTHER_LENDING',
  DealFinancingOther: 'DEAL_FINANCING_OTHER',
  DealFinancingConsolidationPoints: 'DEAL_FINANCING_CONSOLIDATION_POINTS',
  DealFinancingReservation: 'DEAL_FINANCING_RESERVATION',
  DealTrancheGeneralInformation: 'DEAL_TRANCHE_GENERAL_INFORMATION',
  DealTranchePricing: 'DEAL_TRANCHE_PRICING',
  DealTrancheInterestConditions: 'DEAL_TRANCHE_INTEREST_CONDITIONS',
  DealTrancheAdditionalInterestInformation: 'DEAL_TRANCHE_ADDITIONAL_INTEREST_INFORMATION',
  DealTrancheFees: 'DEAL_TRANCHE_FEES',
  DealTrancheAmortization: 'DEAL_TRANCHE_AMORTIZATION',
  DealTrancheAccounting: 'DEAL_TRANCHE_ACCOUNTING',
  DealTrancheCustomerSpecificAttributes: 'DEAL_TRANCHE_CUSTOMER_SPECIFIC_ATTRIBUTES',
  DealTrancheBasicAgreementsCustomFields: 'DEAL_TRANCHE_BASIC_AGREEMENTS_CUSTOM_FIELDS',
  DealTrancheInterestRateHedgingRequirements: 'DEAL_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENTS',
  DealTrancheCustomerOptions: 'DEAL_TRANCHE_CUSTOMER_OPTIONS',
  BusinessPartnerAssociatedBorrowers: 'BUSINESS_PARTNER_ASSOCIATED_BORROWERS',
  DealDocuments: 'DEAL_DOCUMENTS',
}

export const useDealDynamicCards = () => {
  const registry = useGetComponentRegistry()

  useEffect(() => {
    // register custom cards package
    registerCwpCustomCards(registry, i18next)

    // Overview
    registry.register(DealCardCodesEnum.DealOverviewGeneralInformation, {
      Component: DealOverviewInformationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewFinancing, {
      Component: FinancingCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCashflow, {
      Component: DealOverviewCashflowMainScenarioChartCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewConditions, {
      Component: DealOverviewConditionsCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewFinancedObjects, {
      Component: DealPropertiesOverviewInformationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCollaterals, {
      Component: DealOverviewCollateralsCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewSyndication, {
      Component: DealOverviewSyndicationCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewCovenants, {
      Component: CovenantCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewChangeOverview, {
      Component: DealChangeOverviewCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewStatusHistory, {
      Component: StatusHistory,
    })
    registry.register(DealCardCodesEnum.DealOverviewMilestones, {
      Component: MilestonesCard,
    })
    registry.register(DealCardCodesEnum.DealOverviewBusinessEvents, {
      Component: BusinessEventsSummaryWrapper,
      componentDisplayHook: useOverviewBusinessEventsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealOverviewInternalTeamMembers, {
      Component: InternalTeamMembers,
    })
    registry.register(DealCardCodesEnum.DealOverviewExternalTeamMembers, {
      Component: ExternalTeamMembers,
    })

    // General Information
    registry.register(DealCardCodesEnum.DealGeneralInformationInfo, {
      Component: DealInformationCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationReporting, {
      Component: DealCustomListCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationRiskConformity, {
      Component: DealRiskConformityCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationBorrowers, {
      Component: DealBorrowerCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationUnits, {
      Component: DealBorrowerUnitsCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationUses, {
      Component: DealUsesCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationDealPricing, {
      Component: DealPricingCard,
    })
    registry.register(DealCardCodesEnum.DealGeneralInformationCustomAssessments, {
      Component: CustomAssessmentsWrapper,
    })

    // Financing
    registry.register(DealCardCodesEnum.DealFinancingSyndicationInfoMessageStrip, {
      Component: DealSyndicationInfoMessageStrip,
      componentDisplayHook: useFinancingSyndicationInfoMessageStripDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingTranchesOverview, {
      Component: TranchesOverviewCard,
      componentDisplayHook: useFinancingTranchesOverviewDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingOtherFinancingSources, {
      Component: OtherFinancingSourcesCard,
      componentDisplayHook: useFinancingOtherFinancingSourcesDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingBankCustomerCreditAccounts, {
      Component: BankCustomerAccountsCard,
      componentDisplayHook: useFinancingBankCustomerAccountsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingBankCustomerOtherAccounts, {
      Component: BankCustomerAccountsCard,
      componentDisplayHook: useFinancingBankCustomerAccountsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingDerivatives, {
      Component: DerivativesCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingMoneyMarkets, {
      Component: MoneyMarketsCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingBonds, {
      Component: BondsCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingRepos, {
      Component: ReposCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingOtherLending, {
      Component: OtherLendingCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingOther, {
      Component: OtherCard,
      componentDisplayHook: useFinancingDealSignedCheckDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingConsolidationPoints, {
      Component: DealConsolidationPointsCard,
      componentDisplayHook: useFinancingDealConsolidationPointsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealFinancingReservation, {
      Component: DealReservationCard,
    })

    // Tranche Details
    registry.register(DealCardCodesEnum.DealTrancheGeneralInformation, {
      Component: TrancheGeneralInformationCard,
    })
    registry.register(DealCardCodesEnum.DealTranchePricing, {
      Component: TranchePricingCard,
      componentDisplayHook: useDealTranchePricingDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheInterestConditions, {
      Component: InterestConditionsCard,
      componentDisplayHook: useDealTrancheInterestConditionsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheAdditionalInterestInformation, {
      Component: AdditionalInterestInformationCard,
      componentDisplayHook: useDealTrancheAdditionalInterestInformationDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheFees, {
      Component: TrancheFeesCard,
      componentDisplayHook: useDealTrancheFeesDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheAmortization, {
      Component: AmortizationCard,
      componentDisplayHook: useDealTrancheAmortizationDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheAccounting, {
      Component: AccountingCard,
      componentDisplayHook: useDealTrancheAccountingDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheCustomerSpecificAttributes, {
      Component: CustomerSpecificAttributesCardLoader,
      componentDisplayHook: useDealTrancheCustomerSpecificAttributesDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheBasicAgreementsCustomFields, {
      Component: BasicAgreementsCustomFieldsCard,
      componentDisplayHook: useDealTrancheBasicAgreementsCustomFieldsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheInterestRateHedgingRequirements, {
      Component: InterestRateHedgingRequirementsCard,
      componentDisplayHook: useDealTrancheInterestRateHedgingRequirementsDisplayHook,
    })
    registry.register(DealCardCodesEnum.DealTrancheCustomerOptions, {
      Component: CustomerOptionsCard,
      componentDisplayHook: useDealTrancheCustomerOptionsDisplayHook,
    })

    // Documents
    registry.register(DealCardCodesEnum.BusinessPartnerAssociatedBorrowers, {
      Component: BusinessPartnerAssociatedBorrowers,
    })
    registry.register(DealCardCodesEnum.DealDocuments, {
      Component: DealDocumentsTableCard,
    })
  }, [registry])
}
