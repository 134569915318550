import {
  Label,
  FlexBox,
  FlexBoxJustifyContent,
  Grid,
  FlexBoxAlignItems,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import styles from 'routes/deals/financing/shared-components/AmountWithCurrencyEditInput.module.css'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'
import isAmountValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isAmountValid'

const AmountWithCurrencyEditInput = ({
  name,
  label,
  handleOnChange,
  fieldValues,
  validationFunction,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  const formattedNumberInputRef = useRef()

  validationFunction = validationFunction ?? (({ amount }) => isAmountValid(amount))
  const handleOnChangeAmount = (newValue, isInternallyValid) => {
    const completeValidationFunction = (value) => isInternallyValid && validationFunction(value)
    handleOnChange(
      name,
      {
        amount: convertToNumberIfValid(newValue),
        currency: fieldValues?.[name]?.currency,
      },
      true,
      true,
      completeValidationFunction,
    )
  }

  return (
    <Grid position="Left" defaultIndent="XL0 L0 M0 S0" defaultSpan="XL6 L6 M6 S6">
      <FlexBox className={styles.labelWrapper} alignItems={FlexBoxAlignItems.Center}>
        <Label>{label}</Label>
      </FlexBox>
      <FlexBox className={styles.inputWrapper} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <FormattedNumberInput
          id={`input-${name}-amount`}
          required
          className={styles.inputField}
          value={convertToNumberIfValid(fieldValues?.[name]?.amount)}
          valueState={validationFunction(fieldValues?.[name]) ? ValueState.None : ValueState.Error}
          valueStateMessage={<span>{t('hint.greater-than-zero')}</span>}
          onInput={(value) => {
            const isInternallyValid = formattedNumberInputRef?.current?.isValid
            handleOnChangeAmount(value, isInternallyValid)
          }}
          ref={formattedNumberInputRef}
        />
        <LoadingSelect
          id={`select-${name}-currency`}
          required
          className={styles.currencyInputSelect}
          onChange={(event) => {
            const newValue = event?.detail?.selectedOption?.dataset?.id
            handleOnChange(
              [name],
              {
                amount: fieldValues?.[name]?.amount,
                currency: newValue,
              },
              false,
              true,
            )
          }}
          loadingHook={useCurrencyCodes}
          selectionName="currencyCodes"
          optionKeyName="key"
          optionDisplayName="display_name"
          selectedKey={fieldValues?.[name]?.currency}
          showEmptyOption={false}
        />
      </FlexBox>
    </Grid>
  )
}

AmountWithCurrencyEditInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  fieldValues: PropTypes.object,
  validationFunction: PropTypes.func,
}

export default AmountWithCurrencyEditInput
