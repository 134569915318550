import camelize from 'camelize'
import { useRequest } from 'hooks/services/baseService'

export const useBorrowerRolesType = (options) => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/BUSINESS_PARTNER_ROLE_TYPE`,
    options,
  })
  return {
    ...result,
    data: camelize(result?.data?.configuration?.BUSINESS_PARTNER_ROLE_TYPE),
  }
}
