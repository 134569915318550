import { Table, TableColumn, TableRow } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import TableCell from 'components/ui/tables/cells/TableCell'
import { UI5_TABLE_CELL_PADDING } from 'routes/deals/financing/trancheDetails/cards/multi-select-card/constants'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-table/MultiSelectCardTable.module.css'

export const ColumnHideOptions = {
  hideInView: 'hideInView',
  hideInEdit: 'hideInEdit',
}

const MultiSelectCardTable = ({
  columnDefinitions,
  inEditMode,
  dataRows,
  noDataText,
  rowsInEdit,
}) => {
  const shouldHideColumn = (column) =>
    (inEditMode && column.hideOption === ColumnHideOptions.hideInEdit) ||
    (!inEditMode && column.hideOption === ColumnHideOptions.hideInView)

  const renderCell = (...args) => {
    const [column, ...otherArgs] = args
    return inEditMode ? column.cellValueEdit(...otherArgs) : column.cellValue(...otherArgs)
  }

  const renderCellValue = (column, row) => {
    switch (column.columnKey) {
      case 'checkboxSelector':
        return renderCell(column, row, { isSelectable: true })
      default:
        return renderCell(column, row)
    }
  }

  const renderRow = (dataRow) => (
    <TableRow key={dataRow.id}>
      {columnDefinitions.map((column) => {
        if (!shouldHideColumn(column)) {
          return (
            <TableCell
              className={styles.tableCellPadding}
              key={`${dataRow.id}-${column.columnKey}`}
            >
              {renderCellValue(column, dataRow)}
            </TableCell>
          )
        }
      })}
    </TableRow>
  )

  const renderTableRows = () => (
    <>{inEditMode ? rowsInEdit.map(renderRow) : dataRows.map(renderRow)}</>
  )

  let minWidthSum = 0

  return (
    <Table
      columns={columnDefinitions.map((column) => {
        if (!shouldHideColumn(column)) {
          const minWidth = inEditMode ? column.minWidthEdit : column.minWidthDisplay
          const hasPopin = !!minWidth && !!column.popinText

          // we still add every column's minWidth to the sum,
          // because we need the overall width relative to the table width for the breakpoints
          minWidthSum += (minWidth ?? 0) + UI5_TABLE_CELL_PADDING

          const popInDef = {
            demandPopin: true,
            minWidth: minWidthSum,
            popinText: column.popinText,
          }
          return (
            <TableColumn key={column.columnKey} {...(hasPopin && { ...popInDef })}>
              {column.title()}
            </TableColumn>
          )
        }
      })}
      noDataText={noDataText}
      className={styles.table}
    >
      {renderTableRows()}
    </Table>
  )
}

MultiSelectCardTable.propTypes = {
  inEditMode: PropTypes.bool,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      title: PropTypes.func,
      popinText: PropTypes.string,
      minWidthDisplay: PropTypes.number,
      minWidthEdit: PropTypes.number,
      cellValue: PropTypes.func,
      cellValueEdit: PropTypes.func,
      hideOption: PropTypes.oneOf(['hideInView', 'hideInEdit']),
    }),
  ).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.object),
  noDataText: PropTypes.string,
  rowsInEdit: PropTypes.array,
}

export default MultiSelectCardTable
