import { Button, Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { MessageBoxTypes, useShowMessageBox } from 'components/ui/message-box/MessageBox'
import { useCalculateInterestRateHedgingRisks } from 'hooks/services/deals/financing/interestRateHedgingRequirements/useCalculateInterestRateHedgingRisks'
import { useEditTrancheInterestRateHedgingRequirements } from 'hooks/services/deals/financing/interestRateHedgingRequirements/useEditTrancheInterestRateHedgingRequirements'
import useTrancheInterestRateHedgingRequirements from 'hooks/services/deals/financing/interestRateHedgingRequirements/useTrancheInterestRateHedgingRequirements'
import useTranche from 'hooks/services/deals/financing/useTranche'
import useTrancheByDisplayId from 'hooks/services/deals/financing/useTrancheByDisplayId'
import { DealContext } from 'routes/deals/DealContext'
import styles from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/InterestRateHedgingRequirementsCard.module.css'
import useInterestRateHedgeFieldDefinitions from 'routes/deals/financing/trancheDetails/cards/interest-rate-hedging-requirements-card/fields/useInterestRateHedgeFieldDefinitions'

const InterestRateHedgingRequirementsCard = () => {
  const { t: tNoPrefix } = useTranslation()
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.interest-rate-hedging-requirement-card',
  })

  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const queryClient = useQueryClient()
  const showMessageBox = useShowMessageBox()
  const showToast = Modals.useShowToast()

  const { trancheDisplayId } = useParams()
  const { data: resolvedTrancheData } = useTrancheByDisplayId(deal.dealUuid, trancheDisplayId)
  const { data: trancheData } = useTranche(deal.dealUuid, resolvedTrancheData.trancheId)

  const { data, isLoading, isError, isFetching } = useTrancheInterestRateHedgingRequirements(
    deal.dealUuid,
    resolvedTrancheData.trancheId,
  )

  const [
    requiredHedgeAmountTotalForRisksCalculation,
    setRequiredHedgeAmountTotalForRisksCalculation,
  ] = useState()
  const [
    requiredHedgeAmountShareForRisksCalculation,
    setRequiredHedgeAmountShareForRisksCalculation,
  ] = useState()
  const [derivativeLineMaturityForRisksCalculation, setDerivativeLineMaturityForRisksCalculation] =
    useState()
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const invalidateInterestRateHeadgingRequirements = () =>
    queryClient.invalidateQueries([
      'deals',
      deal.dealUuid,
      'tranches',
      resolvedTrancheData.trancheId,
      'interestRateHedgingRequirements',
    ])

  const updateMutation = useEditTrancheInterestRateHedgingRequirements(
    {
      onSuccess: () => {
        setIsWritingToBackend(false)
        invalidateInterestRateHeadgingRequirements()
        showToast({ children: tNoPrefix('toast.changes-saved') })
      },
    },
    { dealId: deal.dealUuid, trancheId: resolvedTrancheData.trancheId },
  )

  const [calculatedRisksData, setCalculatedRisksData] = useState({})

  const updateCalculatedRisksData = (data) => {
    setCalculatedRisksData({
      replacementRisk: {
        value: data.replacement_risk.amount,
        valueHeadquarter: data.replacement_risk_headquarter?.amount,
      },
      settlementRisk: {
        value: data.settlement_risk.amount,
        valueHeadquarter: data.settlement_risk_headquarter?.amount,
      },
    })
  }

  const calculateRisksMutation = useCalculateInterestRateHedgingRisks(
    {
      onSuccess: ({ data: returnedData }) => {
        setIsWritingToBackend(false)
        updateCalculatedRisksData(returnedData)
      },
      onError: () => {
        setIsWritingToBackend(false)
        showMessageBox({
          type: MessageBoxTypes.Error,
          className: styles.errorMessageBox,
          children: (
            <div className={styles.errorMessageBoxDescription}>
              {t('calculate-risks.errorMessage', {
                returnObjects: true,
              }).map((e) => (
                <div key={e}>{e}</div>
              ))}
            </div>
          ),
        })
      },
    },
    {
      dealId: deal.dealUuid,
      trancheId: resolvedTrancheData.trancheId,
    },
  )

  const {
    totalCommitment: { currency: trancheCurrency } = {},
    maturity: trancheMaturity,
    ownShare: { commitment: trancheOwnShareCommitment } = {},
  } = resolvedTrancheData

  const { firstDrawdownDate } = trancheData

  const { lastEditedText } = useLastEditedTextByEmail({
    email: data?.lastUpdated?.name,
    timestamp: data?.lastUpdated?.lastUpdatedOn,
  })

  const [areCollapsibleFieldsOpen, setAreCollapsibleFieldsOpen] = useState(data?.required)

  useEffect(() => {
    setAreCollapsibleFieldsOpen(data?.required)
  }, [data])

  const fieldDefinitions = useInterestRateHedgeFieldDefinitions({
    data,
    calculatedRisksData,
    areCollapsibleFieldsOpen,
    setAreCollapsibleFieldsOpen,
    trancheCurrency,
    trancheMaturity,
    trancheOwnShareCommitment,
    requiredHedgeAmountTotalForRisksCalculation,
    setRequiredHedgeAmountTotalForRisksCalculation,
    requiredHedgeAmountShareForRisksCalculation,
    setRequiredHedgeAmountShareForRisksCalculation,
    derivativeLineMaturityForRisksCalculation,
    setDerivativeLineMaturityForRisksCalculation,
  })

  const onSaveClicked = (editedFields) => {
    setIsWritingToBackend(true)
    updateMutation.mutate({ data: editedFields })
  }

  const onCancelChanges = () => {
    setAreCollapsibleFieldsOpen(data?.required)
  }

  const getRiskCalculationsRequestBody = () => ({
    derivativeLineMaturity: derivativeLineMaturityForRisksCalculation,
    requiredHedgeAmountTotal: {
      amount: requiredHedgeAmountTotalForRisksCalculation,
      currency: trancheCurrency,
    },
    trancheFirstDrawdownDate: firstDrawdownDate,
  })

  const getRiskCalculations = () => {
    calculateRisksMutation.mutate({ data: getRiskCalculationsRequestBody() })
  }

  const CalculateRisksButton = () => (
    <Button
      data-testid="calculateRisks-button"
      disabled={!areCollapsibleFieldsOpen}
      onClick={getRiskCalculations}
      className={styles.riskCalculationButton}
    >
      {t('calculate-risks')}
    </Button>
  )

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t(`title`)}
      cardHeaderSubtitle={lastEditedText}
      additionalEditHeaderProps={{
        additionalActions: <CalculateRisksButton />,
        isSaveLoading: isWritingToBackend,
      }}
      fieldDefinitions={fieldDefinitions}
      // Overwrites the loading state used for the LoadingStateWrapper after save has been clicked.
      // The above `isSaveLoading` is used instead to set an inert card state
      isHandlingSaveOverwrite={false}
      // we only pass the fetching state as the data for this card is delivered by a prop
      // and the card itself is already wrapped in a LoadingStateWrapper for the initial loading
      isLoading={isFetching || isLoading}
      isEmpty={false}
      isEditable={allowedOperations.includes(
        FINANCING_TRANCHE_INTEREST_RATE_HEDGING_REQUIREMENT_UPDATE,
      )}
      isError={isError}
      saveHookIsSuccess={updateMutation?.isSuccess}
      saveHookIsError={updateMutation?.isError}
      fillEmptyValuesWithPlaceholder={true}
      saveChanges={onSaveClicked}
      cancelChanges={onCancelChanges}
    />
  )
}

InterestRateHedgingRequirementsCard.propTypes = {
  interestRateHedgingRequirements: PropTypes.exact({
    derivativeLineMaturity: PropTypes.string,
    comment: PropTypes.string,
    lastUpdated: PropTypes.shape({
      name: PropTypes.string,
      lastUpdatedOn: PropTypes.string,
    }),
  }),
}

export default InterestRateHedgingRequirementsCard
