import { Modals } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import useDealInformationFieldDefinitions from 'components/domains/deals/information/useDealInformationFieldDefinitions'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { useUpdateDeal } from 'hooks/services/deals/useUpdateDeal'
import { DealContext } from 'routes/deals/DealContext'

export const DealInformationCard = ({ pageData }) => {
  const { deal = {} } = pageData

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.general-information.information',
  })
  const { t: tNoPrefix } = useTranslation('translation')
  const { allowedOperations } = useContext(DealContext)

  const { isLoading: isLoadingFieldDefinitions, data: { fieldDefinitions } = {} } =
    useDealInformationFieldDefinitions(deal)

  const queryClient = useQueryClient()

  const { lastEditedText } = useLastEditedTextByEmail({
    email: deal.lastUpdatedBy,
    timestamp: deal.lastUpdatedAt,
  })

  const onDealUpdated = async () => {
    await queryClient.invalidateQueries(['deals', deal.dealUuid])
    Modals.showToast({ children: tNoPrefix('toast.changes-saved') })
  }

  const {
    mutate: updateDeal,
    isSuccess: isSuccessUpdateDeal,
    isError: isErrorUpdateDeal,
  } = useUpdateDeal({
    onSuccess: onDealUpdated,
    onError: () => {},
  })

  const onSaveChanges = (dealChanges) => {
    updateDeal({ dealUuid: deal.dealUuid, dealUpdates: dealChanges })
  }

  const onCancelChanges = () => {}

  return (
    <DisplayAndEditCard
      cardHeaderTitle={t('title')}
      cardHeaderSubtitle={lastEditedText}
      isLoading={isLoadingFieldDefinitions}
      fieldDefinitions={fieldDefinitions}
      fillEmptyValuesWithPlaceholder
      saveChanges={onSaveChanges}
      saveHookIsError={isErrorUpdateDeal}
      saveHookIsSuccess={isSuccessUpdateDeal}
      cancelChanges={onCancelChanges}
      isEditable={allowedOperations.includes(dealsPermissions.updateDeal)}
    />
  )
}

DealInformationCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string.isRequired,
      lastUpdatedBy: PropTypes.string.isRequired,
      lastUpdatedAt: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default DealInformationCard
