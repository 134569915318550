import {
  CheckBox,
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  Modals,
  Text,
  FlexBox,
  FlexBoxDirection,
} from '@fioneer/ui5-webcomponents-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import camelize from 'camelize'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import z from 'zod'
import styles from 'components/domains/deals/financing/tranche-create/TrancheCreateDialog.module.css'
import NameInput from 'components/domains/deals/financing/tranche-create/input/NameInput'
import OwnShareInput from 'components/domains/deals/financing/tranche-create/input/OwnShareInput'
import TotalCommitmentInput from 'components/domains/deals/financing/tranche-create/input/TotalCommitmentInput'
import CurrencySelect from 'components/domains/deals/financing/tranche-create/select/CurrencySelect'
import LoanTypeSelect from 'components/domains/deals/financing/tranche-create/select/LoanTypeSelect'
import ProductTypeSelect from 'components/domains/deals/financing/tranche-create/select/ProductTypeSelect'
import Dialog, { DialogSize, DialogPrimaryButton } from 'components/ui/dialog/Dialog'
import useValidatedHandleSubmit from 'components/ui/input/useValidatedHandleSubmit'
import { useCreateTranche } from 'hooks/services/deals/financing/useCreateTranche'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import paths from 'routes/paths'
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js'

const useFormSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.deals.tranche-create.basic-information.validation',
  })

  return useMemo(
    () =>
      z
        .object({
          name: z.string().min(1, { message: t('name') }),
          productTypeCode: z.string().min(1, { message: t('product-type') }),
          loanTypeCode: z.string().min(1, { message: t('loan-type') }),
          currencyCode: z.string().min(1, { message: t('currency') }),
          totalCommitment: z
            .number({
              invalid_type_error: t('total-commitment'),
              required_error: t('total-commitment'),
              coerce: true,
            })
            .nonnegative(t('total-commitment')),
          ownShare: z
            .number({
              invalid_type_error: t('own-share'),
              required_error: t('own-share'),
              coerce: true,
            })
            .nonnegative(t('own-share')),
        })
        .superRefine((data, ctx) => {
          const { totalCommitment, ownShare } = data

          if (isNil(totalCommitment) || isNil(ownShare)) return
          if (totalCommitment >= ownShare) return

          ctx.addIssue({
            path: ['ownShare'],
            message: t('own-share'),
            code: 'too_big',
            inclusive: true,
            maximum: totalCommitment,
            type: 'number',
          })
        }),
    [t],
  )
}

const propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const TrancheCreateDialog = ({ onClose, open }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    deal: { dealUuid, displayId: dealDisplayId },
  } = useContext(DealContext)
  const { data: deal } = useDealMini(dealUuid)

  const initialTranche = {
    name: '',
    productTypeCode: '',
    loanTypeCode: '',
    currencyCode: '',
    totalCommitment: undefined,
    ownShare: undefined,
  }

  // HINT: resetting the state of the input fields (the HTML way) is currently only working for `Input` components
  //       => use a "cachebuster" as key to reset the state of the input fields
  // TODO: make it work automatically for the other components as well
  //       looks like the InputFormSupport is not quite working as expected
  //       please try upgrading UI5 first before trying to fix it manually
  const [cachebuster, setCachebuster] = useState(0)

  const form = useForm({
    mode: 'all',
    values: initialTranche,
    resolver: zodResolver(useFormSchema()),
  })

  const isValidTranche = form.formState.isValid
  const hasChanges = form.formState.isDirty

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isMultiTrancheCreateCheckBoxActivated, setIsMultiTrancheCreateCheckBoxActivated] =
    useState(false)
  const [hasCreatedAtLeastOneTranche, setHasCreatedAtLeastOneTranche] = useState(false)

  const queryClient = useQueryClient()

  const resetState = () => {
    form.reset(initialTranche)
    setCachebuster((prev) => prev + 1)
  }

  const handleOnCloseDialog = (force = false, event) => {
    if (hasChanges && !force) {
      event?.preventDefault()
      setIsCancelDialogOpen(true)
    } else {
      if (hasCreatedAtLeastOneTranche) {
        queryClient.invalidateQueries(['deals', dealUuid, 'tranches'])
        queryClient.invalidateQueries(['deals', dealUuid, 'syndication'])
      }
      resetState()
      setIsMultiTrancheCreateCheckBoxActivated(false)
      onClose()
      setHasCreatedAtLeastOneTranche(false)
    }
  }

  const onSuccessNavigate = (displayId, trancheDisplayId) =>
    navigate(
      `/${
        paths.deals
      }/${displayId}/financing/tranches/${trancheDisplayId}?${searchParams.toString()}`,
    )

  const createTranche = useCreateTranche({
    onSuccess: (response) => {
      setHasCreatedAtLeastOneTranche(true)
      const { displayId: trancheDisplayId } = camelize(response)
      Modals.showToast({
        children: <Text>{t('pages.deals.tranche-create.create-tranche-successful')}</Text>,
      })

      resetState()

      if (!isMultiTrancheCreateCheckBoxActivated) {
        handleOnCloseDialog(true)
        queryClient.invalidateQueries(['deals', dealUuid, 'tranches'])
        queryClient.invalidateQueries(['deals', dealUuid, 'syndication'])
        onSuccessNavigate(dealDisplayId, trancheDisplayId)
      }
    },
    onError: () => {
      setIsErrorDialogOpen(true)
    },
  })

  const { handleSubmit: handleOnSubmitTranche, isSubmitDisabled } = useValidatedHandleSubmit(
    (e) => {
      e.preventDefault()
      if (isValidTranche && !createTranche.isLoading) {
        createTranche.mutate({ tranche: form.getValues(), deal })
      }
    },
    form,
  )

  const onCloseCancelDialog = (event) => {
    setIsCancelDialogOpen(false)

    if (event.detail.action === MessageBoxActions.OK) {
      if (hasCreatedAtLeastOneTranche) {
        queryClient.invalidateQueries(['deals', dealUuid, 'tranches'])
        queryClient.invalidateQueries(['deals', dealUuid, 'syndication'])
      }
      resetState()
      setIsMultiTrancheCreateCheckBoxActivated(false)
      onClose()
      setHasCreatedAtLeastOneTranche(false)
    }
  }

  const onCloseErrorDialog = (event) => {
    setIsErrorDialogOpen(false)
    if (event.detail.action === MessageBoxActions.Retry) {
      handleOnSubmitTranche(event)
    }
  }

  return (
    <>
      <Dialog
        id="tranche-create-dialog"
        open={open}
        headerText={t('pages.deals.tranche-create.create-tranche')}
        size={DialogSize.M}
        primaryButton={
          <DialogPrimaryButton
            id="tranche-create-dialog-submit-button"
            design="Emphasized"
            onClick={handleOnSubmitTranche}
            disabled={isSubmitDisabled}
          >
            {t('buttons.create')}
          </DialogPrimaryButton>
        }
        onBeforeClose={(event) => {
          handleOnCloseDialog(false, event)
        }}
        leftFooterContent={
          <CheckBox
            id="tranche-create-dialog-checkbox"
            text={t('pages.deals.tranche-create.create-tranche.create-multiple-checkbox')}
            checked={isMultiTrancheCreateCheckBoxActivated}
            onChange={(event) => setIsMultiTrancheCreateCheckBoxActivated(event.target.checked)}
          />
        }
      >
        <FormProvider {...form}>
          <form key={cachebuster}>
            <FlexBox direction={FlexBoxDirection.Column} className={styles.containerFlexBox}>
              <NameInput />
              <ProductTypeSelect />
              <LoanTypeSelect />
              <CurrencySelect />
              <TotalCommitmentInput />
              <OwnShareInput />
            </FlexBox>
          </form>
        </FormProvider>
      </Dialog>

      <MessageBox
        id={'tranche-create-cancel-dialog'}
        type={MessageBoxTypes.Warning}
        open={isCancelDialogOpen}
        onClose={onCloseCancelDialog}
        actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
        emphasizedAction={MessageBoxActions.OK}
        titleText={t('pages.deals.tranche-create.cancel-dialog-title')}
        className={styles.modalDialog}
      >
        <div className={styles.modalDialogDescription}>
          {t('pages.deals.tranche-create.cancel-dialog-text')}
        </div>
      </MessageBox>

      <MessageBox
        id={'tranche-create-error-dialog'}
        type={MessageBoxTypes.Error}
        open={isErrorDialogOpen}
        onClose={onCloseErrorDialog}
        actions={[MessageBoxActions.Retry, MessageBoxActions.Cancel]}
        emphasizedAction={MessageBoxActions.Retry}
        titleText={t('pages.deals.tranche-create.error-dialog.title')}
        className={styles.modalDialog}
      >
        <div className={styles.modalDialogDescription}>
          {t('pages.deals.tranche-create.error-dialog.description', {
            returnObjects: true,
          }).map((element) => (
            <div key={element}>{element}</div>
          ))}
        </div>
      </MessageBox>
    </>
  )
}

TrancheCreateDialog.propTypes = propTypes

export default TrancheCreateDialog
