import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import mapBondsConditionData from 'components/domains/business-partners/tile/financial-product/bonds/mapper/mapBondsConditionData'
import {
  TABLE_ROW_HEIGHT,
  ROW_TYPE_BOND,
} from 'routes/deals/financing/non-loan-products/bonds/constants'

const mapBondsByNonLoanProductsData = (data) => {
  if (isNil(data) || isEmpty(data)) {
    return {
      rows: [],
      amountOfBonds: 0,
    }
  }
  const trades = data?.trades ?? []

  const bonds = trades.map((trade) => {
    const {
      isin,
      productType,
      productSubType,
      limitSystemProductClass,
      issuerId,
      mtm,
      mtmHeadquarter,
      amortization,
      startDate,
      maturityDate,
      initialNotional,
      initialNotionalHeadquarter,
      currentNotional,
      currentNotionalHeadquarter,
      buyOrSell,
      rateType,
      rateIndexName,
      tenor,
      currentRate,
      conditions,
    } = trade ?? {}
    return {
      rowHeight: TABLE_ROW_HEIGHT,
      rowType: ROW_TYPE_BOND,

      bondCondition: isin,
      productType,
      productSubType,
      limitSystemProductClass,
      issuerCounterparty: issuerId,
      amortization,
      startDate,
      maturityDate,
      buyOrSell,

      mtm: {
        original: {
          amount: mtm?.amount,
          currency: mtm?.currency,
        },
        headquarter: {
          amount: mtmHeadquarter?.amount,
          currency: mtmHeadquarter?.currency,
        },
      },
      initialNotional: {
        original: {
          amount: initialNotional?.amount,
          currency: initialNotional?.currency,
        },
        headquarter: {
          amount: initialNotionalHeadquarter?.amount,
          currency: initialNotionalHeadquarter?.currency,
        },
      },
      currentNotional: {
        original: {
          amount: currentNotional?.amount,
          currency: currentNotional?.currency,
        },
        headquarter: {
          amount: currentNotionalHeadquarter?.amount,
          currency: currentNotionalHeadquarter?.currency,
        },
      },
      rateType,
      rateIndexName,
      tenor,
      currentCouponRate: currentRate,

      subRows: mapBondsConditionData(conditions),
    }
  })

  return {
    amountOfBonds: bonds.length,
    rows: bonds,
  }
}

export default mapBondsByNonLoanProductsData
