import PropTypes from 'prop-types'
import { useContext } from 'react'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'

/**
 * Returns true/false whether BorrowerUnits should be read only wrt. the deal adjustment process
 * @param borrowerBpId, e.g. the borrower of the working version to be checked
 * @returns {isReadOnlyForDealAdjustment, isWorkingVersion}
 */
const useBorrowerUnitsReadOnlyForDealAdjustments = (borrowerBpId) => {
  const {
    deal: currentDeal,
    dealHeaders: { live: dealHeaderLive },
  } = useContext(DealContext)
  const isWorkingVersion = currentDeal.workingVersion === WorkingVersionType.WORKING_VERSION
  const liveDealUuid = isWorkingVersion ? dealHeaderLive.dealUuid : null //useDealMini does not fetch if null
  const miniResult = useDealMini(liveDealUuid)
  const liveDealMini = miniResult.data ?? {}

  const isReadOnlyForDealAdjustment =
    currentDeal.workingVersion === WorkingVersionType.WORKING_VERSION && // can only be readOnly if working version
    (miniResult.isLoading ||
      miniResult.isFetching ||
      miniResult.isError || // should be readOnly while loading etc
      liveDealMini.borrowerBpId === borrowerBpId) // readOnly if working version borrower same as live borrower

  return { isReadOnlyForDealAdjustment, isWorkingVersion }
}

useBorrowerUnitsReadOnlyForDealAdjustments.propTypes = {
  borrowerBpId: PropTypes.string.isRequired,
}

export default useBorrowerUnitsReadOnlyForDealAdjustments
