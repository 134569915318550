import { useTranslation } from 'react-i18next'
import { useProductTypeCodes } from 'hooks/services/deals/financing/configGetters/useProductTypeCodes'

const useProductTypeFields = ({ productTypeCode, productTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'productTypeCode',
      label: t('product-type'),
      value: productTypeShortText,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'productTypeCode',
      label: t('product-type'),
      value: productTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      isMandatory: true,
      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useProductTypeCodes,
        selectionName: 'productTypeCodes',
      },
    },
  ]
}

export default useProductTypeFields
