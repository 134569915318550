import { useMutation } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useCreateDealUse = ({ onSuccess, onError }) => {
  const { post } = useAccessTokenRequest()

  return useMutation(
    async ({ dealUuid, dealUse }) =>
      await post({
        path: `/deals/${dealUuid}/uses`,
        body: snakecaseKeys(dealUse),
      }),
    { onSuccess, onError },
  )
}

export default useCreateDealUse
