import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardHeaderLastEditedText } from 'components/domains/deals/card/CardHeaderLastEditedText'
import CardSection from 'components/domains/deals/card/CardSection'
import DealCustomListCardDisplayItem from 'components/domains/deals/custom-list-card/DealCustomListCardDisplayItem'
import DealCustomListCardEditItem from 'components/domains/deals/custom-list-card/DealCustomListCardEditItem'
import Card from 'components/ui/card/Card'
import { ToggleEditMode } from 'components/ui/card/CardHeaderWithEditMode'
import { RequestStateResolver } from 'components/ui/loading/RequestStateResolver'
import useCustomEntityFields from 'hooks/services/deals/configurations/useCustomEntityFields'
import useCreateCustomEntities from 'hooks/services/deals/custom-entities/useCreateCustomEntities'
import useCustomEntities from 'hooks/services/deals/custom-entities/useCustomEntities'
import useUpdateCustomEntities from 'hooks/services/deals/custom-entities/useUpdateCustomEntities'

const CustomEntity = {
  REPORTING: 'REPORTING',
}

const DealCustomListCard = ({ pageData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals' })

  const {
    deal: { dealUuid },
    allowedOperations = [],
    allowedOperationsForReporting,
  } = pageData

  const [isEditMode, setIsEditMode] = useState(false)
  const [formState, setFormState] = useState({})
  const [formStateInitialized, setFormStateInitialized] = useState(false)
  const [formErrors, setFormErrors] = useState(new Map())
  const queryClient = useQueryClient()

  const entityType = CustomEntity.REPORTING
  const linkedEntityId = dealUuid
  const requiredPermissionForEdit = allowedOperationsForReporting

  const {
    data: { fieldConfigs = [] } = {},
    isLoading: isLoadingConfig = false,
    isError: isErrorConfig = false,
  } = useCustomEntityFields(entityType)

  const {
    data: { custom_entities = [] } = {},
    isLoading = false,
    isError = false,
  } = useCustomEntities({ dealUuid, entityType, linkedEntityId })

  const { last_updated_by, last_updated_at, custom_entity_uuid, custom_fields } =
    custom_entities[0] ?? {}

  useEffect(() => {
    if (!isLoading && !isError && !formStateInitialized) {
      setFormState(custom_fields)
      setFormStateInitialized(true)
    }
  }, [custom_fields, formStateInitialized, isError, isLoading])

  const updateFormState = (newState) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }
  const updateFormErrors = (key, value) => {
    setFormErrors((prevState) => {
      if (value) {
        prevState.set(key, value)
      } else {
        prevState.delete(key)
      }
      return new Map(prevState)
    })
  }

  const invalidateCustomEntities = () =>
    queryClient.invalidateQueries([
      'deals',
      dealUuid,
      'custom-entities',
      entityType,
      linkedEntityId,
    ])

  const createCustomEntities = useCreateCustomEntities({
    onSuccess: () => {
      setIsEditMode(false)
      invalidateCustomEntities()
    },
    onError: () => setIsEditMode(false),
  })
  const updateCustomEntities = useUpdateCustomEntities({
    onSuccess: () => {
      setIsEditMode(false)
      invalidateCustomEntities()
    },
    onError: () => setIsEditMode(false),
  })

  const saveChanges = () => {
    const payLoad = { entityType, linkedEntityId, customFields: formState }
    if (custom_entity_uuid) {
      updateCustomEntities.mutate({
        dealUuid,
        customEntityUuid: custom_entity_uuid,
        ...payLoad,
      })
      return
    }
    createCustomEntities.mutate({ dealUuid, ...payLoad })
  }

  const abortChanges = () => {
    setIsEditMode(false)
  }

  const showEditButton = useMemo(
    () => allowedOperations.includes(requiredPermissionForEdit),
    [allowedOperations, requiredPermissionForEdit],
  )

  const renderDisplayMode = useCallback(
    () =>
      fieldConfigs.map((fieldConfig, index) => (
        <DealCustomListCardDisplayItem
          key={`${fieldConfig.displayName}-${index}`}
          fieldConfig={fieldConfig}
          data={custom_fields}
        />
      )),
    [fieldConfigs, custom_fields],
  )

  const renderEditMode = useCallback(
    () =>
      fieldConfigs.map((fieldConfig, index) => (
        <DealCustomListCardEditItem
          key={`${fieldConfig.displayName}-${index}`}
          fieldConfig={fieldConfig}
          formState={formState}
          updateFormState={updateFormState}
          updateFormErrors={updateFormErrors}
        />
      )),
    [fieldConfigs, formState],
  )

  return (
    <Card
      header={
        <CardHeaderLastEditedText
          title={t('customer-specific.reporting.title')}
          timestamp={last_updated_at}
          email={last_updated_by}
          actions={
            showEditButton && (
              <ToggleEditMode
                isEditMode={isEditMode}
                onToggle={() => setIsEditMode(true)}
                onSave={saveChanges}
                onCancel={abortChanges}
                checkSaveDisabled={() => !!formErrors.size}
              />
            )
          }
        />
      }
    >
      <RequestStateResolver
        isLoading={isLoading || isLoadingConfig}
        isError={isError || isErrorConfig}
        renderContent={() => (
          <CardSection>{isEditMode ? renderEditMode() : renderDisplayMode()}</CardSection>
        )}
      />
    </Card>
  )
}

DealCustomListCard.propTypes = {
  pageData: PropTypes.shape({
    deal: PropTypes.shape({
      dealUuid: PropTypes.string.isRequired,
    }),
    allowedOperations: PropTypes.arrayOf(PropTypes.string),
    allowedOperationsForReporting: PropTypes.string,
  }).isRequired,
}

export default DealCustomListCard
