import { useTranslation } from 'react-i18next'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'

const useMaturityDateFields = ({ maturityDate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })
  const { format: formatDate } = useShortDateFormatter()

  return [
    {
      name: 'maturity',
      label: t('maturity'),
      value: formatDate(maturityDate),
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'maturity',
      label: t('maturity'),
      value: formatDate(maturityDate),
      editComponentType: 'DatePicker',
      editComponentProps: {},
      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useMaturityDateFields
