export const NON_LOAN_PRODUCT_TABLE_TYPES = Object.freeze({
  DERIVATIVES: 'derivatives',
  MONEY_MARKETS: 'moneyMarkets',
  BONDS: 'bonds',
  REPOS: 'repos',
  OTHER_LENDING: 'otherLending',
})

export const ROW_TYPE_TRADE = 'trade'
export const ROW_TYPE_CONDITION = 'condition'
