import { TextAlign, VerticalAlign } from '@fioneer/ui5-webcomponents-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import OtherLendingTableCell from 'routes/deals/financing/non-loan-products/other-lending/OtherLendingTableCell'

const columnKeyPrefix = 'pages.deals.other-lending-table.columns'

const getDefaultColumnDefinition = (columnKey, t) => ({
  Header: () => <Trans i18nKey={`${columnKeyPrefix}.${columnKey}-label`} />,
  title: t(`${columnKey}-label`).replace('<br/>', ' '),
  accessor: columnKey,
})

const useOtherLendingTableColumnDefinitions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: columnKeyPrefix,
  })
  const allColumns = useMemo(
    () => ({
      tradeCondition: {
        minWidth: 300,
      },
      productType: {
        width: 200,
      },
      productSubType: {
        width: 200,
      },
      limitSystemProductClass: {
        width: 200,
        isVisible: false,
      },
      counterparty: {
        minWidth: 250,
      },
      mtm: {
        width: 180,
        hAlign: TextAlign.Right,
      },
      amortization: {
        width: 180,
      },
      startDate: {
        width: 120,
        hAlign: TextAlign.Right,
      },
      maturityDate: {
        width: 120,
        hAlign: TextAlign.Right,
      },
      payOrReceive: {
        minWidth: 100,
      },
      initialNotional: {
        width: 180,
        hAlign: TextAlign.Right,
      },
      currentNotional: {
        width: 180,
        hAlign: TextAlign.Right,
      },
      rateType: {
        width: 100,
      },
      rateIndexName: {
        width: 150,
      },
      tenor: {
        width: 100,
      },
      spread: {
        width: 140,
        hAlign: TextAlign.Right,
      },
      currentRate: {
        width: 120,
        hAlign: TextAlign.Right,
      },
      endOfFixedInterestPeriod: {
        width: 160,
        hAlign: TextAlign.Right,
      },
    }),
    [],
  )

  return useMemo(
    () =>
      Object.keys(allColumns).map((columnKey) => ({
        ...getDefaultColumnDefinition(columnKey, t),
        Cell: OtherLendingTableCell,
        isVisible: true,
        isSelectableForHiding: true,
        disableDragAndDrop: true,
        disableResizing: false,
        vAlign: VerticalAlign.Middle,
        disableSortBy: true,
        ...allColumns[columnKey],
      })),
    [allColumns, t],
  )
}

export default useOtherLendingTableColumnDefinitions
