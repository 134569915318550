import { Link, MessageBoxActions, MessageBoxTypes } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import { Trans } from 'react-i18next'

const handleClickOnDocumentLink = (id) => {
  window.open(`/documents/${id}`, '_blank')
}

const showDocumentLinkMessageBox = async ({
  get,
  queryClient,
  eventId,
  decisionStageId,
  showMessageBox,
  titleText,
  i18nKey,
}) => {
  queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
  const { data = {} } = await get({ path: `/events/${eventId}/decision-stages/${decisionStageId}` })
  const { archivedDocumentId } = camelize(data)
  showMessageBox({
    type: MessageBoxTypes.Success,
    titleText,
    children: (
      <Trans
        i18nKey={i18nKey}
        components={[
          <Link
            key="archived-document-link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleClickOnDocumentLink(archivedDocumentId)}
          />,
        ]}
      />
    ),
    actions: [MessageBoxActions.OK],
  })
}

export default showDocumentLinkMessageBox
