import { Option as UI5Option } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  /**
   * Additional data to be returned.
   *
   * If provided, `Select` will return an object on change (`{value: string} & data`) instead of `value` directly.
   */
  data: PropTypes.objectOf(PropTypes.string),
}

/**
 * @param {PropTypes.InferProps<typeof propTypes>} props
 */
const Option = ({ children, value, className, data }) => {
  const prefixedData = Object.fromEntries(
    Object.entries(data ?? {}).map(([key, val]) => [`data-${key}`, val]),
  )

  return (
    <UI5Option value={value} className={className ?? undefined} {...prefixedData}>
      {children}
    </UI5Option>
  )
}

Option.propTypes = propTypes

export default Option
