/**
 * This small function filters the provided non-loan-product data on deal level (i.e., for useNonLoanProductsByBorrower)
 * by a given mapping attribute on trade level and the corresponding mapping values, that should match that attribute.
 * Usually, the mapping parameters are acquired by nonLoanProducts config, with the values accessed by NON_LOAN_PRODUCT_TABLE_TYPES.
 *
 * @param tradeMappingAttribute - mapping attribute on trade level, e.g., limitSystemProductClass
 * @param tradeMappingValues - mapping values that must, individually be included by the data's mapping attribute
 * @param data - deal-based non-loan-product data, i.e., deal -> [trades]
 * @param options
 * @param options.excludeTradeMappingValues - exclude all provided tradeMappingValues, defaults to false
 */
export const getNonLoanProductDealDataByConfig = ({
  tradeMappingAttribute,
  tradeMappingValues,
  data = [],
  options = { excludeTradeMappingValues: false },
}) => ({
  trades: data?.trades?.filter((trade) => {
    const includesTradeMappingValue = tradeMappingValues?.includes(trade?.[tradeMappingAttribute])
    return options.excludeTradeMappingValues
      ? !includesTradeMappingValue
      : includesTradeMappingValue
  }),
})
