import { Modals, ObjectStatus, ValueState } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FINANCING_TRANCHE_UPDATE } from 'api/deals/financing/allowedOperationsConstants'
import useLastEditedTextByEmail from 'components/domains/deals/card/useLastEditedTextByEmail'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import useEditTranche from 'hooks/services/deals/financing/useEditTranche'
import { DealContext } from 'routes/deals/DealContext'
import { HTTP_STATUS_CODES } from 'routes/deals/financing/financingConstants'
import useRankFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/rank/useRankFields'
import useTotalCommitmentFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/totalCommitment/useTotalCommitmentFields'
import useAvailableAmountFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useAvailableAmountFields'
import useBorrowerFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useBorrowerFields'
import useCommitmentOwnShareFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useCommitmentOwnShareFields'
import useCoverpoolFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useCoverpoolFields'
import useCreditRiskTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useCreditRiskTypeFields'
import useDurationTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useDurationTypeFields'
import useFirstDrawdownDateFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useFirstDrawdownDateFields'
import useLatestDrawingDateFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useLatestDrawingDateFields'
import useLoanTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useLoanTypeFields'
import useMainCollateralTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useMainCollateralTypeFields'
import useMaturityDateFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useMaturityDateFields'
import useOutstandingAmountFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useOutstandingAmountFields'
import useProductTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useProductTypeFields'
import useRevolvingTypeFields from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/useRevolvingTypeFields'
import mapErrorCodes from 'routes/deals/syndication/errorHandling/mapErrorCodes'

const TrancheGeneralInformationCard = ({ pageData }) => {
  const { tranche, isFetching = false, isExistingBusinessView } = pageData

  const { t: tTrancheDetails } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details',
  })
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)

  const showToast = Modals.useShowToast()

  const editTranche = useEditTranche()
  const queryClient = useQueryClient()

  const {
    trancheName,
    productTypeCode,
    productTypeShortText,
    loanTypeCode,
    loanTypeShortText,
    productStatus = {},
    totalCommitment,
    totalCommitmentHeadquarter,
    borrowerName,
    borrowerId,
    ownShare,
    ownShareHeadquarter,
    displayId,
    externalContractId,
    mainCollateralTypeCode,
    mainCollateralTypeShortText,
    creditRiskTypeCode,
    creditRiskTypeShortText,
    revolvingTypeCode,
    revolvingTypeShortText,
    durationTypeCode,
    durationTypeShortText,
    maturityDate,
    firstDrawdownDate,
    latestDrawingDate,
    coverpoolEligibleAmount,
    coverpoolEligibleAmountHeadquarter,
    timeToCoverpoolInMonths,
    ownCommitmentShare,
    productNumber,
    deviatingBookingLocation,
    lastUpdated,
    ownShareExistingBusiness = {},
  } = tranche || {}

  const { lastEditedText } = useLastEditedTextByEmail({
    email: lastUpdated?.name,
    timestamp: lastUpdated?.lastUpdatedOn,
  })

  const totalCommitmentFields = useTotalCommitmentFields({
    totalCommitment,
    totalCommitmentHeadquarter,
  })
  const commitmentOwnShareFields = useCommitmentOwnShareFields({
    ownShare: isExistingBusinessView ? ownShareExistingBusiness.ownShare : ownShare,
    ownShareHeadquarter: isExistingBusinessView
      ? ownShareExistingBusiness.ownShareHeadquarter
      : ownShareHeadquarter,
    ownCommitmentShare: isExistingBusinessView
      ? ownShareExistingBusiness.ownCommitmentShare
      : ownCommitmentShare,
  })
  const availableAmountFields = useAvailableAmountFields({
    ownShare: isExistingBusinessView ? ownShareExistingBusiness.ownShare : ownShare,
    ownShareHeadquarter: isExistingBusinessView
      ? ownShareExistingBusiness.ownShareHeadquarter
      : ownShareHeadquarter,
    totalCommitmentHeadquarter: isExistingBusinessView
      ? ownShareExistingBusiness.ownShareHeadquarter
      : totalCommitmentHeadquarter,
  })
  const outstandingAmountFields = useOutstandingAmountFields({
    ownShare: isExistingBusinessView ? ownShareExistingBusiness.ownShare : ownShare,
    ownShareHeadquarter: isExistingBusinessView
      ? ownShareExistingBusiness.ownShareHeadquarter
      : ownShareHeadquarter,
    totalCommitmentHeadquarter: isExistingBusinessView
      ? ownShareExistingBusiness.ownShareHeadquarter
      : totalCommitmentHeadquarter,
  })

  const firstDrawdownDateFields = useFirstDrawdownDateFields({
    firstDrawdownDate,
  })

  const latestDrawingDateFields = useLatestDrawingDateFields({
    latestDrawingDate,
  })

  const maturityDateFields = useMaturityDateFields({
    maturityDate,
  })

  const coverpoolFields = useCoverpoolFields({
    coverpoolEligibleAmount,
    coverpoolEligibleAmountHeadquarter,
    timeToCoverpoolInMonths,
  })
  const borrowerFields = useBorrowerFields({
    borrowerId,
    borrowerName,
  })
  const productTypeFields = useProductTypeFields({
    productTypeCode,
    productTypeShortText,
  })
  const loanTypeFields = useLoanTypeFields({
    loanTypeCode,
    loanTypeShortText,
  })
  const creditRiskTypeFields = useCreditRiskTypeFields({
    creditRiskTypeCode,
    creditRiskTypeShortText,
  })
  const rankFields = useRankFields({
    rank: tranche?.rank,
  })
  const mainCollateralTypeFields = useMainCollateralTypeFields({
    mainCollateralTypeCode,
    mainCollateralTypeShortText,
  })
  const revolvingTypeFields = useRevolvingTypeFields({ revolvingTypeCode, revolvingTypeShortText })
  const durationTypeFields = useDurationTypeFields({ durationTypeCode, durationTypeShortText })

  const [saveError, setSaveError] = useState(undefined)
  const [isWritingToBackend, setIsWritingToBackend] = useState(false)

  const onSaveClicked = (data) => {
    setIsWritingToBackend(true)
    editTranche.mutate(
      {
        dealId: deal.dealUuid,
        trancheId: tranche?.trancheId,
        ...data,
      },
      {
        onSuccess: () => {
          setIsWritingToBackend(false)
          // invalidate all information about tranches so that the overview table and the syndication is updated as well
          queryClient.invalidateQueries(['deals', deal.dealUuid, 'tranches'])
          queryClient.invalidateQueries(['deals', deal.dealUuid, 'syndication'])
          showToast({ children: t('toast.changes-saved') })
        },
        onError: async (error) => {
          setIsWritingToBackend(false)
          const errorResponseJson = await error.response.json()
          setSaveError(errorResponseJson)
        },
      },
    )
  }

  const onEditChanges = (field, _, setInvalidFormState) => {
    const borrowerFieldName = borrowerFields[0].name

    const borrower = field[borrowerFieldName]
    if (borrower) {
      const invalidBusinessPartner = !borrower.id && borrower.name

      if (invalidBusinessPartner) {
        setInvalidFormState((oldState) => [...oldState, field.key])
      } else {
        setInvalidFormState((oldState) => oldState.filter((element) => element !== field.key))
      }
    }
  }

  const generalInformationFields = [
    {
      label: tTrancheDetails('tranche-name'),
      name: 'trancheName',
      value: trancheName,

      isShownInDisplay: true,
      isShownInEdit: true,

      isMandatory: true,
      editComponentType: 'Input',
      editComponentProps: { type: 'string' },
    },
    {
      label: tTrancheDetails('display-id'),
      name: 'displayId',
      value: displayId,

      isShownInDisplay: true,
      isShownInEdit: true,

      editComponentType: 'Input',
      editComponentProps: { type: 'string', readonly: true },
    },
    {
      label: tTrancheDetails('external-contract-id'),
      name: 'externalContractId',
      value: externalContractId?.toString().replace(',', ', '),

      isShownInDisplay: true,
      isShownInEdit: true,

      editComponentType: 'Input',
      editComponentProps: { type: 'string', readonly: true },
    },
    ...productTypeFields,
    ...loanTypeFields,
    {
      label: tTrancheDetails('product-status'),
      name: 'productStatus', // CML status
      value: productStatus?.translation,
      formattedValue: productStatus?.translation && (
        <ObjectStatus inverted state={ValueState.Information}>
          {productStatus?.translation}
        </ObjectStatus>
      ),

      isShownInDisplay: !!isExistingBusinessView,
      isShownInEdit: false,

      editComponentType: 'Input',
      editComponentProps: { type: 'string', readonly: true },
    },
    ...borrowerFields,
    ...mainCollateralTypeFields,
    ...creditRiskTypeFields,
    ...rankFields,
    ...revolvingTypeFields,
    ...durationTypeFields,
    {
      name: 'deviatingBookingLocation',
      label: tTrancheDetails('deviating-booking-location'),
      // This field is still WIP by team A, as of 20.02.2023
      value: deviatingBookingLocation,

      isShownInDisplay: true,
      isShownInEdit: true,

      editComponentType: 'Input',
      editComponentProps: { type: 'string', readonly: true },
    },
    ...totalCommitmentFields,
    ...commitmentOwnShareFields,
    ...outstandingAmountFields,
    ...availableAmountFields,
    ...firstDrawdownDateFields,
    ...latestDrawingDateFields,
    ...maturityDateFields,
    ...coverpoolFields,
    {
      name: 'productNumber',
      label: tTrancheDetails('product-number'),
      value: productNumber,

      isShownInDisplay: true,
      isShownInEdit: true,

      editComponentType: 'Input',
      editComponentProps: { type: 'String' },
    },
  ]

  return (
    <DisplayAndEditCard
      cardHeaderTitle={tTrancheDetails(`general-information`)}
      cardHeaderSubtitle={lastEditedText}
      fieldDefinitions={generalInformationFields}
      additionalEditHeaderProps={{ isSaveLoading: isWritingToBackend }}
      // Overwrites the loading state used for the LoadingStateWrapper after save has been clicked.
      // The above `isSaveLoading` is used instead to set an inert card state
      isHandlingSaveOverwrite={false}
      // we only pass the fetching state as the data for this card is delivered by a prop
      // and the card itself is already wrapped in a LoadingStateWrapper for the initial loading
      isLoading={isFetching}
      isEmpty={false}
      saveChanges={onSaveClicked}
      saveHookIsSuccess={editTranche?.isSuccess}
      saveHookIsError={editTranche?.isError}
      saveHookErrorSummary={
        saveError?.status === HTTP_STATUS_CODES.CONFLICT
          ? `${t('pages.deals.financing.error.general')}\n\n${mapErrorCodes(saveError.code)}`
          : t('pages.deals.financing.error.general')
      }
      fillEmptyValuesWithPlaceholder={true}
      onEditChanges={onEditChanges}
      cancelChanges={() => {
        borrowerFields[0].onCancel()
      }}
      isEditable={!isExistingBusinessView && allowedOperations.includes(FINANCING_TRANCHE_UPDATE)}
    />
  )
}

TrancheGeneralInformationCard.propTypes = {
  pageData: PropTypes.shape({
    tranche: PropTypes.shape({
      id: PropTypes.string,
      trancheName: PropTypes.string.isRequired,
      trancheStatus: PropTypes.string,
      productTypeCode: PropTypes.string.isRequired,
      productTypeShortText: PropTypes.string.isRequired,
      loanTypeCode: PropTypes.string.isRequired,
      loanTypeShortText: PropTypes.string.isRequired,
      trancheId: PropTypes.string,
      dealId: PropTypes.string,
      displayId: PropTypes.string.isRequired,
      externalContractId: PropTypes.arrayOf(PropTypes.string),
      borrowerName: PropTypes.string,
      borrowerId: PropTypes.string,
      mainCollateralTypeCode: PropTypes.string,
      mainCollateralTypeShortText: PropTypes.string,
      creditRiskTypeCode: PropTypes.string,
      creditRiskTypeShortText: PropTypes.string,
      rank: PropTypes.number,
      revolvingTypeCode: PropTypes.string,
      revolvingTypeShortText: PropTypes.string,
      durationTypeCode: PropTypes.string,
      durationTypeShortText: PropTypes.string,
      deviatingBookingLocation: PropTypes.string,
      totalCommitment: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      totalCommitmentHeadquarter: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      ownShare: PropTypes.shape({
        commitment: PropTypes.number,
        outstanding: PropTypes.number,
        available: PropTypes.number,
        currency: PropTypes.string,
      }),
      ownShareHeadquarter: PropTypes.shape({
        commitment: PropTypes.number,
        outstanding: PropTypes.number,
        available: PropTypes.number,
        currency: PropTypes.string,
      }),
      ownShareExistingBusiness: PropTypes.shape({
        ownShare: PropTypes.shape({
          commitment: PropTypes.number,
          outstanding: PropTypes.number,
          currency: PropTypes.string,
        }),
        ownShareHeadquarter: PropTypes.shape({
          commitment: PropTypes.number,
          outstanding: PropTypes.number,
          currency: PropTypes.string,
        }),
        ownCommitmentShare: PropTypes.number,
      }),
      maturityDate: PropTypes.string,
      firstDrawdownDate: PropTypes.string,
      latestDrawingDate: PropTypes.string,
      coverpoolEligibleAmount: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      coverpoolEligibleAmountHeadquarter: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      timeToCoverpoolInMonths: PropTypes.number,
      ownCommitmentShare: PropTypes.number,
      productNumber: PropTypes.string,
      productStatus: PropTypes.shape({
        translation: PropTypes.string,
        status: PropTypes.string,
      }),
      lastUpdated: PropTypes.shape({
        name: PropTypes.string,
        lastUpdatedOn: PropTypes.string,
      }),
      dataSource: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    isExistingBusinessView: PropTypes.bool,
  }).isRequired,
}

export default TrancheGeneralInformationCard
