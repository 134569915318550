import PropTypes from 'prop-types'
import BankCustomerAccountsTable from 'components/domains/deals/financing/bank-customer-accounts/overview/BankCustomerAccountsTable'
import Card from 'components/ui/card/Card'

const BankCustomerAccountsCard = ({ staticData }) => {
  const { isCreditAccountsCard = false } = staticData

  return (
    <Card>
      <BankCustomerAccountsTable isCreditAccountsTable={isCreditAccountsCard} />
    </Card>
  )
}

BankCustomerAccountsCard.propTypes = {
  staticData: PropTypes.shape({
    isCreditAccountsCard: PropTypes.bool,
  }),
}

export default BankCustomerAccountsCard
