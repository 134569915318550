import { FlexBox, FlexBoxDirection } from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import { ErrorDataUnavailableInCell } from 'components/ui/errors/ErrorDataUnavailableInCell'
import SmallLoadingWrapper from 'components/ui/loading/SmallLoadingWrapper'
import styles from 'components/ui/tables/cells/PercentageCell.module.css'
import { useNumberFormatter } from 'hooks/i18n/useI18n'

const propTypes = {
  /**
   * The value to be formatted and displayed as the percentage.
   *
   * The value will be multiplied by 100.
   *
   * The value can either be a single value (number/string) or an array of values, in order to display multiple rows. */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Options to modify how the Cell will look like.
   *
   * - `isBold` Display the monetary values (!= the percentage) in bold font. Defaults to false.
   * - `maximumFractionDigits` Defines the maximum digits of the percentage value. Defaults to 2.
   * - `minimumFractionDigits` Defines the minimum digits of the percentage value. Defaults to 2.
   */
  options: PropTypes.shape({
    isBold: PropTypes.bool,
    maximumFractionDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
  }),
  /** Defines whether a loading spinner is shown. */
  isLoading: PropTypes.bool,
  /** Defines whether a loading spinner is shown. */
  isFetching: PropTypes.bool,
  /** Defines whether an error is shown. */
  isError: PropTypes.bool,
  /** Allows to define a custom error component. Defaults to undefined so that `<ErrorDataUnavailableInCell />` is shown. */
  errorToDisplay: PropTypes.node,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared percentage cell for usage within the Responsive and Analytical Table.
 *
 * It covers the single percentage cell use case, as well as multi-row percentage cell.
 *
 * @typedef Props
 *
 * @property {Array|String|Number} value The value to be formatted and displayed as the percentage.
 * @property {Object} options Options to modify how the Cell will look like.
 * @property {Boolean} options.isBold Display the percentage value in bold font. Defaults to false.
 * @property {Number} options.maximumFractionDigits Defines the maximum digits of the percentage value. Defaults to 2.
 * @property {Number} options.minimumFractionDigits Defines the minimum digits of the percentage value. Defaults to 2.
 * @property {Boolean} isLoading Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isFetching Defines whether a loading spinner is shown. Defaults to false.
 * @property {Boolean} isError Defines whether an error is shown.
 * @property {React.ReactNode} errorToDisplay Allows to define a custom error component. Defaults to undefined so that <ErrorDataUnavailableInCell /> is shown.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const PercentageCell = ({
  value,
  options: { isBold = false, maximumFractionDigits = 2, minimumFractionDigits = 2 } = {},
  isLoading = false,
  isFetching = false,
  isError = false,
  className,
  errorToDisplay,
}) => {
  const formatPercentage = useNumberFormatter({
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
    style: 'percent',
  })

  if (!Array.isArray(value)) {
    value = [value]
  }

  const renderContent = () => {
    if (isNil(value)) {
      return <div />
    }

    return (
      <FlexBox
        direction={FlexBoxDirection.Column}
        className={compact([styles.contentWrapper, className]).join(' ')}
      >
        {value.map((item, index) => (
          <div key={index} className={isBold ? styles.textBold : ''}>
            {!isNil(item) ? formatPercentage(item) : <>&nbsp;</>}
          </div>
        ))}
      </FlexBox>
    )
  }

  return (
    <SmallLoadingWrapper
      isLoading={isLoading || isFetching}
      isError={isError}
      error={errorToDisplay ?? <ErrorDataUnavailableInCell />}
      renderContent={renderContent}
    />
  )
}

PercentageCell.propTypes = propTypes

export default PercentageCell
