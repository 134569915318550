import {
  DatePicker,
  DateTimePicker,
  Input,
  MultiComboBox,
  MultiComboBoxItem,
  Text,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import get from 'lodash.get'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CardEditItem } from 'components/domains/deals/card/CardItem'
import {
  customFieldConfigProps,
  CustomFieldTypes,
} from 'components/domains/deals/card/customFieldConfigsProps'
import styles from 'components/domains/deals/custom-list-card/DealCustomListCardEditItem.module.css'
import DealCustomListCardSelect from 'components/domains/deals/custom-list-card/DealCustomListCardSelect'
import { useAreaMeasureUnitCodesShort } from 'components/domains/deals/custom-list-card/useAreaMeasureUnitCodesShort'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import LabeledSwitch from 'components/ui/input/LabeledSwitch'
import LoadingSelect from 'components/ui/select/LoadingSelect'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import { UserProfileContext } from 'routes/UserProfileContext'
import set from 'utils/set'

const cardCustomEditItemProps = {
  fieldConfig: customFieldConfigProps,
  formState: PropTypes.instanceOf(Object).isRequired,
  updateFormState: PropTypes.func.isRequired,
  updateFormErrors: PropTypes.func.isRequired,
}

const DealCustomListCardEditItem = ({
  fieldConfig: { key, type, displayName, selectOptions, validation, visibility } = {},
  formState,
  updateFormState = () => {},
  updateFormErrors = () => {},
}) => {
  const { zoneId: timeZone } = useContext(UserProfileContext) ?? {}
  const { format: formatDateTime, localePattern: localePatternDateTime } = useShortDateFormatter({
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone,
  })
  const { localePattern: localePatternDate } = useShortDateFormatter({ timeZone })
  const [valueState, setValueState] = useState(ValueState.None)
  const [isSelectError, setIsSelectError] = useState(false)
  const formattedNumberInputRef = useRef()
  const [isInvalidNumberInput, setIsInvalidNumberInput] = useState(false)

  const value = useMemo(() => get(formState, key), [key, formState])

  const changeValue = useCallback(
    (newValue) => {
      const newState = set({}, key, newValue)
      updateFormState(newState)
    },
    [key, updateFormState],
  )

  useEffect(() => {
    const { required, regexPattern, minLength, maxLength, minValue, maxValue } = validation
    switch (type) {
      case CustomFieldTypes.Number:
      case CustomFieldTypes.Percentage:
        if (
          (required && !value) ||
          (typeof value === 'number' && minValue !== null && value < minValue) ||
          (typeof value === 'number' && maxValue !== null && value > maxValue)
        ) {
          setValueState(ValueState.Error)
          updateFormErrors(key, true)
          return
        } else if (isInvalidNumberInput) {
          setValueState(ValueState.None)
          updateFormErrors(key, true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key, false)
        return
      case CustomFieldTypes.Bool:
        if (typeof value !== 'boolean') {
          changeValue(!!value)
        }
        return
      case CustomFieldTypes.Measurement:
        if (required && !value.unit) {
          setIsSelectError(true)
          updateFormErrors(key + '.unit', true)
        } else {
          setIsSelectError(false)
          updateFormErrors(key + '.unit', false)
        }
        if (
          (required && (!value?.value || (minValue !== null && value?.value < minValue))) ||
          (maxValue !== null && value?.value > maxValue)
        ) {
          setValueState(ValueState.Error)
          updateFormErrors(key + '.area', true)
          return
        } else if (isInvalidNumberInput) {
          setValueState(ValueState.None)
          updateFormErrors(key + '.area', true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key + '.area', false)
        return
      case CustomFieldTypes.Currency:
        if (required && !value.currency_code) {
          setIsSelectError(true)
          updateFormErrors(key + '.currency_code', true)
        } else {
          setIsSelectError(false)
          updateFormErrors(key + '.currency_code', false)
        }
        if (
          (required && (!value?.value || (minValue !== null && value?.value < minValue))) ||
          (maxValue !== null && value?.value > maxValue)
        ) {
          setValueState(ValueState.Error)
          updateFormErrors(key + '.amount', true)
          return
        } else if (isInvalidNumberInput) {
          setValueState(ValueState.None)
          updateFormErrors(key + '.amount', true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key + '.amount', false)
        return
      case CustomFieldTypes.MultiSelect:
        if (required && !value?.length) {
          setValueState(ValueState.Error)
          updateFormErrors(key, true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key, false)
        return
      case CustomFieldTypes.String:
        if (
          (required && !value) ||
          (!!value &&
            ((minLength !== null && value.length < minLength) ||
              (maxLength !== null && value.length > maxLength) ||
              (regexPattern && !new RegExp(regexPattern).test(value))))
        ) {
          setValueState(ValueState.Error)
          updateFormErrors(key, true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key, false)
        return
      default:
        if (required && !value) {
          setValueState(ValueState.Error)
          updateFormErrors(key, true)
          return
        }
        setValueState(ValueState.None)
        updateFormErrors(key, false)
    }
  }, [changeValue, isInvalidNumberInput, key, type, updateFormErrors, validation, value])

  const editComponent = useMemo(() => {
    switch (type) {
      case CustomFieldTypes.Number:
        return (
          <FormattedNumberInput
            value={value}
            onChange={(parsedNumber) => {
              changeValue(parsedNumber)
              setIsInvalidNumberInput(!formattedNumberInputRef?.current?.isValid)
            }}
            valueState={valueState}
            ref={formattedNumberInputRef}
          />
        )
      case CustomFieldTypes.Bool:
        return (
          <LabeledSwitch
            isLoading={false}
            checked={!!value}
            onChange={(e) => changeValue(e.target.checked)}
          />
        )
      case CustomFieldTypes.Date:
        return (
          <DatePicker
            formatPattern={localePatternDate}
            value={value}
            onChange={(e) => {
              changeValue(DateTime.fromFormat(e.detail.value, localePatternDate).toISODate())
            }}
            valueState={valueState}
          />
        )
      case CustomFieldTypes.Datetime:
        return (
          <DateTimePicker
            value={formatDateTime(value)}
            format={localePatternDateTime}
            valueState={valueState}
            onChange={(e) => {
              const luxonDate = DateTime.fromFormat(e.detail.value, localePatternDateTime)
              changeValue(
                luxonDate.invalid
                  ? new Date(e.detail.value).toISOString()
                  : luxonDate.toJSDate().toISOString(),
              )
            }}
          />
        )
      case CustomFieldTypes.Percentage:
        return (
          <FormattedNumberInput
            value={typeof value === 'number' ? value * 100 : value}
            valueState={valueState}
            icon={<Text>{'%'}</Text>}
            onChange={(percentValue) => {
              changeValue(typeof percentValue === 'number' ? percentValue / 100 : percentValue)
              setIsInvalidNumberInput(!formattedNumberInputRef?.current?.isValid)
            }}
            ref={formattedNumberInputRef}
          />
        )
      case CustomFieldTypes.Currency:
        return (
          <div className={styles.currency}>
            <FormattedNumberInput
              value={value?.value}
              valueState={valueState}
              onChange={(amount) => {
                changeValue({ ...value, value: amount })
                setIsInvalidNumberInput(!formattedNumberInputRef?.current?.isValid)
              }}
              ref={formattedNumberInputRef}
            />
            <LoadingSelect
              loadingHook={useCurrencyCodes}
              selectionName="currencyCodes"
              optionKeyName="key"
              optionDisplayName="display_name"
              selectedKey={value?.currency_code}
              onChange={(_, currency_code) => changeValue({ ...value, currency_code })}
              isErrorState={isSelectError}
            />
          </div>
        )
      case CustomFieldTypes.Measurement:
        return (
          <div className={styles.measurement}>
            <FormattedNumberInput
              value={value?.value}
              valueState={valueState}
              onChange={(unitValue) => {
                changeValue({ ...value, value: unitValue })
                setIsInvalidNumberInput(!formattedNumberInputRef?.current?.isValid)
              }}
              ref={formattedNumberInputRef}
            />
            <LoadingSelect
              loadingHook={useAreaMeasureUnitCodesShort}
              selectionName="measure_unit_codes"
              optionKeyName="key"
              optionDisplayName="display_name_short"
              selectedKey={value?.unit}
              onChange={(_, unit) => changeValue({ ...value, unit })}
              isErrorState={isSelectError}
            />
          </div>
        )
      case CustomFieldTypes.SingleSelect:
        return (
          <DealCustomListCardSelect
            value={value}
            required={validation.required}
            options={selectOptions}
            optionsKey="configSelectOptionUuid"
            optionName="value"
            onChange={changeValue}
          />
        )
      case CustomFieldTypes.MultiSelect:
        return (
          <MultiComboBox
            onSelectionChange={(e) =>
              changeValue(e.detail.items.map((option) => option.dataset.value))
            }
            valueState={valueState}
          >
            {selectOptions.map((option) => (
              <MultiComboBoxItem
                key={option.configSelectOptionUuid}
                selected={value?.includes(option.configSelectOptionUuid)}
                text={option.value}
                data-value={option.configSelectOptionUuid}
              >
                {option.value}
              </MultiComboBoxItem>
            ))}
          </MultiComboBox>
        )
      case CustomFieldTypes.String:
      default:
        return (
          <Input
            value={value}
            onChange={(e) => changeValue(e.target.value)}
            valueState={valueState}
            maxLength={validation.maxLength}
          />
        )
    }
  }, [
    changeValue,
    formatDateTime,
    isSelectError,
    localePatternDate,
    localePatternDateTime,
    selectOptions,
    type,
    validation.maxLength,
    validation.required,
    value,
    valueState,
  ])

  if (!visibility.editMode) return null

  return <CardEditItem label={displayName} value={editComponent} required={validation.required} />
}

DealCustomListCardEditItem.propTypes = cardCustomEditItemProps

export default DealCustomListCardEditItem
