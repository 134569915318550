import {
  FlexBox,
  FlexBoxAlignItems,
  Link,
  Modals,
  ObjectStatus,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  decisionStageStatus,
  getObjectStatusForDecisionStageStatus,
} from 'api/decision-process/decisionProcessApi'
import { businessEventAllowedOperations } from 'api/events/eventAllowedOperations'
import useDecisionStageName from 'components/domains/business-events-and-tasks/common/useDecisionStageName'
import DecisionStageDecisionPaperLink from 'components/domains/business-events-and-tasks/decision-stage/DecisionStageDecisionPaperLink'
import { useDecisionStageDecisionPaperName } from 'components/domains/business-events-and-tasks/decision-stage/useDecisionStageDecisionPaperName'
import DisplayAndEditCard from 'components/ui/card/DisplayAndEditCard'
import { hideOptions } from 'components/ui/card/DisplayCardView'
import DisplayCardViewInfoItemView from 'components/ui/card/DisplayCardViewInfoItemView'
import EditCardItem from 'components/ui/card/EditCardItem'
import editComponentTypes from 'components/ui/card/editComponentTypes'
import { ErrorDataUnavailableInContent } from 'components/ui/errors/ErrorDataUnavailableInContent'
import { useShortDateFormatter } from 'hooks/i18n/useI18n'
import useEditDecisionStage from 'hooks/services/business-events-and-tasks/decision-process/stages/useEditDecisionStage'
import useDocument from 'hooks/services/documents/useDocument'
import BusinessEventsAndTasksContext from 'routes/business-events-and-tasks/BusinessEventsAndTasksContext'

const DecisionStageInformationCard = ({ decisionStage, isPdfView = false }) => {
  const {
    event: { id: eventId },
    allowedOperations,
  } = useContext(BusinessEventsAndTasksContext)
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.business-events-and-tasks.decision-stage.info-card',
  })
  const { t: tNoPrefix } = useTranslation()
  const { format: formatDate } = useShortDateFormatter()
  const showToast = Modals.useShowToast()

  const hasEditPermission = useMemo(
    () => allowedOperations.includes(businessEventAllowedOperations.updateDecisionStage),
    [allowedOperations],
  )

  const {
    id: decisionStageId,
    info: { decisionStatus },
    decisionPaper,
    archivedDocumentId,
  } = decisionStage
  const {
    data: archivedDocument,
    isFetching: isDocumentFetching,
    isError: isDocumentError,
  } = useDocument({
    documentId: archivedDocumentId,
  })

  const decisionStatusPlannedOrDiscarded = useMemo(
    () =>
      [decisionStageStatus.planned, decisionStageStatus.inProgressRequestDiscarded].includes(
        decisionStatus,
      ),
    [decisionStatus],
  )

  const isCardEditable = useMemo(
    () => hasEditPermission && decisionStatusPlannedOrDiscarded,
    [hasEditPermission, decisionStatusPlannedOrDiscarded],
  )

  const decisionStageDisplayName = useDecisionStageName({
    name: decisionStage.info.name,
    decisionStageType: decisionStage.info.decisionType,
  })

  const queryClient = useQueryClient()
  const {
    mutate: mutateDecisionStage,
    isSuccess: isSavingSuccess,
    isError: isSavingError,
  } = useEditDecisionStage()

  const mutateWrapper = useCallback(
    (newValues) => {
      mutateDecisionStage(
        {
          decisionStageId,
          eventId,
          ...newValues,
        },
        {
          onSuccess: () => {
            showToast({ children: tNoPrefix('toast.changes-saved') })
            queryClient.invalidateQueries(['events', eventId, 'decision-stages'])
          },
        },
      )
    },
    [decisionStageId, eventId, mutateDecisionStage, queryClient, showToast, tNoPrefix],
  )

  const formattedDecisionDate = useMemo(() => {
    if (!decisionStage.info.decisionDate) {
      return '-'
    }
    const formattedDate = formatDate(decisionStage.info.decisionDate)
    return decisionStatusPlannedOrDiscarded
      ? `${formattedDate} ${t('label.decision-date-planned')}`
      : formattedDate
  }, [decisionStage.info.decisionDate, decisionStatusPlannedOrDiscarded, formatDate, t])

  const renderedDecisionStatus = useMemo(() => {
    const { objectStatus, translationKey } = getObjectStatusForDecisionStageStatus(
      decisionStage.info.decisionStatus,
    )

    return (
      <FlexBox alignItems={FlexBoxAlignItems.Start}>
        <ObjectStatus inverted state={objectStatus}>
          {tNoPrefix(translationKey)}
        </ObjectStatus>
      </FlexBox>
    )
  }, [decisionStage.info.decisionStatus, tNoPrefix])

  const decisionPaperVersionName = useDecisionStageDecisionPaperName({
    decisionPaperVersion: decisionPaper?.version,
    decisionStageStatus: decisionStatus,
  })

  const decisionPaperComponent = useMemo(
    () => ({
      component: (
        <DisplayCardViewInfoItemView
          label={t('label.linked-decision-paper')}
          value={
            decisionPaper ? (
              <DecisionStageDecisionPaperLink
                decisionPaperVersion={decisionPaper.version}
                decisionStageStatus={decisionStatus}
                eventId={eventId}
                showConfirmationForLinks={false}
              />
            ) : (
              '-'
            )
          }
        />
      ),
      value: decisionPaper ? decisionPaperVersionName : '-',
    }),
    [decisionPaper, decisionPaperVersionName, decisionStatus, eventId, t],
  )

  const onArchivedDocumentLinkClick = useCallback(() => {
    window.open(`/documents/${archivedDocumentId}`, '_blank')
  }, [archivedDocumentId])

  const fieldDefinitions = useMemo(
    () => [
      {
        label: t('label.name'),
        name: 'name',
        editComponentType: editComponentTypes.Input,
        value: decisionStageDisplayName ?? '',
        isShownInDisplay: true,
        isShownInEdit: true,
        editComponentProps: { disabled: true },
      },
      {
        label: t('label.linked-decision-paper'),
        name: 'decisionPaper',
        value: decisionPaperComponent.value,
        customInfoComponent: decisionPaperComponent.component,
        editComponentType: editComponentTypes.Input,
        isShownInDisplay: true,
        isShownInEdit: true,
        editComponentProps: { disabled: true },
      },
      {
        label: t('label.decision-date'),
        name: 'decisionDate',
        value: formatDate(decisionStage.info.decisionDate),
        editComponentType: editComponentTypes.DatePicker,
        formattedValue: formattedDecisionDate,
        isShownInDisplay: true,
        isShownInEdit: true,
      },
      {
        label: t('label.decision-status'),
        value: renderedDecisionStatus,
        customEditComponent: (
          <EditCardItem label={t('label.decision-status')} editComponent={renderedDecisionStatus} />
        ),
        isShownInDisplay: true,
        isShownInEdit: true,
      },
      {
        label: t('label.audit-document'),
        value: archivedDocument?.name ? (
          <Link onClick={onArchivedDocumentLinkClick}>{archivedDocument.name}</Link>
        ) : (
          ''
        ),
        isShownInDisplay: true,
        isShownInEdit: false,
        hideOption: hideOptions.hideWhenEmpty,
      },
    ],
    [
      t,
      decisionStageDisplayName,
      decisionPaperComponent.value,
      decisionPaperComponent.component,
      formatDate,
      decisionStage.info.decisionDate,
      formattedDecisionDate,
      renderedDecisionStatus,
      archivedDocument,
      onArchivedDocumentLinkClick,
    ],
  )

  return (
    <DisplayAndEditCard
      fieldDefinitions={fieldDefinitions}
      cardHeaderTitle={t('title')}
      saveChanges={mutateWrapper}
      saveHookIsError={isSavingError}
      saveHookIsSuccess={isSavingSuccess}
      isEditable={!isPdfView && isCardEditable}
      isLoading={isDocumentFetching}
      isError={isDocumentError}
      errorContent={<ErrorDataUnavailableInContent />}
    />
  )
}

DecisionStageInformationCard.propTypes = {
  decisionStage: PropTypes.shape({
    archivedDocumentId: PropTypes.string,
    id: PropTypes.string.isRequired,
    info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      decisionType: PropTypes.string.isRequired,
      decisionDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      decisionStatus: PropTypes.string.isRequired,
    }),
    decisionPaper: PropTypes.shape({
      version: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isPdfView: PropTypes.bool,
}

export default DecisionStageInformationCard
