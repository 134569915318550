import { useState } from 'react'
import { createPortal } from 'react-dom'
import TrancheCreateButton from 'components/domains/deals/financing/tranche-create/TrancheCreateButton'
import TrancheCreateDialog from 'components/domains/deals/financing/tranche-create/TrancheCreateDialog'

const TrancheCreate = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleClick = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <TrancheCreateButton onClick={handleClick} />
      {createPortal(
        <TrancheCreateDialog onClose={handleClose} open={isDialogOpen} />,
        document.body,
      )}
    </>
  )
}

export default TrancheCreate
