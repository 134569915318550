import {
  Label,
  FlexBox,
  FlexBoxJustifyContent,
  Input,
  Grid,
  FlexBoxAlignItems,
  ValueState,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/rank/RankEditInput.module.css'
import isRankValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isRankValid'

const RankEditInput = ({ label, name, handleOnChange, fieldValues }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  const [value, setValue] = useState(fieldValues.rank)

  const handleOnChangeRank = (event) => {
    const newValue = event.target.value
    setValue(newValue)

    if (isRankValid(newValue)) {
      handleOnChange(name, Number(newValue), true, true)
    }
  }

  return (
    <Grid position="Left" defaultIndent="XL0 L0 M0 S0" defaultSpan="XL6 L6 M6 S6">
      <FlexBox className={styles.labelWrapper} alignItems={FlexBoxAlignItems.Center}>
        <Label>{label}</Label>
      </FlexBox>
      <FlexBox className={styles.inputWrapper} justifyContent={FlexBoxJustifyContent.SpaceBetween}>
        <Input
          id="input-rank"
          type="Number"
          className={styles.inputField}
          value={fieldValues?.rank}
          valueState={isRankValid(value) ? ValueState.None : ValueState.Error}
          valueStateMessage={<span>{t('hint.greater-than-zero')}</span>}
          onInput={handleOnChangeRank}
          val
        />
      </FlexBox>
    </Grid>
  )
}

RankEditInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  fieldValues: PropTypes.object,
}

export default RankEditInput
