import { useTranslation } from 'react-i18next'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import { useCurrencyCodes } from 'hooks/services/deals/financing/configGetters/useCurrencyCodes'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import TotalCommitmentEditInput from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/totalCommitment/TotalCommitmentEditInput'

const useTotalCommitmentFields = ({ totalCommitment, totalCommitmentHeadquarter }) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })
  const { data, isLoading, isError } = useCurrencyCodes()

  return [
    {
      name: 'totalCommitment',
      label: t('total-commitment'),
      value: formatMoney(totalCommitment?.amount, totalCommitment?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'totalCommitment',
      label: t('total-commitment'),
      customInfoComponent: ConvertedAmount(
        totalCommitmentHeadquarter?.amount,
        totalCommitmentHeadquarter?.currency,
      ),
      isShownInDisplay: totalCommitment?.currency !== totalCommitmentHeadquarter?.currency,

      isShownInEdit: false,
    },
    {
      name: 'totalCommitment',
      value: totalCommitment,
      renderCustomEditComponent: ({ handleOnChange, fieldValues }) => (
        <TotalCommitmentEditInput
          handleOnChange={handleOnChange}
          fieldValues={fieldValues}
          currencyCodes={(!isLoading && !isError && data.currencyCodes) || []}
        />
      ),
      isMandatory: true,

      isShownInDisplay: false,
      isShownInEdit: true,
    },
  ]
}

export default useTotalCommitmentFields
