const useMultiSelectCardSaveHandler =
  ({ saveRows, saveComment, setInEditMode, setWaitingForRequests, refreshData }) =>
  async () => {
    setWaitingForRequests(true)

    // TODO: Add error handling

    await saveComment()
    await saveRows()

    setInEditMode(false)
    setWaitingForRequests(false)

    await refreshData()
  }

export default useMultiSelectCardSaveHandler
