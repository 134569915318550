import { useTranslation } from 'react-i18next'
import { useCreditRiskTypeCodes } from 'hooks/services/deals/financing/configGetters/useCreditRiskTypeCodes'

const useCreditRiskTypeFields = ({ creditRiskTypeCode, creditRiskTypeShortText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })

  return [
    {
      name: 'creditRiskTypeCode',
      label: t('credit-risk-type'),
      value: creditRiskTypeShortText,
      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'creditRiskTypeCode',
      label: t('credit-risk-type'),
      value: creditRiskTypeCode,

      isShownInDisplay: false,
      isShownInEdit: true,

      editComponentType: 'LoadingSelect',
      editComponentProps: {
        loadingHook: useCreditRiskTypeCodes,
        selectionName: 'creditRiskTypeCodes',
      },
    },
  ]
}

export default useCreditRiskTypeFields
