import { Input } from '@fioneer/ui5-webcomponents-react'
import ValueState from '@ui5/webcomponents-base/dist/types/ValueState'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isRankValid from 'routes/deals/syndication/syndicationStructure/utils/validation/isRankValid'

const RankInput = ({ id, rowKey, value, handleOnChange, setInteractedWithRowState }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.financing.other-financing-sources',
  })
  const [valueStateRank, setValueStateRank] = useState(
    isRankValid(value) ? ValueState.None : ValueState.Error,
  )

  const validateRankField = (newValue) => {
    if (isRankValid(newValue)) {
      setValueStateRank(ValueState.None)
      handleOnChange({ rowKey, newValue })
    } else {
      setValueStateRank(ValueState.Error)
    }
  }

  return (
    <Input
      id={id}
      value={value}
      valueState={valueStateRank}
      valueStateMessage={<span>{t('hint.betweenZeroAnd99')}</span>}
      onInput={(event) => {
        const newValue = event.target.value
        validateRankField(newValue)
        setInteractedWithRowState(rowKey, 'rank')
      }}
      onBlur={(event) => {
        const newValue = event.target.value
        validateRankField(newValue)
        setInteractedWithRowState(rowKey, 'rank')
      }}
    />
  )
}

RankInput.propTypes = {
  id: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
  setInteractedWithRowState: PropTypes.func.isRequired,
}
export default RankInput
