import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing creditRisk type ecode keys and their localized display name
 * The amortizationFrequencyTypeCodes structure is as follows:
 * {
 *   "amortizationFrequencyTypeCodes": [
 *       {
 *          "category": "AMORTIZATION_FREQUENCY_TYPE",
            "code": "Monthly",
            "short_text": "Monatlich",
            "additional_properties": {}
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { amortizationFrequencyTypeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const useAmortizationFrequencyTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/AMORTIZATION_FREQUENCY_TYPE`,
    translated: true,
  })

  const transformedData = {
    amortizationFrequencyTypeCodes: result?.data?.configuration?.AMORTIZATION_FREQUENCY_TYPE.map(
      (element) => ({
        key: element.code,
        display_name: element.short_text,
      }),
    ),
  }

  return {
    ...result,
    data: transformedData,
  }
}
