import { useRequest } from 'hooks/services/baseService'

/**
 * Returns all deal financing creditRisk type ecode keys and their localized display name
 * The creditRiskTypeCodes structure is as follows:
 * {
 *   "creditRiskTypeCodes": [
 *       {
 *           "key": "Junior",
 *           "display_name": "Junior"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { creditRiskTypeCodes: {display_name: string, key: string}[] }}}
 *
 */
export const useCreditRiskTypeCodes = () => {
  const result = useRequest({
    path: `/financing-structures/configurations/category/CREDIT_RISK_TYPE`,
    translated: true,
  })

  const transformedData = {
    creditRiskTypeCodes: result?.data?.configuration?.CREDIT_RISK_TYPE.map((element) => ({
      key: element.code,
      display_name: element.short_text,
    })),
  }

  return {
    ...result,
    data: transformedData,
  }
}
