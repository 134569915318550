import ActionsCell from 'components/ui/tables/cells/ActionsCell'
import BooleanCell from 'components/ui/tables/cells/BooleanCell'
import BpsCell from 'components/ui/tables/cells/BpsCell'
import ButtonCell from 'components/ui/tables/cells/ButtonCell'
import DateCell from 'components/ui/tables/cells/DateCell'
import EntityCell from 'components/ui/tables/cells/EntityCell'
import HighlightCell from 'components/ui/tables/cells/HighlightCell'
import MoneyCell from 'components/ui/tables/cells/MoneyCell'
import NavigationCell from 'components/ui/tables/cells/NavigationCell'
import NumberCell from 'components/ui/tables/cells/NumberCell'
import PercentageCell from 'components/ui/tables/cells/PercentageCell'
import PopoverCell from 'components/ui/tables/cells/PopoverCell'
import StatusCell from 'components/ui/tables/cells/StatusCell'
import TextCell from 'components/ui/tables/cells/TextCell'
import WatcherCell from 'components/ui/tables/cells/WatcherCell'

export default Object.freeze({
  money: MoneyCell,
  entity: EntityCell,
  percentage: PercentageCell,
  status: StatusCell,
  number: NumberCell,
  bps: BpsCell,
  highlight: HighlightCell,
  actions: ActionsCell,
  boolean: BooleanCell,
  button: ButtonCell,
  data: DateCell,
  navigation: NavigationCell,
  popover: PopoverCell,
  text: TextCell,
  watcher: WatcherCell,
})
