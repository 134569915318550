import PropTypes from 'prop-types'
import { useState } from 'react'
import DealDocumentsTable from 'components/domains/deals/documents/DealDocumentsTable'
import DocumentTypes from 'components/domains/documents/DocumentTypes'
import DocumentUploadDialog from 'components/domains/documents/DocumentUploadDialog'

const DealDocumentsTableCard = ({
  pageData: {
    documentRoot,
    isLoadingDocuments,
    isErrorDocuments,
    hasDocumentUploadPermission,
    deal,
  } = {},
}) => {
  const initialUploadedDocumentIds = []
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState(initialUploadedDocumentIds)

  const [files, setFiles] = useState(undefined)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)

  const handleOnDrop = ({ files: newFiles } = {}) => {
    setFiles(newFiles)
    setIsUploadDialogOpen(true)
  }

  return (
    <>
      <DocumentUploadDialog
        type={DocumentTypes.Deal}
        isOpen={isUploadDialogOpen}
        initialState={{
          open: undefined,
          files,
          defaultFilterCriteria: {
            property: { dealId: deal.dealUuid },
          },
          selectedEntities: [{ id: deal.dealUuid, name: deal.name, displayId: deal.displayId }],
        }}
        onUploadFinished={(newDocumentIds) => {
          setUploadedDocumentIds(newDocumentIds)
        }}
        onClose={() => {
          setIsUploadDialogOpen(false)
          setFiles(undefined)
        }}
      />
      <DealDocumentsTable
        documentRoot={documentRoot}
        isLoading={isLoadingDocuments}
        isError={isErrorDocuments}
        uploadedDocumentIds={uploadedDocumentIds}
        handleOnDrop={handleOnDrop}
        hasDocumentUploadPermission={hasDocumentUploadPermission}
      />
    </>
  )
}

DealDocumentsTableCard.propTypes = {
  pageData: PropTypes.shape({
    documentRoot: PropTypes.object.isRequired,
    isLoadingDocuments: PropTypes.bool.isRequired,
    isErrorDocuments: PropTypes.bool.isRequired,
    hasDocumentUploadPermission: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      dealUuid: PropTypes.string,
      name: PropTypes.string,
      displayId: PropTypes.string,
    }).isRequired,
  }),
}

export default DealDocumentsTableCard
