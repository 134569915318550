import { Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import EditCardItem from 'components/ui/card/EditCardItem'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import { useCustomizableCurrencyFormatter } from 'hooks/i18n/useI18n'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import styles from 'routes/deals/financing/shared-components/useStandardMoneyField.module.css'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const useRequiredHedgeAmountTotalFields = ({
  name,
  value,
  valueHeadquarter,
  label,
  trancheOwnShareCommitment,
  setRequiredHedgeAmountShare,
  setRequiredHedgeAmountTotalForRisksCalculation,
  validationFunction,
}) => {
  const formatMoney = useCustomizableCurrencyFormatter()

  const renderRequiredHedgeAmountTotalInput = ({ handleBulkOnChange, fieldValues, item }) => {
    const inputComponent = (
      <FormattedNumberInput
        id={`requiredHedgeAmountTotal-input`}
        icon={<Text className={styles.inputPaddingRight}>{value?.currency}</Text>}
        value={convertToNumberIfValid(value?.amount)}
        valueState={validationFunction(fieldValues[name]) ? ValueState.None : ValueState.Error}
        valueStateMessage={
          <span>
            {t(
              'pages.deals.tranches.details.interest-rate-hedging-requirement-card.error.non-negative-number',
            )}
          </span>
        }
        data-testid={`requiredHedgeAmountTotal-input`}
        onInput={(newValue) => {
          setRequiredHedgeAmountTotalForRisksCalculation(newValue)
          const newAmountShare = newValue ? newValue / trancheOwnShareCommitment : ''
          handleBulkOnChange({
            requiredHedgeAmountTotal: newValue,
            requiredHedgeAmountShare: newAmountShare,
          })
          setRequiredHedgeAmountShare(newAmountShare)
        }}
      />
    )
    return <EditCardItem key={item.label} editComponent={inputComponent} label={item.label} />
  }

  return [
    {
      name,
      label,
      value: formatMoney(value?.amount, value?.currency, {
        currencyDisplay: 'code',
      }),

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name,
      label,
      value: value?.amount,
      renderCustomEditComponent: renderRequiredHedgeAmountTotalInput,
      isShownInDisplay: false,
      isShownInEdit: true,
    },
    {
      name,
      label,
      customInfoComponent: ConvertedAmount(valueHeadquarter?.amount, valueHeadquarter?.currency),

      isShownInDisplay:
        !!valueHeadquarter && !!value.amount && value.currency !== valueHeadquarter?.currency,
      isShownInEdit: false,
    },
  ]
}

export default useRequiredHedgeAmountTotalFields
