import { Modals, TableRow, Text, ValueState } from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealBorrowerUnitRelationshipSelect from 'components/domains/deals/borrower-units/DealBorrowerUnitRelationshipSelect'
import DealBorrowerUnitSelect from 'components/domains/deals/borrower-units/DealBorrowerUnitSelect'
import styles from 'components/domains/deals/borrower-units/DealBorrowerUnitsEditRow.module.css'
import BusinessPartnerAutocompleteInput from 'components/domains/deals/creation-dialog/BusinessPartnerAutocompleteInput'
import useShowErrorMessageBox from 'components/domains/deals/message/useShowErrorMessageBox'
import SaveAndCancelButton from 'components/domains/deals/tables/SaveAndCancelButton'
import TableCell from 'components/ui/tables/cells/TableCell'
import useBusinessPartnerRelationshipCreate from 'hooks/services/business-partners/relationships/useBusinessPartnerRelationshipCreate'

const formatDealBorrower = ({ id, fullName } = {}) => {
  if (!fullName) {
    return ''
  }
  return id ? `${fullName} (${id})` : fullName
}

const dealBorrowerUnitsEditRowProps = {
  borrowerBpId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      roleIds: PropTypes.arrayOf(PropTypes.string),
      relationshipTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          modifiable: PropTypes.bool,
        }),
      ),
      heads: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          fullName: PropTypes.string,
          relationshipType: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  onClose: PropTypes.func.isRequired,
}

const DealBorrowerUnitsEditRow = ({ borrowerBpId, units, onClose = () => {} }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.borrower-units' })
  const showToast = Modals.useShowToast()
  const showErrorMessageBox = useShowErrorMessageBox()
  const queryClient = useQueryClient()
  const [formState, setFormState] = useState({})
  const [formValidation, setFormValidation] = useState({})
  const [unitHeadRoles, setUnitHeadRoles] = useState([])
  const getUnitById = (relationshipId) => units.find(({ id }) => id === relationshipId) ?? {}
  const selectedUnit = getUnitById(formState.relationshipId)

  const { mutate: createRelationship } = useBusinessPartnerRelationshipCreate({
    onSuccess: () => {
      onClose()
      showToast({ children: t('create.success') }, document.body)
      queryClient.invalidateQueries(['businesspartners', borrowerBpId, 'relationships'])
    },
    onError: async (error) => {
      const { errors: [errorResponse] = [] } = await error.response.json()
      showErrorMessageBox({ message: t('create.error'), error: errorResponse })
    },
  })
  const saveChanges = () => {
    const payload = {
      ...formState,
      isRelatedBusinessPartnerBp2: selectedUnit.isRelatedBusinessPartnerBp2,
    }
    createRelationship({ businessPartnerId: borrowerBpId, ...payload })
  }

  const updateFormValidation = (key, isValid) => {
    setFormValidation((prev) => ({ ...prev, [key]: isValid ? ValueState.None : ValueState.Error }))
  }

  const validateUnit = (relationshipId) => {
    updateFormValidation('unit', !!relationshipId)
  }

  const validateHead = (bpId, relationshipId, roles) => {
    const { roleIds = [] } = getUnitById(relationshipId)
    const isValid =
      bpId && (!roleIds.length || roles.some((backEndRole) => roleIds.includes(backEndRole.id)))
    updateFormValidation('head', isValid)
  }

  const validateRelationship = (relationshipId, relationshipType) => {
    const { relationshipTypes = [] } = getUnitById(relationshipId)
    const isValid = !relationshipTypes.length || !!relationshipType
    updateFormValidation('relationship', isValid)
  }

  const changeUnit = (relationshipId) => {
    const relationshipType = null
    updateFormValidation('unit', !!relationshipId)
    validateHead(formState.relatedBusinessPartnerId, relationshipId, unitHeadRoles)
    validateRelationship(relationshipId, relationshipType)
    setFormState((prevState) => ({ ...prevState, relationshipId, relationshipType }))
  }

  const changeUnitHead = (relatedBusinessPartnerId, { roles = [] }) => {
    const backEndRoles = roles.flatMap((role) => role.backEndRoles)
    validateHead(relatedBusinessPartnerId, formState.relationshipId, backEndRoles)
    validateUnit(formState.relationshipId)
    setUnitHeadRoles(backEndRoles)
    setFormState((prevState) => ({ ...prevState, relatedBusinessPartnerId }))
  }

  const changeRelationship = (relationshipType) => {
    validateRelationship(formState.relationshipId, relationshipType)
    setFormState((prevState) => ({ ...prevState, relationshipType }))
  }

  const isFormDirty = Object.keys(formState).length > 0
  const isFormValid = isFormDirty && !Object.values(formValidation).includes(ValueState.Error)

  return (
    <TableRow>
      <TableCell>
        <DealBorrowerUnitSelect
          className={styles.unitSelect}
          units={units}
          onChange={changeUnit}
          valueState={formValidation.unit}
          valueStateMessage={<Text wrapping>{t('unit.invalid')}</Text>}
        />
      </TableCell>
      <TableCell>
        <BusinessPartnerAutocompleteInput
          className={styles.unitHeadInput}
          onBusinessPartnerSelected={changeUnitHead}
          suggestionItemProps={({ name: fullName, id }) => ({
            text: formatDealBorrower({ id, fullName }),
          })}
          valueForUnknown={(text) => ({ name: text })}
          additionalSearchParams={{ roles: selectedUnit.roleIds ?? [] }}
          valueState={formValidation.head}
          valueStateMessage={<Text wrapping>{t('head.invalid')}</Text>}
        />
      </TableCell>
      <TableCell>
        <DealBorrowerUnitRelationshipSelect
          className={styles.relationshipInput}
          relationshipTypes={selectedUnit.relationshipTypes ?? []}
          onChange={changeRelationship}
          valueState={formValidation.relationship}
          valueStateMessage={<Text wrapping>{t('relationship.invalid')}</Text>}
        />
      </TableCell>
      <TableCell>
        <SaveAndCancelButton
          onSave={saveChanges}
          onCancel={onClose}
          saveDisabled={!isFormValid}
          showCancelPopover={isFormDirty}
        />
      </TableCell>
    </TableRow>
  )
}

DealBorrowerUnitsEditRow.propTypes = dealBorrowerUnitsEditRowProps

export default DealBorrowerUnitsEditRow
