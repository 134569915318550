import compact from 'lodash.compact'
import isNil from 'lodash.isnil'
import PropTypes from 'prop-types'
import styles from 'components/ui/tables/cells/HighlightCell.module.css'

const propTypes = {
  /**
   * The value state that changes the color of the cell.
   *
   * The state should be one of the UI5 ValueState enum values.
   */
  valueState: PropTypes.string,
  /** Defines additional styling to the outmost div of the cell's content. */
  className: PropTypes.string,
}

/**
 * Shared Highlight Cell for usage within the Responsive and Analytical Table.
 *
 * Display a narrow rectangle spanned across the whole height of the row,
 * usually used as the leftmost column to highlight a certain row.
 * Request states are omitted here, since no data is being shown.
 *
 * @typedef Props
 * @property {ValueState} statusState The value state that changes the color of the cell.
 * @property {String} className Defines additional styling to the outmost div of the cell's content.
 */

/**
 * @param {Props} props Note for Storybook: cf. code for complete JSDoc
 */
const HighlightCell = ({ valueState, className }) => {
  if (isNil(valueState)) {
    return <div />
  }
  return (
    <div className={compact([styles.highlightWrapper, className]).join(' ')}>
      <div
        className={compact([styles.highlightCell, styles[valueState.toLowerCase()]]).join(' ')}
      />
    </div>
  )
}

HighlightCell.propTypes = propTypes

export default HighlightCell
