import {
  AnalyticalTableScaleWidthMode,
  Icon,
  TextAlign,
  ValueState,
  VerticalAlign,
} from '@fioneer/ui5-webcomponents-react'
import find from 'lodash.find'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  FINANCING_BASIC_AGREEMENT_CREATE,
  FINANCING_TRANCHE_CREATE,
} from 'api/deals/financing/allowedOperationsConstants'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import TrancheCreate from 'components/domains/deals/financing/tranche-create/TrancheCreate'
import AnalyticalTableNoDataComponent from 'components/ui/tables/analytical/AnalyticalTableNoDataComponent'
import AnalyticalTableWithToolbar from 'components/ui/tables/analytical/AnalyticalTableWithToolbar'
import useFinancing from 'hooks/services/deals/financing/useFinancing'
import useDealMini from 'hooks/services/deals/useDealMini'
import { DealContext } from 'routes/deals/DealContext'
import { DATA_SOURCES, DEAL_STATUS_RANKS } from 'routes/deals/financing/financingConstants'
import TrancheWriteBasicAgreementButton from 'routes/deals/financing/tranchesOverview/ActionButtons/TranchesWriteBasicAgreementButton'
import styles from 'routes/deals/financing/tranchesOverview/TranchesOverviewTable.module.css'
import TranchesOverviewTableCell from 'routes/deals/financing/tranchesOverview/TranchesOverviewTableCell'
import { TABLE_HEADER_ROW_HEIGHT } from 'routes/deals/financing/tranchesOverview/constants'
import useExpandTranchesDataWithExistingBusinessInformation from 'routes/deals/financing/tranchesOverview/useExpandTranchesDataWithExistingBusinessInformation'
import mapTranchesData from 'routes/deals/financing/utils/mapTranchesData'
import paths from 'routes/paths'

const TranchesOverviewTable = ({ openedAtTimestamp }) => {
  const { deal, financingAllowedOperations: allowedOperations } = useContext(DealContext)
  const isWorkingVersion = useMemo(
    () => WorkingVersionType.WORKING_VERSION === deal?.workingVersion,
    [deal?.workingVersion],
  )

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const dataSourceParam = searchParams.get('dataSource') || DATA_SOURCES.NEW_BUSINESS
  const tableRef = useRef(null)

  const { data, isLoading, isError, isFetching } = useFinancing(deal.dealUuid)

  const { data: dealData = {} } = useDealMini(deal.dealUuid)

  const columnDefinitions = () =>
    [
      {
        Header: t('pages.deals.tranches-table.tranche-label'),
        accessor: 'tranche',
        minWidth: 270,
      },
      ...(dataSourceParam === DATA_SOURCES.NEW_BUSINESS
        ? [
            {
              Header: t('pages.deals.tranches-table.rank-label'),
              accessor: 'rank',
              hAlign: TextAlign.Right,
              width: 50,
            },
          ]
        : []),
      {
        Header: t('pages.deals.tranches-table.loan-type-label'),
        accessor: 'loanTypeCode',
        width: 110,
      },
      {
        Header: t('pages.deals.tranches-table.commitment-total-label'),
        accessor: 'totalCommitment',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: () => (
          <Trans>
            {`${t('pages.deals.tranches-table.commitment-label')} <br/>(${t(
              'pages.deals.tranches-table.own-share-label',
            )})`}
          </Trans>
        ),
        accessor: 'commitment',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: () => (
          <Trans>
            {`${t('pages.deals.tranches-table.outstanding-label')} <br/>(${t(
              'pages.deals.tranches-table.own-share-label',
            )})`}
          </Trans>
        ),
        accessor: 'outstanding',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: () => (
          <Trans>
            {`${t('pages.deals.tranches-table.available-label')} <br/>(${t(
              'pages.deals.tranches-table.own-share-label',
            )})`}
          </Trans>
        ),
        accessor: 'available',
        hAlign: TextAlign.Right,
        width: 180,
      },
      {
        Header: t('pages.deals.tranches-table.maturity-label'),
        accessor: 'maturity',
        hAlign: TextAlign.Right,
        width: 130,
      },
      {
        Header: t('pages.deals.tranches-table.repayment-label'),
        accessor: 'repayment',
        width: 100,
      },
      {
        Header: t('pages.deals.tranches-table.customer-margin-label'),
        accessor: 'customerMargin',
        width: 130,
        hAlign: TextAlign.Right,
      },
      {
        Header: t('pages.deals.tranches-table.interest-rate-type-label'),
        accessor: 'interestRateTypeCode',
        width: 130,
      },
      // "fake" entry to have an additional column to show the navigation arrow
      {
        Header: '',
        accessor: 'navigation',
        vAlign: VerticalAlign.Middle,
        hAlign: TextAlign.Right,
        disableSortBy: true,
        width: 20,
        disableResizing: true,
      },
    ].map((element) => ({
      disableDragAndDrop: true,
      disableResizing: false,
      Cell: TranchesOverviewTableCell,
      vAlign: VerticalAlign.Top,
      ...element,
    }))

  const [sortingParameters, setSortingParameters] = useState({
    columnKey: 'tranche.rank',
    sortDescending: false,
  })

  const sortingDefinitions = () => {
    const sortableColumns = [
      { title: t('pages.deals.tranches-table.rank-label'), columnKey: 'tranche.rank' },
      { title: t('pages.deals.tranches-table.tranche-id-label'), columnKey: 'tranche.displayId' },
      { title: t('pages.deals.tranches-table.tranche-name-label'), columnKey: 'tranche.name' },
      { title: t('pages.deals.tranches-table.loan-type-label'), columnKey: 'loanTypeCode' },
      {
        title: t('pages.deals.tranches-table.commitment-total-label'),
        columnKey: 'totalCommitment',
      },
      {
        title: `${t('pages.deals.tranches-table.commitment-label')} (${t(
          'pages.deals.tranches-table.own-share-label',
        )})`,
        columnKey: 'commitment',
      },
      {
        title: `${t('pages.deals.tranches-table.outstanding-label')} (${t(
          'pages.deals.tranches-table.own-share-label',
        )})`,
        columnKey: 'outstanding',
      },
      {
        title: `${t('pages.deals.tranches-table.available-label')} (${t(
          'pages.deals.tranches-table.own-share-label',
        )})`,
        columnKey: 'available',
      },
      { title: t('pages.deals.tranches-table.maturity-label'), columnKey: 'maturity' },
      { title: t('pages.deals.tranches-table.repayment-label'), columnKey: 'repayment' },
      { title: t('pages.deals.tranches-table.customer-margin-label'), columnKey: 'customerMargin' },
      {
        title: t('pages.deals.tranches-table.interest-rate-type-label'),
        columnKey: 'interestRateTypeCode',
      },
    ]
    return {
      columnKey: sortingParameters.columnKey,
      // as the summary row needs to be the last element in the data array, the native functionality is not used
      isSortingAscending: !sortingParameters.sortDescending,
      sortableColumns: sortableColumns.map(({ title, columnKey }) => ({ title, columnKey })),
      onUpdateSorting: (event) => {
        const { sortBy, sortDescending } = event
        // strangely event does not contain the columnKey of the selected element
        const { columnKey } = find(sortableColumns, ['title', sortBy])
        setSortingParameters({ columnKey, sortDescending })
      },
    }
  }

  const sortMoney = (tranches, columnKey) => {
    const getEuroValue = (element, columnKey) => {
      const obj = get(element, columnKey)
      return obj.converted?.value ? obj.converted.value : obj.original.value
    }
    tranches.sort((a, b) => (getEuroValue(a, columnKey) < getEuroValue(b, columnKey) ? 1 : -1))
  }

  const { data: expandedData, isFetching: isFetchingExistingBusinessSyndications } =
    useExpandTranchesDataWithExistingBusinessInformation({
      data,
      isExistingBusiness: dataSourceParam === DATA_SOURCES.EXISTING_BUSINESS,
      dealUuid: deal.dealUuid,
    })

  const tableData = useMemo(() => {
    const sortData = (tranches, { columnKey, sortDescending }) => {
      const copy = [...tranches]
      if (['totalCommitment', 'commitment', 'available', 'outstanding'].includes(columnKey))
        sortMoney(copy, columnKey)
      else copy.sort((a, b) => (get(a, columnKey) < get(b, columnKey) ? 1 : -1))
      return sortDescending ? copy : copy.reverse()
    }

    if (!isEmpty(expandedData)) {
      const mappedTranchesData = mapTranchesData(expandedData)
      if (mappedTranchesData.length > 1) {
        return [
          ...sortData(mappedTranchesData.slice(0, -1), sortingParameters),
          mappedTranchesData.at(-1),
        ]
      }
      return mappedTranchesData
    } else return []
  }, [expandedData, sortingParameters])

  const highlightNewTranche = (row) =>
    row.creationTimestamp && Date.parse(row.creationTimestamp) > openedAtTimestamp.valueOf()
      ? ValueState.Information
      : ValueState.None

  const onNavigateTranche = (displayId, trancheDisplayId) => {
    const updatedSearchParams = new URLSearchParams()
    updatedSearchParams.set('dataSource', dataSourceParam)
    updatedSearchParams.set('working-version', `${isWorkingVersion}`)

    navigate(
      `/${
        paths.deals
      }/${displayId}/financing/tranches/${trancheDisplayId}?${updatedSearchParams.toString()}`,
    )
  }

  const onNavigateDrawdown = (displayId, drawdownId) => {
    const updatedSearchParams = new URLSearchParams()
    updatedSearchParams.set('dataSource', dataSourceParam)
    updatedSearchParams.set('working-version', `${isWorkingVersion}`)

    navigate(
      `/${
        paths.deals
      }/${displayId}/financing/drawdowns/${drawdownId}?${updatedSearchParams.toString()}`,
    )
  }

  const [isCollapseIconDisabled, setIsCollapseIconDisabled] = useState(true)

  const handleOnRowExpand = (row) => {
    const tableState = tableRef?.current?.state
    const expandedRowCountBeforeStateUpdate =
      tableState?.expanded && Object.keys(tableState.expanded).length
    if (expandedRowCountBeforeStateUpdate > 1 || !row.isExpanded) {
      setIsCollapseIconDisabled(false)
    } else {
      setIsCollapseIconDisabled(true)
    }
  }

  const expandOrCollapseSubRows = (isExpanded) => {
    tableRef?.current?.toggleAllRowsExpanded(isExpanded)
    setIsCollapseIconDisabled(!isExpanded)
  }

  const renderAdditionalActions = useCallback(() => {
    const additionalTableActions = []

    allowedOperations.includes(FINANCING_TRANCHE_CREATE) &&
      dataSourceParam === DATA_SOURCES.NEW_BUSINESS &&
      additionalTableActions.push(<TrancheCreate key={'create-tranche-button'} />)

    allowedOperations.includes(FINANCING_BASIC_AGREEMENT_CREATE) &&
      dataSourceParam === DATA_SOURCES.NEW_BUSINESS &&
      dealData?.rank >= DEAL_STATUS_RANKS.CREDIT_APPROVED &&
      dealData?.rank <= DEAL_STATUS_RANKS.SIGNED &&
      additionalTableActions.push(
        <TrancheWriteBasicAgreementButton key={'write-basic-agreement-tranche-button'} />,
      )

    dataSourceParam === DATA_SOURCES.EXISTING_BUSINESS &&
      additionalTableActions.push(
        ...[
          <Icon
            className={styles.collapseIcon}
            key="collapse-subrows"
            name="collapse-all"
            interactive
            onClick={() => expandOrCollapseSubRows(false)}
            disabled={isCollapseIconDisabled}
          />,
          <Icon
            className={styles.expandIcon}
            key="expand-subrows"
            name="expand-all"
            interactive
            onClick={() => expandOrCollapseSubRows(true)}
          />,
        ],
      )

    return additionalTableActions
  }, [allowedOperations, dataSourceParam, dealData?.rank, isCollapseIconDisabled])

  return (
    <div className={styles.tableWrapper}>
      <AnalyticalTableWithToolbar
        loading={!isLoading && (isFetching || isFetchingExistingBusinessSyndications)}
        ref={tableRef}
        nrOfEntries={data?.tranches?.length ?? 0}
        title={t('pages.deals.tranches-table.header')}
        customSorting={sortingDefinitions()}
        additionalActions={renderAdditionalActions()}
        id="tranches-table"
        className={styles['tranches-table']}
        sortable={false}
        minRows={0}
        columns={columnDefinitions()}
        // high number of visible rows fixes re-rendering of height on expand
        visibleRows={99}
        overscanCountHorizontal={99}
        // rowHeight needs to be set to a non-empty string to not show an empty row at the end
        rowHeight={'individual'}
        headerRowHeight={TABLE_HEADER_ROW_HEIGHT}
        data={tableData}
        isTreeTable={dataSourceParam === DATA_SOURCES.EXISTING_BUSINESS}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Default}
        withRowHighlight={true}
        highlightField={highlightNewTranche}
        NoDataComponent={() => (
          <AnalyticalTableNoDataComponent
            isLoading={isLoading}
            isError={isError}
            tableId={'tranches-table'}
          />
        )}
        onRowClick={(e) => {
          const trancheDisplayId =
            dataSourceParam === DATA_SOURCES.NEW_BUSINESS
              ? e.detail.row.original.tranche?.displayId
              : e.detail.row.original.tranche?.externalContractId[0]
          const isDrawdown = e.detail.row.original.isDrawdown

          if (isDrawdown) {
            const drawdownDisplayId = e.detail.row.original.tranche?.externalContractId
            return onNavigateDrawdown(deal.displayId, drawdownDisplayId)
          }

          if (trancheDisplayId) {
            return onNavigateTranche(deal.displayId, trancheDisplayId)
          }
        }}
        onRowExpandChange={(event) => handleOnRowExpand(event.detail.row)}
      />
    </div>
  )
}

TranchesOverviewTable.propTypes = {
  openedAtTimestamp: PropTypes.number.isRequired,
}

export default TranchesOverviewTable
