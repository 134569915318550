import {
  Label,
  FlexBox,
  Text,
  FlexBoxDirection,
  FlexBoxAlignItems,
} from '@fioneer/ui5-webcomponents-react'
import isNil from 'lodash.isnil'
import { useTranslation } from 'react-i18next'
import { useCustomizableCurrencyFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'
import ConvertedAmount from 'routes/deals/financing/shared-components/ConvertedAmount'
import styles from 'routes/deals/financing/shared-components/ConvertedAmount.module.css'

const useCommitmentOwnShareFields = ({ ownShare, ownShareHeadquarter, ownCommitmentShare }) => {
  const formatMoney = useCustomizableCurrencyFormatter()
  const { t } = useTranslation('translation', { keyPrefix: 'pages.deals.tranches.details' })
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const ShareComponent = () => (
    <FlexBox
      fitContainer
      direction={FlexBoxDirection.Column}
      alignItems={FlexBoxAlignItems.End}
      className={styles.convertedAmountWrapper}
    >
      <FlexBox>
        <Label className={styles.marginRight}>{t('share')}</Label>
        <Text>{formatShare(ownCommitmentShare)}</Text>
      </FlexBox>
    </FlexBox>
  )

  return [
    {
      name: 'commitmentOwnShare',
      label: t('commitment-own-share'),
      value: formatMoney(ownShare?.commitment, ownShare?.currency, { currencyDisplay: 'code' }),

      isShownInDisplay: true,
      isShownInEdit: false,

      editComponentType: 'Input',
      editComponentProps: { type: 'Number', readonly: true },
    },

    {
      name: 'commitmentOwnShare',
      customInfoComponent: ConvertedAmount(
        ownShareHeadquarter?.commitment,
        ownShareHeadquarter?.currency,
      ),
      isShownInDisplay:
        !!ownShareHeadquarter &&
        !isNil(ownShare?.commitment) &&
        ownShare?.currency !== ownShareHeadquarter?.currency,
      isShownInEdit: false,
    },
    {
      name: 'commitmentOwnShare',
      customInfoComponent: ShareComponent(),
      isShownInDisplay: !isNil(ownCommitmentShare),
      isShownInEdit: false,
    },
  ]
}

export default useCommitmentOwnShareFields
