import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

export const useComplexityLevels = (options = {}) =>
  useCamelizedResponse(
    useRequest({
      path: `/deals/configurations/complexity-levels`,
      translated: true,
      useCache: true,
      keys: ['deals', 'configurations', 'complexity-levels'],
      ...options,
    }),
  )
