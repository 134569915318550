import { useTranslation } from 'react-i18next'
import { useAverageInterestRateCalculationTypeCodes } from 'hooks/services/deals/financing/configGetters/useAverageInterestRateCalculationTypeCodes'
import { useDateRollingConventionTypeCodes } from 'hooks/services/deals/financing/configGetters/useDateRollingConventionTypeCodes'
import useStandardDateField from 'routes/deals/financing/shared-components/useStandardDateField'
import useStandardDropdownField from 'routes/deals/financing/shared-components/useStandardDropdownField'
import useStandardInputField from 'routes/deals/financing/shared-components/useStandardInputField'
import useStandardShareField from 'routes/deals/financing/shared-components/useStandardShareField'

const useAdditionalInterestInformationFieldDefinitions = (data) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details.additional-interest-information-card',
  })

  const averageInterestRateCalculationFields = useStandardDropdownField({
    name: 'averageInterestRateCalculationTypeCode',
    label: t('average-interest-rate-calculation'),
    value: data?.averageInterestRateCalculationShortText,
    code: data?.averageInterestRateCalculationTypeCode,
    selectionName: 'typeCodes',
    selectedKey: data?.averageInterestRateCalculationTypeCode,
    loadingHook: useAverageInterestRateCalculationTypeCodes,
  })

  const dateRollingConventionFields = useStandardDropdownField({
    name: 'dateRollingConventionTypeCode',
    label: t('rolling-convention'),
    value: data?.dateRollingConventionShortText,
    code: data?.dateRollingConventionTypeCode,
    selectionName: 'typeCodes',
    selectedKey: data?.dateRollingConventionTypeCode,
    loadingHook: useDateRollingConventionTypeCodes,
  })

  const contractualFloorStrikeFields = useStandardShareField({
    name: 'contractualFloorStrike',
    label: t('contractual-floor-strike'),
    share: data?.contractualFloorStrike,
    validationFunction: () => true, // Overwrite default validation function and allow all values
  })

  const holidayCalendarField = useStandardInputField({
    name: 'holidayCalendar',
    label: t('holiday-calendar'),
    value: data?.holidayCalendar,
  })

  const lockOutPeriodField = useStandardInputField({
    name: 'lockOutPeriod',
    label: t('lock-out-period'),
    value: data?.lockOutPeriod,
  })

  const lookbackPeriodField = useStandardInputField({
    name: 'lookbackPeriod',
    label: t('lookback-period'),
    value: data?.lookbackPeriod,
  })

  const observationPeriodField = useStandardInputField({
    name: 'observationPeriod',
    label: t('observation-period'),
    value: data?.observationPeriod,
  })

  const endOfTheFixedInterestPeriodField = useStandardDateField({
    name: 'endOfTheFixedInterestPeriod',
    label: t('end-of-fixed-interest-period'),
    value: data?.endOfTheFixedInterestPeriod,
  })

  const deviatingInterestPaymentDateField = useStandardInputField({
    name: 'deviatingInterestPaymentDate',
    label: t('deviating-interest-payment-date'),
    value: data?.deviatingInterestPaymentDate,
  })

  const penaltyInterestField = useStandardInputField({
    name: 'penaltyInterest',
    label: t('penalty-interest'),
    value: data?.penaltyInterest,
  })

  return [
    ...endOfTheFixedInterestPeriodField,
    ...contractualFloorStrikeFields,
    ...holidayCalendarField,
    ...dateRollingConventionFields,
    ...deviatingInterestPaymentDateField,
    ...averageInterestRateCalculationFields,
    ...lockOutPeriodField,
    ...lookbackPeriodField,
    ...observationPeriodField,
    ...penaltyInterestField,
  ]
}

export default useAdditionalInterestInformationFieldDefinitions
