import { useMemo } from 'react'
import { useGetPageConfig } from 'hooks/page-config/useGetPageConfig'

/**
 * @typedef {Object} DynamicPageEntryData
 * @property {number} position
 * @property {string} code
 * @property {number} span
 * @property {string} if
 * @property {number} forEach
 * @property {object | array | number | string | boolean | undefined | null} dynamicData
 * @property {Record<string, unknown> | undefined} data
 * @property {DynamicPageEntryData[] | undefined} children
 *
 * @typedef {Object} DynamicPageConfigData
 * @property {string} name
 * @property {string} type
 * @property {string} defaultTab
 * @property {DynamicPageEntryData[] | undefined} children
 */

/**
 * @param {string} pageCode
 * @returns {{isLoading: boolean, page: DynamicPageConfigData | undefined}}
 */
export const usePageComponents = (pageCode) => {
  const pageConfig = useGetPageConfig({ code: pageCode })
  return useMemo(() => ({ page: pageConfig.data, isLoading: pageConfig.isLoading }), [pageConfig])
}
