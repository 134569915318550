import { FlexBox, FlexBoxAlignItems, Text } from '@fioneer/ui5-webcomponents-react'
import { CustomerOptionsDatePicker } from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsDatePicker'
import CustomerOptionsFloatInput from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsFloatInput'
import CustomerOptionsIntegerInput from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsIntegerInput'
import CustomerOptionsSelect from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsSelect'
import CustomerOptionsTextInput from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/edit-mode-cells/CustomerOptionsTextInput'
import styles from 'routes/deals/financing/trancheDetails/cards/customer-options-card/cell-renderers/renderDisplayModeCell.module.css'
import {
  CUSTOMER_OPTION_ROW_TYPES,
  CUSTOMER_OPTIONS_COLUMN_KEYS,
  OPTION_ATTRIBUTE_DATA_TYPES,
} from 'routes/deals/financing/trancheDetails/cards/customer-options-card/customerOptionsConstants'
import { convertToNumberIfValid } from 'routes/deals/financing/utils/convertToNumberIfValid'

const renderEditModeCell = (props) => {
  const { cell, row, updateOptionAttributeState } = props

  const columnKey = cell.column.id

  const renderClusterOptionAttributeCell = () => {
    switch (row.original.rowType) {
      case CUSTOMER_OPTION_ROW_TYPES.CLUSTER: {
        return <Text className={styles.textBold}>{cell.value}</Text>
      }
      case CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE: {
        if (row.original.type === OPTION_ATTRIBUTE_DATA_TYPES.TEXT) {
          return (
            <FlexBox alignItems={FlexBoxAlignItems.Start} className={styles.textCell}>
              <Text>{cell.value}</Text>
            </FlexBox>
          )
        }
        return <Text>{cell.value}</Text>
      }
      default:
        return <Text>{cell.value}</Text>
    }
  }

  const renderValueCell = () => {
    if (row.original.rowType === CUSTOMER_OPTION_ROW_TYPES.OPTION_ATTRIBUTE) {
      switch (row.original.type) {
        case OPTION_ATTRIBUTE_DATA_TYPES.DATE: {
          return (
            <CustomerOptionsDatePicker
              valueObj={{ value: cell.value }}
              handleOnChange={(newValue) => {
                updateOptionAttributeState(row.original.optionId, row.original.id, newValue)
              }}
            />
          )
        }
        case OPTION_ATTRIBUTE_DATA_TYPES.FLOAT: {
          return (
            <CustomerOptionsFloatInput
              value={convertToNumberIfValid(cell.value)}
              handleOnChange={(newValue) => {
                updateOptionAttributeState(row.original.optionId, row.original.id, newValue)
              }}
            />
          )
        }
        case OPTION_ATTRIBUTE_DATA_TYPES.INT: {
          return (
            <CustomerOptionsIntegerInput
              value={cell.value}
              handleOnChange={(newValue) => {
                updateOptionAttributeState(row.original.optionId, row.original.id, newValue)
              }}
            />
          )
        }
        case OPTION_ATTRIBUTE_DATA_TYPES.ENUM: {
          return (
            <CustomerOptionsSelect
              selectedKey={cell.value}
              options={row.original.range}
              handleOnChange={(newValue) => {
                updateOptionAttributeState(row.original.optionId, row.original.id, newValue)
              }}
            />
          )
        }
        case OPTION_ATTRIBUTE_DATA_TYPES.TEXT: {
          return (
            <CustomerOptionsTextInput
              value={cell.value}
              handleOnChange={(newValue) => {
                updateOptionAttributeState(row.original.optionId, row.original.id, newValue)
              }}
            />
          )
        }
      }
    }

    return <Text>{cell.value}</Text>
  }

  switch (columnKey) {
    case CUSTOMER_OPTIONS_COLUMN_KEYS.CLUSTER_OPTION_ATTRIBUTE:
      return renderClusterOptionAttributeCell()

    case CUSTOMER_OPTIONS_COLUMN_KEYS.VALUE:
      return renderValueCell()
  }
}

export default renderEditModeCell
