import { useEffect, useState } from 'react'
import Card from 'components/ui/card/Card'
import TranchesOverviewTable from 'routes/deals/financing/tranchesOverview/TranchesOverviewTable'

const TranchesOverviewCard = () => {
  const [openedAtTimestamp, setOpenedAtTimestamp] = useState(Date.now())

  // Save the timestamp of opening this page once after initial rendering
  useEffect(() => {
    setOpenedAtTimestamp(Date.now())
  }, [])

  return (
    <Card>
      <TranchesOverviewTable openedAtTimestamp={openedAtTimestamp} />
    </Card>
  )
}

export default TranchesOverviewCard
