import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/deals/borrower-units/DealBorrowerUnitsCard.module.css'
import CloseableMessageStrip from 'components/ui/feedback/CloseableMessageStrip'

const dealBorrowerUnitsMessageStripesProps = {
  isReadOnlyForDealAdjustment: PropTypes.bool.isRequired,
  isWorkingVersion: PropTypes.bool.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      heads: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
      name: PropTypes.string,
    }),
  ),
}

const DealBorrowerUnitsMessageStripes = ({
  units: [firstUnit = {}, secondUnit = {}] = [],
  isReadOnlyForDealAdjustment,
  isWorkingVersion,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.borrower-units.info' })

  // Message Strip: Review unit or an Preliminary GCC can be added here
  const hasOneUnit = firstUnit.heads?.length > 0 || secondUnit.heads?.length > 0
  const hasBothUnits = firstUnit.heads?.length > 0 && secondUnit.heads?.length > 0
  const hasNoUnit = !hasOneUnit && !hasBothUnits
  const messageStripReview = () => {
    if (hasOneUnit) {
      return (
        <CloseableMessageStrip>
          {t('one-unit', { unit: !firstUnit.heads.length ? firstUnit?.name : secondUnit?.name })}
        </CloseableMessageStrip>
      )
    } else if (hasNoUnit) {
      return (
        <CloseableMessageStrip>
          {t('no-unit', { unit_1: firstUnit.name, unit_2: secondUnit.name })}
        </CloseableMessageStrip>
      )
    }
    return <></>
  }

  //Message Strip: Readonly for DealAdjustment & Disclaimer for Deadladjustment
  const messageStripDealAdjustment = () => {
    if (isWorkingVersion && isReadOnlyForDealAdjustment) {
      return <CloseableMessageStrip>{t('deal-adjustment-edit-readonly')}</CloseableMessageStrip>
    }
    if (isWorkingVersion && !isReadOnlyForDealAdjustment) {
      return <CloseableMessageStrip>{t('deal-adjustment-edit-disclaimer')}</CloseableMessageStrip>
    }
    return <></>
  }

  return (
    <div className={styles.container}>
      {messageStripReview()}
      {messageStripDealAdjustment()}
    </div>
  )
}

DealBorrowerUnitsMessageStripes.propTypes = dealBorrowerUnitsMessageStripesProps

export default DealBorrowerUnitsMessageStripes
