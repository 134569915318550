import { Panel, Text } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useCustomizableCurrencyFormatter, useNumberFormatter } from 'hooks/i18n/useI18n'
import TranchePricingListItem from 'routes/deals/financing/trancheDetails/cards/pricing-card/TranchePricingListItem'
import styles from 'routes/deals/financing/trancheDetails/cards/pricing-card/panels/TranchePricingGeneralInformationPanel.module.css'

const TranchePricingGeneralInformationPanel = ({ pricingData }) => {
  const formatShare = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  })

  const formatMoney = useCustomizableCurrencyFormatter()

  const renderMoney = (moneyObject) =>
    formatMoney(moneyObject?.amount, moneyObject?.currency, {
      currencyDisplay: 'code',
    })

  const tranchePricingListItems = [
    {
      label: t('pages.deals.tranches.details.pricing-card.customerMarginAverage'),
      value: formatShare(pricingData.customerMarginAverage),
      highlight: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.grossInterestMargin3mEuribor'),
      value: formatShare(pricingData.grossInterestMargin3mEuribor),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.grossInterestMarginOn'),
      value: formatShare(pricingData.grossInterestMarginOn),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.grossRevenueMargin3mEuribor'),
      value: formatShare(pricingData.grossRevenueMargin3mEuribor),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.grossRevenueMarginOn'),
      value: formatShare(pricingData.grossRevenueMarginOn),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.fundingSpreadDrawdown3mRef'),
      value: formatShare(pricingData.fundingSpreadDrawdown3mRef),
      highlight: true,
      isSectionTitle: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.fundingSpreadDrawdownOn'),
      value: formatShare(pricingData.fundingSpreadDrawdownOn),
      highlight: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.netRevenueMargin'),
      value: formatShare(pricingData.netRevenueMargin),
      highlight: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.full-cost'),
      highlight: true,
      isSectionTitle: true,
      labelOnly: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitMarginFullCost'),
      value: formatShare(pricingData.economicProfitMarginFullCost),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitAmountFullCost'),
      value: renderMoney(pricingData.economicProfitAmountFullCost),
    },
    {
      value: renderMoney(pricingData.economicProfitAmountFullCostHeadquarter),
      isHidden:
        pricingData.economicProfitAmountFullCostHeadquarter?.currency ===
        pricingData.economicProfitAmountFullCost?.currency,
      isConvertedAmount: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.roeCapFullCost'),
      value: formatShare(pricingData.roeCapFullCost),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.direct-cost'),
      highlight: true,
      isSectionTitle: true,
      labelOnly: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitMarginDirectCost'),
      value: formatShare(pricingData.economicProfitMarginDirectCost),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitAmountDirectCost'),
      value: renderMoney(pricingData.economicProfitAmountDirectCost),
    },
    {
      value: renderMoney(pricingData.economicProfitAmountDirectCostHeadquarter),
      isHidden:
        pricingData.economicProfitAmountDirectCostHeadquarter?.currency ===
        pricingData.economicProfitAmountDirectCost?.currency,
      isConvertedAmount: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.lower-cost'),
      highlight: true,
      isSectionTitle: true,
      labelOnly: true,
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitMarginLowerCost'),
      value: formatShare(pricingData.economicProfitMarginLowerCost),
    },
    {
      label: t('pages.deals.tranches.details.pricing-card.economicProfitAmountLowerCost'),
      value: renderMoney(pricingData.economicProfitAmountLowerCost),
    },
    {
      value: renderMoney(pricingData.economicProfitAmountLowerCostHeadquarter),
      isHidden:
        pricingData.economicProfitAmountLowerCostHeadquarter?.currency ===
        pricingData.economicProfitAmountLowerCost?.currency,
      isConvertedAmount: true,
    },
  ]

  return (
    <Panel
      header={
        <Text>{t('pages.deals.tranches.details.pricing-card.general-info-panel-title')}</Text>
      }
    >
      <div className={styles.panelContent}>
        {tranchePricingListItems.map(
          (item, index) =>
            !item.isHidden && (
              <TranchePricingListItem key={`${index}-pricing-info-panel`} {...item} />
            ),
        )}
      </div>
    </Panel>
  )
}

TranchePricingGeneralInformationPanel.propTypes = {
  pricingData: PropTypes.shape({
    customerMarginAverage: PropTypes.number,
    grossInterestMargin3mEuribor: PropTypes.number,
    grossInterestMarginOn: PropTypes.number,
    grossRevenueMargin3mEuribor: PropTypes.number,
    grossRevenueMarginOn: PropTypes.number,
    fundingSpreadDrawdown3mRef: PropTypes.number,
    fundingSpreadDrawdownOn: PropTypes.number,
    netRevenueMargin: PropTypes.number,
    economicProfitMarginFullCost: PropTypes.number,
    economicProfitAmountFullCost: PropTypes.object,
    economicProfitAmountFullCostHeadquarter: PropTypes.object,
    roeCapFullCost: PropTypes.number,
    economicProfitMarginDirectCost: PropTypes.number,
    economicProfitAmountDirectCost: PropTypes.object,
    economicProfitAmountDirectCostHeadquarter: PropTypes.object,
    economicProfitMarginLowerCost: PropTypes.number,
    economicProfitAmountLowerCost: PropTypes.object,
    economicProfitAmountLowerCostHeadquarter: PropTypes.object,
  }),
}

export default TranchePricingGeneralInformationPanel
