import { FlexBox, FlexBoxDirection, Label } from '@fioneer/ui5-webcomponents-react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormattedNumberInput from 'components/ui/input/FormattedNumberInput'
import errorToUI5 from 'components/ui/input/errorToUI5'

const PROPERTY_KEY = 'ownShare'

const OwnShareInput = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.deals.tranche-create.basic-information',
  })

  const { register, formState, unregister, watch } = useFormContext()
  const error = formState.errors[PROPERTY_KEY]

  const { valueState, valueStateMessage } = errorToUI5(error)

  const fields = register(PROPERTY_KEY, {
    required: true,
    setValueAs: (val) => (val ? parseFloat(val) : undefined),
    deps: ['totalCommitment'],
  })

  useEffect(() => () => unregister(PROPERTY_KEY), [unregister])

  const handleChange = async (newValue) => {
    await fields.onChange({
      target: { value: String(newValue), name: PROPERTY_KEY },
      type: 'change',
    })
  }

  const handleBlur = async (newValue) => {
    await fields.onBlur({ target: { value: String(newValue), name: PROPERTY_KEY }, type: 'blur' })
  }

  return (
    <FlexBox direction={FlexBoxDirection.Column}>
      <Label showColon required for="tranche-own-share-input">
        {t('tranche-own-share')}
      </Label>
      <FormattedNumberInput
        id="tranche-own-share-input"
        {...fields}
        // HINT: The value isn't formatted when keeping the component entirely uncontrolled and the total commitment is copied over
        value={watch(PROPERTY_KEY)}
        onChange={handleChange}
        onBlur={handleBlur}
        valueState={valueState}
        valueStateMessage={valueStateMessage}
      />
    </FlexBox>
  )
}

export default OwnShareInput
