import isNil from 'lodash.isnil'
import {
  ROW_TYPE_SUMMARY,
  TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT,
  TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
} from 'routes/deals/financing/tranchesOverview/constants'

const mapSummaryData = ({ summary, summaryHeadquarter }) => {
  let primary
  let secondary

  // We need to explicitly check the currency attribute, because we expand the summary objects for EB with syndication data.
  // Therefore, an otherwise completely null summary object will be expanded with syndication amounts,
  // while the currency will be undefined, rendering as the "original" currency row.
  if (!isNil(summary) && isNil(summaryHeadquarter?.currency)) {
    primary = summary
  } else if (isNil(summary?.currency) && !isNil(summaryHeadquarter)) {
    primary = summaryHeadquarter
  } else {
    primary = summary
    secondary = summaryHeadquarter
  }

  return {
    totalCommitment: {
      primary: {
        value: primary.totalCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.totalCommitment,
        currency: secondary?.currency,
      },
    },
    commitment: {
      primary: {
        value: primary.ownCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.ownCommitment,
        currency: secondary?.currency,
      },
      share: primary.ownCommitmentShare,
    },
    syndicatedAmountTotal: {
      primary: {
        value: primary.totalCommitment - primary.ownCommitment,
        currency: primary.currency,
      },
      secondary: {
        value: secondary ? secondary?.totalCommitment - secondary?.ownCommitment : undefined,
        currency: secondary?.currency,
      },
      share: 1 - primary.ownCommitmentShare,
    },
    outstanding: {
      primary: {
        value: primary.outstanding,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.outstanding,
        currency: secondary?.currency,
      },
    },
    available: {
      primary: {
        value: primary.available,
        currency: primary.currency,
      },
      secondary: {
        value: secondary?.available,
        currency: secondary?.currency,
      },
    },
    rowType: ROW_TYPE_SUMMARY,
    rowHeight:
      primary && secondary && primary?.currency !== secondary?.currency
        ? TABLE_ROW_WITH_HEADQUARTER_CURRENCY_HEIGHT
        : TABLE_ROW_WITHOUT_HEADQUARTER_CURRENCY_HEIGHT,
  }
}

export default mapSummaryData
