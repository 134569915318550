import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RankEditInput from 'routes/deals/financing/trancheDetails/cards/trancheGeneralInformationCard/fields/rank/RankEditInput'

const useRankFields = ({ rank }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.deals.tranches.details',
  })

  return [
    {
      label: t('rank'),
      name: 'rank',
      value: rank,

      isShownInDisplay: true,
      isShownInEdit: false,
    },
    {
      name: 'rank',
      value: rank,

      isShownInDisplay: false,
      isShownInEdit: true,

      renderCustomEditComponent: ({ handleOnChange, fieldValues }) => (
        <RankEditInput
          label={t('rank')}
          name="rank"
          handleOnChange={handleOnChange}
          fieldValues={fieldValues}
        />
      ),
    },
  ]
}

useRankFields.defaultProps = {
  rank: PropTypes.number,
}

export default useRankFields
