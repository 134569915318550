import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
} from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import CWPCKEditor from 'components/ui/rich-text-editor/CWPCKEditor'
import {
  MAX_COLLAPSED_COMMENT_LENGTH,
  MAX_COMMENT_LENGTH,
} from 'routes/deals/financing/financingConstants'
import styles from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-comment-section/MultiSelectCardCommentSection.module.css'
import TextShowMoreWrapper from 'routes/deals/financing/trancheDetails/cards/multi-select-card/multi-select-card-comment-section/TextShowMoreWrapper'

const MultiSelectCardCommentSection = ({ editComment, setEditComment, inEditMode }) => {
  const renderDisplayView = () => (
    <TextShowMoreWrapper
      text={editComment}
      characterLimit={MAX_COLLAPSED_COMMENT_LENGTH}
      shouldFormatHtml={true}
    />
  )

  const renderEditView = () => (
    <CWPCKEditor
      setCurrentContent={setEditComment}
      currentContent={editComment}
      maxCharacterAmount={MAX_COMMENT_LENGTH}
    />
  )

  return (
    <FlexBox
      fitContainer
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.displayCardItem}
      direction={FlexBoxDirection.Column}
    >
      <Label className={styles.title}>{t('pages.deals.detail.comment')}</Label>
      <div className={styles.commentBox}>{inEditMode ? renderEditView() : renderDisplayView()}</div>
    </FlexBox>
  )
}

MultiSelectCardCommentSection.propTypes = {
  editComment: PropTypes.string.isRequired,
  setEditComment: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
}

export default MultiSelectCardCommentSection
