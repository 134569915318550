import isNil from 'lodash.isnil'

const decimalFractionDigits = 4
const percentageFractionDigits = 2

export const convertPercentageToDecimal = (value, fractionDigits = decimalFractionDigits) =>
  // Percentages need to be divided by 100 to have a number (usually) between 0 and 1.
  // To avoid floating point errors, we limit the fraction digits to a fixed number that defaults to 4
  !isNil(value) ? Number(value / 100).toFixed(fractionDigits) : undefined

export const convertDecimalToPercentage = (value, fractionDigits = percentageFractionDigits) => {
  if (value === '' || isNil(value)) {
    return value
  }
  return Number(value * 100).toFixed(fractionDigits)
}
