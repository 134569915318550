import { useMutation } from '@tanstack/react-query'
import { useAccessTokenRequest } from 'api/useAccessTokenRequest'

const useDeleteOtherFinancingSource = ({ onSuccess, onError }) => {
  const { delete: deleteCall } = useAccessTokenRequest()

  return useMutation(
    async ({ dealId, itemId }) =>
      await deleteCall({
        path: `/financing-structures/deals/${dealId}/other-financing-sources/items/${itemId}`,
      }),
    { onSuccess, onError },
  )
}

export default useDeleteOtherFinancingSource
