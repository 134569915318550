import { useTypeCodes } from 'hooks/services/deals/financing/configGetters/useTypeCodes'
/**
 * Returns all interest rate hedge type code keys and their localized display name
 * The interestRateHedgeTypeCodes structure is as follows:
 * {
 *   "interestRateHedgeTypeCodes": [
 *       {
 *           "key": "1",
 *           "display_name": "Swap"
 *       },
 *       [...]
 *   ]
 * }
 * @returns {{isLoading: boolean, isError: boolean, data: { typeCodes: {display_name: string, key: string}[] }}}
 *
 */

export const useInterestRateHedgeTypeCodes = () => useTypeCodes('INTEREST_RATE_HEDGE_TYPE')
