import { isMissingPermissionError, isNotFoundError } from 'api/requests'
import { useRequest } from 'hooks/services/baseService'
import { useCamelizedResponse } from 'hooks/services/queryHelper'

const REQUEST_RETRIES = 3

const useTrancheInterestRateHedgingRequirements = (dealId, trancheId) =>
  useCamelizedResponse(
    useRequest({
      path: `/financing-structures/deals/${dealId}/tranches/${trancheId}/interest-rate-hedging-requirements`,
      translated: true,
      keys: ['deals', dealId, 'tranches', trancheId, 'interestRateHedgingRequirements'],
      options: {
        enabled: !!trancheId,
        retry: (failureCount, error) => {
          if (isNotFoundError(error) || isMissingPermissionError(error)) {
            return false
          }
          if (failureCount < REQUEST_RETRIES) {
            return true
          }
          return false
        },
      },
    }),
  )

export default useTrancheInterestRateHedgingRequirements
